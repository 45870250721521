$logo-bar-item-height: 118px;
$logo-bar-item-height-sm: 194px;

.logoBar {
	&__slidesHolder {
		position: relative;
		height: calc($logo-bar-item-height + 20px);

		@include md {
			height: calc($logo-bar-item-height-sm + 20px);
		}
		.wrapper-white & {
			overflow: hidden;
		}
	}

	&__origin {
		height: $logo-bar-item-height;
		transform: translate3d(50%, 10px, 0);

		@include md {
			height: $logo-bar-item-height-sm;
		}
	}

	&__slides {
		height: $logo-bar-item-height;
		position: absolute;
		left: 0;
		display: flex;
		justify-content: space-between;
		visibility: hidden;

		@include md {
			height: $logo-bar-item-height-sm;
		}

		&.is-visible {
			visibility: visible;
		}
	}

	&__slide {
		width: 177px;
		height: $logo-bar-item-height;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		box-shadow: $card-shadow;

		@include md {
			width: 291px;
			height: $logo-bar-item-height-sm;
		}

		^&[data-slides-count='4']:not([data-slides-fit='false']) & {
			&:nth-child(n + 5) {
				display: none;
			}
		}

		^&[data-slides-count='3']:not([data-slides-fit='false']) & {
			&:nth-child(n + 4) {
				display: none;
			}
		}

		^&[data-slides-count='2']:not([data-slides-fit='false']) & {
			&:nth-child(n + 3) {
				display: none;
			}
		}

		^&[data-slides-count='1']:not([data-slides-fit='false']) & {
			&:nth-child(n + 2) {
				display: none;
			}
		}
	}

	&__link {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		user-drag: none;
		-webkit-user-drag: none;
		display: block;
		size: 100%;
		background: none;

		&:hover {
			background: none;
		}
	}

	&__header {
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: 30px;

		@include md {
			margin-bottom: 20px;
		}
	}

	&__headline,
	&__tagline,
	&__copy {
		text-align: center;
	}

	&__headline {
		@include font('headline', 28px, 33px, normal);
		color: $darkblue;

		@include md {
			font-size: 34px;
			line-height: 38px;
		}
	}

	&__tagline {
		@include font('headline', 13px, 15px, 3px);
		color: #666;
		text-transform: uppercase;
		margin-bottom: 20px;

		@include md {
			font-size: 16px;
			line-height: 18px;
			letter-spacing: 4.3px;
		}
	}

	&__copy {
		@include font('regular', 18px, 26px, normal);
		margin-bottom: 26px;
		color: #666;

		@include md {
			font-size: 21px;
			margin-bottom: 70px;
		}
	}

	&__imageHolder {
		margin-bottom: 30px;
	}

	&__image {
		pointer-events: none;
		object-fit: cover;
		size: 100%;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		user-drag: none;
		-webkit-user-drag: none;
	}

	&__controlsHolder {
		position: relative;
		margin-top: 30px;
		visibility: hidden;
		height: 25px;

		@include md {
			margin-top: 45px;
		}

		&.is-visible {
			visibility: visible;
		}
	}

	&__dotsHolder {
		position: absolute;
		left: 0;
		top: 0;
		size: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__dots {
		position: relative;
		z-index: 1;
		line-height: 0;
	}

	&__dot {
		display: inline-block;
		font-size: 0;
		line-height: 0;
		&:not(:last-child) {
			margin-right: 10px;
		}
	}

	&__dot-button {
		size: 10px;
		background-color: $darkblue;
		border: none;
		border-radius: 100%;
		transition: background-color ease-in 0.1s;
		&.is-active {
			background-color: $orange;
		}
		&:hover {
			background-color: $orange;
		}
	}

	&__arrowsHolder {
		position: absolute;
		left: 0;
		top: 0;
		size: 100%;
		display: none;
		justify-content: space-between;
		align-items: center;

		@include md {
			display: flex;
		}
	}

	&__navig-button {
		font-size: 0;
		transition: transform 150ms ease;
		background-color: transparent;
		border: 0;
		color: $darkblue;

		&:last-child:before {
			right: 0;
		}

		&:before {
			font-size: 22px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin-right: 0 !important;
			transition: transform 250ms ease;
		}

		&:hover:before {
			transform: translateY(-50%) scale(1.25);
		}
	}
}
