.form {
	// margin
	&:not(:last-child) {
		margin-bottom: 30px;
	}

	&--padded {
		@include sm {
			padding: 0 $ce-side-padding;
		}
	}

	&__row {
		position: relative;

		&:not(:last-child) {
			margin-bottom: 30px;
		}

		&--small-mobile-margin {
			@include <sm {
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}

		&:not(:first-child) {
			[data-step-type='summary'] & {
				padding-top: 30px;
				border-top: 1px solid $grey-3;
			}
		}

		&--flex {
			display: flex;
			align-items: center;
		}

		&--action-bar {
			border-top: 1px solid #d4d4d4;
			margin-top: 40px;
			padding-top: 30px;
			display: flex;
			justify-content: space-between;
			flex-direction: column;

			@include sm {
				flex-direction: row;
				align-items: center;
			}

			& + & {
				margin-top: 0;
			}
		}

		&--action-bar-text {
			display: flex;
			align-items: center;
			flex: 1;
			p {
				@include font('', 18px, 22px, normal);
				@include md {
					@include font('', 21px, 26px, normal);
				}
			}
		}

		&--action-bar-info {
			margin-top: 112px;
		}

		&--action-bar-void {
			> .acv-void {
				width: calc(100% + 30px);
			}
		}

		&--with-separator {
			margin-top: 50px;
			&:before {
				content: '';
				display: block;
				height: 1px;
				width: calc(100% - $gutter);
				margin-left: calc($gutter / 2);
				background-color: $grey-3;
				margin-bottom: 30px;
			}
		}

		&--reverse-order-mobile {
			@include <sm {
				flex-direction: column-reverse;
			}
		}

		&--tiny-margin {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		&--tiny-margin {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		&--big-margin {
			&:not(:last-child) {
				margin-bottom: 64px;
			}
		}

		&--error {
			color: $signal-red;
			@include font('', 15px, 20px, normal);
			margin-top: -9px;

			a:not([class]) {
				text-decoration: underline;
				&:hover {
					color: inherit;
					text-decoration: none;
					background-image: none;
				}
			}
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;

		.btn:not(.btn--inline-block) + .btn:not(.btn--inline-block) {
			margin-top: 0;
		}
	}

	// .form__input
	&__input {
		&[class*='flatpickr-input'] {
			background-image: svg-load('calendar.svg', fill: $typo-color);
			background-repeat: no-repeat;
			background-position: right 15px center;
			background-size: auto 20px;
			padding-right: 50px;
			padding-top: 16px;
			padding-bottom: 16px;

			&[disabled]:not(.keep-normal),
			.is-disabled:not(.keep-normal) > & {
				background-image: svg-load('calendar.svg', fill: $grey-3);
			}
		}
		&--with-button {
			padding-right: 60px !important;
		}
	}

	&__input-remove-safari-autocomplete {
		&::-webkit-contacts-auto-fill-button,
		&::-webkit-credentials-auto-fill-button {
			visibility: hidden;
			pointer-events: none;
			position: absolute;
			right: 0;
		}
	}

	// .form__button
	&__button {
		width: 100%;

		@include sm {
			width: auto;
		}
	}

	// .form__checkbox
	&__checkbox {
		display: table-cell;
		vertical-align: middle;

		&:not(:last-child) {
			margin-right: 15px;
			margin-bottom: 0;
		}

		&--small {
			size: 24px !important;
		}

		.form__input-group--outlined-checkbox &:before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			border: 1px solid #9b9b9b;
			border-radius: 8px;
		}

		.form__input-group--outlined-checkbox:hover &:before,
		.form__input-group--outlined-checkbox &:checked:before {
			border-color: $orange;
		}
	}

	&__radio {
		&--small {
			size: 20px !important;
		}
	}

	&__radio-label-group {
		display: flex;
		align-items: center;
		gap: 0 10px;

		& + & {
			margin-top: 20px;
		}

		input {
			flex: 0 0 auto;
			margin-bottom: 0;
		}
	}

	input[type='checkbox']&__switch {
		position: relative;
		border-radius: 100px;
		height: 32px;
		width: 60px;
		background: none;
		background-color: rgba($anthrazit, 0.25);
		border: none;
		transition: background-color 300ms easeInOutSine;
		-webkit-tap-highlight-color: transparent;

		&--white {
			background-color: rgba($white, 0.25);
		}

		&:checked {
			background-color: $white;
		}

		&:after {
			content: '';
			position: absolute;
			top: -8px;
			left: -8px;
			size: 48px;
			opacity: 0.2;
			border-radius: 50%;
			background-color: white;
			transition: left 300ms easeInOutSine, transform 300ms easeInOutSine, visibility 300ms easeInOutSine;
			transform: scale(0.7);
			visibility: hidden;
		}

		&:hover:after {
			transform: scale(1);
			visibility: visible;
		}

		&:checked:after {
			left: calc(100% - 40px);
		}

		&:before {
			content: '';
			position: absolute;
			top: 2px;
			left: 2px;
			size: 28px;
			border-radius: 50%;
			background-color: $anthrazit;
			transition: left 300ms easeInOutSine, background-color 300ms easeInOutSine;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.22);
			z-index: 1;
		}

		&--white {
			&:before {
				background-color: $white;
			}
		}

		&:checked:before {
			background-color: $signal-green;
			left: calc(100% - 30px);
		}

		&--small {
			height: 20px;
			width: 37px;

			&:before {
				size: 16px;
			}

			&:checked:before {
				background-color: $signal-green;
				left: calc(100% - 19px);
			}

			&:after {
				size: 28px;
				top: -4px;
				left: -4px;
			}

			&:checked:after {
				left: calc(100% - 25px);
			}
		}
	}

	// .form__errors
	&__errors {
	}

	// .form__copy
	&__copy {
		@include font('regular', 15px, 20px, 0);
		color: $grey-1;

		&--bold {
			@include font('bold', 15px, 20px, 0);
		}

		&--large {
			@include font('', 18px, 26px, 0);
		}

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&:empty {
			display: none;
		}
	}

	// .form__input-group
	&__input-group {
		position: relative;

		&:not(:last-child) {
			margin-bottom: 30px;
		}

		&--text-group {
			@include lg {
				display: flex;
				justify-content: center;
				padding-top: 39px;
			}
		}

		&--input-button {
			@include md {
				display: flex;
				gap: 0 14px;
				flex-wrap: wrap;

				> input {
					width: 0;
				}

				input {
					flex: 1 1 auto;
					height: 50px;
					margin-bottom: 0;
					font-size: 21px;
				}

				.form__input-button-group {
					button {
						height: 45px;
					}
				}
			}

			&:after {
				width: 100%;
			}
		}

		^&__row > div:not(:first-child) & {
			margin-top: 30px;

			@include lg {
				margin-top: 0;
			}

			&.form__input-group--lg-single-col {
				@include only-lg {
					margin-top: 30px;
				}
			}
		}

		&::before,
		&::after {
			@include font('regular', 13px, 16px, 0px);
			display: inline-block;
			margin-top: 5px;
		}

		&.is-invalid[data-error-message],
		&.persist-error-message[data-error-message] {
			&::after {
				content: attr(data-error-message);
				color: $signal-red;
			}
		}

		&[data-info-message]:not([data-error-message]) {
			&::after {
				content: attr(data-info-message);
				color: $grey-2;
			}
		}

		&[data-char-message] {
			&::before {
				content: attr(data-char-message);
				position: absolute;
				right: 0;

				.is-disabled& {
					color: $grey-3;
				}

				^&:not([data-error-message])& {
					top: 100%;
				}

				[data-error-message]& {
					bottom: 0;
				}
			}
		}

		&--outlined-checkbox {
			padding: 16px;
		}

		&--stacked {
			display: flex;
			flex-direction: column;
		}
	}

	&__input-button-group {
		position: relative;
		input:not([type='button'], [type='submit'], [type='search']) {
			margin-bottom: 0;
		}

		button,
		input[type='button'],
		input[type='submit'],
		input[type='search'] {
			outline: 0;
			position: absolute;
			-webkit-appearance: none;
			background-color: white;
			border: 0;
			right: 1px;
			top: 2px;
			font-size: 0;
			width: 58px;
			height: calc(58px - 4px);
		}

		&.is-invalid[data-validation-error-message] {
			&::after {
				@include font('regular', 13px, 16px, 0px);
				display: inline-block;
				margin-top: 5px;
				content: attr(data-validation-error-message);
				color: $signal-red;
			}
		}

		^&__cal-button {
			background-image: svg-load('calendar.svg', fill: $typo-color);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: auto 20px;

			@media (hover: none) {
				pointer-events: none;
			}

			&[disabled] {
				display: none;
			}
		}

		^&__clear-button {
			right: 58px;
			width: 20px;
			&:after {
				@include acv-icons-mixin();
				content: '$acv-icons-var-cross';
				font-size: 10px;
			}

			&[disabled] {
				display: none;
			}
		}
	}

	&__zip-city-group {
		display: flex;
		gap: 12px;

		& div {
			&:first-child {
				width: 81px;
				flex: 0 0 auto;
				margin-bottom: 0;
			}
			&:last-child {
				flex: 1 1 auto;
			}
		}
	}

	&__eye-button {
		background-image: svg-load('acv-icons/show-password.svg', fill: $typo-color);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 20px;

		.is-invalid & {
			background-image: svg-load('acv-icons/show-password.svg', fill: $signal-red);
		}

		.form__input[type='text'] + & {
			background-image: svg-load('acv-icons/hide-password.svg', fill: $typo-color);

			.is-invalid & {
				background-image: svg-load('acv-icons/hide-password.svg', fill: $signal-red);
			}
		}

		&[disabled] {
			display: none;
		}
	}

	&__hidden-cal-input {
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		width: 0;
	}

	&__price-radio {
		position: relative;

		input[type='radio'] {
			visibility: hidden;
			pointer-events: none;
			position: absolute;

			&:checked + label {
				background-color: $orange;
				color: white;
			}
		}

		label {
			/* ^& input[type='radio']:checked + & {
				background-color: $orange;
			} */

			position: relative;
			@include font('regular', 15px, 20px, normal);
			padding: 17px 20px;
			padding-right: 112px;
			cursor: pointer;
			display: block;
			background-color: white;
			border: 3px solid $orange;
			color: $orange;
			transition: background-color 250ms ease, color 250ms ease;
			border-radius: 1px;

			@include xl {
				padding-left: 10px;
			}

			@include xxl {
				padding-left: 17px;
			}

			.details {
				position: absolute;
				right: 17px;
				top: 50%;
				transform: translateY(-50%);
				text-align: right;

				@include xl {
					right: 10px;
				}

				@include xxl {
					right: 17px;
				}

				.sub {
					font-size: 10px;
					line-height: 10px;
				}

				.price {
					position: relative;
					font-size: 10px;
					line-height: 10px;
					margin-right: 10px;

					&:after {
						content: '€';
						@include font('regular', 13px, 10px, normal);
						position: absolute;
						right: -9px;
						top: -3px;
					}

					&__value {
						@include font('headline', 20px, 26px, -0.67px);
					}
				}
			}
		}

		&--label-solo {
			label {
				text-align: center;
				padding-right: 20px;
			}
		}
	}

	&__color-radio {
		text-align: center;
		display: flex;
		align-items: center;
		flex-direction: column;

		.label {
			@include font('regular', 15px, 20px, normal);
			margin-bottom: 5px;
		}

		.price {
			@include font('regular', 10px, 10px, normal);
		}
	}

	&__color-label {
		margin-bottom: 14px;
	}

	&__color-hint {
		size: 51px;
		border-radius: 50%;
		border: 1px solid $darkblue;
	}

	// .form__label
	&__label {
		@include font('regular', 13px, 16px, 0px);
		color: $grey-1;
		display: table;
		transition: color 250ms ease;

		&:not(:last-child) {
			margin-bottom: 8px;
		}

		.is-invalid > & {
			color: $signal-red;
		}

		.is-disabled > & {
			color: $grey-3;
		}

		^&__row--flex & {
			@include font('bold', 15px, 20px, 0);
		}

		&--vertical-align {
			display: flex;
			> input {
				flex: 0 0 auto;
			}
			align-items: center;
		}
	}

	&__inner-headline {
		@include font('bold', 13px, 16px, 0);
		margin-bottom: 20px;
	}

	// .form__label-text
	&__label-text {
		@include font('regular', 18px, 26px, 0px);
		color: $darkblue;
		display: table-cell;
		vertical-align: middle;

		&--small {
			font-size: 13px;
			line-height: 16px;
			color: $grey-2;

			a {
				padding: 2px 0;
				background-size: 1px 1px;
			}
		}

		&--dark {
			color: $typo-color;
		}

		a {
			background-image: linear-gradient($orange, $orange);
			&:hover {
				background-image: linear-gradient($orange, $orange);
				color: $orange;
			}
		}

		.is-disabled & {
			color: $grey-3;
		}

		.form__input-group--outlined-checkbox & {
			div:first-child:not(:last-child) {
				margin-bottom: 3px;
			}
		}
	}

	&__file-upload-wrapper {
		display: flex;
		justify-content: space-between;

		.form__input-group {
			max-width: 50%;
		}
	}

	&__file-upload-input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	&__file-upload-label {
		display: block;
		align-items: center;
		cursor: pointer;
	}

	&__file-upload-button {
		display: inline-block;
		background-color: $darkblue;
		color: $white;
		padding: 13px 30px;

		&:not(:last-child) {
			margin-right: 15px;
		}
	}

	&__file-upload-text {
		display: inline-block;
		padding: 13px 5px 13px 0;
	}

	fieldset {
		padding: 0;
		margin: 0;
		border: 0;
	}

	&__fieldset {
		&--remove-inline-size {
			min-inline-size: 0;
		}
	}

	&__search-input-wrapper {
		position: relative;

		&:before {
			content: '$acv-icons-var-lupe';
			position: absolute;
			font-family: 'acv-icons';
			font-size: 24px;
			color: $grey-3;
			transform: scaleX(-1) translateY(-50%);
			size: 24px;
			z-index: 1;
			top: 50%;
			left: 16px;
		}

		input[type='text'] {
			padding-left: 55px;
		}
	}

	&__file-select-box {
		border: 1px solid $grey-2;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 110px;

		@include md {
			height: 164px;
		}

		&.is-full {
			border-color: $grey-3;
			color: $grey-3;
			pointer-events: none;

			~ .moxie-shim {
				display: none;
			}
		}

		&.is-limit-error {
			border-color: $signal-red;
			color: $signal-red;
			pointer-events: none;

			~ .moxie-shim {
				display: none;
			}
		}

		&:not(.is-full).is-dragging {
			border-color: $signal-green !important;
			background-color: $signal-green-soft;
			color: $signal-green;
		}

		&:hover {
			border-color: $orange;
		}

		&[data-error-message] {
			border-color: $signal-red;

			&:after {
				content: attr(data-error-message);
				color: $signal-red;
				@include font('regular', 13px, 16px, 0px);
				position: absolute;
				left: 0;
				top: calc(100% + 25px);

				@include xl {
					top: calc(100% + 8px);
				}
			}
		}
	}

	&__file-select-file-info {
		@include font('', 13px, 16px, normal);
		color: $grey-1;
		margin-top: 8px;

		@include xl {
			text-align: right;
		}

		^&__file-select-box.is-limit-error ~ & {
			color: $signal-red;
		}
	}

	&__file-select-box-icon {
		margin-bottom: 6px;
		font-size: 32px;
		color: $anthrazit;
		&:before {
			margin: 0 !important;
		}

		^&__file-select-box.is-full & {
			color: $grey-3;
		}

		^&__file-select-box.is-limit-error & {
			display: none;
		}

		&--limit-error {
			display: none;
			^^&__file-select-box.is-limit-error & {
				display: block;
			}
		}
		^&__file-select-box.is-limit-error & {
			color: $signal-red;
		}
	}

	&__file-select-box-text-content {
		pointer-events: none;
		text-align: center;
		@include font('', 13px, 16px, normal);
		display: flex;
		flex-direction: column;
		gap: 5px;
		margin-bottom: 6px;

		strong {
			color: $anthrazit;

			^^&__file-select-box.is-full & {
				color: $grey-3;
			}

			^^&__file-select-box.is-limit-error & {
				color: $signal-red;
			}
		}

		div + div {
			margin-top: 5px;
		}

		^&__file-select-box.is-dragging & {
			display: none;
		}

		&--normal {
			display: none;
			@include md {
				display: block;
			}
			^^&__file-select-box.is-limit-error &,
			^^&__file-select-box.is-full & {
				display: none;
			}
		}

		&--dragging {
			display: none;
			margin-bottom: 0;
			^^&__file-select-box:not(.is-full).is-dragging & {
				display: block;
			}
		}

		&--full {
			display: none;
			margin-bottom: 0;
			^^&__file-select-box.is-full & {
				display: block;
			}
		}

		&--limit-error {
			display: none;
			margin-bottom: 0;
			^^&__file-select-box.is-limit-error & {
				display: block;
			}
		}
	}

	&__file-select-button {
		@include font('', 12px, 14px, normal);

		^&__file-select-box.is-full &,
		^&__file-select-box.is-limit-error &,
		^&__file-select-box.is-dragging & {
			display: none;
		}
	}

	&__file-select-files-list-item {
		display: flex;
		padding: 16px 13px 16px 0;
		align-items: stretch;

		&.is-inactive {
			opacity: 0.5;
		}

		& + & {
			border-top: 1px solid $grey-3;
		}

		&:before {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 25px;
			color: $anthrazit;
		}
	}

	&__file-select-files-list {
		&:not(:empty) {
			margin-top: 16px;
		}
	}

	&__file-select-files-list-item-content {
		flex-grow: 1;
		min-width: 0;
	}

	&__file-select-files-list-item-filename {
		@include font('bold', 15px, 20px, normal);
		color: $anthrazit;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 10px;
	}

	&__file-select-files-list-item-filesize {
		@include font('', 13px, 16px, normal);
		color: $grey-2;
	}

	&__file-select-files-list-item-error-message {
		@include font('', 13px, 16px, normal);
		color: $signal-red;
	}

	&__file-select-files-list-item-status {
		display: flex;
		align-items: center;

		.acv-icons {
			display: none;
			&:before {
				margin: 0 !important;
				font-size: 20px;
			}
		}

		.is-file-status-ok {
			color: $signal-green;
			^&.is-ok& {
				display: block;
			}
		}

		.is-file-status-error {
			color: $signal-red;
			^&.is-error& {
				display: block;
			}
		}
	}

	&__file-select-files-list-item-remove-button {
		border: 0;
		background: none;
		padding: 0;
		font-size: 0;
		color: $anthrazit;
		margin-left: 12px;
		transition: color 250ms ease;
		position: relative;

		&:before {
			font-size: 15px;
			margin: 0 !important;
		}

		&:after {
			content: '';
			display: block;
			size: 28px;
			background-color: $signal-red-soft;
			position: absolute;
			left: 50%;
			top: 50%;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;
			transition: opacity 250ms ease;
			opacity: 0;

			^&:hover& {
				opacity: 1;
			}
		}

		&:hover {
			color: $signal-red;
		}
	}

	&__meta-text {
		font-size: 15px;
		color: $grey-1;
		&:not(:first-child) {
			margin-top: 16px;
		}
	}
}
