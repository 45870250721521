.frame-type-acvcontent_tarifcomparetarifcompareinfo {
	margin-top: 0 !important;
}

.tarifCompareInfo {
	&__table {
		display: flex;
		gap: 2px;

		@include <lg {
			flex-wrap: wrap;
		}
	}

	&__head,
	&__cell {
		flex: 1 1 10px;

		display: flex;
		min-height: 75px;

		@include lg {
			padding: 19px 24px;

			^&--layout-10 & {
				background-color: $vista;
				border-radius: 6px;
			}
		}
	}

	&__head {
		@include font('bold', 14px, 18px, normal);
		hyphens: auto;
		align-items: center;

		&--top {
			align-items: flex-start;
		}

		@include <lg {
			@include font('normal', 14px, 18px, normal);
			flex: 1 0 auto;
			width: 100%;
			min-height: unset;
			margin-top: 21px;
			margin-bottom: 13px;
		}
	}

	&__cell {
		^&--layout-10 & {
			background-color: $vista;
			border-radius: 6px;
		}
		&--icon {
			justify-content: center;
			align-items: center;
			[class*='checkmark-simple'] {
				color: $signal-green;
				&:before {
					margin-right: 0;
					font-size: 10.5px;

					@include lg {
						font-size: 13px;
					}
				}
			}

			[class*='cross'] {
				color: $signal-red;
				&:before {
					margin-right: 0;
					font-size: 12px;

					@include lg {
						font-size: 16px;
					}
				}
			}
			@include <lg {
				justify-content: center;
			}
		}

		&--rte {
			@include <md {
				> * {
					padding: 20px 20px;
				}
			}

			@include only-md {
				> * {
					padding: 10px 10px;
				}
			}

			@include only-md {
				&:not(:last-child) > * {
					padding-right: 20px;
				}
			}

			* {
				@include font('regular', 14px, 22px, normal);
			}

			li + li {
				margin-top: 24px;

				@include only-md {
					margin-top: 12px;
				}
			}
		}

		@include <md {
			&:not(.visible):nth-child(n + 2) {
				display: none;
			}
		}

		@include <lg {
			min-height: 33px;
			border-radius: 3px;
		}
	}

	&__head-text {
	}

	&__details {
		position: relative;
		display: none;
		transition: background-color 250ms ease, visibility 250ms ease;

		@include <lg {
			position: fixed;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			z-index: 8;
			display: block;
			visibility: hidden;
			pointer-events: none;
		}

		@include lg {
			margin-top: 69px;
		}

		&.open {
			display: block;
			@include <lg {
				visibility: visible;
				pointer-events: unset;
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
	}

	&__details-inner {
		@include <lg {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			overflow: hidden;
			max-height: calc(100vh - 117px);
			background: $white;
			border-radius: 15px 15px 0 0;
			padding: 36px;
			box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
			display: flex;
			flex-direction: column;
			transform: translate3d(0, 100%, 0);
			visibility: hidden;
			transition: transform 0.25s ease, visibility 0.25s ease;
		}

		^&__details.open & {
			@include <lg {
				transform: translate3d(0, 0, 0);
				visibility: visible;
			}
		}

		@include lg {
			padding: 0 0 121px 24px;
		}
	}

	&__close-button {
		position: absolute;
		right: 33px;
		top: 8px;
		background: transparent;
		border: 0;
		color: $orange;
		&:before {
			font-size: 18px;
			margin-right: 0 !important;
		}
	}

	&__columns {
		display: flex;
		gap: 60px;

		@include <lg {
			overflow: hidden;
		}
	}

	&__column {
		display: flex;
		overflow: auto;
		@include <lg {
			&:nth-child(2) {
				display: none;
			}
		}

		@include lg {
			flex: 1 1 50%;
		}
	}

	&__detail-headline {
		color: $anthrazit;
		@include font('headline', 20px, 25px, -0.0125em);
		margin-bottom: 56px;

		@include lg {
			@include font('headline', 28px, 36px, -0.0125em);
			margin-bottom: 59px;
		}
	}

	&__detail-copy {
		color: $anthrazit;
		* {
			@include font('normal', 15px, 25px, normal);
		}

		strong .rte-orange {
			@include font('normal', 14px, 22px, normal);
		}

		p + p {
			margin-top: 15px;
		}

		p + ul {
			margin-top: 24px;
		}

		li + li {
			margin-top: 25px;
		}
	}

	&__contact {
		background-color: $cornflower;
		padding: 56px;
		color: $anthrazit;
	}

	&__contact-headline {
		@include font('headline', 20px, 26px, 0.0025em);
		margin-bottom: 53px;
	}

	&__contact-copy {
		* {
			@include font('normal', 14px, 22px, normal);
		}

		li * {
			@include font('normal', 15px, 25px, -0.005em);
		}

		li + li {
			margin-top: 31px;
		}

		p + ul {
			margin-top: 57px;
		}

		ul + p {
			margin-top: 65px;
		}
	}
}
