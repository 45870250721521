.cke_editable,
.rte-copy {
	table {
		background-color: white;
		width: 100%;
		@include <sm {
			hyphens: auto;
		}

		border-collapse: collapse;
		thead {
			background: $orange;
			color: #fff;
		}

		tr {
			&.border-bottom {
				border-bottom: 1px solid $grey-3;
			}
		}

		td {
			padding: 10px 15px;
			min-width: 100px;
			vertical-align: top;
		}
	}
}

.rteTable {
	padding: 0;
	min-width: 300px;
	@include sm {
		padding: 0 40px;
	}
	&__content {
		overflow-x: auto;
		background-color: white;
	}
}
