$btnTransitionDuration: 250ms;

#CybotCookiebotDialog {
	padding: 0 !important;
	text-rendering: optimizeLegibility !important;

	div,
	a {
		font-family: 'Lato' !important;
	}
}

#CybotCookiebotDialogBody {
	max-width: 100% !important;
	padding-bottom: 20px !important;

	@include md {
		max-width: 720px !important;
	}

	@include lg {
		max-width: 960px !important;
	}

	@include xl {
		max-width: 1140px !important;
	}
}

#CybotCookiebotDialogBodyContentTitle {
	font-family: 'Flexo-Demi' !important;
	font-size: 22.5px !important;
	line-height: 26px !important;
	color: $darkblue !important;
	margin-bottom: 20px !important;
	font-weight: normal !important;
}

#CybotCookiebotDialogBodyContentText {
	font-family: 'Lato' !important;
	font-size: 13px !important;
	line-height: 20px !important;
	color: $grey-1 !important;
	hyphens: auto;

	@include md {
		font-size: 15px !important;
		hyphens: none;
	}

	a {
		background-image: linear-gradient($orange, $orange);
		background-size: 1px 2px !important;
		background-repeat: repeat-x !important;
		background-position: 0 99% !important;
		padding-bottom: 2px !important;
		color: $grey-1 !important;
		text-decoration: none !important;
		font-size: 13px !important;

		@include md {
			font-size: 15px !important;
		}

		&:hover {
			background-image: linear-gradient($orange, $orange);
			color: $orange !important;
		}
	}
}

//BUTTONS
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
	padding-bottom: 10px !important;
	display: flex !important;
	flex-direction: column-reverse;
	width: 100%;

	@include sm {
		display: block !important;
		width: auto;
	}
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
	@include btn-secondary-dark;
	@include btn-hover-from-bottom;

	font-family: 'Lato black' !important;
	font-weight: normal !important;
	letter-spacing: 0.8px !important;
	border: 3px solid $darkblue !important;
	background-color: transparent !important;
	position: relative;
	padding: 15px 30px !important;
	width: auto !important;
	text-transform: uppercase !important;
	color: $darkblue !important;
	transition: opacity $btnTransitionDuration ease, color $btnTransitionDuration ease, background-color $btnTransitionDuration ease, border-color $btnTransitionDuration ease !important;

	&:hover {
		color: $white !important;
	}
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
	@include btn-primary-dark;
	@include btn-hover-from-top;

	font-family: 'Lato black' !important;
	font-weight: normal !important;
	letter-spacing: 0.8px !important;
	border: 3px solid $darkblue !important;
	background-color: transparent !important;
	position: relative;
	padding: 15px 30px !important;
	width: auto !important;
	text-transform: uppercase !important;
	transition: opacity $btnTransitionDuration ease, color $btnTransitionDuration ease, background-color $btnTransitionDuration ease, border-color $btnTransitionDuration ease !important;
}

//DETAILS
.CybotCookiebotDialogBodyLink {
	position: relative !important;
	padding-right: 12px !important;
	display: inline-flex !important;
	align-items: center !important;
	text-decoration: none !important;

	&:after {
		@include acv-icons-mixin();
		content: '$acv-icons-var-arrow-right';
		margin-left: 3px;
		font-size: 0.7em;
		margin-right: initial;
		transform: translateY(1px) rotate(90deg);
		display: inline-block;
		margin-left: 15px;
	}
}
.CybotCookiebotDialogBodyLevelDetailsButtonExpanded {
	&:after {
		transform: rotate(270deg);
	}
}

#CybotCookiebotDialogDetail {
	padding-bottom: 40px !important;
	max-width: 100% !important;
	margin: 0 auto !important;

	@include md {
		max-width: 720px !important;
	}

	@include lg {
		max-width: 960px !important;
	}

	@include xl {
		max-width: 1140px !important;
	}
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypeDetails a {
	background-image: linear-gradient($orange, $orange);
	background-size: 1px 2px !important;
	background-repeat: repeat-x !important;
	background-position: 0 99% !important;
	padding-bottom: 2px !important;
	color: $grey-1 !important;
	text-decoration: none !important;

	&:hover {
		background-image: linear-gradient($orange, $orange);
		color: $orange !important;
	}
}

#CybotCookiebotDialogDetailBody {
	width: auto !important;
	max-width: 100% !important;
	padding: 0 8px 0 8px !important;
}

a.CybotCookiebotDialogDetailBodyContentTabsItemSelected:hover,
a.CybotCookiebotDialogDetailBodyContentTabsItem:hover {
	background: $white !important;
}

a.CybotCookiebotDialogDetailBodyContentCookieContainerTypes:hover,
a.CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected:hover {
	background-image: unset !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible + span,
#CybotCookiebotDialog :focus-visible {
	outline: none !important;
}

#CybotCookiebotDialogBodyButtonsWrapper button {
	border-radius: 0 !important;
	padding: 15px 30px !important;
	border: 3px solid $darkblue !important;
	display: block;
	@include font('black', 12px, 14px, 0.8px);
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	outline: 0;
	background: none;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
	background-color: $darkblue !important;
}
