.sectionIntro {
	&__wrapper {
		background-size: cover;
		background-position: center;
	}

	& .has-shadow {
		box-shadow: $card-shadow;
		padding: 60px 15px;

		@include sm {
			padding: 60px 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		padding: 0 $ce-side-padding;
	}

	&__copy-single {
		^&__show-more-checkbox:checked ~ & {
			display: none;
		}
	}

	&__show-more-checkbox {
		visibility: hidden;
		position: absolute;
		left: -100vw;
	}

	&__show-more-label {
		margin-top: 32px;
		display: inline-block;
		cursor: pointer;
		color: white;

		&:hover {
			color: white;
			background: linear-gradient(white, white);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
		}

		[data-style='white'] &,
		.wrapper-white & {
			color: $orange;

			&:hover {
				color: $orange;

				background: linear-gradient($orange, $orange);
				background-size: 1px 2px;
				background-repeat: repeat-x;
				background-position: 0% 99%;
			}
		}

		&:before {
			content: 'Mehr anzeigen';
		}
		&:after {
			content: '+';
		}

		^&__show-more-checkbox:checked ~ & {
			&:before {
				content: 'Weniger anzeigen';
			}
			&:after {
				content: '–';
			}
		}
	}

	&__copy-more-wrapper {
		margin-top: 32px;
	}

	&__copy-more {
		width: 100%;
		display: none;
		^&__show-more-checkbox:checked ~ & {
			display: block;
		}
	}

	&__btnWrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;
		order: 4;
		z-index: 1;

		&:nth-last-child(1) {
			margin-bottom: 0;
		}

		@include sm {
			flex-direction: row;
		}

		.pre-component-subheadline + &,
		.pre-component-headline + & {
			margin-top: 20px;

			@include md {
				margin-top: 30px;
			}
		}
	}

	.pre-component-copy {
		order: 3;
	}
}

.subheadline-onImage {
	color: $white;
}

.noPaddingMobile {
	@include <sm {
		padding: 0 !important;
	}
}
