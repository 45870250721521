$ce-gallery-row-spacing: 25px;
$ce-gallery-column-spacing: 25px;
.ce-gallery {
	display: inline-block;
	font-size: 0;

	&--intext {
		margin-bottom: calc($gutter / 2);
		width: 100%;
		@include md {
			width: auto;
			margin-bottom: 0;
		}
	}

	&:not(&--intext) {
		width: 100%;
	}

	&--center {
		text-align: center;
	}

	&--left {
		text-align: left;
	}

	&--right {
		text-align: right;
	}

	&--above {
		margin-bottom: 20px;
	}

	&--below {
		margin-top: 20px;
	}

	&--fixed-width {
		^&--above&,
		^&--below& {
			width: 100%;

			^^&__column {
				flex: 1 1 100%;
				&:first-child:last-child {
					width: 100%;
				}
			}

			^^&__row {
				display: flex;
				flex-direction: column;
				@include sm {
					flex-direction: row;
				}
			}
		}

		^&__content {
			width: 100%;
		}

		@include md {
			^&--intext& {
				width: calc(50% - $gutter / 2);
			}
		}

		&^&--intext {
			@include md {
				&:not(^^&--no-wrap) {
					&^^^&--right {
						float: right;
					}

					&^^^&--left {
						float: left;
					}
				}
			}
		}

		^&--intext& {
			& ^^&__row {
				display: flex;
				flex-direction: column;
				@include sm {
					flex-direction: row;
				}
			}

			^^&--no-wrap& {
				&^^^&--right {
					order: 11;
				}

				&^^^&--left {
					order: 9;
				}
			}
		}

		.image {
			width: 100%;

			.image-embed-item {
				width: 100%;
				height: auto;
			}
		}
	}

	&:not(&--fixed-width) {
		& ^&__row {
			display: flex;
			flex-direction: column;

			@include sm {
				flex-direction: row;
			}
		}

		^&__column {
			width: 100%;

			@include lg {
				width: initial;
			}
		}

		^&--intext& {
			flex: 0 0 auto;

			width: 100%;

			@include md {
				width: 50%;
			}

			@include lg {
				width: initial;
			}

			& ^^&__row {
				display: flex;
			}

			^^&--no-wrap& {
				&^^^&--right {
					order: 11;
				}

				&^^^&--left {
					order: 1;
				}
			}

			&:not(^^&--no-wrap) {
				&^^^&--right {
					float: right;
				}

				&^^^&--left {
					float: left;
				}
			}

			^&__column {
				width: 100%;
				@include sm {
					width: initial;
				}
			}
		}

		& ^&__content {
			width: 100%;
			@include lg {
				width: initial;
			}
		}

		.image {
			width: 100%;

			.image-embed-item {
				width: 100%;
				height: auto;
			}
		}
	}

	&__content {
		display: inline-block;
	}

	&__row {
		text-align: left;

		& + & {
			margin-top: $ce-gallery-row-spacing;
		}
	}

	&__column {
		text-align: left;
		display: inline-block;

		a {
			display: block;
		}

		& + & {
			margin-top: $ce-gallery-row-spacing;
			@include sm {
				margin-top: initial;
				margin-left: $ce-gallery-column-spacing;
			}
		}
	}
}
