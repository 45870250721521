.stepForm {
	&__form {
		margin-top: 20px;
	}

	&__big-icon {
		margin-bottom: 26px;
	}

	&__headline {
		@include font('headline', 28px, 33px, normal);
	}

	&__sub-headline {
		@include font('headline', 15px, 20px, normal);

		^&__headline + & {
			margin-top: 19px;
		}
	}

	&__document-copy {
		margin-top: 11px;

		* {
			color: $grey-1;
			@include font('regular', 15px, 20px, normal);

			@include md {
				@include font('regular', 13px, 16px, normal);
			}
		}

		p + p {
			margin-top: 12px;
			@include md {
				margin-top: 10px;
			}
		}
	}

	&__complete-wrapper {
		min-height: auto;
	}

	&__status-screen {
		width: 100%;
		background-color: white;
		padding: 80px 20px 60px;
	}

	&__loading-dots {
		display: flex;
		justify-content: center;
	}

	&__process-headline {
		text-align: center;
		color: $anthrazit;
		@include font('', 28px, 36px, normal);
		margin-bottom: 19px;
		display: flex;
		flex-direction: column;

		&:before {
			font-size: 47px;
			color: $signal-green;
			margin: 0 !important;
			padding-top: 6px;
			padding-bottom: 17px;
		}

		&.acv-icons--warning:before {
			color: $signal-red;
		}
	}

	&__process-copy {
		p {
			@include font('', 18px, 26px, normal);

			& + p {
				margin-top: 40px;
			}
		}
	}

	&__process-buttons {
		margin-top: 30px;
		display: flex;
		justify-content: center;
	}
}
