$navigation-itemPaddingBig: 30px;
$navigation-itemPaddingSmall: 15px;

$loginButtonHeightMobile: 104px;

:root {
	--100dvh: 100dvh;
}

.navigation {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	padding: 20px;
	align-items: center;
	background-image: linear-gradient(90deg, rgba(255, 132, 25, 0) 0%, rgba(244, 153, 43, 0) 100%);
	z-index: 10;
	height: 70px;

	&::after {
		content: '';
		width: 100%;
		height: 1px;
		background-color: white;
		opacity: 0.29;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 100;
		pointer-events: none;
	}

	&.is-opaque,
	&.is-open,
	&.is-small {
		&::before {
			opacity: 1;
		}
	}
	&.is-empty {
		transition: height 250ms ease;
		height: 70px;
		@include lg {
			height: 90px;
		}

		&.is-small {
			@include lg {
				height: 50px;
			}
		}
	}

	// &::before {
	// 	content: '';
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-image: $orange-gradient;
	// 	opacity: 0.5;
	// 	z-index: -1;
	// 	transition: opacity 0.3s, background-image 0.3s;

	// 	^&:not(.is-open, .is-small).bg-blue& {
	// 		background-image: $blue-gradient;
	// 	}

	// 	^&:not(.bg-blue)& {
	// 		@include lg {
	// 			background-image: $orange-gradient;
	// 		}
	// 	}
	// }

	@include lg {
		padding: 0 40px;
		height: auto;
	}

	&__background {
		pointer-events: none;
	}

	&__bg {
		pointer-events: none;
		opacity: 0.5;
		transition: opacity 0.3s;

		^&.is-opaque &,
		^&.is-open &,
		^&.is-small & {
			opacity: 1;
		}

		&--orange {
			^^&.is-opaque &,
			^^&.is-small &,
			^^&:not(.bg-blue) & {
				background-image: $orange-gradient;
			}

			^^&.is-open & {
				background-color: $orange;
				background-image: none;

				@include lg {
					background-image: $orange-gradient;
				}
			}
		}

		&--blue {
			^^&:not(.is-open, .is-small).bg-blue & {
				background-image: $blue-gradient;
			}
		}

		&.invisible {
			opacity: 0;
		}
	}

	&__brand {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&__brand-link {
		background: none;
		display: flex;
		justify-content: center;
		@include normalizeHover;
	}

	&__brand-image {
		display: inline-block;
		vertical-align: middle;
		width: 85.5px;
		height: 28px;
		background-size: 100%;
		background-repeat: no-repeat;
		margin-right: 10px;
		transition: height 0.2s, width 0.2s;

		^&.is-small & {
			@include lg {
				width: 73px;
			}
		}
	}

	&__brand-caption {
		display: inline-block;
		vertical-align: middle;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.1s, visibility 0.1s;
		height: 30px;

		@media (min-width: 386px) {
			visibility: visible;
			opacity: 1;
		}

		@include betweenLgAndXl {
			opacity: 0;
			visibility: hidden;
		}

		^&.is-small & {
			@include lg {
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	&__list-holder {
		position: absolute;
		left: 0;
		top: 70px;
		width: 100%;
		height: 100vh;
		background: $orange;
		transform: translate3d(100%, 0, 0);
		overflow: auto;
		// -webkit-overflow-scrolling: touch;

		^&.is-open & {
			@include <lg {
				height: calc(100vh - 70px - $loginButtonHeightMobile);
				min-height: calc(var(--100dvh) - 70px - $loginButtonHeightMobile);
				max-height: calc(var(--100dvh) - 70px - $loginButtonHeightMobile);
			}
		}

		^&.is-open.is-logged-in & {
			@include <lg {
				height: calc(100vh - 70px - calc($loginButtonHeightMobile * 2));
				min-height: calc(var(--100dvh) - 70px - calc($loginButtonHeightMobile * 2));
				max-height: calc(var(--100dvh) - 70px - calc($loginButtonHeightMobile * 2));
			}
		}

		@include lg {
			position: static;
			background: transparent;
			width: 100%;
			height: auto;
			transform: none;
			max-width: 1250px;
			margin: 0 auto;
		}

		&.has-transition {
			transition: transform 0.3s;

			@include lg {
				transition: none;
			}
		}

		&.is-in {
			transform: translate3d(0, 0, 0);

			@include lg {
				transform: none;
			}
		}
	}

	&__list {
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		position: absolute;

		@include lg {
			position: static;
			padding: 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			background: transparent;
			width: 100%;
			height: auto;
		}
	}

	&__sublist {
		padding: 32px 20px;

		@include lg {
			padding: 50px 0;
		}
	}

	&__item {
		overflow: hidden;

		@include lg {
			overflow: visible;
		}

		&--level-2 {
			text-align: center;

			@include lg {
				text-align: left;
			}
		}

		&--only-mobile {
			@include lg {
				display: none;
			}
		}
	}

	&__link-wrap {
		position: relative;

		^&__item--level-1.is-open & {
			&::after {
				opacity: 0;
			}
		}

		^&__item--level-1 & {
			&::after {
				content: '';
				width: 100%;
				height: 1px;
				background-color: white;
				opacity: 0.29;
				position: absolute;
				bottom: 0;
				transition: opacity 0.3s;

				@include lg {
					display: none;
				}
			}
		}
	}

	&__link {
		color: white;
		@include font('regular', 15px, 20px, 0);
		position: relative;
		background: none;

		&:hover {
			background-image: none;
			color: white;
		}

		&--level-1 {
			display: block;
			padding: 28px 20px;
			text-transform: uppercase;
			@include font('black', 14px, 14px, 0.9px);
			transition: padding 0.2s;

			@include lg {
				padding: 38px $navigation-itemPaddingSmall;
			}

			@include xxl {
				padding: 38px $navigation-itemPaddingBig;
			}

			^^&.is-small & {
				@include lg {
					padding: 18px $navigation-itemPaddingSmall;
				}

				@include xxl {
					padding: 18px $navigation-itemPaddingBig;
				}
			}

			&::after {
				content: '';
				position: absolute;
				width: calc(100% - calc($navigation-itemPaddingSmall * 2));
				height: 0px;
				background-color: white;
				bottom: 0;
				left: $navigation-itemPaddingSmall;
				transition: height 0.1s;

				@include xxl {
					width: calc(100% - calc($navigation-itemPaddingBig * 2));
					left: $navigation-itemPaddingBig;
				}

				^&:hover&,
				^^^&__item--level-1.is-current &,
				^^^&__item--level-1.is-hovered & {
					@include lg {
						height: 3px;
					}
				}

				// ^^^&.is-open ^^^&__item--level-1.is-current:not(.is-hovered) & {
				.navigation.is-open ^^^&__item--level-1.is-current:not(.is-hovered) & {
					height: 0;
				}
			}
		}

		&--level-2 {
			display: block;
			padding: 12px 0;

			@include lg {
				padding: 5px 0;
			}

			&::before {
				content: '';
				position: absolute;
				width: 0;
				height: calc(80% - 10px);
				background-color: white;
				left: -15px;
				top: 50%;
				transform: translateY(-50%);
				transition: width 0.1s;

				^&:hover&,
				^^^&__item--level-2.is-current & {
					@include lg {
						width: 3px;
					}
				}
			}
		}
	}

	&__flyout {
		height: 0;
		transition: height 0.3s;
		background: $orange-dark;
		box-shadow: inset 0 3px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.5);

		@include lg {
			visibility: hidden;
			opacity: 0;
			position: absolute;
			height: auto !important;
			top: 100%;
			left: 0;
			width: 100%;
			z-index: 10;
			background: $orange-gradient;
			box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.2);
			transition: opacity 0.3s, visibility 0.3s;
		}

		^&.no-transition & {
			@include lg {
				transition: none;
			}
		}

		&.is-active {
			@include lg {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&__burger {
		z-index: 12;
		position: absolute;
		right: 0;
		top: 0;
		width: 70px;
		height: 100%;
		background-color: $orange-dark;

		@include lg {
			display: none;
		}
	}

	&__burger-inner {
		transition: transform 0.2s;

		^&.is-open & {
			transform: translateX(1px);
		}
	}

	&__burger-btn {
		display: inline-block;
		padding: 5px;
		font-size: 0;
		background: transparent;
		border: 0;
		outline: none;
		width: 100%;
		height: 100%;
	}

	&__burger-rect {
		display: block;
		border-radius: 100px;
		width: 17px;
		height: 2px;
		background-color: white;
		margin: 0 auto;
		pointer-events: none;

		&:not(:last-child) {
			margin-bottom: 4px;
		}

		&--top {
			transform-origin: left;
			transition: transform 0.2s;

			^^&.is-open & {
				transform: rotate(45deg);
			}
		}

		&--mid {
			^^&.is-open & {
				opacity: 0;
			}
		}

		&--bot {
			transform-origin: left;
			transition: transform 0.2s;

			^^&.is-open & {
				transform: rotate(-45deg);
			}
		}
	}

	&__arrow-btn {
		position: absolute;
		width: 70px;
		height: 100%;
		right: 0;
		top: 0;
		background: transparent;
		border: 0;
		outline: none;
		text-indent: -9999px;
		z-index: 1;
		background-image: svg-load('pulldown.svg', fill: white);
		background-repeat: no-repeat;
		background-position: center;
		transition: transform 0.2s;

		^&__item.is-open & {
			transform: rotate(180deg);
		}

		@include lg {
			display: none;
		}
	}

	&__meta {
		position: absolute;
		display: flex;
		height: 100%;
		right: 70px;
		top: 0;

		@include lg {
			right: 0;
		}
	}

	&__icon-wrapper {
		width: 70px;
		height: 100%;
		transition: background-color 250ms ease;

		@include lg {
			width: 110px;
		}

		&--user {
			background-color: $orange-dark;
		}

		&--sos {
			background-color: $darkblue;

			@include lg {
				display: none;
			}
		}

		&--user,
		&--contact,
		&--logout {
			display: none;

			@include lg {
				display: block;
			}
		}

		&--contact,
		&--logout {
			@include <xl {
				width: 50px;
			}
		}

		&:hover {
			background-color: $darkblue;
		}
	}

	&__meta-link {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 15px;
		overflow: hidden;
		text-decoration: none;
		background: none;

		&:hover {
			background: none;
		}

		^&.is-small & {
			@include lg {
				justify-content: flex-start;
			}
		}

		^&__icon-wrapper--sos & {
			border: 0;
			width: 100%;
		}
	}

	&__meta-icon {
		size: 20px;
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: center;

		@include lg {
			flex-shrink: 0;
		}

		^&__icon-wrapper--sos & {
			background-image: svg-load('sos.svg', fill: $orange, stroke: $orange);
		}

		^&__icon-wrapper--contact & {
			background-image: svg-load('contact.svg', fill: transparent);
		}

		^&__icon-wrapper--logout & {
			background-image: svg-load('logout.svg', fill: white);
		}

		^&__icon-wrapper--user & {
			background-image: svg-load('user.svg', fill: white);

			@include <lg {
				size: 30px;
			}
		}
	}

	&__meta-label {
		color: white;
		@include font('black', 9px, 14px, 1.4px);
		text-transform: uppercase;
		margin-top: 10px;
		transition: opacity 0.3s, transform 0.2s;

		@include lg {
			@include font('black', 12px, 14px, 0.8px);
		}

		^&__icon-wrapper--sos & {
			color: $orange-dark;
		}

		^&__icon-wrapper--contact &,
		^&__icon-wrapper--logout & {
			@include <xl {
				display: none;
			}
		}

		^&.is-small & {
			@include lg {
				opacity: 0;
				transform: scale(0);
			}
		}
	}

	&__user-mobile {
		transform: translate3d(100%, 0, 0);
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		transition: transform 0.3s;

		@include lg {
			display: none;
		}

		^&.is-open & {
			@include <lg {
				transform: translate3d(0, 0, 0);
			}
		}
	}

	&__user-mobile-link {
		height: $loginButtonHeightMobile;
		display: block;
		background: $darkblue;
		display: flex;
		padding: 25px;
		align-items: center;
		color: white;

		&:not(:first-child) {
			border-top: 1px solid #9b9b9b;
		}

		&:hover {
			color: white;
		}
	}

	&__user-mobile-icon {
		size: 30px;
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 15px;

		&--profile {
			background-image: svg-load('user.svg', fill: white);
		}

		&--logout {
			background-image: svg-load('logout.svg', fill: white);
		}
	}

	&__user-mobile-label {
		@include font('black', 14px, 14px, 0.93px);
		text-transform: uppercase;
	}
}
