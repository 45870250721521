.user-info-block {
	&__main {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
	}

	&__column {
		margin-bottom: 30px;
		width: 100%;
		display: flex;
		flex-direction: column;

		&:not(&--late-break) {
			@include lg {
				margin-bottom: 0;
				width: calc(50% - 30px);
			}
		}

		&--late-break {
			@include xl {
				margin-bottom: 0;
				width: calc(50% - 30px);
			}
		}

		&:first-child {
			@include lg {
				margin-right: 60px;
			}
		}
	}

	&__head {
		margin-bottom: 55px;
		color: $anthrazit;
	}

	&__sub-head {
		margin-bottom: 30px;
		color: $anthrazit;
	}

	&__icon {
		display: inline-block;
		vertical-align: middle;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 15px 60px;
		width: 100%;
	}

	&__list-item {
		word-break: break-word;
		display: flex;
		width: 100%;
		flex-direction: column;

		@include sm {
			flex-direction: row;
			align-items: flex-start;
		}

		@include <md {
			&:nth-child(odd) {
				order: 1;
			}

			&:nth-child(even) {
				order: 2;
			}
		}

		@include lg {
			width: calc(50% - 30px);
		}

		&--full {
			width: 100%;
		}
	}

	&__status-wrapper {
		display: flex;

		& > div {
			position: relative;
		}
		.member-status-badge {
			transform: translateY(-1px);
			margin-left: 10px;
		}
	}

	&__item-label {
		font-weight: bold;
		width: 100%;
		margin-right: 32px;
		display: inline-block;
		flex-shrink: 0;
		margin-bottom: 6px;

		@include sm {
			min-width: 160px;
			width: auto;
			margin-bottom: 0;
		}
	}

	&__value-group {
		display: flex;
		flex-direction: column;
		gap: 11px;
	}

	&__tariff-desc {
		display: flex;
		gap: 9px;
		align-items: center;
	}

	&__headline {
		margin-bottom: 8px;
	}

	&__edit-headline {
		@include font('', 28px, 33px);
		color: $orange;
		margin-bottom: 35px;

		@include md {
			@include font('', 34px, 38px);
			margin-bottom: 65px;
		}
	}

	&__copy {
		@include font('', 15px, 20px);
	}

	&__info-text {
		:--headlines + p {
			margin-top: 8px;
		}
		margin-top: 64px;
		margin-bottom: 32px;
	}

	&__footer {
		margin-top: auto;
		justify-content: flex-end;

		&--with-text {
			flex-direction: column;
			gap: 20px;

			@include lg {
				flex-direction: row;
			}
		}
	}

	&__tarif-info {
		display: flex;
		gap: 10px 32px;
		margin-bottom: 65px;
		flex-direction: column;

		@include md {
			flex-direction: row;
		}
	}

	&__tarif-info-head {
		@include font('bold', 21px, 26px);
	}

	&__tarif-info-content {
		display: flex;
		flex-direction: column;
		gap: 11px;
		@include font('', 21px, 26px);
	}

	&__big-icon {
		margin-bottom: 20px;

		@include md {
			margin-bottom: 30px;
		}
	}

	&__process-headline {
		text-align: center;
		color: $anthrazit;
		@include font('', 28px, 36px, normal);
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;

		@include md {
			margin-bottom: 40px;
			@include font('', 34px, 38px, normal);
		}
	}

	&__process-copy {
		p {
			@include font('', 18px, 26px, normal);

			@include md {
				@include font('', 21px, 26px, normal);
			}

			& + p {
				margin-top: 10px;
			}
		}
	}

	&__process-buttons {
		margin-top: 30px;
		display: flex;

		@include md {
			margin-top: 50px;
			justify-content: flex-end;
		}
	}
}
