.calendar {
	display: table;
	@include font('regular', 13px, 16px, normal);
	background-color: $white;
	width: 100%;
	text-align: center;
	table-layout: fixed;

	&__wrapper {
		@include sm {
		}

		@include md {
			padding: 0;
		}
	}

	&__head-outer {
		background-color: $darkblue;

		@include sm {
			padding: 0 12px;
		}
	}

	&__content-outer {
		border-bottom: 1px solid $grey-3;
		padding: 16px 0 52px 0;

		@include sm {
			padding: 16px 12px 52px 12px;
			border: 1px solid $grey-3;
			border-top: 0;
		}
	}

	&__weekdays {
		display: table-row;
		background-color: $darkblue;
		color: $white;
		height: 49px;
		padding: 0 26px;
	}

	&__weekday {
		display: table-cell;
		vertical-align: middle;
	}

	&__weeks {
		border: 1px solid $grey-3;
		border-top: none;
		position: relative;
		display: table-row-group;
	}

	&__week {
		display: table-row;
		padding-bottom: 27px;

		&:not(:first-child) {
			border-top: 1px solid $grey-3;
		}

		&:first-child {
			align-self: flex-end;
		}

		&:last-child {
			align-self: flex-start;
		}
	}

	&__day {
		display: table-cell;
		position: relative;
		z-index: 1;
		height: 30px;
		vertical-align: middle;
		padding: 15px 0 25px 0;
		margin: 0 14px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		^&__week:not(:first-child) & {
			&.is-current-month {
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 1px;
					background: $grey-3;
					width: 100%;
				}

				&:first-child {
					&:before {
						right: 0;
						left: initial;
						width: 70%;
					}
				}

				&:last-child {
					&:before {
						width: 70%;
					}
				}
			}
		}

		&.is-current-day {
			&:after {
				z-index: -1;
				content: '';
				border-radius: 50%;
				size: 30px;
				border: 2px solid $darkblue;
				position: absolute;
				left: 50%;
				top: calc(50% - 5px);
				transform: translate3d(-50%, -50%, 0);
			}
		}

		&.has-events {
			color: $white;
			&:after {
				z-index: -1;
				content: '';
				background-color: $orange;
				border-radius: 50%;
				size: 30px;
				position: absolute;
				left: 50%;
				top: calc(50% - 5px);
				transform: translate3d(-50%, -50%, 0);
			}
		}

		&.is-another-month {
			font-size: 0;
		}
	}
}
