.ASD-address-select {
	&__input {
		appearance: none !important;
		position: relative !important;
		height: $input-height !important;
		background-color: $anthracite-5 !important;
		color: $anthracite !important;
		border: 0 !important;
		border-bottom: 1px solid $anthracite-40 !important;
		border-radius: 0 !important;
		transition: border-color 250ms ease !important;
		padding: 22px 10px 0 !important;
		width: 100% !important;
		margin: 0 !important;

		&.is-invalid {
			border-color: $signal-red !important;
		}

		&[disabled] {
			color: $anthracite-80 !important;

			&:placeholder-shown,
			&.is-filled {
				color: $anthracite-80 !important;
				opacity: 0.5 !important;
			}
		}

		&:focus {
			outline: none !important;
			border-color: $anthracite !important;
		}

		&::placeholder {
			color: $anthracite-80 !important;
		}

		&--flyout {
			background-color: white !important;
			height: 30px !important;
			padding: 0 !important;
			margin-top: 10px !important;
			@include font('regular', 16px, 18px, normal);
			color: $typo-color !important;
			border-radius: 0 !important;

			@include md {
				display: none !important;
			}
		}
	}

	&__error {
		display: none;
		padding: 3px 10px 0;
		@include font('regular', 14px, 16px, normal);
		max-width: 100%;
		color: $signal-red;

		^&.is-invalid:not(:focus) ~ & {
			display: block;
		}
	}

	&__wrapper {
		visibility: hidden;
		pointer-events: none;
		z-index: -0;

		@include <md {
			height: 100dvh;
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
		}

		&.is-visible {
			visibility: visible;
			pointer-events: auto;
			z-index: 50;
		}
	}

	&__bg {
		position: fixed;
		top: 0;
		left: 0;
		background-color: black;
		opacity: 0;
		width: 100dvw;
		height: 100dvh;
		visibility: hidden;
		pointer-events: none;
		transition: opacity 0.3s ease-in-out;
		z-index: 10;

		@include md {
			display: none;
		}

		^&__wrapper.is-visible & {
			visibility: visible;
			opacity: 0.2;
			pointer-events: auto;
		}
	}

	&__flyout {
		z-index: 50;
		background-color: white;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100dvw;
		height: 50vh;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.22);
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		padding: 20px;
		transform: translateY(100%);
		transition: transform 0.3s ease;

		@include md {
			position: absolute;
			bottom: unset;
			top: 52px;
			left: 0;
			width: 100%;
			height: auto;
			border-radius: 0;
			padding: 0;
			transform: none;
			transition: none;
			display: none;
		}

		^&__wrapper.is-visible & {
			transform: translateY(0);

			@include md {
				transform: none;
				display: block;
			}
		}

		&--big {
			@include md {
				top: 82px;
			}
		}

		&-top {
			display: flex;
			justify-content: flex-end;
			width: 100%;

			@include md {
				display: none;
			}
		}

		&-close {
			font-size: 0;
			margin-bottom: 5px;
			background-color: transparent;
			border: 0;
			text-align: right;

			&:before {
				font-size: 1rem;
				margin: 0 !important;
				color: $typo-color;
			}

			@include md {
				display: none;
			}
		}

		&-label {
			@include font('regular', 15px, 16px);
			color: $grey-1;

			@include md {
				display: none;
			}
		}

		&-option-list {
			@include <md {
				overflow: scroll;
				height: calc(100% - 60px);
			}
		}

		&-option {
			padding: 8px 0;
			width: 100%;
			border: 0;
			background-color: transparent;
			text-align: left;
			transition: background-color 250ms ease;
			color: inherit;
			display: flex;
			flex-direction: column;
			gap: 2px;

			@include md {
				display: block;
				padding: 10px 17px;
			}

			&:hover,
			&.is-selected {
				background-color: $anthracite-20;
			}
		}

		.main-text {
			@include font('bold', 16px, 18px, normal);
			color: $typo-color;
			pointer-events: none;
		}

		.secondary-text {
			@include font('regular', 16px, 18px, normal);
			color: $grey-2;
			pointer-events: none;

			@include md {
				&::before {
					content: ',';
					margin-right: 5px;
				}
			}
		}
	}
}
