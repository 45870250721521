.contractWidgetTabPane {
	@for $i from 1 to 10 {
		#tab$(i):checked ~ &__holder .tab$(i) {
			display: block;
		}

		#tab$(i):checked ~ &__nav [for='tab$(i)']::after {
			transform: scaleY(1);
		}
	}

	&__headline {
		@include font('bold', 13px, 16px, 0px);
		color: $grey-2;

		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}

	&__copy {
		@include font('regular', 13px, 16px, 0px);
		color: $grey-2;
	}

	&__text {
		&:not(:first-child) {
			margin-top: 30px;
			@include md {
				margin-top: 93px;
			}
		}
	}

	&__holder {
		display: block;
		margin-left: 0;
		clear: both;
	}

	&__tab {
		display: none;
		width: 100%;
		background: #fff;
		line-height: 1.5em;
		letter-spacing: 0.3px;
		color: #444;
	}

	&__toggle {
		display: none !important;
	}

	&__nav {
		border-bottom: 1px solid $grey-3;
		margin-bottom: 30px;
	}

	&__nav-item {
		display: inline-block;

		&:not(:first-child) {
			margin-left: 15px;
		}
	}

	&__nav-label {
		@include font('black', 12px, 14px, 0.8px);
		cursor: pointer;
		display: block;
		text-transform: uppercase;
		padding: 14px 0;
		position: relative;
		// border-bottom: 4px solid $orange;

		&::after {
			content: '';
			width: 100%;
			height: 4px;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			background: $orange;
			transition: transform 250ms ease;
			transform-origin: bottom;
			transform: scaleY(0);

			^&:hover& {
				transform: scaleY(1);
			}
		}
	}
}
