$input-height: 52px;
$typo-color: $anthrazit;

$orange: #eb730f;
$orange-70: #f19d57;

$gradient-orange: linear-gradient(-90deg, #f07814 0%, #f9b000 100%);

$yellow: #fece43;
$yellow-20: #fff5d6;

$green: #00894c;
$green-20: #d3eeda;
$green-15: #def5e4;

$anthracite: #253746;
$anthracite-80: #515f6b;
$anthracite-60: #7c8790;
$anthracite-40: #a8afb5;
$anthracite-20: #d3d7da;
$anthracite-10: #e9ebec;
$anthracite-5: #f4f5f6;

$red: #e21e36;
$red-15: #fbdde1;

body {
	color: $typo-color;
}
