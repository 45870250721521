.u-loading-dots {
	display: inline-block;
	position: relative;
	size: 80px;

	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #fff;

		^&--dark& {
			background: $typo-color;
		}

		^&--orange& {
			background: $orange;
		}
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	div:nth-child(1) {
		left: 8px;
		animation: loading-dot-1 0.6s infinite;
	}
	div:nth-child(2) {
		left: 8px;
		animation: loading-dot-2 0.6s infinite;
	}
	div:nth-child(3) {
		left: 32px;
		animation: loading-dot-2 0.6s infinite;
	}
	div:nth-child(4) {
		left: 56px;
		animation: loading-dot-3 0.6s infinite;
	}
}
@keyframes loading-dot-1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes loading-dot-3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes loading-dot-2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}
