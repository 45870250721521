.quotationBox {
	&__wrapper {
		background-color: $grey-4;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 60px 15px;
		text-align: center;

		@include md {
			padding: 60px $ce-side-padding;
		}
	}

	&__prehead {
		color: $grey-1;
		margin-bottom: 20px !important;
		@include font('headline', 13px, 15px, 3px);

		@include md {
			font-size: 16px;
			line-height: 18px;
			letter-spacing: 4.3px;
		}
	}

	&__quote {
		@include font('headline', 28px, 33px, normal);
		color: $orange;
		margin-bottom: 40px;
		width: 100%;

		&:before {
			content: '„';
			display: inline-block;
		}

		&:after {
			content: '“';
			display: inline-block;
		}

		@include lg {
			line-height: 36px;
		}
	}

	&__author {
		@include font('regular', 18px, 26px, normal);
		color: $grey-1;

		@include lg {
			font-size: 15px;
			line-height: 20px;
		}
	}

	blockquote {
		width: 100%;
		order: 2;
	}
}
