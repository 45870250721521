.calendarListPagination {
	display: flex;
	justify-content: space-between;
	padding: 6px 0;

	margin-top: 25px;

	&__dots {
		position: relative;
		z-index: 1;
		line-height: 0;
	}

	&__dot {
		display: inline-block;
		font-size: 0;
		line-height: 0;
		&:not(:last-child) {
			margin-right: 10px;
		}
	}

	&__dot-button {
		size: 10px;
		background-color: $darkblue;
		border: none;
		border-radius: 100%;
		transition: background-color 250ms ease;
		&.is-active {
			background-color: $orange;
		}
		&:hover {
			background-color: $orange;
		}
	}

	&__navig-button {
		position: relative;
		font-size: 0;
		transition: transform 150ms ease;
		background-color: transparent;
		border: 0;
		color: $darkblue;

		&[disabled] {
			&:before {
				color: $grey-2;
			}
		}

		&:last-child:before {
			right: 0;
		}

		&:before {
			color: $darkblue;
			font-size: 22px;
			position: absolute;
			top: 50%;
			margin-right: 0 !important;
			transform: translateY(-50%);
			transition: transform 250ms ease, color 250ms ease;
		}

		&:hover:before {
			transform: translateY(-50%) scale(1.25);
		}
	}
}
