.ASD-info-box {
	padding: 20px;
	border-radius: 12px;
	color: $anthracite;
	background-color: $yellow-20;

	&__headline {
		margin-bottom: 10px;

		&:before {
			color: $yellow;
			margin-right: 7px !important;
		}
	}

	&--success {
		background-color: $green-20;

		^&__headline:before {
			color: $green;
		}
	}

	&--alert {
		background-color: $red-15;

		^&__headline:before {
			color: $red;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}

	&__text {
		display: flex;
		align-items: center;
		&:before {
			font-size: 22.5px;
		}
	}
}
