.member-status-badge {
	position: relative;
	background: linear-gradient(90deg, #f07814 0%, #f9b000 100%);
	font-size: 12px;
	border-radius: 200px;
	padding: 1px 9px;
	font-weight: bold;
	border: 3px solid;
	overflow: hidden;
	text-align: center;

	&::before {
		content: '';
		position: absolute;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
		width: 40px;
		height: 80px;
		left: -100px;
		top: -40px;
		animation-name: shine;
		animation-duration: 4s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-out;
		opacity: 0.5;
	}

	&:not(.gold, .silver, .platinum) {
		display: none;
	}

	&.gold {
		background: linear-gradient(90deg, #f07814 0%, #f9b000 100%);
		border-color: #fce4d0;
		color: white;
	}

	&.silver {
		background: linear-gradient(90deg, #e8e8e8 0%, #fff 100%);
		border-color: $grey-4;
		color: $typo-color;
	}

	&.platinum {
		background: linear-gradient(90deg, #233746 0%, #666 100%);
		border-color: #b2b9be;
		color: $grey-3;
	}
}

@keyframes shine {
	0% {
		transform: translateX(0) rotate(23deg);
	}
	40% {
		transform: translateX(200px) rotate(23deg);
	}
	100% {
		transform: translateX(200px) rotate(23deg);
	}
}
