.bounties {
	&__form {
		@include sm {
			padding: 0 $ce-side-padding;
		}

		@include lg {
			padding: 0;
		}
	}

	&__list {
		position: relative;
		font-size: 0;
		margin: 0 -10px;
		padding: 0 10px;
		display: grid;
		gap: 40px;

		@include sm {
			grid-template-columns: 1fr 1fr;
			gap: 40px 20px;
			padding: 0 10px;
		}

		@include lg {
			padding: 0 calc($ce-side-padding + 10px);
			grid-template-columns: 1fr 1fr;
		}
	}

	&__item {
		width: 100%;
		display: block;
	}

	&__label {
		cursor: pointer;
	}

	&__image {
		width: 100%;
		height: auto;
		pointer-events: none;
		user-select: none;
		margin-bottom: 15px;

		@include lg {
			width: 100%;
			height: auto;
		}
	}

	input[type='radio']&__radio {
		margin-bottom: 0;
		margin-right: 15px;
		vertical-align: middle;
	}

	&__caption {
		vertical-align: middle;
		@include font('regular', 15px, 20px, 0);

		@include xl {
			@include font('regular', 21px, 26px, 0);
		}
	}

	&__block {
		@include lg {
			padding: 0 $ce-side-padding;
		}
	}

	&__text-between {
		margin-bottom: 50px;

		p {
			margin-top: 20px;
		}
	}

	&__own-value {
		@include font('regular', 21px, 26px, 0);
	}

	&__success-message {
		a:not(.btn) {
			background-image: linear-gradient($orange, $orange);
			&:hover {
				background-image: linear-gradient($orange, $orange);
				color: $orange;
			}
		}
	}
}
