.ASD-button {
	position: relative;

	@include font('bold', 14px, 17px, 0.01em);
	user-select: none;
	background: none;

	a& {
		background-image: none !important;
	}

	&--primary,
	&--secondary,
	&--tertiary,
	&--special,
	&--green {
		border: 0;
		width: 100%;
		min-height: 43px;
		border-radius: 12px;

		padding: 10px 32px;

		display: inline-flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		z-index: 1;

		transition: background-color 250ms ease, border-color 250ms ease, color 250ms ease;

		&:focus-visible {
			outline: none;
		}

		&:before {
			content: '';
			position: absolute;
			display: block;
			left: 2px;
			top: 2px;
			width: 0;
			height: calc(100% - 4px);
			background: white;
			border-radius: 10px;
			z-index: -1;
			visibility: hidden;
			opacity: 0;
			transition: opacity 250ms ease, width 250ms ease, visibility 250ms ease;
		}

		&:focus-visible {
			&:before {
				width: calc(100% - 4px);
				visibility: visible;
				opacity: 1;
			}
		}

		@media (hover: hover) {
			&:hover:not([disabled]) {
				&:before {
					width: calc(100% - 4px);
					visibility: visible;
					opacity: 1;
				}
			}
		}

		@include md {
			width: auto;
			min-width: 200px;
		}
	}

	&--primary {
		background: $anthracite;
		color: white;

		@media (hover: hover) {
			&:hover {
				color: $anthracite;
			}
		}
		&:focus-visible {
			color: $anthracite;
		}

		&:not(^&--loading)[disabled] {
			background: $anthracite-60;
			border-color: $anthracite-60;
		}
	}

	&--secondary {
		background: $anthracite-20;
		border-color: $anthracite-20;
		color: $anthracite;

		&:not(^&--loading)[disabled] {
			color: $anthracite-60;
			background: $anthracite-10;
			border-color: $anthracite-10;
		}
	}

	&--tertiary {
		background: white;
		border-color: white;
		color: $anthracite;

		&:before {
			background: var(--button-tertiary-hover-background-color, #f5f6f9);
		}

		@media (hover: hover) {
			&:hover {
				color: var(--button-tertiary-hover-color, $anthracite);
			}
		}
		&:focus-visible {
			color: var(--button-tertiary-hover-color, $anthracite);
		}

		&:not(^&--loading)[disabled] {
			color: $anthracite-60;
			background: $anthracite-20;
			border-color: $anthracite-20;
		}
	}

	&--special {
		background: $orange;
		border-color: $orange;
		color: white;

		&:focus-visible {
			border-color: $orange;
			color: $orange;
		}

		@media (hover: hover) {
			&:hover {
				border-color: $orange;
				color: $orange;
			}
		}
		&:focus-visible {
			border-color: $orange;
			color: $orange;
		}

		&:not(^&--loading)[disabled] {
			color: white;
			background: $orange-70;
			border-color: $orange-70;
		}
	}

	&--green {
		background: $green;
		border-color: $green;
		color: white;

		&:focus-visible {
			border-color: $green;
			color: $green;
		}

		@media (hover: hover) {
			&:hover {
				border-color: $green;
				color: $green;
			}
		}
		&:focus-visible {
			border-color: $green;
			color: $green;
		}
	}

	&--text {
		display: inline-block;
		color: $anthracite;
		background: transparent;
		text-decoration: underline;
		border: 0;
		transition: color 250ms ease;

		&:focus-visible {
			outline: none;
		}

		&:focus-visible {
			@media not (hover: none) {
				color: $orange;
			}
			background: transparent;
		}

		@media (hover: hover) {
			&:hover {
				color: $orange;
				background: transparent;
			}
		}

		&[disabled] {
			color: $anthracite-60;
		}
	}

	&--loading {
		color: transparent !important;
		background-image: url('./../images/loading-blue.webp');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 40px auto;
		pointer-events: none;
		^&--primary&,
		^&--special& {
			background-image: url('./../images/loading-white.webp');
		}
	}
}
