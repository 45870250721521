.mediakit {
	&__wrapper {
		background-color: $darkblue;
		color: white;
	}

	&__header {
		padding: 22px 20px 13px;
	}

	&__headline {
		@include font('headline', 34px, 38px, normal);
	}

	&__copy {
		@include font('regular', 15px, 20px, normal);
		padding-bottom: 11px;

		&:not(:first-child) {
			margin-top: 12px;
		}
	}

	&__item {
		position: relative;
		border-top: 1px solid $grey-1;

		&:before {
			content: '';
			position: absolute;
			width: 6px;
			height: calc(100% + 1px);
			transform: scaleX(0);
			transform-origin: left;
			transition: transform 250ms ease;
			left: 0;
			bottom: 0;
			background-color: $orange;
		}

		&:hover {
			&:before {
				transform: scaleX(1);
			}
		}

		&:after {
			font-size: 26px;
			position: absolute;
			right: 25px;
			top: 50%;
			transform: translateY(-50%);
			color: $orange;
			pointer-events: none;
		}
	}

	&__link {
		@include font('regular', 21px, 26px, normal);
		padding: 16px 70px 16px 20px;
		display: block;
		background: none;
		word-wrap: break-word;
		&:hover {
			background: none;
			color: inherit;
		}
	}
}
