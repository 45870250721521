.ASD-step-screen {
	max-width: 680px;
	margin: 0 auto;
	background-color: white;
	padding: 50px 0;
	border-radius: 12px;

	@include md {
		padding: 64px 0;
	}

	&__section {
		position: relative;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 15px;

		@include md {
			padding: 0 100px;
		}

		& + & {
			margin-top: 30px;

			@include md {
				margin-top: 20px;
			}
		}

		& + &--divider {
			margin-top: 80px;

			@include md {
				margin-top: 104px;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: -30px;
				left: 20px;
				width: calc(100% - 40px);
				height: 1px;
				background-color: $anthracite-40;

				@include md {
					top: -24px;
					left: 100px;
					width: calc(100% - 200px);
				}
			}
		}
	}
}
