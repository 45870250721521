.tour-advice {
	&__form {
		padding: 0px 40px;
	}

	&__headline {
		@include font('headline', 26px, 30px, 0px);
		color: $grey-1;
		text-align: center;
		margin-bottom: 30px;
	}

	&__copy {
		@include font('bold', 15px, 20px, 0);
		color: $grey-1;
		margin-bottom: 30px;
	}

	&__info {
		@include font('normal', 15px, 20px, 0);
		color: $grey-1;
		margin-bottom: 20px;
	}

	&__form-error,
	&__form-success {
		margin-bottom: 30px;
		padding: 0px 40px;
	}

	.is-hidden {
		display: none;
	}
}
