.u-text-split-epsilon {
	overflow: hidden;
	display: flex;

	span {
		white-space: nowrap;

		&:first-child {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
