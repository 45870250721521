.event-calendar {
	@include only-sm {
		padding: 0 30px;
	}

	&__loader {
		position: relative;
		size: 75px;
		margin: 0 auto;

		.ec-list & {
			margin: 25px auto;
		}

		&::after {
			content: '';
			display: block;
			size: 100%;
			border: 5px solid $orange;
			border-bottom-color: transparent;
			border-left-color: transparent;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			pointer-events: none;
			transition: opacity 0.2s;
			animation: rotate 0.9s ease-in-out infinite;
		}
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}
