.ce-textpic,
.ce-image {
	@include sm {
		padding: 0 $ce-side-padding;
		.no-default-ce-padding &{
			padding: 0;
		}
	}

	&.ce-nowrap {
		@include md {
			display: flex;
		}
		> div {
			flex-basis: 50%;
			order: 10;
		}

		@include sm {
			&.ce-right {
				> div {
					@include md {
						&:first-child {
							margin-left: calc($gutter / 2);
						}
						&:last-child {
							margin-right: calc($gutter / 2);
						}
					}
				}
			}

			&.ce-left {
				> div {
					@include md {
						&:first-child {
							margin-right: calc($gutter / 2);
						}
						&:last-child {
							margin-left: calc($gutter / 2);
						}
					}
				}
			}
		}
	}

	&:not(.ce-nowrap) {
		&.ce-intext {
			> div {
				&:first-child {
					margin-bottom: calc($gutter / 2);
				}
			}

			@include sm {
				&.ce-right {
					.ce-gallery {
						@include md {
							margin-left: $gutter;
						}
					}
				}

				&.ce-left {
					.ce-gallery {
						@include md {
							margin-right: $gutter;
						}
					}
				}
			}
		}
	}

	&:not(&--fixed-width) {
		> .ce-bodytext {
			flex: 1 1 auto;
		}
	}

	.image-caption {
		@include font('regular', 12px, 16px, normal);
		min-width: 100%;
		width: 0;
		padding-top: 5px;
	}

	.image a {
		background: none;
	}
}
