.u-bc- {
	&orange {
		^&debug& {
			background-color: $orange;
		}
		--button-tertiary-hover-background-color: $orange;
		--button-tertiary-hover-color: white;
	}

	&anthracite {
		^&debug& {
			background-color: $anthracite;
		}
		--button-tertiary-hover-background-color: $anthracite;
		--button-tertiary-hover-color: white;
	}

	&red-15 {
		^&debug& {
			background-color: $red-15;
		}
		--button-tertiary-hover-background-color: $red-15;
		--button-tertiary-hover-color: $anthracite;
	}

	&yellow-20 {
		^&debug& {
			background-color: $yellow-20;
		}
		--button-tertiary-hover-background-color: $yellow-20;
		--button-tertiary-hover-color: $anthracite;
	}

	&green-20 {
		^&debug& {
			background-color: $green-20;
		}
		--button-tertiary-hover-background-color: $green-20;
		--button-tertiary-hover-color: $anthracite;
	}
}
