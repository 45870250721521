.profile-app {
	margin-top: -60px;

	@include md {
		margin-top: -30px;
	}

	@include lg {
		margin-top: -15px;
	}

	&__image {
		width: 105vw;
		height: auto;
		margin: 0 auto;

		@include lg {
			max-width: 1690px;
		}
	}

	&__text-container  {
		color: $white;
		text-align: center;
		margin-top: 10px;

		@include lg {
			margin-top: 90px;
		}
	}

	&__headline {
		@include font('', 25px, 25px);
		margin-bottom: 34px;
		display: inline-block;
		position: relative;
		text-transform: uppercase;

		@include sm {
			@include font('', 35px, 35px);
		}

		@include lg {
			@include font('', 72px, 66px);
		}

		&::after {
			content: '';
			width: 100%;
			height: 5px;
			background: $white;
			position: absolute;
			display: block;
			left: 0;
			top: 28px;

			@include sm {
				top: 38px;
				height: 7px;
			}

			@include lg {
				top: 72px;
			}
		}
	}

	&__copy {
		margin: 0 auto;
		width: 90%;

		@include md {
			padding-top: 20px;
		}

		@include lg {
			padding-top: 45px;
		}

		> p,
		ul,
		ol {
			@include font('', 18px, 25px);
			@include md {
				@include font('', 30px, 36px);
			}

			@include lg {
				@include font('', 52px, 66px, -0.5px);
			}
		}
	}

	&__store-image-container {
		display: flex;
		justify-content: center;
		margin-top: 40px;

		@include sm {
			margin-top: 60px;
		}

		@include lg {
			margin-top: 110px;
		}
	}

	&__store-image-item {
		& + & {
			margin-left: 20px;

			@include lg {
				margin-left: 40px;
			}
		}
	}

	&__store-image-link {
		&,
		&:hover {
			background: none;
		}
	}

	&__store-image {
		height: 40px;
		width: auto;

		@include sm {
			height: 60px;
		}

		@include lg {
			height: 80px;
		}
	}
}
