$act-animation-duration: 5s;
$act-animation-count: 1;

.animatedContactTeaser {
	&__bg-container {
		@include <md {
			width: 100%;
			height: 265px;
			margin-top: 15px;
		}

		@include md {
			max-width: unset;
			width: 626px;
			height: 413px;
		}
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0 auto;
	}

	&__images {
		display: flex;
		gap: 13px;
		height: 100%;
		justify-content: center;
		align-items: center;

		@include md {
			gap: 21px;
		}
	}

	&__image-container {
		position: relative;
		width: 90px;

		@include <md {
			width: 58px;
			transform: scale(0.64);
			transform-origin: left center;
		}
	}

	&__image-cell {
		position: absolute;
		width: 90px;
		overflow: hidden;
		border-radius: 45px;
		top: 0;
		transform: translate3d(0, -50%, 0);
		background-color: $orange;

		animation-duration: $act-animation-duration;
		animation-iteration-count: $act-animation-count;

		&[data-index='0'] {
			height: 159px;
			transform: translate3d(0, -50%, 0) translate3d(0, -45px, 0);
			^^&.is-active & {
				animation-name: animatedContactTeaser-cell-0;
			}

			& ^^&__image {
				transform: translate3d(0, -50%, 0) translate3d(0, 5px, 0);
				^1^&.is-active & {
					animation-name: animatedContactTeaser-image-0;
				}
			}
		}

		&[data-index='1'] {
			height: 293px;
			transform: translate3d(0, -50%, 0) translate3d(0, -10px, 0);
			^^&.is-active & {
				animation-name: animatedContactTeaser-cell-1;
			}

			& ^^&__image {
				transform: translate3d(0, -50%, 0) translate3d(0, -10px, 0);
				^^^&.is-active & {
					animation-name: animatedContactTeaser-image-1;
				}
			}
		}

		&[data-index='2'] {
			height: 209px;
			transform: translate3d(0, -50%, 0) translate3d(0, 5px, 0);
			^^&.is-active & {
				animation-name: animatedContactTeaser-cell-2;
			}

			& ^^&__image {
				transform: translate3d(0, -50%, 0) translate3d(0, -50px, 0);
				^^^&.is-active & {
					animation-name: animatedContactTeaser-image-2;
				}
			}
		}

		&[data-index='3'] {
			height: 174px;
			transform: translate3d(0, -50%, 0) translate3d(0, 30px, 0);
			^^&.is-active & {
				animation-name: animatedContactTeaser-cell-3;
			}

			& ^^&__image {
				transform: translate3d(0, -50%, 0) translate3d(0, 30px, 0);
				^^^&.is-active & {
					animation-name: animatedContactTeaser-image-3;
				}
			}
		}

		&[data-index='4'] {
			height: 170px;
			transform: translate3d(0, -50%, 0) translate3d(0, -65px, 0);
			^^&.is-active & {
				animation-name: animatedContactTeaser-cell-4;
			}

			& ^^&__image {
				transform: translate3d(0, -50%, 0) translate3d(0, 25px, 0);
				^^^&.is-active & {
					animation-name: animatedContactTeaser-image-4;
				}
			}
		}
	}

	&__image {
		width: 100%;
		height: auto;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate3d(0, -50%, 0);

		animation-duration: $act-animation-duration;
		animation-iteration-count: $act-animation-count;
	}

	&__contacts {
		display: flex;
		flex-direction: column;
		gap: 36px 70px;
		justify-content: center;
		margin-top: 7px;

		@include md {
			flex-wrap: wrap;
			flex-direction: row;
		}
	}

	&__contact {
		color: white;
		text-align: center;
	}

	&__contact-name {
		@include font('', 24px, 31px, normal);

		@include md {
			@include font('', 24px, 28px, normal);
		}
	}

	&__contact-link {
		@include font('regular', 21px, 26px, normal);

		@include md {
			background-image: none;
		}
	}

	&__cta {
		margin: 38px auto 0 auto;
		display: table;

		@include md {
			margin: 53px auto 0 auto;
		}
	}
}

@keyframes animatedContactTeaser-cell-0 {
	0% {
		height: 159px;
		transform: translate3d(0, -50%, 0) translate3d(0, -45px, 0);
	}
	50% {
		height: 264px;
		transform: translate3d(0, -50%, 0) translate3d(0, 30px, 0);
	}
	100% {
		height: 159px;
		transform: translate3d(0, -50%, 0) translate3d(0, -45px, 0);
	}
}

@keyframes animatedContactTeaser-image-0 {
	0% {
		transform: translate3d(0, -50%, 0) translate3d(0, 5px, 0);
	}
	50% {
		transform: translate3d(0, -50%, 0) translate3d(0, -20px, 0);
	}
	100% {
		transform: translate3d(0, -50%, 0) translate3d(0, 5px, 0);
	}
}

@keyframes animatedContactTeaser-cell-1 {
	0% {
		height: 293px;
		transform: translate3d(0, -50%, 0) translate3d(0, -10px, 0);
	}
	50% {
		height: 193px;
		transform: translate3d(0, -50%, 0) translate3d(0, 10px, 0);
	}
	100% {
		height: 293px;
		transform: translate3d(0, -50%, 0) translate3d(0, -10px, 0);
	}
}

@keyframes animatedContactTeaser-image-1 {
	0% {
		transform: translate3d(0, -50%, 0) translate3d(0, -10px, 0);
	}
	50% {
		transform: translate3d(0, -50%, 0) translate3d(0, -55px, 0);
	}
	100% {
		transform: translate3d(0, -50%, 0) translate3d(0, -10px, 0);
	}
}

@keyframes animatedContactTeaser-cell-2 {
	0% {
		height: 209px;
		transform: translate3d(0, -50%, 0) translate3d(0, 5px, 0);
	}
	50% {
		height: 301px;
		transform: translate3d(0, -50%, 0) translate3d(0, -5px, 0);
	}
	100% {
		height: 209px;
		transform: translate3d(0, -50%, 0) translate3d(0, 5px, 0);
	}
}

@keyframes animatedContactTeaser-image-2 {
	0% {
		transform: translate3d(0, -50%, 0) translate3d(0, -50px, 0);
	}
	50% {
		transform: translate3d(0, -50%, 0) translate3d(0, -5px, 0);
	}
	100% {
		transform: translate3d(0, -50%, 0) translate3d(0, -50px, 0);
	}
}

@keyframes animatedContactTeaser-cell-3 {
	0% {
		height: 174px;
		transform: translate3d(0, -50%, 0) translate3d(0, 30px, 0);
	}
	50% {
		height: 217px;
		transform: translate3d(0, -50%, 0) translate3d(0, -5px, 0);
	}
	100% {
		height: 174px;
		transform: translate3d(0, -50%, 0) translate3d(0, 30px, 0);
	}
}

@keyframes animatedContactTeaser-image-3 {
	0% {
		transform: translate3d(0, -50%, 0) translate3d(0, 30px, 0);
	}
	50% {
		transform: translate3d(0, -50%, 0) translate3d(0, 15px, 0);
	}
	100% {
		transform: translate3d(0, -50%, 0) translate3d(0, 30px, 0);
	}
}

@keyframes animatedContactTeaser-cell-4 {
	0% {
		height: 170px;
		transform: translate3d(0, -50%, 0) translate3d(0, -65px, 0);
	}
	50% {
		height: 295px;
		transform: translate3d(0, -50%, 0) translate3d(0, -20px, 0);
	}
	100% {
		height: 170px;
		transform: translate3d(0, -50%, 0) translate3d(0, -65px, 0);
	}
}

@keyframes animatedContactTeaser-image-4 {
	0% {
		transform: translate3d(0, -50%, 0) translate3d(0, 25px, 0);
	}
	50% {
		transform: translate3d(0, -50%, 0) translate3d(0, 0px, 0);
	}
	100% {
		transform: translate3d(0, -50%, 0) translate3d(0, 25px, 0);
	}
}
