// NO MARGIN
.u-ce-margin-top-none {
	margin-top: 0 !important;
}

.u-ce-margin-bottom-none {
	margin-bottom: -$content-element-margin !important;

	@include sm {
		margin-bottom: -$content-element-margin-sm !important;
	}

	@include md {
		margin-bottom: -$content-element-margin-md !important;
	}

	@include lg {
		margin-bottom: -$content-element-margin-lg !important;
	}

	@include xl {
		margin-bottom: -$content-element-margin-xl !important;
	}

	@include xxl {
		margin-bottom: -$content-element-margin-xxl !important;
	}
}

.u-ce-margin-bottom-none-md-lg {
	@include sm {
		margin-bottom: -$content-element-margin-sm !important;
	}

	@include md {
		margin-bottom: -$content-element-margin-md !important;
	}

	@include lg {
		margin-bottom: -$content-element-margin-lg !important;
	}

	@include xl {
		margin-bottom: -$content-element-margin-xl !important;
	}

	@include xxl {
		margin-bottom: -$content-element-margin-xxl !important;
	}
}

// MEDIUM MARGIN – TOP

@mixin u-ce-margin-top-medium {
	margin-top: $content-element-margin-medium !important;

	@include sm {
		margin-top: $content-element-margin-medium-sm !important;
	}

	@include md {
		margin-top: $content-element-margin-medium-md !important;
	}

	@include lg {
		margin-top: $content-element-margin-medium-lg !important;
	}

	@include xl {
		margin-top: $content-element-margin-medium-xl !important;
	}

	@include xxl {
		margin-top: $content-element-margin-medium-xxl !important;
	}
}

.u-ce-margin-top-medium {
	@include u-ce-margin-top-medium;
}

// MEDIUM MARGIN - BOTTOM

@mixin u-ce-margin-bottom-medium {
	margin-bottom: calc($content-element-margin-medium - $content-element-margin) !important;

	@include sm {
		margin-bottom: calc($content-element-margin-medium-sm - $content-element-margin-sm) !important;
	}

	@include md {
		margin-bottom: calc($content-element-margin-medium-md - $content-element-margin-md) !important;
	}

	@include lg {
		margin-bottom: calc($content-element-margin-medium-lg - $content-element-margin-lg) !important;
	}

	@include xl {
		margin-bottom: calc($content-element-margin-medium-xl - $content-element-margin-xl) !important;
	}

	@include xxl {
		margin-bottom: calc($content-element-margin-medium-xxl - $content-element-margin-xxl) !important;
	}
}

.u-ce-margin-bottom-medium {
	@include u-ce-margin-bottom-medium;
}

.content-element + .u-ce-margin-top-force-standard,
.u-ce-margin-top-force-standard {
	margin-top: $content-element-margin !important;

	@include sm {
		margin-top: $content-element-margin-sm !important;
	}

	@include md {
		margin-top: $content-element-margin-md !important;
	}

	@include lg {
		margin-top: $content-element-margin-lg !important;
	}

	@include xl {
		margin-top: $content-element-margin-xl !important;
	}

	@include xxl {
		margin-top: $content-element-margin-xxl !important;
	}
}

.content-element + .u-ce-margin-bottom-force-standard,
.u-ce-margin-bottom-force-standard {
	margin-bottom: $content-element-margin !important;

	@include sm {
		margin-bottom: $content-element-margin-sm !important;
	}

	@include md {
		margin-bottom: $content-element-margin-md !important;
	}

	@include lg {
		margin-bottom: $content-element-margin-lg !important;
	}

	@include xl {
		margin-bottom: $content-element-margin-xl !important;
	}

	@include xxl {
		margin-bottom: $content-element-margin-xxl !important;
	}
}

// Smaller margin templates

.have-small-margin {
	&.u-ce-margin-bottom-none {
		margin-bottom: -$content-element-margin-small !important;

		@include sm {
			margin-bottom: -$content-element-margin-small-sm !important;
		}

		@include md {
			margin-bottom: -$content-element-margin-small-md !important;
		}

		@include lg {
			margin-bottom: -$content-element-margin-small-lg !important;
		}

		@include xl {
			margin-bottom: -$content-element-margin-small-xl !important;
		}

		@include xxl {
			margin-bottom: -$content-element-margin-small-xxl !important;
		}
	}

	&.u-ce-margin-top-medium {
		margin-top: $content-element-margin-tiny !important;

		@include sm {
			margin-top: $content-element-margin-tiny-sm !important;
		}

		@include md {
			margin-top: $content-element-margin-tiny-md !important;
		}

		@include lg {
			margin-top: $content-element-margin-tiny-lg !important;
		}

		@include xl {
			margin-top: $content-element-margin-tiny-xl !important;
		}

		@include xxl {
			margin-top: $content-element-margin-tiny-xxl !important;
		}
	}

	&.u-ce-margin-bottom-medium {
		margin-bottom: calc($content-element-margin-tiny - $content-element-margin-small) !important;

		@include sm {
			margin-bottom: calc($content-element-margin-tiny-sm - $content-element-margin-small-sm) !important;
		}

		@include md {
			margin-bottom: calc($content-element-margin-tiny-md - $content-element-margin-small-md) !important;
		}

		@include lg {
			margin-bottom: calc($content-element-margin-tiny-lg - $content-element-margin-small-lg) !important;
		}

		@include xl {
			margin-bottom: calc($content-element-margin-tiny-xl - $content-element-margin-small-xl) !important;
		}

		@include xxl {
			margin-bottom: calc($content-element-margin-tiny-xxl - $content-element-margin-small-xxl) !important;
		}
	}

	&.content-element + &.u-ce-margin-top-force-standard,
	&.u-ce-margin-top-force-standard {
		margin-top: $content-element-margin-tiny !important;

		@include sm {
			margin-top: $content-element-margin-tiny-sm !important;
		}

		@include md {
			margin-top: $content-element-margin-tiny-md !important;
		}

		@include lg {
			margin-top: $content-element-margin-tiny-lg !important;
		}

		@include xl {
			margin-top: $content-element-margin-tiny-xl !important;
		}

		@include xxl {
			margin-top: $content-element-margin-tiny-xxl !important;
		}
	}

	&.content-element + &.u-ce-margin-bottom-force-standard,
	&.u-ce-margin-bottom-force-standard {
		margin-bottom: $content-element-margin-tiny !important;

		@include sm {
			margin-bottom: $content-element-margin-tiny-sm !important;
		}

		@include md {
			margin-bottom: $content-element-margin-tiny-md !important;
		}

		@include lg {
			margin-bottom: $content-element-margin-tiny-lg !important;
		}

		@include xl {
			margin-bottom: $content-element-margin-tiny-xl !important;
		}

		@include xxl {
			margin-bottom: $content-element-margin-tiny-xxl !important;
		}
	}
}
