.ASD-modal {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9001;

	width: 100vw;
	height: 100dvh;

	display: flex;
	justify-content: center;
	align-items: center;

	padding: 15px;

	&__transition-wrapper {
		transition: opacity 0.3s;
		position: relative;
		z-index: 9001;
	}

	&__shadow-box {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0.3;
	}

	&__box {
		position: relative;
		width: 100%;
		max-width: 560px;
		background-color: white;
		padding: 20px 15px;
		border-radius: 10px;

		@include md {
			padding: 20px;
		}
	}

	&__close-btn {
		position: absolute;
		right: 0;
		top: 0;
	}

	&__headline {
		position: relative;
		padding-left: 30px;
		&:before {
			left: -1px;
			font-size: 26px;
			margin-right: 10px !important;
			position: absolute;
		}
	}

	&__password-row {
		margin-top: 15px !important;
	}

	&__controls {
		display: flex;
		max-width: 300px;
		gap: 10px;
		flex-direction: column;
	}
}
