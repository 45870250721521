/*=============================================
=            sticky-linkbox            =
=============================================*/

.stickyLinkbox {
	background: white;

	&__category-content {
		padding: 40px 0;

		p {
			@include font('regular', 15px, 20px, 0);
			color: $typo-color;
		}

		p:not(:last-child) {
			margin-bottom: 15px;
		}
	}

	&__content {
		position: relative;
		padding: 0;

		@include sm {
			padding: 0 $ce-side-padding;
		}

		@include md {
			padding: 0 0 0 $ce-side-padding;
		}

		&::after {
			content: '';
			display: block;
			width: 200vw;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate3d(-50%, 0, 0);
			background: white;

			@include sm {
				display: none;
			}
		}
	}

	&__headline-1 {
		@include font('headline', 34px, 38px, 0);
		color: $darkblue;
		padding: 40px 0;
		position: relative;
		z-index: 1;
	}

	&__headline-2 {
		@include font('headline', 28px, 28px, 0);
		color: $orange;

		&:not(:last-child) {
			margin-bottom: 40px;
		}

		&::after {
			background: $orange;
			content: '';
			display: block;
			height: 6px;
			margin-top: 20px;
			width: 80px;
		}
	}

	&__item {
		position: relative;
		border-bottom: 1px solid $grey-4;

		&:last-child {
			border: none;
		}

		&::before {
			content: '';
			width: 6px;
			height: 100%;
			background: $grey-3;
			position: absolute;
			left: 0;
			top: 0;
			transform: scaleX(0);
			transform-origin: left;
			transition: transform 0.3s ease;

			.is-active:not(.is-disabled)&,
			:hover& {
				transform: scaleX(1);
			}
		}
	}

	&__list {
		position: relative;
		z-index: 2;
	}

	&__link {
		display: block;
		text-decoration: none;
		padding: 20px;
		opacity: 1;
		transition: opacity 250ms ease;
		background: none;

		&:hover {
			background: none;
		}

		.is-disabled & {
			opacity: 0.25;
			&:hover {
				opacity: 1;
			}
		}
	}

	&__navigation {
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
	}

	&__search {
		position: relative;
		background: $darkblue;
		padding: 40px 0;

		&::after {
			content: '';
			top: 0;
			display: block;
			width: 150vw;
			height: 100%;
			position: absolute;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			background: $darkblue;
			z-index: 0;

			@include sm {
				display: none;
			}
		}
	}

	&__search-head {
		@include font('headline', 28px, 32px, 0);
		text-align: center;
		color: white;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__search-holder {
		position: relative;
		z-index: 2;

		@include sm {
			padding: 0 $ce-side-padding;
		}
	}

	&__search-input {
		display: block;
		width: 100%;
		height: 58px;
		padding: 0 10px;
		border-radius: 0px;
	}

	&__search-label {
		@include font('regular', 15px, 20px, 0);
		display: block;
		color: $grey-2;

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	&__sidebar {
		display: none;
		padding: 40px 0;

		@include md {
			display: block;
		}
	}
}

/*=====  End of sticky-linkbox  ======*/
