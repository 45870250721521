.ec-pdf-generator {
	display: flex;
	align-items: center;
	margin: 0 auto;
	position: relative;
	padding-left: 16px;
	flex-shrink: 0;
	outline: none;

	@include md {
		margin: initial;
	}

	&:before {
		content: '$acv-icons-var-download';
		font-family: 'acv-icons';
		font-size: 12px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	& button {
		border: 0;
		color: inherit;
		padding-bottom: 2px;
		text-decoration: none;
		background: linear-gradient(#ffffff, #ffffff);
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		background-image: linear-gradient($orange, $orange);
		background-size: 1px 2px;
		&:hover {
			cursor: pointer;
			color: $orange;
		}
	}
}
