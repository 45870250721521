.u-show {
	&-block {
		display: block !important;

		&-only-sm {
			@include only-sm {
				display: block;
			}
		}

		&-sm {
			@include sm {
				display: block;
			}
		}

		&-only-md {
			@include only-md {
				display: block;
			}
		}

		&-md {
			@include md {
				display: block;
			}
		}

		&-only-lg {
			@include only-lg {
				display: block;
			}
		}

		&-lg {
			@include lg {
				display: block;
			}
		}
	}
}
