.leasingForm {
	position: relative;

	&__white-wrapper {
		position: relative;
		padding-top: 25px !important;
		padding-bottom: 25px !important;

		@include md {
			padding-top: 50px !important;
			padding-bottom: 50px !important;
		}

		&:before,
		&:after {
			width: 100vw;
			height: 100%;
			display: block;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 0;
			background: white;

			@include sm {
				width: calc(100vw - 60px);
			}

			@include md {
				width: 690px;
			}

			@include lg {
				width: 930px;
			}

			@include xl {
				width: 1110px;
			}
			@include xxl {
				width: 1250px;
			}
		}

		&:before {
			content: '';
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.22);
		}

		&--cover-top {
			padding-top: 0 !important;
			&:after {
				content: '';
				top: -20px;
				height: 20px;
			}
		}

		& + & {
			margin-top: $content-element-margin !important;

			@each $val, $i in sm, md, lg, xl, xxl {
				@include $(val) {
					margin-top: $content-element-margin-$(val) !important;
				}
			}
		}
	}

	.form {
		@include sm {
			padding: 0 $ce-side-padding;
		}
	}

	[data-step]:not(.is-active) {
		display: none;
	}

	&__step {
		position: relative;
	}

	&__sum {
		margin-top: $content-element-margin !important;

		@each $val, $i in sm, md, lg, xl, xxl {
			@include $(val) {
				margin-top: $content-element-margin-$(val) !important;
			}
		}

		&.is-fixed {
			bottom: 0;
			position: fixed;
			margin-bottom: 0 !important;
			z-index: 12;
			padding: 15px 0 !important;
		}

		^&.has-keyboard & {
			display: none;
		}
	}

	&__sum-wrapper {
		background-color: #fff;
		position: relative;
	}

	&__sum-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;

		@include xl {
			flex-direction: row;
		}

		& + & {
			margin-top: 10px;

			@include xl {
				margin-top: 30px;
			}
		}
	}

	&__sum-head {
		@include font('headline', 17px, 19px, normal);
		color: $darkblue;
		margin-bottom: 10px;
	}

	&__content-wrapper {
		flex: 1 1 auto;

		&--right {
			flex-shrink: 0;
			flex-grow: 0;
		}
	}

	&__member-teaser-wrapper {
		margin-top: 25px;
		background-image: svg-load(tankgutschein.svg, fill: $orange);
		background-repeat: no-repeat;
		background-size: contain;
		padding-left: 50px;
	}

	&__member-teaser {
		@include font('bold', 16px, 19px, normal);
		text-transform: uppercase;
		&--bold {
			text-transform: none;
			font-size: 23px;
			line-height: 28px;
		}
	}

	&__sum-month {
		top: 0;
		right: 0;
		@include font('headline', 28px, 32px, -0.64px);
		color: $orange;
		margin-right: 20px;

		@include md {
			font-size: 48px;
			line-height: 48px;
			letter-spacing: -1.61px;
			margin-bottom: 10px;
		}
	}

	&__sum-month-text {
		position: relative;
		display: inline-block;
		&:after {
			content: '€';
			@include font('regular', 20px, 10px, normal);
			position: absolute;
			right: -18px;
			top: 1px;

			@include md {
				top: 2px;
			}
		}
	}

	&__label {
		hyphens: auto;

		&--center {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}

	&__add-text {
		@include font('regular', 16px, 16px, normal);
	}

	&__fix-price {
		position: relative;
		color: $darkblue;
		@include font('headline', 13px, 17px, -0.43px);
		margin-left: 10px;
		display: inline-block;
		&:after {
			content: '€';
			@include font('regular', 13px, 10px, normal);
			position: absolute;
			right: -11px;
			top: -3px;
		}
	}

	&__footer-text {
		@include font('regular', 10px, 12px, normal);
		margin-top: 10px;
	}

	&__car-images {
		margin-bottom: 34px !important;
	}

	&__car-image-holder {
		overflow: hidden;
		position: relative;
		background-color: #e4e8eb;
		//height: 183px;

		&:before {
			display: block;
			content: '';
			width: 100%;
			padding-top: calc(187 / 233 * 100%);
		}

		&--big {
			margin-bottom: 30px;
			&:before {
				padding-top: calc(393 / 742 * 100%);
			}
		}
	}

	&__car-image {
		left: 0;
		top: 0;
		position: absolute;
		size: 100%;
		object-fit: cover;

		&--big {
			object-fit: contain;
		}
	}

	&__color-row {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__color-column {
		width: 50%;

		&:nth-child(n + 3) {
			@include <xl {
				margin-top: 20px;
			}
		}

		@include xl {
			margin-top: 0;
			width: 170px;
		}
	}

	&__color-group {
	}

	&__summary-row {
		display: flex;
		border-bottom: 1px solid $grey-4;
		padding-bottom: 3px;
		color: #333 !important;

		& + & {
			margin-top: 10px;
		}

		&:first-child {
			margin-top: 15px;
		}
	}

	&__summary-column {
		&--headline {
			width: 45%;
			padding-right: 20px;
		}

		&--car-info {
			width: 30%;
			padding-right: 20px;
		}

		@include <md {
			hyphens: auto;
		}

		&:first-child {
			flex-shrink: 0;
		}
	}

	&__summary-head {
		@include font('bold', 21px, 26px, normal);
	}

	&__action-bar {
		margin-top: 0;
		padding-top: 30px;
		position: relative;

		&--summary {
			border-top: none;
			padding-top: 0;
		}
	}

	&__action-banderole {
		position: relative;

		margin-top: $content-element-margin !important;

		@each $val, $i in sm, md, lg, xl, xxl {
			@include $(val) {
				margin-top: $content-element-margin-$(val) !important;
			}
		}

		@include <sm {
			background-color: $darkblue;
		}

		&--add-space {
			margin-top: 40px;
		}
	}

	&__action-banderole-inner {
		background-color: $darkblue;
	}

	&__action-banderole-content-wrapper {
		@include xl {
			padding-right: 30px;
		}
	}

	&__action-banderole-content {
		position: relative;
		padding-bottom: 50px;
		margin-top: 25px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include sm {
			margin-top: 50px;
			padding: 0 $ce-side-padding 50px;
		}

		@include lg {
			flex-direction: row-reverse;
			align-items: flex-start;
		}

		&--center {
			@include lg {
				align-items: center;
			}
		}
	}

	&__action-banderole-content-segment {
		width: 100%;
		@include <sm {
			display: flex;
			flex-direction: column;
		}

		& + & {
			margin-top: 50px;
		}
	}

	&__action-banderole-buttons {
		margin-bottom: 70px;
		display: flex;
		flex-direction: column;
		@include sm {
			display: block;
		}
	}

	&__action-banderole-head {
		color: $white;
		margin-bottom: 25px;
		max-width: 100%;
	}

	&__action-banderole-copy {
		color: $white;
		margin-bottom: 25px;
	}

	&__action-banderole-badge {
		flex-shrink: 0;
		position: relative !important;
		display: block !important;
		transform: none !important;
		size: 250px !important;
		margin-right: 0 !important;
		top: 0px;
		padding: 0;

		background-image: svg-load(tankgutschein.svg, fill: $orange);
		background-repeat: no-repeat;
		background-position: center 20px;
		background-size: 28%;

		@include <xl {
			margin-bottom: 20px;
		}

		@include xxl {
			size: 283px !important;
			background-size: 33%;
			background-position: center 25px;
		}
	}

	&__action-banderole-badge-inner {
		justify-content: flex-end !important;
		padding-bottom: 40px !important;
	}

	&__action-banderole-badge-teaser {
		@include font('black', 14px, 17px, normal);
		text-transform: uppercase;
		position: relative;

		&:not(:last-child) {
			margin-bottom: 25px;

			&:after {
				content: '';
				display: block;
				border-top: 1px dashed $darkblue;
				width: 100%;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -12px;
			}
		}
	}

	&__action-banderole-badge-info {
		@include font('black', 25px, 25px, normal);
	}

	&__form-head {
		@include font('headline', 28px, 36px, normal);
	}

	&__form-copy:not(:first-child) {
		margin-top: 10px;
	}

	&__form-row:not(:last-child) {
		margin-bottom: 75px !important;
	}
}
