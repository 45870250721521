.bigBadge {
	position: absolute;
	right: 0;
	vertical-align: middle;
	top: 50%;
	transform: translateY(calc(-50% + 106px));
	display: flex;
	flex-direction: column;
	width: 290px;
	align-items: center;
	padding: 20px 30px;
	margin-right: 15px;
	background-color: white;
	box-shadow: $badge-shadow;
	z-index: 5;
	text-align: center;
	display: none;

	@include lg {
		display: block;
	}

	&.is-round {
		border-radius: 50%;
		padding: 0;
		width: auto;
		size: 290px;
		box-shadow: $badge-shadow;
		transition: transform 0.3s;
		transform-origin: bottom;

		&::after {
			content: '';
			position: absolute;
			width: calc(100% - 16px);
			height: calc(100% - 16px);
			top: 8px;
			left: 8px;
			border: 2px solid #f28b10;
			border-radius: 50%;
			z-index: -1;
		}

		&:hover {
			transform: scale(1.2);
		}
	}

	&__inner {
		^&.is-round & {
			position: absolute;
			padding: 30px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&__text {
		@include font('bold', 15px, 20px, 0);
		color: $typo-color;
		text-transform: uppercase;
		margin-bottom: 25px;
		width: 100%;

		^&.is-round & {
			@include font('black', 20px, 24px, 1.1px);
			margin-bottom: 15px;

			small {
				display: block;
			}
		}
	}

	&__price {
		@include font('headline', 54px, 54px, 0);
		color: $orange;
		width: 100%;

		^&.is-round & {
			font-family: 'Flexo-Demi';
			font-size: 96px;
			line-height: 96px;
		}

		&:after {
			content: '€';
			font-family: 'Lato';
			position: absolute;
			font-size: 21px;
			line-height: 25px;
			display: inline-block;
			margin-left: 3px;
			vertical-align: top;
			bottom: auto;

			^^&.is-round & {
				font-family: 'Flexo-Demi';
				font-size: 72px;
				line-height: 72px;
				position: relative;
				vertical-align: baseline;
			}
		}

		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}

	&__subline {
		@include font('regular', 15px, 20px, 0);
		color: $typo-color;
		width: 100%;

		^&.is-round & {
			color: $orange;
			text-transform: uppercase;
			@include font('black', 14px, 16px, 0);

			&::after {
				font-family: acv-icons !important;
				font-style: normal;
				font-weight: 400 !important;
				vertical-align: baseline;
				content: '\f101';
				margin-left: 5px;
				font-size: 0.7em;
				margin-right: 0;
			}
		}
	}

	&__link {
		font-size: 0;
		background-image: none;

		@include normalizeHover;
	}
}
