.frame-type-acvcontent_marketingbar:empty {
	display: none;
}

.marketing-bar {
	box-shadow: 0 -7px 7px 0 rgba(0, 0, 0, 0.2);
	position: fixed;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	bottom: 0;
	left: 0;
	background-color: $darkblue;
	padding: 25px 25px;
	text-align: center;
	display: none;
	transform: translate3d(0, 100%, 0);
	opacity: 0;
	transition: opacity 0.3s, transform 0.4s;
	z-index: 9999;

	&.is-shown {
		display: flex;
	}

	@include lg {
		text-align: left;
	}

	&__cross {
		background: transparent;
		border: 0;
		position: absolute;
		top: 10px;
		right: 10px;

		.acv-icons__after {
			color: white;
			font-size: 12px;
			line-height: 12px;
		}

		@include md {
			display: none;
		}
	}

	&__image {
		display: none;
		position: absolute;
		bottom: 0;
		left: 50px;
		height: auto;

		@include xl {
			display: block;
			max-width: 15%;
		}
	}

	&__copy {
		margin-bottom: 20px;
		width: 100%;
		display: flex;
		justify-content: center;

		&--big {
			margin-bottom: 0;
		}

		@include xl {
			justify-content: flex-start;
			width: 37%;
			margin-left: 20%;
			&--big {
				width: 75%;
			}
		}

		@include xxl {
			width: 45%;
			margin-left: 20.6%;
			&--big {
				width: 75%;
			}
		}

		@include xl {
			margin-bottom: 0;
		}

		> p {
			@include font('headline', 15px, 18px, 0);
			color: white;

			^&--big& {
				@include font('headline', 17px, 34px, 0);
				text-transform: uppercase;
			}

			^&--orange& {
				color: $orange;
			}

			@include md {
				^&--big& {
					font-size: 27px;
					line-height: 27px;
				}
			}

			@include xxl {
				@include font('headline', 21px, 25px, 0);
			}
		}
	}

	&__links {
		width: 100%;

		@include lg {
			display: flex;
			justify-content: center;
		}

		@include xl {
			width: auto;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 50px;
		}
	}

	&__link {
		&--dismiss {
			display: none;

			@include md {
				display: inline-block;
			}
		}
	}

	&__close-button {
		color: white;
		position: absolute;
		top: 20px;
		right: 20px;
		background-color: transparent;
		border: 0;
		outline: none;

		&:active {
			transform: scale(0.8, 0.8);
		}
	}
}
