.tariff-selection {
	&__switches {
		display: flex;
		justify-content: center;
		flex-direction: column;
		max-width: 483px;
		margin-left: auto;
		margin-right: auto;

		@include md {
			gap: 10px;
			flex-direction: row;
			max-width: initial;
		}

		@include lg {
			flex-direction: row;
			gap: 30px;
		}

		&:not(:first-child) {
			margin-top: 59px;
		}
	}

	&__switch-wrapper {
		display: flex;
		align-items: center;
		color: white;
		width: 100%;

		@include <md {
			padding: 16px 0;
			& + & {
				border-top: 1px solid $orange-dark;
			}
		}

		@include md {
			flex-direction: column;
		}

		@include lg {
			width: 311px;
		}
	}

	&__switch-text-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		padding-right: 16px;

		@include md {
			padding-right: 0;
			align-items: center;
		}
	}

	&__switch-name {
		@include font('bold', 16px, 25px, normal);

		@include lg {
			@include font('', 21px, 26px, normal);
		}
	}

	&__switch-text {
		@include font('regular', 15px, 20px, normal);
	}

	&__switch-discount {
		background: rgba(255, 255, 255, 0.2);
		@include font('bold', 15px, 20px, normal);
		border-radius: 16px;
		padding: 1px 5px 1px 4px;
		margin-left: 1px;
		&:after {
			content: '€';
			@include font('', 9px, 18px, normal);
			display: inline-block;
			transform: translateY(-3px);
		}

		&--for-name {
			@include lg {
				display: none;
			}
		}

		&--for-text {
			@include <lg {
				display: none;
			}
		}
	}

	&__switch {
		flex: 0 0 auto;

		@include md {
			margin-top: 15px;
		}
	}

	&__cards {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-top: 75px;

		@include md {
			margin-top: 78px;
		}
	}

	&__tariff-cards {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;
		gap: 76px;

		@include md {
			flex-wrap: nowrap;
			gap: 30px;
		}

		@include lg {
		}
	}

	&__card {
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: white;
		width: 100%;
		box-shadow: $card-shadow;
		padding: 70px 0 29px;
		max-width: 483px;

		@include md {
			padding-bottom: 40px;
		}

		&:first-child:last-child {
			@include md {
				margin-top: 19px;
			}
		}
	}

	&__card-image {
		position: absolute;
		top: 0;
		left: 50%;
		height: auto;
		width: 130px;
		transform: translate3d(-50%, -38px, 0);

		@include md {
			width: 134px;
		}

		&--big {
			width: 195px;
			transform: translate3d(-50%, -73px, 0);
		}
	}

	&__card-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 20px 30px;

		@include lg {
			padding: 0 40px 40px;
		}
	}

	&__card-headline {
		text-transform: uppercase;
		text-align: center;
		color: $anthrazit;
		@include font('headline', 24px, 31px, normal);

		@include lg {
			@include font('', 28px, 36px, normal);
		}
	}

	&__card-additional-headline {
		text-align: center;
		color: $grey-2;
		@include font('bold', 18px, 24px, normal);

		@include lg {
			@include font('', 21px, 26px, normal);
		}
	}

	&__card-price-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 19px;

		@include md {
			margin-top: 16px;
		}
	}

	&__card-price {
		@include font('headline', 72px, 72px, normal);
		position: relative;
		color: $anthrazit;
		&:after {
			content: '€';
			position: absolute;
			top: 0;
			left: 100%;
			@include font('headline', 28px, 36px, normal);
		}
	}

	&__card-price-year {
		@include font('regular', 15px, 20px, normal);
		position: absolute;
		bottom: 12px;
		left: 100%;
		color: $grey-1;
	}

	&__card-discount {
		@include font('bold', 18px, 26px, normal);
		background-color: $signal-green-soft;
		border-radius: 900px;
		color: $signal-green;
		padding: 3px 12px 1px;
		margin-top: 7px;

		@include lg {
			margin-top: 10px;
			padding: 0 12px 5px;
			@include font('', 21px, 26px, normal);
		}
	}

	&__card-description {
		margin-top: 8px;
		color: $grey-1;
		@include font('regular', 16px, 25px, normal);

		@include md {
			margin-top: 16px;
			@include font('', 15px, 20px, normal);
		}

		p + p {
			margin-top: 9px;
		}
	}

	&__card-copy {
		border-top: 1px solid $grey-4;
		padding: 30px 20px;

		p,
		ul,
		li {
			font-size: 16px;
			line-height: 25px;

			@include lg {
				font-size: 21px;
				line-height: 26px;
			}
		}

		ul {
			margin-left: 20px;
		}

		li.checkmark--green-big {
			@include <lg {
				margin-left: 8px;
			}

			@include lg {
				padding-left: 4px;
			}

			&:before {
				font-size: 18px;

				@include <lg {
					left: -24px;
					top: 4px;
				}
				@include lg {
					font-size: 24px;
				}
			}

			& + li.checkmark--green-big {
				margin-top: 16px;

				@include lg {
					margin-top: 30px;
				}
			}
		}

		@include lg {
			padding: 40px 40px;
		}
	}

	&__card-footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		border-top: 1px solid $grey-4;
		padding: 30px 20px 0;

		@include lg {
			padding: 40px 40px 0;
			gap: 19px;
		}
	}

	&__additional-link {
		color: $anthrazit;
		@include <lg {
			@include font('', 12px, 14px, 0.08em);
		}
	}

	&__overlay {
		@include md {
			display: none;
		}
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 100;
	}

	&__overlay-cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0;
		transition: opacity 300ms ease-out;

		^&__overlay.is-open & {
			opacity: 0.5;
		}
	}

	&__overlay-content {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		background: $orange-dark $orange-gradient;
		padding: 20px 0;
		transform: translateY(100%) translateY(50px);
		transition: transform 300ms ease-out;
		display: flex;
		flex-direction: column;
		align-items: center;

		^&__overlay.is-open & {
			transform: translateY(0);
		}
	}

	&__overlay-close-button {
		position: absolute;
		left: 50%;
		top: -26px;
		transform: translateX(-50%);
		size: 52px;
		border-radius: 50%;
		background-color: white;
		border: 0;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.22);
		font-size: 0;
		color: transparent;

		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: $anthrazit;
			font-size: 13px;
			margin: 0 !important;
		}
	}

	&__overlay-open-button {
		position: fixed;
		z-index: 90;
		left: 50%;
		bottom: 25px;
		transform: translateX(-50%);
		height: 52px;
		border-radius: 100px;
		background-color: white;
		border: 0;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.22);
		@include font('black', 12px, 14px, 0.08em);
		text-transform: uppercase;
		color: $darkblue;
		white-space: nowrap;
		padding: 0 24px 0 44px;

		&:before {
			position: absolute;
			left: 17px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 14px;
			transition: color 300ms ease;

			^&.has-active-filter& {
				color: $signal-green;
			}
		}

		@include md {
			display: none;
		}
	}
}
