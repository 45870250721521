.productCards {
	&__wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__card {
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: white;
		width: 100%;
		box-shadow: $card-shadow;
		margin-top: 30px;
		cursor: pointer;
		justify-content: center;

		@include sm {
			width: 397px;
		}

		@include lg {
			width: calc(50% - 15px);
		}

		@include xl {
			width: calc(33.33333% - 20px);
		}

		&:first-child {
			margin-top: initial;
		}

		@include lg {
			&:not(:nth-child(n + 3)) {
				margin-top: initial;
			}
		}

		@include only-lg {
			&:nth-child(odd):not(:last-child) {
				margin-right: 15px;
			}

			&:nth-child(even) {
				margin-left: 15px;
			}
		}

		@include xl {
			&:nth-child(3n + 1):not(:last-child) {
				margin-right: 30px;
			}

			&:nth-child(3n + 3) {
				margin-left: 30px;
			}
		}

		@include xl {
			&:not(:nth-child(n + 4)) {
				margin-top: initial;
			}
		}

		&:hover ^&__imageWrapper:after {
			transform: scaleY(1);
		}

		&:hover .btn--blue {
			color: $darkblue;
			background-color: transparent;
		}

		&:hover .btn--blue.btn--orange {
			color: $orange;
			background-color: transparent;
		}

		&:hover .btn--blue:before {
			transform: scaleY(0);
		}
	}

	&__content {
		padding: 30px 20px;
		display: flex;
		flex-direction: column;
		width: 100%;
		flex-grow: 1;

		@include sm {
			padding: 30px 40px;
		}
	}

	&__imageWrapper {
		position: relative;
		width: 100%;
		height: 172px;

		@include sm {
			height: 200px;
		}

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: $darkblue;
			opacity: 0.4;
		}

		&:after {
			content: '';
			position: absolute;
			background-image: $darkblue-gradient;
			width: 100%;
			height: 100%;
			transform: scaleY(0.5);
			transform-origin: bottom;
			bottom: 0;
			left: 0;
			transition: transform 0.3s ease-out;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__bottomWrapper {
		margin-top: auto;
		z-index: 1;
	}

	&__cardHeadline {
		@include font('bold', 15px, 19px, 0);
		color: $typo-color;
		margin-bottom: 10px;
		@include sm {
			margin-bottom: 20px;
		}
	}

	&__copy {
		@include font('regular', 15px, 20px, 0);
		color: $grey-1;
		margin-bottom: 25px;

		@include sm {
			margin-bottom: 15px;
		}
	}

	&__label {
		@include font('headline', 28px, 36px, 0);
		position: absolute;
		@include sm {
			left: 40px;
		}
		left: 20px;
		bottom: 30px;
		color: white;
		text-shadow: $text-shadow;
		z-index: 2;
	}

	&__price {
		@include font('headline', 35px, 41px, 0);
		color: $orange;
		text-align: center;
		margin-bottom: 10px;

		@include sm {
			@include font('headline', 54px, 60px, 0);
			margin-bottom: 15px;
		}

		&:after {
			content: '€';
			font-family: 'Lato';
			position: absolute;
			font-size: 21px;
			line-height: 25px;
			display: inline-block;
			margin-left: 3px;
			vertical-align: top;
			bottom: auto;
		}
	}

	&__subline {
		@include font('regular', 15px, 23px, 0);
		color: $typo-color;
		text-align: center;
		margin-bottom: 20px;

		@include sm {
			margin-bottom: 30px;
		}
	}

	&__link {
		font-size: 0;
		z-index: 6;
		background: none;
		&:hover {
			background: none;
		}
	}
}

.frame-type-acvcontent_sectionintro + .frame-type-acvcontent_productcard {
	@include u-ce-margin-top-medium;
}
