.contractWidgetCheckboxPane {
	@for $i from 1 to 10 {
		#tab$(i):checked ~ &__nav [for='tab$(i)'] {
			&:after {
				content: '';
			}
			&:before {
				background-color: $orange;
				border: none;
			}
		}
	}

	&__nav {
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;

		@include md {
			flex-direction: row;
			margin-bottom: 43px;
		}
	}

	&__nav-item {
		display: inline-block;

		& + & {
			margin-top: 15px;
			@include md {
				margin-top: 0;
				margin-left: 40px;
			}
		}
	}

	&__nav-label {
		@include font('regular', 21px, 26px, normal);
		cursor: pointer;
		display: block;
		position: relative;
		display: flex;
		align-items: center;

		@include md {
			@include font('', 21px, 26px, normal);
		}

		&:before {
			flex: 0 0 auto;
			content: '';
			size: 32px;
			display: block;
			background-color: white;
			border: 1px solid $grey-2;
			border-radius: 50%;
			margin-right: 15px;
		}

		&::after {
			position: absolute;
			left: 10px;
			top: 10px;
			size: 12px;
			display: block;
			background-color: white;
			border-radius: 50%;
		}
	}
}
