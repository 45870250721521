.ec-filter {
	&__infocopy {
		@include font('bold', 15px, 19px, normal);
		margin-bottom: 20px;
	}
	&__toggle {
		@include sm {
			display: none;
		}
	}

	&__bar {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 20px;
		flex-wrap: wrap;
		align-items: flex-start;

		@include sm {
			display: flex;
			flex-direction: row;
			border-bottom: 1px solid $grey-3;
			padding-bottom: 20px;
			margin-bottom: 20px;
			margin-top: initial;
			width: auto;
		}

		@include md {
			flex-direction: row;
		}
	}

	&__props {
		display: flex;
		position: fixed;
		background: white;
		top: 0;
		left: 0;
		z-index: 200;
		height: 100%;
		width: 100%;

		@include <sm {
			flex-direction: column;
			opacity: 0;
			visibility: hidden;
			transform: translate3d(0, 100%, 0);
			transition: opacity 300ms, transform 300ms, visibility 300ms;
		}

		@include sm {
			display: block;
			position: relative;
			height: auto;
			width: auto;
			overflow: visible;
			flex-grow: 1;
			padding-right: 15px;
		}

		&.visible {
			display: flex;
			flex-direction: column;

			@include <sm {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
			}
		}
	}

	&__props-inner {
		width: 100%;
		overflow-y: auto;

		@include sm {
			display: flex;
			position: relative;
			width: auto;
			overflow-y: visible;
			width: 100%;
			justify-content: space-between;
		}

		@include md {
			display: inline-block;
		}
	}

	&__prop {
		overflow: hidden;

		@include <sm {
			padding: 0;
			border-bottom: solid 1px $grey-3;
			display: flex;
			flex-direction: column-reverse;
		}

		@include sm {
			display: inline-block;
			overflow: visible;
			&:not(:last-child) {
				margin-right: 15px;
			}
		}

		@include lg {
			&:not(:last-child) {
				margin-right: 30px;
			}
		}

		&:nth-child(n + 3) {
			.ec-filter-dropdown {
				@include sm {
					right: 0;
				}

				@include md {
					right: inherit;
				}
			}
		}
	}

	&__mobile-head {
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $darkblue;
		border-bottom: solid 1px $grey-3;

		background: white;

		@include sm {
			display: none;
		}
	}

	&__mobile-heading {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		@include font('headline', 20px, 25px, normal);
		font-family: 'MaxWebPro-Demibold';
	}

	&__delete-filter {
		margin-left: 15px;
		@include font('regular', 15px, 20px, normal);
		color: $darkblue;
		border: 0;
		background: linear-gradient(#ffffff, #ffffff);
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		background-image: linear-gradient($orange, $orange);
		padding-bottom: 2px;
		text-decoration: none;
		&:hover {
			cursor: pointer;
			color: $orange;
		}
		&:focus {
			outline: none;
		}
	}

	&__close-filter {
		margin-right: 24px;
		border: 0;
		background: none;
		&:before {
			content: '$acv-icons-var-cross';
			font-family: 'acv-icons';
			font-size: 12px;
		}
	}

	&__button {
		@include font('black', 12px, 14px, 0.8px);
		color: $darkblue;
		opacity: 0.3;
		position: relative;
		background: none;
		border: 0;
		text-transform: uppercase;
		padding-right: 15px;

		&:hover,
		&.has-actives,
		^&-dropdown.visible ~ & {
			opacity: 1;
		}

		@include <sm {
			min-height: 73px;
			width: 100%;
			text-align: left;
			padding-right: 0;
			padding: 0 16px;
		}

		&:after {
			content: '$acv-icons-var-arrow-right';
			font-family: 'acv-icons';
			font-size: 10px;
			position: absolute;
			transform: translateY(-50%) rotate(90deg);
			right: 0;
			top: 50%;
			opacity: 0.3;

			@include <sm {
				right: 26px;
				font-size: 12px;
				opacity: 1;
			}
		}

		&:hover:after,
		^&-dropdown.visible ~ &:after {
			@include sm {
				color: $orange;
				opacity: 1;
			}
		}

		&.has-actives:after {
			opacity: 1;
		}

		&:focus {
			outline: none;
			color: inherit;
		}
	}

	&__button-key {
		display: block;

		^&__button:hover &,
		^&-dropdown.visible ~ .ec-filter__button & {
			@include sm {
				color: $orange;
				opacity: 1;
			}
		}

		^&__button.has-actives & {
			opacity: 1;
		}
	}

	&__button-values {
		display: block;
		@include font('regular', 12px, 14px, 0);
		margin-top: 3px;
		text-transform: none;
		max-width: 250px;
		color: $grey-2;

		@include sm {
			display: none;
		}

		&:empty {
			display: none;
		}
	}

	&__toggle {
		width: 100%;
		position: relative;
		padding-left: 48px;
		&:after {
			content: '$acv-icons-var-filter';
			font-family: 'acv-icons';
			font-size: 16px;
			position: absolute;
			left: calc(50% - 14px);
			top: 50%;
			transform: translate(calc(-50% - 14px), -50%);
		}
	}

	&__pills {
		@include <sm {
			display: block;
			margin-top: 30px;
		}

		&-item {
			position: relative;
			@include font('bold', 13px, 16px, normal);
			color: #333;
			padding: 4px 28px 4px 12px;
			background: $grey-3;
			border: 0;
			border-radius: 30px;
			margin-right: 5px;
			opacity: 0;
			transform: scale(1);
			transition: background ease-out 0.1s, transform ease-out 0.1s;
			animation: pills-fadeIn 0.2s forwards cubic-bezier(0.85, 0.21, 0.69, 1.22);
			animation-delay: 0.1s;
			margin-bottom: 15px;

			&:nth-last-child(2) {
				margin-right: 10px;
			}
			&:after {
				content: '$acv-icons-var-cross';
				position: absolute;
				font-family: 'acv-icons';
				color: $typo-color;
				right: 8px;
				font-size: 8px;
				top: 50%;
				transform: translateY(-50%);
			}
			&:hover {
				background: #d4d4d4b3;
			}
			&:focus {
				outline: none;
			}
			@include <sm {
				margin-top: 0;
				margin-bottom: 15px;
			}
		}
		&-delete {
			@include font('regular', 13px, 20px, normal);
			color: $darkblue;
			border: 0;
			background: linear-gradient(#ffffff, #ffffff);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			background-image: linear-gradient($orange, $orange);
			padding-bottom: 1px;
			text-decoration: none;
			margin-bottom: 15px;
			&:hover {
				cursor: pointer;
				color: $orange;
			}
			&:focus {
				outline: none;
			}
		}
	}

	&__apply-all-wrapper {
		width: 100%;
		padding: 15px;
		border-top: solid 1px $grey-3;
		background: white;
		z-index: 10;
		margin-top: auto;

		@include sm {
			display: none;
		}
	}

	&__apply-all-btn {
		width: 100%;
	}
}

@keyframes pills-fadeIn {
	from {
		opacity: 0;
		transform: scale(0.97);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
