@font-face {
    font-family: "acv-icons";
    src: url("/typo3conf/ext/acv_content/Resources/Public/fonts/icon-fonts/acv-icons/acv-icons.eot?d1c286df42a18ce231f4e3632c487aee?#iefix") format("embedded-opentype"),
url("/typo3conf/ext/acv_content/Resources/Public/fonts/icon-fonts/acv-icons/acv-icons.woff2?d1c286df42a18ce231f4e3632c487aee") format("woff2"),
url("/typo3conf/ext/acv_content/Resources/Public/fonts/icon-fonts/acv-icons/acv-icons.woff?d1c286df42a18ce231f4e3632c487aee") format("woff"),
url("/typo3conf/ext/acv_content/Resources/Public/fonts/icon-fonts/acv-icons/acv-icons.ttf?d1c286df42a18ce231f4e3632c487aee") format("truetype"),
url("/typo3conf/ext/acv_content/Resources/Public/fonts/icon-fonts/acv-icons/acv-icons.svg?d1c286df42a18ce231f4e3632c487aee#acv-icons") format("svg");
}

[class*='acv-icons'] {
    line-height: 1;

    &:not(acv-icons__after):before {
		@include acv-icons-mixin;
	}
}

@mixin acv-icons-mixin() {
	font-family: acv-icons !important;
    font-style: normal;
    font-weight: normal !important;
    margin-right: 5px;
    vertical-align: baseline;
}

.acv-icons {
	&__after:after {
		@include acv-icons-mixin;
		margin-right: 0;
	}
}


.acv-icons__middle {
    vertical-align: middle;
}


$acv-icons-var-add: \f101;
.acv-icons {
	&--add {
		&:not(^&__after):before {
			content: '$acv-icons-var-add';
		}

		&^&__after:after {
			content: '$acv-icons-var-add';
		}
	}
}


$acv-icons-var-arrow-right: \f102;
.acv-icons {
	&--arrow-right {
		&:not(^&__after):before {
			content: '$acv-icons-var-arrow-right';
		}

		&^&__after:after {
			content: '$acv-icons-var-arrow-right';
		}
	}
}


$acv-icons-var-bang: \f103;
.acv-icons {
	&--bang {
		&:not(^&__after):before {
			content: '$acv-icons-var-bang';
		}

		&^&__after:after {
			content: '$acv-icons-var-bang';
		}
	}
}


$acv-icons-var-bullhorn: \f104;
.acv-icons {
	&--bullhorn {
		&:not(^&__after):before {
			content: '$acv-icons-var-bullhorn';
		}

		&^&__after:after {
			content: '$acv-icons-var-bullhorn';
		}
	}
}


$acv-icons-var-bulli-arrow: \f105;
.acv-icons {
	&--bulli-arrow {
		&:not(^&__after):before {
			content: '$acv-icons-var-bulli-arrow';
		}

		&^&__after:after {
			content: '$acv-icons-var-bulli-arrow';
		}
	}
}


$acv-icons-var-bulli-like: \f106;
.acv-icons {
	&--bulli-like {
		&:not(^&__after):before {
			content: '$acv-icons-var-bulli-like';
		}

		&^&__after:after {
			content: '$acv-icons-var-bulli-like';
		}
	}
}


$acv-icons-var-calendar-new: \f107;
.acv-icons {
	&--calendar-new {
		&:not(^&__after):before {
			content: '$acv-icons-var-calendar-new';
		}

		&^&__after:after {
			content: '$acv-icons-var-calendar-new';
		}
	}
}


$acv-icons-var-calendar: \f108;
.acv-icons {
	&--calendar {
		&:not(^&__after):before {
			content: '$acv-icons-var-calendar';
		}

		&^&__after:after {
			content: '$acv-icons-var-calendar';
		}
	}
}


$acv-icons-var-check_fat-line: \f109;
.acv-icons {
	&--check_fat-line {
		&:not(^&__after):before {
			content: '$acv-icons-var-check_fat-line';
		}

		&^&__after:after {
			content: '$acv-icons-var-check_fat-line';
		}
	}
}


$acv-icons-var-checkmark-new: \f10a;
.acv-icons {
	&--checkmark-new {
		&:not(^&__after):before {
			content: '$acv-icons-var-checkmark-new';
		}

		&^&__after:after {
			content: '$acv-icons-var-checkmark-new';
		}
	}
}


$acv-icons-var-checkmark-simple: \f10b;
.acv-icons {
	&--checkmark-simple {
		&:not(^&__after):before {
			content: '$acv-icons-var-checkmark-simple';
		}

		&^&__after:after {
			content: '$acv-icons-var-checkmark-simple';
		}
	}
}


$acv-icons-var-checkmark: \f10c;
.acv-icons {
	&--checkmark {
		&:not(^&__after):before {
			content: '$acv-icons-var-checkmark';
		}

		&^&__after:after {
			content: '$acv-icons-var-checkmark';
		}
	}
}


$acv-icons-var-close-circle: \f10d;
.acv-icons {
	&--close-circle {
		&:not(^&__after):before {
			content: '$acv-icons-var-close-circle';
		}

		&^&__after:after {
			content: '$acv-icons-var-close-circle';
		}
	}
}


$acv-icons-var-clubs: \f10e;
.acv-icons {
	&--clubs {
		&:not(^&__after):before {
			content: '$acv-icons-var-clubs';
		}

		&^&__after:after {
			content: '$acv-icons-var-clubs';
		}
	}
}


$acv-icons-var-cross-new: \f10f;
.acv-icons {
	&--cross-new {
		&:not(^&__after):before {
			content: '$acv-icons-var-cross-new';
		}

		&^&__after:after {
			content: '$acv-icons-var-cross-new';
		}
	}
}


$acv-icons-var-cross: \f110;
.acv-icons {
	&--cross {
		&:not(^&__after):before {
			content: '$acv-icons-var-cross';
		}

		&^&__after:after {
			content: '$acv-icons-var-cross';
		}
	}
}


$acv-icons-var-document: \f111;
.acv-icons {
	&--document {
		&:not(^&__after):before {
			content: '$acv-icons-var-document';
		}

		&^&__after:after {
			content: '$acv-icons-var-document';
		}
	}
}


$acv-icons-var-documentDownload: \f112;
.acv-icons {
	&--documentDownload {
		&:not(^&__after):before {
			content: '$acv-icons-var-documentDownload';
		}

		&^&__after:after {
			content: '$acv-icons-var-documentDownload';
		}
	}
}


$acv-icons-var-download: \f113;
.acv-icons {
	&--download {
		&:not(^&__after):before {
			content: '$acv-icons-var-download';
		}

		&^&__after:after {
			content: '$acv-icons-var-download';
		}
	}
}


$acv-icons-var-edit: \f114;
.acv-icons {
	&--edit {
		&:not(^&__after):before {
			content: '$acv-icons-var-edit';
		}

		&^&__after:after {
			content: '$acv-icons-var-edit';
		}
	}
}


$acv-icons-var-emergency: \f115;
.acv-icons {
	&--emergency {
		&:not(^&__after):before {
			content: '$acv-icons-var-emergency';
		}

		&^&__after:after {
			content: '$acv-icons-var-emergency';
		}
	}
}


$acv-icons-var-envelope-send: \f116;
.acv-icons {
	&--envelope-send {
		&:not(^&__after):before {
			content: '$acv-icons-var-envelope-send';
		}

		&^&__after:after {
			content: '$acv-icons-var-envelope-send';
		}
	}
}


$acv-icons-var-envelope: \f117;
.acv-icons {
	&--envelope {
		&:not(^&__after):before {
			content: '$acv-icons-var-envelope';
		}

		&^&__after:after {
			content: '$acv-icons-var-envelope';
		}
	}
}


$acv-icons-var-error: \f118;
.acv-icons {
	&--error {
		&:not(^&__after):before {
			content: '$acv-icons-var-error';
		}

		&^&__after:after {
			content: '$acv-icons-var-error';
		}
	}
}


$acv-icons-var-exclamation: \f119;
.acv-icons {
	&--exclamation {
		&:not(^&__after):before {
			content: '$acv-icons-var-exclamation';
		}

		&^&__after:after {
			content: '$acv-icons-var-exclamation';
		}
	}
}


$acv-icons-var-filter: \f11a;
.acv-icons {
	&--filter {
		&:not(^&__after):before {
			content: '$acv-icons-var-filter';
		}

		&^&__after:after {
			content: '$acv-icons-var-filter';
		}
	}
}


$acv-icons-var-flagge: \f11b;
.acv-icons {
	&--flagge {
		&:not(^&__after):before {
			content: '$acv-icons-var-flagge';
		}

		&^&__after:after {
			content: '$acv-icons-var-flagge';
		}
	}
}


$acv-icons-var-gift: \f11c;
.acv-icons {
	&--gift {
		&:not(^&__after):before {
			content: '$acv-icons-var-gift';
		}

		&^&__after:after {
			content: '$acv-icons-var-gift';
		}
	}
}


$acv-icons-var-globe: \f11d;
.acv-icons {
	&--globe {
		&:not(^&__after):before {
			content: '$acv-icons-var-globe';
		}

		&^&__after:after {
			content: '$acv-icons-var-globe';
		}
	}
}


$acv-icons-var-hide-password: \f11e;
.acv-icons {
	&--hide-password {
		&:not(^&__after):before {
			content: '$acv-icons-var-hide-password';
		}

		&^&__after:after {
			content: '$acv-icons-var-hide-password';
		}
	}
}


$acv-icons-var-info-square: \f11f;
.acv-icons {
	&--info-square {
		&:not(^&__after):before {
			content: '$acv-icons-var-info-square';
		}

		&^&__after:after {
			content: '$acv-icons-var-info-square';
		}
	}
}


$acv-icons-var-info: \f120;
.acv-icons {
	&--info {
		&:not(^&__after):before {
			content: '$acv-icons-var-info';
		}

		&^&__after:after {
			content: '$acv-icons-var-info';
		}
	}
}


$acv-icons-var-liste: \f121;
.acv-icons {
	&--liste {
		&:not(^&__after):before {
			content: '$acv-icons-var-liste';
		}

		&^&__after:after {
			content: '$acv-icons-var-liste';
		}
	}
}


$acv-icons-var-location_ec: \f122;
.acv-icons {
	&--location_ec {
		&:not(^&__after):before {
			content: '$acv-icons-var-location_ec';
		}

		&^&__after:after {
			content: '$acv-icons-var-location_ec';
		}
	}
}


$acv-icons-var-lock: \f123;
.acv-icons {
	&--lock {
		&:not(^&__after):before {
			content: '$acv-icons-var-lock';
		}

		&^&__after:after {
			content: '$acv-icons-var-lock';
		}
	}
}


$acv-icons-var-logo-email: \f124;
.acv-icons {
	&--logo-email {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-email';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-email';
		}
	}
}


$acv-icons-var-logo-facebook: \f125;
.acv-icons {
	&--logo-facebook {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-facebook';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-facebook';
		}
	}
}


$acv-icons-var-logo-instagram: \f126;
.acv-icons {
	&--logo-instagram {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-instagram';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-instagram';
		}
	}
}


$acv-icons-var-logo-linkedin: \f127;
.acv-icons {
	&--logo-linkedin {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-linkedin';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-linkedin';
		}
	}
}


$acv-icons-var-logo-tiktok: \f128;
.acv-icons {
	&--logo-tiktok {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-tiktok';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-tiktok';
		}
	}
}


$acv-icons-var-logo-whatsapp: \f129;
.acv-icons {
	&--logo-whatsapp {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-whatsapp';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-whatsapp';
		}
	}
}


$acv-icons-var-logo-x: \f12a;
.acv-icons {
	&--logo-x {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-x';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-x';
		}
	}
}


$acv-icons-var-logo-xing: \f12b;
.acv-icons {
	&--logo-xing {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-xing';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-xing';
		}
	}
}


$acv-icons-var-logo-youtube: \f12c;
.acv-icons {
	&--logo-youtube {
		&:not(^&__after):before {
			content: '$acv-icons-var-logo-youtube';
		}

		&^&__after:after {
			content: '$acv-icons-var-logo-youtube';
		}
	}
}


$acv-icons-var-long-arrow: \f12d;
.acv-icons {
	&--long-arrow {
		&:not(^&__after):before {
			content: '$acv-icons-var-long-arrow';
		}

		&^&__after:after {
			content: '$acv-icons-var-long-arrow';
		}
	}
}


$acv-icons-var-lupe: \f12e;
.acv-icons {
	&--lupe {
		&:not(^&__after):before {
			content: '$acv-icons-var-lupe';
		}

		&^&__after:after {
			content: '$acv-icons-var-lupe';
		}
	}
}


$acv-icons-var-mail: \f12f;
.acv-icons {
	&--mail {
		&:not(^&__after):before {
			content: '$acv-icons-var-mail';
		}

		&^&__after:after {
			content: '$acv-icons-var-mail';
		}
	}
}


$acv-icons-var-nature: \f130;
.acv-icons {
	&--nature {
		&:not(^&__after):before {
			content: '$acv-icons-var-nature';
		}

		&^&__after:after {
			content: '$acv-icons-var-nature';
		}
	}
}


$acv-icons-var-nav-chevron-right: \f131;
.acv-icons {
	&--nav-chevron-right {
		&:not(^&__after):before {
			content: '$acv-icons-var-nav-chevron-right';
		}

		&^&__after:after {
			content: '$acv-icons-var-nav-chevron-right';
		}
	}
}


$acv-icons-var-nav-mobile-arrow-right: \f132;
.acv-icons {
	&--nav-mobile-arrow-right {
		&:not(^&__after):before {
			content: '$acv-icons-var-nav-mobile-arrow-right';
		}

		&^&__after:after {
			content: '$acv-icons-var-nav-mobile-arrow-right';
		}
	}
}


$acv-icons-var-nav-mobile-chevron-right: \f133;
.acv-icons {
	&--nav-mobile-chevron-right {
		&:not(^&__after):before {
			content: '$acv-icons-var-nav-mobile-chevron-right';
		}

		&^&__after:after {
			content: '$acv-icons-var-nav-mobile-chevron-right';
		}
	}
}


$acv-icons-var-page-arrow-left: \f134;
.acv-icons {
	&--page-arrow-left {
		&:not(^&__after):before {
			content: '$acv-icons-var-page-arrow-left';
		}

		&^&__after:after {
			content: '$acv-icons-var-page-arrow-left';
		}
	}
}


$acv-icons-var-page-arrow-right: \f135;
.acv-icons {
	&--page-arrow-right {
		&:not(^&__after):before {
			content: '$acv-icons-var-page-arrow-right';
		}

		&^&__after:after {
			content: '$acv-icons-var-page-arrow-right';
		}
	}
}


$acv-icons-var-paper-upload: \f136;
.acv-icons {
	&--paper-upload {
		&:not(^&__after):before {
			content: '$acv-icons-var-paper-upload';
		}

		&^&__after:after {
			content: '$acv-icons-var-paper-upload';
		}
	}
}


$acv-icons-var-paper: \f137;
.acv-icons {
	&--paper {
		&:not(^&__after):before {
			content: '$acv-icons-var-paper';
		}

		&^&__after:after {
			content: '$acv-icons-var-paper';
		}
	}
}


$acv-icons-var-pdf: \f138;
.acv-icons {
	&--pdf {
		&:not(^&__after):before {
			content: '$acv-icons-var-pdf';
		}

		&^&__after:after {
			content: '$acv-icons-var-pdf';
		}
	}
}


$acv-icons-var-person-new: \f139;
.acv-icons {
	&--person-new {
		&:not(^&__after):before {
			content: '$acv-icons-var-person-new';
		}

		&^&__after:after {
			content: '$acv-icons-var-person-new';
		}
	}
}


$acv-icons-var-phone: \f13a;
.acv-icons {
	&--phone {
		&:not(^&__after):before {
			content: '$acv-icons-var-phone';
		}

		&^&__after:after {
			content: '$acv-icons-var-phone';
		}
	}
}


$acv-icons-var-phone_ec: \f13b;
.acv-icons {
	&--phone_ec {
		&:not(^&__after):before {
			content: '$acv-icons-var-phone_ec';
		}

		&^&__after:after {
			content: '$acv-icons-var-phone_ec';
		}
	}
}


$acv-icons-var-pin: \f13c;
.acv-icons {
	&--pin {
		&:not(^&__after):before {
			content: '$acv-icons-var-pin';
		}

		&^&__after:after {
			content: '$acv-icons-var-pin';
		}
	}
}


$acv-icons-var-plane: \f13d;
.acv-icons {
	&--plane {
		&:not(^&__after):before {
			content: '$acv-icons-var-plane';
		}

		&^&__after:after {
			content: '$acv-icons-var-plane';
		}
	}
}


$acv-icons-var-play-arrow: \f13e;
.acv-icons {
	&--play-arrow {
		&:not(^&__after):before {
			content: '$acv-icons-var-play-arrow';
		}

		&^&__after:after {
			content: '$acv-icons-var-play-arrow';
		}
	}
}


$acv-icons-var-refresh: \f13f;
.acv-icons {
	&--refresh {
		&:not(^&__after):before {
			content: '$acv-icons-var-refresh';
		}

		&^&__after:after {
			content: '$acv-icons-var-refresh';
		}
	}
}


$acv-icons-var-route: \f140;
.acv-icons {
	&--route {
		&:not(^&__after):before {
			content: '$acv-icons-var-route';
		}

		&^&__after:after {
			content: '$acv-icons-var-route';
		}
	}
}


$acv-icons-var-send: \f141;
.acv-icons {
	&--send {
		&:not(^&__after):before {
			content: '$acv-icons-var-send';
		}

		&^&__after:after {
			content: '$acv-icons-var-send';
		}
	}
}


$acv-icons-var-shieldFail: \f142;
.acv-icons {
	&--shieldFail {
		&:not(^&__after):before {
			content: '$acv-icons-var-shieldFail';
		}

		&^&__after:after {
			content: '$acv-icons-var-shieldFail';
		}
	}
}


$acv-icons-var-show-password: \f143;
.acv-icons {
	&--show-password {
		&:not(^&__after):before {
			content: '$acv-icons-var-show-password';
		}

		&^&__after:after {
			content: '$acv-icons-var-show-password';
		}
	}
}


$acv-icons-var-simple-checkmark: \f144;
.acv-icons {
	&--simple-checkmark {
		&:not(^&__after):before {
			content: '$acv-icons-var-simple-checkmark';
		}

		&^&__after:after {
			content: '$acv-icons-var-simple-checkmark';
		}
	}
}


$acv-icons-var-simple-trash: \f145;
.acv-icons {
	&--simple-trash {
		&:not(^&__after):before {
			content: '$acv-icons-var-simple-trash';
		}

		&^&__after:after {
			content: '$acv-icons-var-simple-trash';
		}
	}
}


$acv-icons-var-slide-left: \f146;
.acv-icons {
	&--slide-left {
		&:not(^&__after):before {
			content: '$acv-icons-var-slide-left';
		}

		&^&__after:after {
			content: '$acv-icons-var-slide-left';
		}
	}
}


$acv-icons-var-slide-right: \f147;
.acv-icons {
	&--slide-right {
		&:not(^&__after):before {
			content: '$acv-icons-var-slide-right';
		}

		&^&__after:after {
			content: '$acv-icons-var-slide-right';
		}
	}
}


$acv-icons-var-stop: \f148;
.acv-icons {
	&--stop {
		&:not(^&__after):before {
			content: '$acv-icons-var-stop';
		}

		&^&__after:after {
			content: '$acv-icons-var-stop';
		}
	}
}


$acv-icons-var-success: \f149;
.acv-icons {
	&--success {
		&:not(^&__after):before {
			content: '$acv-icons-var-success';
		}

		&^&__after:after {
			content: '$acv-icons-var-success';
		}
	}
}


$acv-icons-var-time: \f14a;
.acv-icons {
	&--time {
		&:not(^&__after):before {
			content: '$acv-icons-var-time';
		}

		&^&__after:after {
			content: '$acv-icons-var-time';
		}
	}
}


$acv-icons-var-tooltip-info-new: \f14b;
.acv-icons {
	&--tooltip-info-new {
		&:not(^&__after):before {
			content: '$acv-icons-var-tooltip-info-new';
		}

		&^&__after:after {
			content: '$acv-icons-var-tooltip-info-new';
		}
	}
}


$acv-icons-var-tooltip-info: \f14c;
.acv-icons {
	&--tooltip-info {
		&:not(^&__after):before {
			content: '$acv-icons-var-tooltip-info';
		}

		&^&__after:after {
			content: '$acv-icons-var-tooltip-info';
		}
	}
}


$acv-icons-var-trash: \f14d;
.acv-icons {
	&--trash {
		&:not(^&__after):before {
			content: '$acv-icons-var-trash';
		}

		&^&__after:after {
			content: '$acv-icons-var-trash';
		}
	}
}


$acv-icons-var-upload: \f14e;
.acv-icons {
	&--upload {
		&:not(^&__after):before {
			content: '$acv-icons-var-upload';
		}

		&^&__after:after {
			content: '$acv-icons-var-upload';
		}
	}
}


$acv-icons-var-warning: \f14f;
.acv-icons {
	&--warning {
		&:not(^&__after):before {
			content: '$acv-icons-var-warning';
		}

		&^&__after:after {
			content: '$acv-icons-var-warning';
		}
	}
}

