.clubEventForm {
	position: relative;
	margin-top: 10px;
	padding-top: 25px;

	@include sm {
		padding: 0 $ce-side-padding;
		padding-top: 50px;
	}

	&:before {
		content: '';
		position: absolute;
		left: 15px;
		width: calc(100% - 30px);
		top: 0;
		height: 1px;
		background-color: $grey-3;

		@include sm {
			left: calc($ce-side-padding + 15px);
			width: calc(100% - ($ce-side-padding * 2) - (30px));
		}
	}

	&__head {
		margin-bottom: 6px;
	}

	&__copy {
		& + & {
			margin-top: 20px;
		}
	}

	&__link-wrapper {
		margin-top: 40px;
		text-align: center;
	}
}
