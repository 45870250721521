.ASD-seals {
	display: flex;
	gap: 40px;
	margin-top: 60px;

	justify-content: center;
	align-items: center;

	@include helpTile {
		position: fixed;

		justify-content: flex-end;
		gap: 17px;

		bottom: 20px;
		left: calc(50% + 414px);

		@include xxl {
			left: calc(50% + 484px);
		}

		&.is-absolute {
			position: absolute;
			right: -117px;
			bottom: 0;
		}
	}

	@include xl {
		&.is-absolute {
			right: -47px;
		}
	}

	&__seal {
		height: 98px;
		flex: 0 0 auto;
	}

	&__image {
		object-fit: contain;
		size: 100%;
		max-width: 110px;
	}
}
