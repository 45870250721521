.frame-type-acvcontent_campaignbox:empty {
	display: none;
}

.campaign-box {
	position: fixed;
	visibility: hidden;
	z-index: 9999;
	text-align: center;
	width: 100%;
	right: 0;
	bottom: 0;
	box-shadow: $box-shadow;
	border-radius: 40px;
	transform: translate3d(0, 100%, 0);
	transition: transform 0.4s ease, visibility 0.4s ease;

	@include md {
		transform: translate3d(200%, 0, 0);
	}

	&.is-visible {
		pointer-events: auto;
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}

	&.is-removed {
		pointer-events: none;
		visibility: hidden;
		transform: translate3d(0, 100%, 0);

		@include md {
			transform: translate3d(200%, 0, 0);
		}
	}

	@include md {
		bottom: 150px;
		right: 40px;
		max-width: 230px;
	}

	&__part {
		&--top {
			padding: 15px 25px;
			background: white;
			border-radius: 40px 40px 0 0;

			@include md {
				padding: 15px;
			}
		}

		&--bottom {
			background: $darkblue;
			color: white;
			padding: 30px 25px 20px 25px;

			@include md {
				padding: 15px 15px 30px 15px;
				border-radius: 0 0 40px 40px;
			}
		}
	}

	&__logo {
		width: 100%;
		max-width: 140px;
		height: auto;
		display: inline-block;
		margin-bottom: 5px;
	}

	&__bottom-text {
		@include font('black', 15px, 20px, 0);
		text-transform: uppercase;
	}

	&__link {
		display: inline-block;
		margin-top: 30px !important;

		@include md {
			margin-top: 25px !important;
		}

		&--mobile {
			@include md {
				display: none;
			}
		}

		&--desktop {
			@include <md {
				display: none;
			}
		}
	}

	&__cross {
		background: transparent;
		border: 0;
		position: absolute;
		top: 20px;
		right: 20px;

		@include md {
			top: -15px;
			right: -15px;
		}

		.acv-icons__after {
			font-size: 12px;
			line-height: 12px;
		}
	}
}
