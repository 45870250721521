.ASD-input {
	position: relative;
	height: $input-height;

	background-color: $anthracite-5;
	color: $anthracite;
	border: 0;
	border-bottom: 1px solid $anthracite-40;

	border-radius: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

	transition: border-color 250ms ease;

	padding: 22px 10px 0;

	caret-color: $anthracite;

	width: 100%;

	&::-ms-clear,
	&::-ms-reveal {
		display: none;
	}

	&:-moz-ui-invalid {
		box-shadow: initial;
	}

	&.is-invalid {
		border-color: $signal-red;
	}

	&:focus {
		outline: none;
		border-color: $anthracite;
	}

	&::placeholder {
		color: $anthracite-80;

		^&[data-is-date-input]& {
			text-transform: uppercase;
		}
	}

	&[disabled] {
		color: $anthracite-80;

		&:placeholder-shown,
		&.is-filled {
			color: $anthracite-80;
		}
	}

	&[type='date'] {
		appearance: none;
		text-transform: uppercase;
		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			display: none;
			appearance: none;
		}
		&::-webkit-date-and-time-value {
			text-align: left;
		}

		&::-webkit-datetime-edit-day-field:focus,
		&::-webkit-datetime-edit-month-field:focus,
		&::-webkit-datetime-edit-year-field:focus {
			background-color: $anthracite;
			color: white;
			outline: none;
		}
	}

	&--cal-hidden {
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		width: 0;
		height: 100%;
	}

	&__error {
		display: none;
		padding: 3px 10px 0;
		@include font('regular', 14px, 16px, normal);

		max-width: 100%;

		a {
			color: inherit !important;
			&:hover {
				color: $orange !important;
			}
		}

		color: $signal-red;
		^&.is-invalid:not(:focus) ~ & {
			display: block;
		}
	}

	&__icon-button {
		position: absolute;
		right: 0;
		top: 0;
		height: 52px;
		width: 45px;

		display: flex;
		align-items: center;
		justify-content: center;

		background-color: transparent;
		border: 0;
		z-index: 5;
		font-size: 0;
		line-height: 0;
		color: transparent;

		&:before {
			font-size: 22px;
			color: $anthracite;
			margin: 0 !important;
			transition: color 250ms ease;
		}

		&:focus-visible {
			outline: none;
		}

		&:hover,
		&:focus-visible {
			color: $orange;
			&:before {
				color: $orange;
			}
		}

		& + ^& {
			padding-right: 20px;
			@supports not (-moz-appearance: none) {
				padding-right: 55px;
			}
		}

		&--text {
			@include font('bold', 13px, 14px, 0.13px);
			text-decoration: underline;
			color: $anthracite-80;
			width: auto;
			padding: 0 15px;
			transition: color 250ms ease;

			& + ^^& {
				padding-right: 80px;
			}
		}

		&--cal {
			background-color: $anthracite-5;
			height: 50px;
			border-top-right-radius: 5px;
			@media (hover: none) {
				pointer-events: none;
			}
		}
	}
}
