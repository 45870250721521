.ASD-link {
	position: relative;

	@include font('bold', 14px, 17px, 0.01em);

	display: inline-block;
	color: $anthracite;
	background: transparent;
	text-decoration: underline;
	border: 0;
	transition: color 250ms ease;

	&:focus-visible {
		outline: none;
	}

	&:focus-visible {
		@media not (hover: none) {
			color: $orange;
		}
		background: transparent;
	}

	@media (hover: hover) {
		&:hover {
			color: $orange;
			background: transparent;
		}
	}
}
