.list-type-ke_search_pi1.frame {
	z-index: 2;
}

.search-form {
	position: relative;
	z-index: 110;

	&__fieldset {
		padding: 0;
		border: 0;
	}

	&__input-container {
		display: flex;
		flex-direction: column;
		gap: 12px;

		@include md {
			flex-direction: row;
			gap: 20px;
			align-items: center;
		}
	}

	&__search-input[data-has-min-length='true'] {
		~ ^&__flyout {
			&.has-autocomplete,
			&.has-searchresults {
				display: flex;
			}
		}
	}

	&__flyout {
		z-index: 10;
	}

	&__flyout-inner {
		padding-bottom: 0;
	}

	&__search-form-inner {
		position: relative;
		display: flex;
		align-items: center;
		gap: 15px;
		width: 100%;
		height: 50px;
		padding: 16px 15px;
		background: $anthracite-5;
		border-radius: 10px;

		@include lg {
			height: 46px;
		}
	}

	&__filters {
		display: flex;
		flex-direction: column;
		gap: 25px;
		color: $anthrazit;
		margin-top: 96px;
	}

	&__filter-radio {
		display: flex;
		gap: 12px 50px;
		flex-wrap: wrap;

		@include <md {
			display: none;
		}

		&--all {
			order: 0;
		}

		&.has-filter {
		}
	}

	&__filter-radio-label-group {
		display: flex;
		align-items: center;
		gap: 12px;

		&.is-disabled {
			order: 2;
		}
		&:not(.is-disabled) {
			order: 1;
		}
	}

	&__filter-radio {
		margin-bottom: 0 !important;
	}

	&__filter-radio-label {
		.is-disabled & {
			color: $anthracite-20;
		}
	}

	&__select {
		@include sm {
			width: auto !important;
		}
	}

	&__filter-headline {
		margin-bottom: 6px;
	}

	&__filter-selected {
		display: flex;
		justify-content: space-between;
	}

	&__filter-pill {
		position: relative;
		@include font('bold', 14px, 1.2, normal);
		color: $white;
		padding: 3px 20px 3px 7px;
		background: $anthrazit;
		border: 0;
		border-radius: 30px;

		&:not(:last-child) {
			margin-right: 5px;
		}
		transition: background-color 250ms ease, color 250ms ease;

		&:after {
			content: '$acv-icons-var-cross';
			position: absolute;
			font-family: 'acv-icons';
			color: inherit;
			right: 8px;
			font-size: 8px;
			top: 50%;
			transform: translateY(-50%) translateY(1px);
		}

		&:hover {
			background-color: $anthracite-25;
			color: $anthrazit;
		}
	}

	&__filter-reset {
		background: none;
		border: 0;
		text-decoration: underline;
		@include font('bold', '', '', '', normal);

		&:hover {
			text-decoration: none;
		}
	}
}
