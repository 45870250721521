.ec-tab-pane {
	@for $i from 1 to 5 {
		input[id^='toggle-tab$(i)']:checked ~ .form-tabs__list label[for^='toggle-tab$(i)']::after {
			transform: scale3d(1, 1, 1);
		}

		input[id^='toggle-tab$(i)']:checked ~ #tab$(i) {
			display: flex;
			@include <sm {
				display: block;
			}
		}
	}

	padding: 0;

	&__wrapper {
		&:hover {
			cursor: default;
		}
		&-inner {
			padding: 12px 0 0;
			border-top: none;
			position: relative;
			@include <sm {
				padding: 20px 0 40px;
			}
		}
	}

	&__list {
		text-align: left;
		&-item:first-child {
			margin-left: 24px;
		}
	}

	&__form {
		padding: 24px;
		& a {
			@include font('regular', 16px, 25px, normal);
			padding-bottom: 2px;
			text-decoration: none;
			background-image: linear-gradient($orange, $orange);
			background-size: 1px 2px;
			&:hover {
				cursor: pointer;
				color: $orange;
			}

			@include sm {
				@include font('regular', 15px, 20px, normal);
			}
		}
	}

	&__details {
		padding: 0;
	}

	&__details-wrapper {
		display: block;
		width: 100%;
	}

	&__details-copy {
		padding: 50px 20px;

		@include sm {
			padding: 24px;
		}

		& + & {
			padding-top: 0;
		}
	}

	&__file-wrapper {
		display: block;
		padding: 24px;

		^&__details-copy + & {
			border-top: solid 1px $grey-3;
		}
	}

	&__file-item {
		display: inline-block;
		position: relative;
		font-weight: bold;
		padding-left: 16px;
		margin-bottom: 12px;
		&:not(:last-child) {
			margin-right: 24px;
		}
		&:before {
			content: '$acv-icons-var-download';
			font-family: 'acv-icons';
			font-size: 12px;
			position: absolute;
			left: 0;
		}
	}

	&__info {
		@include font('regular', 15px, 20px, normal);
		color: $typo-color;
		position: relative;
		padding-left: 24px;

		&:not(:last-child) {
			@include sm {
				margin-right: 24px;
			}
			@include <sm {
				margin-bottom: 16px;
			}
		}

		&:before {
			font-family: 'acv-icons';
			font-size: 10px;
			position: absolute;
			left: 0;
		}

		&--address {
			&:before {
				content: '$acv-icons-var-location_ec';
				font-size: 18px;
			}
		}
		&--time {
			&:before {
				content: '$acv-icons-var-time';
				font-size: 16px;
			}
		}
		&--phone {
			&:before {
				content: '$acv-icons-var-phone_ec';
				font-size: 16px;
			}
		}
	}
}

.ec-form__submit {
	display: flex !important;
	justify-content: flex-end;
	& button {
		@include btn-primary-orange;
		@include <sm {
			width: 100%;
		}
	}
}
