.basic-list {
    &:not(.visible){
        display: none;

    }
    &__nav{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__pagination{
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            @include font('bold', 24px, normal, 0);

                display: flex;
                align-items: flex-end;
                font-size: 15px;
                line-height: 20px;
                padding: 2px 0;

            a {
                padding: 0;
                color: $darkblue;
            }

            &:not(:last-child) {
                margin-right: 30px;
            }

            &:hover {
                background-image: none;
                color: $orange;
            }

            .acv-icons__before {
                padding-bottom: 2px;
                color: $darkblue;
            }

            &.active {
                color: $orange;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: $orange;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }

	&__button {
		color: inherit;
		background: none;
		border: 0;
		outline: 0;

		&:hover {
			color: $orange;
		}
	}

	&__arrow {
		background: none;
		border: 0;
		outline: 0;
		transition: transform 0.25s ease;

		&:hover {
			transform: scale(1.2);
		}

        &:first-child{
            margin-right: 30px;
        }

        &:last-child{
            margin-left: 30px;
        }

        @include font('', 20px, normal, 0);
        padding-top: 5px;
	}
}
