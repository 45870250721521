.video-generic {
	padding: 0 40px;

	&__wrapper {
		position: relative;
		width: 100%;
	}

	&__source {
		position: absolute;
		size: 100%;
		top: 0;
		left: 0;
	}
}
