$navigation-height-mobile: 70px;
$navigation-height-mobile-search: 80px;
$navigation-height: 90px;
$navigation-height-small: 60px;

.main-navigation {
	:root {
		--100dvh: 100dvh;
	}

	//REQUIRED FOR OVERRITE CLASSIC DESIGN - REMOVE ON DESIGN REWORK/MERGE
	@mixin removeLinkStyles {
		background: transparent !important; // override default a styles
		&:hover {
			background-image: none !important; // override default a styles
		}
	}
	//END

	@mixin columnHeadline {
		display: block;
		text-transform: uppercase;
		margin-bottom: 20px;
		@include font('regular', 12px, 16px, 0.75px);

		@include lg {
			@include font('regular', 14px, 16px, 0);
		}
	}

	@mixin arrowLink {
		@include removeLinkStyles;
		@include font('bold', 14px, 16px, 0);
		width: 100%;
		background-color: $anthracite-5 !important;
		border-radius: 12px;
		display: flex;
		justify-content: center;
		padding: 15px;

		@include lg {
			@include font('bold', 16px, 18px, 0);
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 10px 7px 10px 15px;
			margin-left: -15px;
			position: relative;
			width: 100%;
			border-radius: unset;
			text-align: left;
			background-color: transparent !important;
		}

		&::before {
			@include lg {
				content: '';
				background-color: $anthracite-5;
				width: 80%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				border-radius: 5px;
				opacity: 0;
				transition: width 0.6s ease, opacity 0.8s ease;
			}
		}

		&::after {
			@include lg {
				@include acv-icons-mixin();
				content: '$acv-icons-var-nav-chevron-right';
				font-size: 24px;
				margin-right: 20%;
				transition: margin-right 0.6s ease;
			}
		}

		&:hover {
			@include lg {
				min-width: 100%;
				width: 100%;
			}

			&::before {
				@include lg {
					width: 100%;
					opacity: 1;
				}
			}
			&::after {
				@include lg {
					margin-right: 0;
				}
			}
		}
	}

	@mixin mobileArrow {
		&::after {
			@include <lg {
				@include acv-icons-mixin();
				content: '$acv-icons-var-nav-mobile-arrow-right';
				font-size: 14px;
			}
		}
	}
	@mixin mobileCurrentMenuItem {
		position: relative;

		&:not(.has-shortcut).is-current::before {
			@include <lg {
				content: '';
				background-color: $anthrazit;
				position: absolute;
				top: 0;
				left: -38px;
				width: 3px;
				height: 100%;
			}
		}
	}

	a {
		@include <lg {
			-webkit-tap-highlight-color: transparent !important;
		}
	}

	&__wrapper {
		z-index: 13;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: fixed;
		padding: 0 20px;
		width: 100%;
		height: $navigation-height-mobile;
		transition: height 0.2s;
		background-color: transparent;
		color: $typo-color;

		display: grid;
		grid-template:
			'brand meta'
			'container container';
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 70px 80px;

		@include lg {
			display: grid;
			grid-template: 'brand container';
			grid-template-columns: auto 1fr;
			grid-template-rows: unset;

			z-index: 14;
			padding: 0 25px;
			height: $navigation-height;
		}

		&::before {
			content: '';
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: white;
			opacity: 0;
			transition: opacity 0.4s ease;
			position: absolute;
			left: 0;
			top: 0;

			@include <md {
				transform: translateX(100%);
				transition: transform 0.4s ease;
				opacity: 1;
			}
		}

		^&.is-open &::before {
			transform: translateX(0%);

			@include md {
				opacity: 1;
			}

			@include lg {
				opacity: unset;
			}
		}

		^&.is-hovered &:before,
		^&.search-is-open &:before {
			@include lg {
				opacity: 1;
			}
		}

		&::after {
			content: '';
			width: 100%;
			height: 1px;
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 100;
			pointer-events: none;
			transition: opacity 0.6s ease;
			background-color: white;
			opacity: 0.29;
		}

		^&.is-small & {
			height: $navigation-height-mobile;

			@include lg {
				height: $navigation-height-small;
			}
		}

		^&.is-open & {
			&::after {
				@include lg {
					background-color: $anthrazit;
					opacity: 0.25;
				}
			}
		}
	}

	&__nav-container {
		display: flex;
		justify-content: flex-end;
		grid-area: meta;
		opacity: 1;
		visibility: visible;
		transition: opacity 0.25s ease, visibility 0.25s ease;

		@include lg {
			display: grid;
			// justify-content: unset;
			grid-template-columns: 1fr auto;
			grid-area: container;
		}

		@include xl {
			flex: 1;
			z-index: 2;
		}

		^&.search-is-open & {
			opacity: 0;
			visibility: hidden;
		}
	}

	&__search-container {
		// position: absolute;
		position: relative;
		display: flex;
		flex: 1;
		grid-area: container;
		margin-left: auto;
		width: 100%;
		height: 100%;

		@include <lg {
			transition: transform 0.4s;
			transform: translateX(100%) translateX(40px);

			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 100vw;
				height: 100%;
				left: -20px;
				top: 0;
				background: $white;
			}
		}

		.is-open & {
			@include <lg {
				transform: translateX(0%);
			}
		}

		@include md {
			width: calc(375px - 40px);
		}

		@include lg {
			margin-left: unset;
			width: 100%;
			display: grid;
			grid-template-columns: 1fr auto;
			height: unset;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.25s ease, visibility 0.25s ease;
		}

		@include lg {
			position: relative;
			z-index: 2;
		}

		^&.search-is-open & {
			opacity: 1;
			visibility: visible;
		}
	}

	&__background-wrapper {
		z-index: -1;
	}

	&__background {
		pointer-events: none;
		opacity: 0.5;
		transition: opacity 0.3s;

		^&.is-small & {
			opacity: 1;
		}

		&--orange {
			^^&.is-small &,
			^^&:not(.bg-blue) & {
				background-image: $orange-gradient;
			}
		}

		&--blue {
			^^&:not(.is-open, .is-small).bg-blue & {
				background-image: $blue-gradient;
			}
		}

		&.invisible {
			opacity: 0;
		}
	}

	//BRAND
	&__brand {
		width: 169px;
		z-index: 2;
	}

	&__brand-link {
		background: none;
		display: flex;
		align-items: center;
		@include normalizeHover;
		@include removeLinkStyles;
	}

	&__brand-image {
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
		transition: height 0.2s, width 0.2s;

		@include <sm {
			height: 23px;
			width: auto;
		}

		^&.is-small & {
			@include lg {
				width: 73px;
			}
		}

		&-path {
			transition: fill 0.3s ease;
		}
		^&.is-open &-path {
			@include <lg {
				fill: $orange;
			}
		}
		^&.is-hovered &-path,
		^&.search-is-open &-path {
			@include lg {
				fill: $orange;
			}
		}
	}

	&__brand-caption {
		display: inline-block;
		vertical-align: middle;
		opacity: 1;
		visibility: visible;
		transition: opacity 0.1s, visibility 0.1s;

		@include <sm {
			height: 21px;
			width: auto;
		}

		^&.is-small & {
			@include lg {
				opacity: 0;
				visibility: hidden;
			}
		}

		&-g {
			transition: fill 0.3s ease;
		}
		^&.is-open &-g {
			@include <lg {
				fill: $orange;
			}
		}
		^&.search-is-open &-g,
		^&.is-hovered &-g {
			@include lg {
				fill: $orange;
			}
		}
	}

	//META
	&__meta {
		display: flex;
		align-items: center;
		height: 100%;
		// position: absolute;
		// right: 8px;
		// top: 0;
		z-index: 2;

		@include <sm {
			gap: 13px;
		}

		@include lg {
			position: relative;
			gap: 15px;
		}

		@include xl {
			gap: 30px;
		}
	}

	//LOGOUT
	&__logout {
		@include removeLinkStyles;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;
		width: 54px;
		height: 54px;
		transform: display 2050ms ease;

		^&.is-small & {
			gap: 0;
		}
	}

	&__logout-label {
		display: none;

		@include lg {
			display: block;
			@include font('Flexo-Bold', 12px, 18px, 0);
			color: $white;
			text-wrap: nowrap;
			transition: opacity 0.2s ease, height 0.2s ease, color 0.2s ease;
		}

		^&.is-hovered & {
			color: $anthrazit;
		}

		^&.is-small & {
			opacity: 0;
			height: 0;
		}
	}

	&__logout-icon-g {
		fill: $white;
		transition: fill 0.3s ease;

		^&.is-open & {
			@include <lg {
				fill: $anthrazit;
			}
		}

		^&.is-hovered & {
			@include lg {
				fill: $anthrazit;
			}
		}
	}

	//ACCOUNT
	&__account {
		@include removeLinkStyles;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 7px;
		width: 54px;
		height: 54px;

		@include lg {
			width: auto;
			height: auto;
			transition: gap 0.2s ease;
		}

		^&.is-small & {
			@include lg {
				gap: 0;
			}
		}
	}

	&__button-grid {
		display: grid;
		gap: 30px;
		grid-template-areas: 'left right';
	}

	&__button-grid-item {
		&--left {
			grid-area: left;
		}

		&--right {
			grid-area: right;
		}
	}

	&__close-search {
		display: none;

		@include lg {
			border: none;
			background-color: transparent;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 7px;
			width: 54px;
			height: 54px;
		}

		^&.is-small & {
			@include lg {
				gap: 0;
			}
		}
	}

	&__account-icon {
		position: relative;

		.is-authenticated &::before {
			content: '';
			border-radius: 50%;
			background-color: $signal-green;
			display: block;
			width: 6px;
			height: 6px;
			position: absolute;
			right: -6px;
			top: -3px;
		}

		svg {
			@include <sm {
				width: auto;
				height: 23px;
			}
		}
	}

	&__account-icon-path {
		fill: $white;
		transition: fill 0.3s ease;

		^&.is-open & {
			@include <lg {
				fill: $anthrazit;
			}
		}

		^&.is-hovered & {
			@include lg {
				fill: $anthrazit;
			}
		}
	}

	&__account-label {
		display: none;

		@include lg {
			display: block;
			@include font('Flexo-Bold', 12px, 18px, 0);
			color: $white;
			text-wrap: nowrap;
			transition: opacity 0.2s ease, height 0.2s ease, color 0.2s ease;
		}

		^&.is-hovered &,
		^&.search-is-open & {
			color: $anthrazit;
		}

		^&.is-small & {
			opacity: 0;
			height: 0;
		}
	}

	//SEARCH BUTTON

	&__search-button {
		display: none;

		@include lg {
			border: none;
			background-color: transparent;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 7px;
			width: 54px;
			height: 54px;
		}

		.is-small & {
			@include lg {
				gap: 0;
			}
		}
	}

	&__search-label {
		display: none;

		@include lg {
			display: block;
			@include font('Flexo-Bold', 12px, 18px, 0);
			color: $white;
			text-wrap: nowrap;
			transition: opacity 0.2s ease, height 0.2s ease, color 0.2s ease;
		}

		.is-hovered & {
			color: $anthrazit;
		}

		.is-small & {
			opacity: 0;
			height: 0;
		}
	}

	&__search-icon-path {
		transition: fill 0.2s ease, stroke 0.2s ease;

		.is-hovered & {
			fill: $anthrazit;
			stroke: $anthrazit;
		}
	}

	//BURGER
	&__burger {
		height: 54px;

		@include lg {
			display: none;
		}
	}

	&__burger-inner {
		.top-line {
			transform-origin: left;
			transition: transform 0.2s ease, stroke 0.3s ease;

			^^&.is-open & {
				transform: rotate(45deg) translate(-3px, 0px);
				stroke: $anthrazit;
			}
		}

		.mid-line {
			transition: opacity 0.2s;

			^^&.is-open & {
				opacity: 0;
			}
		}

		.bot-line {
			transition: transform 0.2s, stroke 0.3s ease;

			^^&.is-open & {
				transform: rotate(-45deg) translate(-9px, -2.3px);
				stroke: $anthrazit;
			}
		}
	}

	&__burger-btn {
		display: inline-block;
		font-size: 0;
		background: transparent;
		border: 0;
		outline: none;
		width: 100%;
		height: 100%;
		padding: 0 5px;

		@include sm {
			padding: 0 17px;
		}
	}

	&__burger-rect {
		display: block;
		border-radius: 100px;
		width: 19px;
		height: 2px;
		background-color: white;
		margin: 0 auto;
		pointer-events: none;

		&:not(:last-child) {
			margin-bottom: 5px;
		}

		^&.is-open & {
			background-color: $anthrazit;
		}

		&--top {
			transform-origin: left;
			transition: transform 0.2s;

			^^&.is-open & {
				transform: rotate(45deg);
			}
		}

		&--mid {
			^^&.is-open & {
				opacity: 0;
			}
		}

		&--bot {
			transform-origin: left;
			transition: transform 0.2s;

			^^&.is-open & {
				transform: rotate(-45deg);
			}
		}
	}

	//MENU 1
	&__menu {
		display: none;

		@include lg {
			display: flex;
			height: 100%;
			gap: 5px;
			justify-content: center;
			transition: opacity 0.2s ease;
		}

		@media (min-width: 1080px) {
			gap: 20px;
		}

		@include xl {
			gap: 60px;
		}

		@include xxl {
			gap: 80px;
		}

		&.is-hidden {
			opacity: 0;
			pointer-events: none;
		}
	}

	&__menu-item {
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: relative;

		a {
			cursor: pointer;
			background: none;
		}
	}

	&__menu-link {
		@include lg {
			pointer-events: none;
			display: block;
			padding: 38px 10px;
			text-transform: uppercase;
			@include font('Flexo-Bold', 14px, 14px, 1px);
			transition: padding 0.2s, color 0.2s;
			color: $white;
			text-wrap: nowrap;
			position: relative;
		}

		@include xxl {
			padding: 38px 30px;
		}

		^&.is-hovered & {
			color: $anthrazit;
		}

		^&.is-small & {
			@include lg {
				padding-top: 23px;
				padding-bottom: 23px;
			}
		}

		&::after {
			@include lg {
				content: '';
				background-color: $white;
				position: absolute;
				bottom: 0;
				height: 0px;
				transition: height 0.2s ease-in-out, background-color 0.2s;
				pointer-events: none;
				left: 10px;
				width: calc(100% - 20px);
			}

			@include xxl {
				left: 30px;
				width: calc(100% - 60px);
			}
		}

		&.is-current::after {
			height: 3px;
		}

		^&.is-hovered &:after {
			background-color: $anthrazit;
		}

		^&.is-open &:after {
			height: 0px;
			background-color: $anthrazit;
		}

		^&.is-open &.is-hovered::after {
			height: 3px;
		}
	}

	//SUBMENU
	&__submenu {
		@include lg {
			border-top: none;
			padding-top: unset;
			margin-top: unset;
			display: flex;
			gap: 5px;
		}
		@include xl {
			gap: 40px;
		}
	}

	&__submenu-item {
		@include <lg {
			margin-top: 40px;
			padding-top: 40px;
			border-top: 1px solid $anthracite-20;

			.is-first-section &:first-child {
				margin-top: 9px;
				padding-top: 0;
				border-top: none;
			}
		}
	}

	&__submenu-title {
		@include columnHeadline;
	}

	&__submenu-pages {
		display: flex;
		flex-direction: column;
		gap: 30px;

		@include lg {
			gap: 5px;
		}

		&.columns-2 {
			@include lg {
				display: grid;
				grid-template-columns: repeat(2, 333px);
				grid-column-gap: 16px;
			}

			@include xl {
				grid-column-gap: 40px;
			}
		}
	}

	&__submenu-page {
		@include mobileCurrentMenuItem;

		^&__flyout-wrapper--service & {
			@include lg {
				min-height: 87px;
				display: flex;
				justify-content: center;
				flex-direction: column;
			}
		}

		&.has-shortcut {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
		}

		&-link {
			@include removeLinkStyles;
			@include mobileArrow;
			padding: 5px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include lg {
				display: flex;
				justify-content: unset;
				align-items: unset;
				gap: 3px;
				position: relative;
				padding: 10px;
				margin-left: -10px;
				// width: 300px; //!remove
				transition: padding-left 0.3s ease;
			}

			@include xl {
				width: 100%;
			}

			&::after {
				@include lg {
					content: '';
					background-color: $anthracite-5;
					width: 40%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					border-radius: 5px;
					opacity: 0;
					transition: width 0.3s ease, opacity 0.4s ease;
				}
			}

			&:hover {
				&::after {
					@include lg {
						width: 100%;
						opacity: 1;
					}
				}
			}
		}

		&-icon-wrapper {
			display: none;

			@include lg {
				display: flex;
				height: 44px;
				width: 37px;
				min-width: 37px;
				position: relative;
				transition: min-width 0.3s ease-in-out;
			}

			^&-link:hover & {
				@include lg {
					min-width: 40px;
				}
				@include xl {
					min-width: 44px;
				}
			}
		}

		&-icon {
			@include lg {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				transition: all 0.3s ease-in-out;
			}

			^&-link:hover & {
				@include lg {
					size: 40px;
				}

				@include lg {
					size: 44px;
				}
			}
		}

		&-shortcut {
			height: fit-content;
			align-self: end;
			grid-column: 2;
			padding: 10px 7px 10px 15px;
			display: block;
			@include arrowLink;
			margin-top: 5px;

			@include lg {
				margin-top: unset;
			}
		}

		.shortcut-icon::before {
			font-size: 24px;
		}

		&-title {
			@include font('bold', 18px, 21px, 0);
			display: flex;
			flex-direction: column;

			@include lg {
				@include font('bold', 16px, 18px, 0);
				padding: 0 10px 0 0;
				transition: transform 0.3s ease;
			}

			^&-shortcut & {
				@include font('bold', 14px, 16px, 0);
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 0;

				@include lg {
					@include font('bold', 16px, 18px, 0);
				}
			}

			^&-link:not(.has-icon):hover & {
				@include lg {
					transform: translateX(5px);
				}
			}
			^&-link.has-icon:hover & {
				@include lg {
					transform: translateX(5px);
				}
			}
		}

		&-subtitle {
			display: none;

			@include lg {
				display: block;
				@include font('regular', 14px, 17px, 0);
				margin-top: 5px;
				max-width: 340px;
			}
		}
	}

	//FLYOUT
	&__flyout {
		z-index: 12;
		position: fixed;
		transition: transform 0.4s, visibility 0.4s;
		transform: translate(100%, 0%);
		width: 100%;
		height: 100dvh;
		margin-top: $navigation-height-mobile;
		pointer-events: none;
		visibility: hidden;

		@include md {
			width: 375px;
			right: 0;
		}

		@include lg {
			background-color: $white;
			width: 100%;
			height: 630px;
			padding: $navigation-height 16px 0 16px;
			box-shadow: 0px 8px 12px 0px #00000040;
			transform: translate(0%, -120%);
			transition: padding 0.2s, transform 0.4s;
			margin-top: unset;
			right: unset;
			pointer-events: all;
		}

		&::before {
			content: '';
			width: 100%;
			height: 1px;
			background-color: $anthrazit;
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			opacity: 0.25;

			@include lg {
				display: none;
			}
		}

		&::after {
			content: '';
			width: 100%;
			height: 100%;
			background-color: #ffffff;
			position: absolute;
			display: block;
			top: $navigation-height-mobile-search;
			left: 0;
			z-index: -1;

			@include lg {
				display: none;
			}
		}

		&.is-open {
			display: block;
			visibility: visible;
			@include <lg {
				transform: translate(0%, 0%);
			}

			@include lg {
				transform: translate(0%, 0%);
			}
		}

		^&.is-small & {
			@include lg {
				padding: $navigation-height-small 16px 16px 16px;
			}
		}
	}

	&__flyout-bg {
		display: none;

		@include md {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100dvh;
			background-color: #000;
			opacity: 0;
			transition: opacity 0.4s ease, visibility 0.4s ease;
			z-index: 8;
			visibility: hidden;
		}

		^&.is-open &,
		^&.search-is-open & {
			display: block;
			opacity: 0.2;
			visibility: visible;
		}

		^&.is-open & {
			@include lg {
				display: none;
			}
		}
	}

	&__flyout-content {
		position: absolute;
		top: 1px; //1px for the border
		right: 0;
		transition: transform 0.4s ease, visibility 0.4s ease;
		transform: translateX(100%);
		background-color: $white;
		width: 100vw;
		height: calc(100vh - $navigation-height-mobile);
		overflow: hidden auto;
		z-index: 2;
		padding-bottom: 140px;
		visibility: hidden;
		pointer-events: all;

		@include md {
			width: 375px;
		}

		@include lg {
			position: relative;
			top: unset;
			right: unset;
			max-width: 1080px;
			width: 100%;
			background-color: transparent;
			height: 0;
			margin: 0 auto;
			padding: 0;
			overflow: unset;
			justify-content: space-between;
			flex-direction: column;
			opacity: 0;
			transform: unset;
			transition: opacity 3s ease;
		}

		@include xl {
			padding: 0;
		}

		&.is-active {
			visibility: visible;
			@include <lg {
				transform: translateX(0%);
			}

			@include lg {
				display: flex;
				transform: unset;
				padding: 40px 0 22px 0;
				animation: showFlyoutContent 0.4s ease;
				animation-fill-mode: forwards;
				height: 100%;
			}

			@include xl {
				padding: 50px 0 40px 0;
			}
		}
	}

	@keyframes showFlyoutContent {
		0% {
			opacity: 0;
			margin-top: -10px;
		}
		100% {
			opacity: 1;
			margin-top: 0;
		}
	}

	&__flyout-back-btn {
		@include font('Flexo-Bold', 16px, 18px, 1px);
		color: $anthrazit;
		background: $white;
		position: sticky;
		top: 0;
		z-index: 5;
		padding: 0 30px;
		border: none;
		width: 100%;
		height: 78px;
		text-transform: uppercase;

		@include lg {
			display: none;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 30px;
			background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		}

		&::before {
			@include acv-icons-mixin();
			content: '$acv-icons-var-nav-mobile-chevron-right';
			font-size: 14px;
			position: absolute;
			left: 30px;
			top: 50%;
			transform: translateY(-50%) rotate(180deg);
		}
	}

	&__flyout-content-title {
		@include lg {
			display: none;
		}
	}

	&__flyout-wrapper {
		padding: 0 38px;
		margin-top: 18px;

		@include lg {
			padding: 0;
			margin-top: 0;
			display: grid;
			gap: 10px;
			max-width: 1080px;
		}

		@include xl {
			max-width: unset;
			width: calc(1080px + 60px);
			padding: 0 30px;
			gap: 32px;
		}

		&--tarif {
			@include lg {
				grid-template-columns: 420px 430px;
				gap: 10px;
			}

			@media (min-width: 1115px) {
				grid-template-columns: 510px 530px;
				gap: 40px;
			}

			.main-navigation__submenu {
				gap: 20px;
			}

			.main-navigation__submenu-item {
				@include lg {
					width: 50%;
				}
			}
		}

		&--service {
			.main-navigation__submenu {
				@include lg {
					display: grid;
					grid-template-columns: 333px 667px;
					gap: 40px;
				}
			}

			.main-navigation__submenu-page-link {
				@include lg {
					width: 333px !important;
				}
			}
		}

		&--relatedtips {
			@include lg {
				grid-template-columns: 450px 300px 250px;
				gap: 0;
			}

			@media (min-width: 1115px) {
				grid-template-columns: 450px 300px 250px;
				gap: 40px;
			}
		}

		&--aboutus {
			@include <lg {
				display: grid;
			}

			.main-navigation__sustainability-card {
				@include <lg {
					grid-area: 1;
				}
			}

			.main-navigation__sustainability-seperator {
				@include <lg {
					grid-area: 2;
				}
			}

			.main-navigation__submenu {
				@include lg {
					display: grid;
					grid-template-columns: 250px 375px;
					gap: 20px;
				}

				@media (min-width: 1115px) {
					gap: 40px;
				}
			}

			@include lg {
				grid-template-columns: auto 300px;
				gap: 20px;
			}

			@media (min-width: 1115px) {
				grid-template-columns: auto 375px;
				gap: 40px;
			}
		}

		&--contact {
			.main-navigation__submenu {
				@include lg {
					display: block;
				}
			}

			@include lg {
				grid-template-columns: minmax(auto, 333px) auto minmax(auto, 333px);
				gap: 0;
			}

			@media (min-width: 1115px) {
				grid-template-columns: 333px 333px 333px;
				gap: 40px;
			}
		}
	}

	&__button-bar {
		border-top: 1px solid $anthracite-20;
		margin: 40px 38px 0 38px;
		padding: 40px 0 0 0;
		display: flex;
		flex-direction: column;
		gap: 35px;

		@include lg {
			border-top: 1px solid $anthracite-20;
			margin: 30px 0 0 0;
			padding: 30px 30px 40px 30px;
			position: relative;
			flex-direction: row;
			gap: unset;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}

		&--left {
			justify-content: flex-start;
		}

		&--right {
			justify-content: flex-end;
		}

		&--both {
			justify-content: space-between;
		}

		^&__button-right,
		^&__button-left {
			@include lg {
				min-width: 315px !important;
			}
		}
	}

	&__emergency-request {
		border-top: 1px solid $anthracite-20;
		padding: 40px 38px 40px 38px;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		background: white;
		pointer-events: auto;

		@include md {
			padding: 40px 38px;
		}

		@include lg {
			display: none;
		}

		@media screen and (max-height: 400px) {
			position: relative;
		}
	}

	&__emergency-request-btn {
		width: 100% !important;
	}

	//MOBILE MENU
	&__mobile-menu {
		padding: 20px 30px 0 30px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		position: absolute;
		top: $navigation-height-mobile-search;
		left: 0;
		width: 100%;
		height: calc(var(--100dvh) - 140px);
		pointer-events: all;

		@media screen and (max-height: 400px) {
			overflow-y: scroll;
		}

		@include lg {
			display: none;
		}
	}

	&__mobile-menu-btn {
		@include font('Flexo-Bold', 18px, 18px, 1px);
		color: $anthrazit;
		display: flex;
		justify-content: space-between;
		padding: 10px 0;
		background: none;
		border: none;
		width: 100%;
		position: relative;
		text-transform: uppercase;

		&::after {
			@include acv-icons-mixin();
			content: '$acv-icons-var-nav-mobile-chevron-right';
			font-size: 14px;
		}

		&::before {
			content: '';
			position: absolute;
			left: -30px;
			top: 0;
			width: 0;
			height: 100%;
			background-color: $anthrazit;
		}

		&.is-current::before {
			width: 3px;
		}
	}

	//TARIFF
	&__tariff-list {
		display: flex;
		flex-direction: column;
		gap: 30px;

		@include lg {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&__tariff-item {
		@include mobileCurrentMenuItem;
	}

	&__tariff {
		@include removeLinkStyles;
		@include mobileArrow;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 0;

		@include lg {
			display: flex;
			flex-direction: column;
			justify-content: unset;
			align-items: unset;
			gap: 11px;
			text-decoration: none;
			position: relative;
			padding: 12px;
			margin-top: -12px;
			margin-left: -12px;
		}

		&::after {
			@include lg {
				content: '';
				background-color: $anthracite-5;
				width: 100%;
				height: 60%;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: -1;
				border-radius: 5px;
				opacity: 0;
				transition: height 0.3s ease, opacity 0.4s ease;
			}
		}

		&:hover::after {
			@include lg {
				height: 100%;
				opacity: 1;
			}
		}

		&-image-wrapper {
			display: none;

			@include lg {
				display: block;
				position: relative;
				height: 130px;
			}
		}

		&-image {
			@include lg {
				width: 200px;
				max-width: 100%;
				height: auto;
				transition: all 0.3s ease-in-out;
				position: absolute;
				bottom: 0;
			}

			^&:hover & {
				@include lg {
					width: 205px;
				}
			}
		}

		&-title {
			@include font('bold', 18px, 18px, 0);
			display: flex;

			@include lg {
				@include font('bold', 16px, 18px, 0);
			}
		}

		&-price {
			margin-left: 16px;

			@include lg {
				font-size: 12px !important;
				margin-left: 10px;
			}
		}

		&-subtitle {
			display: none;

			@include lg {
				display: block;
				@include font('regular', 14px, 17px, 0);
			}
		}
	}

	//RELATED TIPS
	&__relatedtips {
		&-list {
			display: flex;
			flex-direction: column;
			gap: 20px;

			@include lg {
				gap: 0px;
			}
		}

		&-more {
			@include arrowLink;
			margin-top: 20px;

			@include lg {
				margin-top: 5px;
			}
		}
	}

	// same as contactmenu-page
	&__relatedtip {
		@include mobileCurrentMenuItem;

		&-headline {
			@include columnHeadline;
		}

		&-link {
			@include removeLinkStyles;
			display: flex;

			@include lg {
				position: relative;
				padding: 15px;
				margin-left: -15px;
				height: fit-content;
				min-width: 300px;
				max-width: 450px;
				min-height: 87px;
			}

			@include xl {
				max-width: unset;
			}

			&::after {
				@include lg {
					content: '';
					background-color: $anthracite-5;
					width: 40%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					border-radius: 5px;
					opacity: 0;
					transition: width 0.3s ease, opacity 0.4s ease;
				}
			}

			&:hover {
				&::after {
					@include lg {
						width: 100%;
						opacity: 1;
					}
				}
			}
		}

		&-image {
			size: 56px;
			object-fit: cover;
			border-radius: 10px;

			@include lg {
				size: 52px;
				transition: all 0.2s ease-in-out;
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			^&:hover & {
				@include lg {
					size: 54px;
				}
			}
		}

		&-title-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 15px;

			@include lg {
				margin-left: unset;
				transform: translateX(calc(52px + 10px));
				transition: transform 0.4s ease;
				max-width: 380px;
			}

			^&:hover & {
				@include lg {
					transform: translateX(calc(52px + 15px));
				}
			}
		}

		&-title {
			@include font('bold', 18px, 21px, 0);
			text-wrap: balance;

			@include lg {
				@include font('bold', 16px, 18px, 0);
			}
		}

		&-subtitle {
			display: none;

			@include lg {
				@include font('regular', 14px, 17px, 0);
				margin-top: 5px;
				text-wrap: balance;
				display: block;
			}
		}
	}

	//EPAPER
	&__epaper {
		display: flex;
		flex-direction: column;
		margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid $anthracite-20;

		@include lg {
			margin-top: unset;
			padding-top: unset;
			border-top: unset;
		}

		&-headline {
			@include columnHeadline;
		}

		&-info {
			@include font('bold', 18px, 21px, 0);

			@include lg {
				@include font('bold', 16px, 18px, 0);
			}
		}

		&-link {
			@include removeLinkStyles;
			@include mobileArrow;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: fit-content;

			@include lg {
				position: relative;
				flex-direction: column;
				align-items: flex-start;
				gap: 20px;
				padding: 12px;
				margin-top: -12px;
				margin-left: -12px;
				margin-bottom: 5px;
				width: fit-content;
			}

			&::after {
				@include lg {
					content: '';
					background-color: $anthracite-5;
					width: 100%;
					height: 60%;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: -1;
					border-radius: 5px;
					opacity: 0;
					transition: height 0.3s ease, opacity 0.4s ease;
				}
			}

			&:hover::after {
				@include lg {
					height: 100%;
					opacity: 1;
				}
			}
		}

		&-image {
			display: none;

			@include lg {
				display: block;
				width: 152px;
				height: 202px;
				border-radius: 10px;
				object-fit: cover;
				margin-bottom: 5px;
				transition: all 0.3s ease-in-out;
			}

			^&-link:hover & {
				@include lg {
					width: 157px;
					height: 207px;
					margin-bottom: 0px;
				}
			}
		}

		&-more {
			@include arrowLink;
			margin-top: 35px;

			@include lg {
				margin-top: unset;
			}
		}
	}

	//SUSTAINABILITY
	&__sustainability-seperator {
		margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid $anthracite-20;
		display: block;

		@include lg {
			display: none;
		}
	}

	&__sustainability-card {
		border: 1px solid $green;
		border-radius: 10px;
		padding: 16px;
		height: fit-content;

		@include lg {
			max-width: 375px;
			justify-self: flex-end;
		}

		&-headline {
			@include columnHeadline;
			margin-bottom: 16px;
		}

		&-copy {
			@include font('bold', 24px, 29px, 0);
		}

		&-btn {
			margin-top: 34px;
			width: 100% !important;
		}
	}

	//CONTACT
	&__contactcard {
		margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid $anthracite-20;

		@include lg {
			width: 333px;
			justify-self: flex-end;
			margin-top: calc(16px + 15px);
			padding-top: unset;
			border-top: unset;
		}
	}

	&__contactcard-top {
		background-color: $anthrazit;
		border-radius: 10px;
		padding: 16px;
		height: fit-content;
		display: flex;
		flex-direction: column;
		align-items: center;

		&-headline {
			@include columnHeadline;
			margin-bottom: 16px !important;
			text-align: center;
			color: $white;
		}

		&-number {
			@include font('bold', 24px, 29px, 0);
			color: $white;
			text-align: center;
			@include removeLinkStyles;

			&:hover {
				text-decoration: underline;
				color: $white;
			}
		}

		&-or {
			@include font('bold', 12px, 14px, 0);
			color: $white;
			display: flex;
			width: 100%;
			align-items: center;
			text-align: center;
			margin: 15px 0;

			&::before,
			&::after {
				content: '';
				display: inline-block;
				width: 50%;
				height: 1px;
				background-color: $white;
				margin: 0 10px;
			}
		}

		&-btn {
			width: 100% !important;
		}
	}

	&__contactcard-bottom {
		background-color: $grey-4;
		color: $anthrazit;
		border-radius: 10px;
		padding: 16px;
		height: fit-content;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;

		&-headline {
			@include columnHeadline;
			margin-bottom: 16px !important;
			color: $anthrazit;
		}

		&-number {
			@include font('bold', 24px, 29px, 0);
			color: $anthrazit;
			text-align: center;
			@include removeLinkStyles;

			&:hover {
				text-decoration: underline;
				color: $anthrazit;
			}
		}

		&-reachable {
			width: 100%;
			margin-top: 16px;

			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background-color: black;
				margin-bottom: 16px;
				opacity: 0.5;
			}
		}

		&-reachable-wrapper {
			display: flex;
			justify-content: space-between;
			width: 100%;
			color: $anthrazit;
			@include font('regular', 16px, 18px, 0);

			@include lg {
				@include font('regular', 14px, 17px, 0);
			}

			* {
				width: fit-content;
			}
		}
	}

	&__contactmenu {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid $anthracite-20;

		@include lg {
			margin-top: calc(16px + 15px);
			padding-top: unset;
			border-top: unset;
			gap: 0;
		}
	}

	// same as relatedtip
	&__contactmenu-page {
		@include mobileCurrentMenuItem;

		&-headline {
			@include columnHeadline;
		}

		&-link {
			@include removeLinkStyles;
			display: flex;

			@include lg {
				position: relative;
				padding: 15px;
				margin-left: -15px;
				height: fit-content;
				min-width: 300px;
				width: 360px;
				min-height: 87px;
			}

			&::after {
				@include lg {
					content: '';
					background-color: $anthracite-5;
					width: 40%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					border-radius: 5px;
					opacity: 0;
					transition: width 0.3s ease, opacity 0.4s ease;
				}
			}

			&:hover {
				&::after {
					@include lg {
						width: 100%;
						opacity: 1;
					}
				}
			}
		}

		&-image {
			size: 56px;
			object-fit: cover;
			border-radius: 10px;

			@include lg {
				size: 52px;
				transition: all 0.2s ease-in-out;
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			^&:hover & {
				@include lg {
					size: 54px;
				}
			}
		}

		&-title-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 15px;

			@include lg {
				margin-left: unset;
				transform: translateX(calc(52px + 10px));
				transition: transform 0.4s ease;
			}

			^&:hover & {
				@include lg {
					transform: translateX(calc(52px + 15px));
				}
			}
		}

		&-title {
			@include font('bold', 18px, 21px, 0);
			text-wrap: balance;

			@include lg {
				@include font('bold', 16px, 18px, 0);
			}
		}

		&-subtitle {
			display: none;
			text-wrap: balance;

			@include lg {
				display: block;
				@include font('regular', 14px, 17px, 0);
				margin-top: 5px;
				width: 250px;
			}
		}
	}
}
