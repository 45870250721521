.file-select-list {
	background-color: white;

	&__content {
		background: $white;
		padding: 20px;
		border-top: 1px solid $grey-3;
		display: flex;
		padding: 22px 19px 17px 11px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
	}

	&__icon {
		flex: 0 0 auto;
		width: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 18px;

		&[class*='cross'] {
			color: $signal-red;
		}

		&[class*='checkmark'] {
			color: $signal-green;
		}

		&:before {
			margin: 0;
			font-size: 12px;
		}
	}

	&__name {
		flex: 1 1 auto;
		font-size: 18px;
	}

	&__remove-button {
		flex: 0 0 auto;
		width: 20px;
		font-size: 0;
		border: 0;
		background: none;
		margin-left: 15px;
		&:before {
			font-size: 22px;
			margin-right: 0 !important;
			transform: translateY(-1px);
			display: inline-block;
		}
	}

	&__error {
		@include font('regular', 13px, 16px, 0px);
		margin-top: 10px;

		color: $signal-red;
	}
}
