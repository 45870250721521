$nodeSize: 22px;
$nodeLine: 19px;
$nodeLineLG: 30px;
$nodeLineXL: 77px;

.timeline {
	background-color: $darkblue;

	@include sm {
		background-color: transparent;
	}

	&__wrapper {
		padding-top: 40px;
		// padding-bottom: 80px;

		@include sm {
			background-color: $darkblue;
		}

		@include lg {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}
	&__wrapper-inner {
		position: relative;
		width: 100%;
		display: inline-flex;
		justify-content: center;

		@include sm {
			padding: 0px 40px;
		}
		@include sm {
			padding: 0px 80px;
		}
		@include lg {
			padding: 0px;
		}
	}

	&__line {
		padding-right: 36px;
		padding-left: 15px;

		@include sm {
			padding-left: 0;
		}

		@include lg {
			position: absolute;
			height: 100%;
			padding: 0;
			left: 50%;
			transform: translateX(-50%);
		}

		&:before {
			content: '';
			height: 100%;
			width: 1px;
			display: block;
			background-color: $grey-1;
		}
	}

	&__node {
		position: relative;
		display: block;

		&::before {
			content: '';
			position: absolute;
			display: block;
			width: $nodeSize;
			height: $nodeSize;
			border-radius: 50%;
			background-color: $darkblue;
			border: 2px solid white;
			left: -47px;
			top: -11px;
			transform: scale(0);
			transition: transform 0.3s ease;
			transition-delay: 0.4s;
		}

		&::after {
			content: '';
			position: absolute;
			display: block;
			width: $nodeLine;
			height: 1px;
			background-color: white;
			left: -23px;
			top: 0;
			transform: scaleX(0);
			transition: transform 0.1s ease-in;
			transition-delay: 0.25s;

			@include lg {
				width: $nodeLineLG;
			}
			@include xl {
				width: $nodeLineXL;
			}
		}

		&-is-open {
			&::before {
				transform: scale(1);
			}

			&::after {
				transform: scaleX(1);
			}
		}
	}

	&__cards {
		padding: 55px 0;

		@include lg {
			position: relative;
			display: inline-flex;
			justify-content: center;
			flex-direction: column;
			width: 100%;
		}
	}

	&__card {
		margin: 35px 0;

		.timeline__card-inner {
			transform: translateX(50px);
			opacity: 0;
		}

		.timeline__node {
			&::after {
				transform-origin: right;
			}
		}

		@include lg {
			width: 100%;
			margin: -15px 0;

			&:nth-child(2n) {
				.timeline__card-inner {
					float: right;
					transform: translateX(50px);
					opacity: 0;
				}

				.timeline__node {
					&::before {
						left: calc(50% - ($nodeSize/2));
					}
					&::after {
						left: calc(50% - (-15px));
						transform-origin: right;
					}
				}
			}

			&:nth-child(2n + 1) {
				.timeline__card-inner {
					transform: translateX(-50px);
				}

				.timeline__node {
					&::before {
						left: auto;
						right: calc(50% - ($nodeSize/2));
					}
					&::after {
						left: auto;
						right: calc(50% - (-15px));
						transform-origin: left;
					}
				}
			}
		}
	}

	&__card-inner {
		background-color: white;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
		opacity: 0;
		transition: transform 0.25s ease, opacity 0.4s ease;

		@include lg {
			width: 330px;
		}
		@include xl {
			width: 350px;
		}
		@include xxl {
			width: 400px;
		}

		&-is-open {
			opacity: 1 !important;
			transform: translateX(0) !important;
		}
	}

	&__card-image {
		height: 172px;
		width: 100%;
		object-fit: cover;

		@include md {
			height: 182px;
		}
	}

	&__card-orange-line {
		display: block;
		height: 6px;
		width: 77px;
		background-color: $orange;
	}

	&__card-content {
		padding: 18px;
		padding-top: 30px;
	}

	&__card-headline {
		@include font('headline', 20px, 25px, 0);
		color: $darkblue;
		padding-bottom: 20px;

		@include md {
		}
	}

	&__card-copy {
		@include font('regular', 15px, 25px, 0);
		color: $grey-1;
		@include md {
		}
	}
}

.frame-type-acvcontent_backgroundwrapper + .frame-type-acvcontent_timeline {
	margin-top: 0 !important;
}
