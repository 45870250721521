$headItemHeightUnSticky: 148px;
.frame-type-acvcontent_tarifcomparetarifcomparegroup {
	margin-top: 0 !important;
	&:not(:first-child):not(.initialized) {
		display: none;
	}
	&.initialized:not(.visible) {
		display: none;
	}
}

.tarifCompareGroup {
	&.sticky-head {
		padding-top: 118px;
		@include lg {
			padding-top: $headItemHeightUnSticky;
		}
	}

	&__head {
		display: flex;
		gap: 2px;

		^&.sticky-head & {
			position: fixed;
			top: 70px;
			margin: 0 auto;
			z-index: 5;
			width: calc(100% - $gutter);
			padding-top: 5px;

			@include lg {
				top: 50px;
				width: 930px;
			}

			@include md {
				width: 690px;
			}

			@include lg {
				width: 930px;
			}

			@include xl {
				width: 1110px;
			}

			@include xxl {
				width: 1250px;
			}

			&:after {
				content: '';
				display: block;
				background: $cornflower;
				opacity: 0.8;
				width: 100vw;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 0;
				height: 100%;
			}
		}
	}

	&__head-item {
		flex: 1 1 10px;
		color: white;
		background-color: $anthrazit;
		border-radius: 15px 3px 5px 5px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		cursor: pointer;

		^&.sticky-head & {
			padding: 9px 15px;

			@include <md {
				max-width: calc(50% - 1px);
				gap: 0;
			}
		}

		@include <md {
			&:not(.visible):nth-child(n + 3) {
				display: none;
			}
		}

		@include <lg {
			gap: 10px;
		}

		@include lg {
			height: $headItemHeightUnSticky;
		}

		&[data-index='0'] {
			background-color: $orange;
			order: 0;
		}

		^&.sticky-head & {
			height: auto;
			z-index: 10;
		}

		&--spacer {
			cursor: auto;
			display: none;
			@include lg {
				visibility: hidden;
				content: '';
				display: block;
				padding: 24px;
				flex: 1 1 10px;
			}
		}
	}

	&__tarif-name {
		@include font('headline', 16px, 20px, normal);
		^&.sticky-head & {
			flex: 0 1 auto;
			@include font('headline', 15px, 16px, normal);
			@include md {
				@include font('headline', 16px, 20px, normal);
			}

			@include <md {
				span {
					display: block;
				}
				span:first-child {
					margin-right: 5px;
				}

				span:last-child {
					flex: 0 1 auto;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				flex-direction: row;
			}
		}

		@include <md {
			display: flex;
			flex-direction: column;
		}

		@include md {
			span:first-child {
				margin-right: 5px;
			}
		}
	}

	&__price {
		display: flex;
		gap: 6px;
		^&.sticky-head & {
			margin-top: 3px;
		}
	}

	&__price-value {
		@include font('regular', 15px, 20px, normal);

		@include lg {
			@include font('headline', 28px, 36px, normal);
		}

		^&.sticky-head & {
			@include font('regular', 12px, 16px, normal);

			@include md {
				@include font('regular', 15px, 20px, normal);
			}
		}
	}

	&__price-label {
		@include font('regular', 15px, 20px, normal);

		@include lg {
			@include font('regular', 13px, 16px, normal);
		}

		^&.sticky-head & {
			@include font('regular', 12px, 16px, normal);

			@include md {
				@include font('regular', 15px, 20px, normal);
			}
		}
	}

	&__section {
		display: flex;
		gap: 2px;
		margin-top: 15px;
		flex-direction: column;

		@include md {
			margin-top: 2px;
		}

		@include lg {
			margin-top: 2px;
		}
	}

	&__content {
		padding-bottom: 48px;
	}
}
