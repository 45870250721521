.ASD-forms {
	width: 100%;

	&__fieldset {
		margin: 0;
		padding: 0;
		border: 0;
		min-inline-size: 0;
	}

	&__input-group {
		position: relative;
	}

	&__row {
		position: relative;

		&--flex {
			display: flex;
			flex-direction: column;
			gap: 10px;

			@include md {
				gap: 20px;
			}
		}

		&--noGap {
			gap: 0;
		}

		& + & {
			margin-top: 20px;

			&--largeMargin {
				margin-top: 30px;
			}

			&--textContent {
				@include md {
					margin-top: 30px;
				}
			}
		}

		&[class*='--col'] {
			display: grid;
			grid-gap: 20px 10px;
		}
		&--col {
			display: grid;
			@for $i from 1 to 12 {
				&-$(i) {
					grid-template-columns: repeat($(i), 1fr);
				}
			}

			@each $v in sm, md, lg, xl, xxl {
				@include $(v) {
					@for $i from 1 to 12 {
						&-$(v)-$(i) {
							grid-template-columns: repeat($(i), 1fr);
						}
					}
				}
			}
		}
	}

	&__sub-title {
		margin-bottom: 15px;
	}

	&__zip-city-row {
		display: grid;
		grid-gap: 20px 10px;

		@include md {
			grid-template-columns: 25% auto;
		}
	}

	&__info-box {
		display: flex;
		flex-direction: column;
		gap: 15px;
		padding: 16px;

		.ASD-step-tile__body &:last-child {
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;
		}

		@include md {
			gap: 10px;
		}

		&--rounded {
			border-radius: 12px;
		}

		&--yellow {
			background-color: $yellow-20;
		}

		&--red {
			background-color: $red-15;
		}

		&--green {
			background-color: $green-20;

			i {
				color: $green;
			}
		}
	}

	&__info-box-content {
		display: flex;
		justify-content: space-between;

		&--with-icon {
			gap: 15px;
			align-items: center;
			justify-content: flex-start;

			i {
				font-size: 0;
				display: block;
				&:before {
					font-size: 34px;
					margin: 0 !important;
				}
			}
		}
	}

	&__info-box-content-icon {
		top: 11px;

		&--red {
			font-size: 18px;
			color: $red;
		}

		&:before {
			margin-right: 8px !important;
			display: inline-block;
			transform: translateY(1px);
		}
	}
}
