.tarifCompareContainer {
	position: relative;

	&__headline {
		@include font('headline', 20px, 25px, -0.0125em);
		margin-bottom: 18px;
		padding-right: 35px;

		@include md {
			padding-right: unset;
			margin-bottom: 48px;
		}

		@include lg {
			@include font('headline', 28px, 36px, -0.015em);
			margin-bottom: 79px;
		}
	}

	&__change-tarif-button {
		position: absolute;
		background: none;
		right: 21px;
		top: 4px;
		border: none;
		color: $anthrazit;
		font-size: 0;

		@include md {
			@include font('normal', 14px, 18px, 0.0025em);
		}

		@include lg {
			right: 21px;
			top: 6px;
			@include font('normal', 20px, 25px, 0.0025em);
		}

		&:after {
			font-size: 11px;

			@include md {
				margin-left: 33px;
			}

			@include lg {
				margin-left: 28px;
				font-size: 15px;
			}
		}
	}

	&__grid {
		position: relative;
	}

	&--on-white {
		background: white;
		padding: 50px 0;
		&:before {
			content: '';
			position: absolute;
			width: 200vw;
			height: 100%;
			left: -50vw;
			top: 0;
			background: white;
		}
	}
}
