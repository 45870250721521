.ASD-toggle {
	appearance: none;
	position: relative;
	border-radius: 100vw;
	height: 20px;
	width: 36px;
	background-color: $anthracite-60;
	border: none;
	transition: background-color 250ms easeInOutSine;
	-webkit-tap-highlight-color: transparent;
	flex: 0 0 auto;
	align-self: flex-start;

	&:not([disabled]) {
		cursor: pointer;
	}

	&:checked {
		background-color: $anthracite;
	}

	&.is-invalid:not(:checked) {
		background-color: $signal-red;
	}

	&:focus {
		outline: none;
	}

	&:before {
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		size: 16px;
		border-radius: 50%;
		background-color: white;
		transition: left 250ms easeInOutSine, background-color 250ms easeInOutSine, outline 250ms ease;
		box-shadow: 0px 3.59px 21.55px 0px #0000000d;
		z-index: 1;
		outline: 2px solid transparent;

		^&:focus& {
			outline: 2px solid $anthracite;
		}
	}

	&:checked:before {
		left: calc(100% - 18px);
	}

	&__label {
		display: flex;
		gap: 25px;
		align-items: center;
	}

	&__label-text {
		color: $anthracite;
		@include font('bold', 14px, 120%, normal);
	}
}
