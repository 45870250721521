.frame-type-acvcontent_tarifcomparetarifcompareservicegroup {
	margin-top: 0 !important;
}

.tarifCompareServiceGroup {
	&__header {
		position: relative;
		border-bottom: 1px solid $grey-3;
		padding: 19px 20px 18px 0;
		cursor: pointer;
		user-select: none;
		transition: border-color 0.3s ease;
		@include font('regular', 15px, 22px, 0.0025em);

		@include lg {
			padding: 42px 24px 34px;
			@include font('regular', 20px, 26px, normal);
		}

		@include <lg {
			-webkit-tap-highlight-color: transparent;
		}

		&:after {
			@include acv-icons-mixin();
			margin-right: 0;
			content: '$acv-icons-var-arrow-right';
			position: absolute;
			right: 5px;
			top: calc(50% + 3px);
			font-size: 11px;
			transform: translateY(-50%) rotate(90deg);
			transition: transform 0.3s ease;
			color: $anthrazit;

			@include lg {
				top: calc(50% + 5px);
				font-size: 15px;
				right: 31px;
			}
		}

		^&.is-open & {
			border-bottom: 2px solid $grey-3;
			&:after {
				transform: translateY(-50%) rotate(-90deg);
			}
		}
	}

	&__content {
		display: none;

		@include <lg {
			padding-top: 15px;
		}

		^&.is-open & {
			display: block;
		}
	}
}
