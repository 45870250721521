.u-hide {
	display: none;

	&-sm {
		@include sm {
			display: none;
		}
	}

	&-md {
		@include md {
			display: none;
		}
	}

	&-lg {
		@include lg {
			display: none;
		}
	}
}
