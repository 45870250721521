.sharing-icons {
	padding-top: 40px;
	.wrapper-white &:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		background-color: $grey-3;

		@include sm {
			left: 15px;
			width: calc(100% - 30px);
		}
	}

	&__copy {
		padding-bottom: 18px;

		@include font('regular', 16px, 25px, 0);
		color: $darkblue;
		text-align: center;

		.wrapper-white & {
			color: $grey-1;
		}

		@include md {
			font-size: 15px;
			line-height: 20px;
			padding-bottom: 14px;
		}
	}

	.shariff {
		ul {
			justify-content: center;
		}

		.shariff-button {
			height: auto;
			width: auto;
			margin: 0;
			padding: 0 22px;

			@include md {
				padding: 0 13px;
			}
		}

		a {
			height: auto;
			width: auto;
			border: none;
			background-color: transparent !important;
			display: flex;
			align-items: center;
			background-image: none;
		}

		a:hover {
			background-color: transparent !important;
		}

		span {
			display: none;
		}
	}

	.shariff-button.twitter a {
		height: 21px;
	}
	.shariff-button.facebook a {
		height: 22px;
	}
	.shariff-button.mail a {
		height: 21px;
	}

	.shariff-button.twitter a:before {
		content: '$acv-icons-var-logo-x';
		font-size: 21px;
	}
	.shariff-button.facebook a:before {
		content: '$acv-icons-var-logo-facebook';
		font-size: 22px;
	}
	.shariff-button.whatsapp a:before {
		content: '$acv-icons-var-logo-whatsapp';
		font-size: 22px;
	}
	.shariff-button.mail a:before {
		content: '$acv-icons-var-logo-email';
		font-size: 21px;
	}

	.shariff-button {
		a {
			&:before {
				font-family: acv-icons !important;
				color: $white;
				transition: color 250ms ease;

				.wrapper-white & {
					color: $darkblue;
				}
			}
			&:hover::before {
				color: $darkblue;

				.wrapper-white & {
					color: $orange;
				}
			}
		}
	}
}

.frame-type-acvcontent_narrowcolumn + .frame-type-acvcontent_sharingicons {
	margin-top: 0 !important;
}

.content__wrapper > .frame-type-acvcontent_sharingicons {
	margin-top: 0 !important;
}
