.ASD-icon-button {
	height: 42px;
	width: 42px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: transparent;
	border: 0;
	z-index: 5;

	&:before {
		font-size: 22px;
		color: $anthracite;
		margin: 0 !important;
		transition: color 250ms ease;
	}

	&:focus-visible {
		outline: none;
	}

	&:hover,
	&:focus-visible {
		color: $orange;
		&:before {
			color: $orange;
		}
	}

	&--small {
		size: 24px;

		&:before {
			font-size: 17px;
		}
	}
}
