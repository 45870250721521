[data-design-key-component='rework'] {
	a:not([class]) {
		color: $anthracite;
		transition: color 250ms ease;

		//REQUIRED FOR OVERRIDE CLASSIC DESIGN - REMOVE ON DESIGN REWORK/MERGE
		background: none;
		text-decoration: underline;
		//END

		&:hover {
			color: $orange;
		}
	}
}
