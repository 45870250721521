.member-form {
	&__message {
		margin-top: 30px;
	}

	&__message-head {
		@include font('headline', 26px, 30px, 0px);
		color: $grey-1;
		text-align: center;
		margin-bottom: 30px;
	}
}
