$image-gallery-item-height: 220px;
$image-gallery-item-height-sm: 477px;
.imageGallery {
	&.is-full {
		background: $darkblue;
	}

	&__content-wrapper {
		background: $darkblue;
		padding: 0 17px 50px 17px;
		overflow: hidden;

		@include sm {
			padding: 0;
			padding-bottom: 40px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		max-width: 823px;
		margin: 0 auto 50px;
		padding-top: 50px;

		@include sm {
			margin-bottom: 90px;
			padding: 60px 40px 0;
		}
	}

	&__subheadline {
		color: $white;
	}

	&__container {
		margin: 40px auto 0;
		position: relative;
		max-width: 743px;
		overflow: hidden;
	}

	&__image {
		size: 100%;
		object-fit: contain;

		&--cover {
			object-fit: cover;
		}
	}

	&__slidesHolder {
		position: relative;
		// height: calc($image-gallery-item-height);
		background-color: $grey-2;
		padding-top: calc(0.6470588235 * 100%);

		@include sm {
			// height: $image-gallery-item-height-sm;
		}

		.wrapper-white & {
			overflow: hidden;
		}
	}

	&__origin {
		// height: $image-gallery-item-height;
		height: 100%;
		transform: translateX(50%);
		position: absolute;
		top: 0;
		width: 100%;
		//left: 50%;

		@include md {
			//height: $image-gallery-item-height-sm;
		}
	}

	&__slides {
		// height: $image-gallery-item-height;
		height: 100%;
		position: absolute;
		left: 0;
		display: flex;
		justify-content: space-between;
		visibility: hidden;

		@include md {
			// height: $image-gallery-item-height-sm;
		}

		&.is-visible {
			visibility: visible;
		}
	}

	&__slide {
		width: calc(100vw - 34px);
		// height: $image-gallery-item-height;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;

		@include sm {
			// width: 743px;
			width: calc(100vw - 30px);
			// height: $image-gallery-item-height-sm;
		}

		@include md {
			width: 743px;
			// height: $image-gallery-item-height-sm;
		}

		^&[data-slides-count='4']:not([data-slides-fit='false']) & {
			&:nth-child(n + 5) {
				display: none;
			}
		}

		^&[data-slides-count='3']:not([data-slides-fit='false']) & {
			&:nth-child(n + 4) {
				display: none;
			}
		}

		^&[data-slides-count='2']:not([data-slides-fit='false']) & {
			&:nth-child(n + 3) {
				display: none;
			}
		}

		^&[data-slides-count='1']:not([data-slides-fit='false']) & {
			&:nth-child(n + 2) {
				display: none;
			}
		}
	}

	&__link {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		user-drag: none;
		-webkit-user-drag: none;
		display: block;
		size: 100%;
		background-image: none;

		&:hover {
			background-image: none;
		}
	}

	&__controlsHolder {
		position: relative;
		width: 100%;
		height: 39px;
		background-color: rgba($darkblue, 0.6);
		margin-top: 30px;
		visibility: hidden;
		margin-top: 5px;

		@include sm {
			margin-top: 0;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&.is-visible {
			visibility: visible;
		}
	}

	&__dotsHolder {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0;
		top: 0;
		size: 100%;
	}

	&__dots {
		position: relative;
		z-index: 1;
		line-height: 0;
	}

	&__dot {
		display: inline-block;
		font-size: 0;
		line-height: 0;
		&:not(:last-child) {
			margin-right: 10px;
		}
	}

	&__dot-button {
		size: 10px;
		background-color: $white;
		border: none;
		border-radius: 100%;
		transition: background-color 250ms ease;
		outline: none;

		&.is-active {
			background-color: $orange;
		}
		&:hover {
			background-color: $orange;
		}
	}

	&__arrowsHolder {
		display: none;

		@include md {
			position: absolute;
			left: 0;
			top: 0;
			size: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 24px;
		}
	}

	&__navig-button {
		position: relative;
		font-size: 0;
		transition: transform 150ms ease;
		background-color: transparent;
		border: 0;
		color: $darkblue;
		outline: none;

		&:last-child:before {
			right: 0;
		}

		&:before {
			color: $white;
			font-size: 22px;
			position: absolute;
			top: 50%;
			margin-right: 0 !important;
			transform: translateY(-50%);
			transition: transform 250ms ease;
		}

		&:hover:before {
			transform: translateY(-50%) scale(1.25);
		}
	}

	&__captions {
		color: $white;
		width: 100%;
		max-width: 743px;
		margin: 10px auto 0 auto;

		@include sm {
			margin-top: 20px;
		}
	}

	&__caption {
		display: none;
		text-align: center;

		a {
			background: linear-gradient($white, $white);
			background-size: 1px 1px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			text-decoration: none;
			background-image: linear-gradient(#f28b10, #f28b10);
			padding: 2px 0;

			&:hover {
				color: $orange;
			}
		}

		&.is-active {
			display: block;
		}
	}
}
