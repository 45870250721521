// .ec-search {
// 	margin-bottom: 50px;
// 	& input {
// 		padding-left: 50px;
// 		@include font('regular', 21px, 26px, normal);
// 		color: $grey-1;
// 		background: $white svg-load('acv-icons/lupe.svg', fill: $grey-2) no-repeat 18px center / auto 20px;

// 		@include <sm {
// 			@include font('regular', 18px, 26px, normal);
// 			background: $white svg-load('acv-icons/lupe.svg', fill: $grey-2) no-repeat 16px center / auto 18px;
// 		}
// 	}
// }

.ec-search {
	position: relative;
	margin-bottom: 50px;
	@include font('regular', 18px, 26px, normal);

	@include sm {
		@include font('regular', 21px, 26px, normal);
	}

	&__wrapper {
		position: relative;
		&:before {
			content: '$acv-icons-var-lupe';
			position: absolute;
			font-family: 'acv-icons';
			font-size: 24px;
			color: $grey-3;
			transform: scaleX(-1) translateY(-50%);
			size: 24px;
			z-index: 1;
			top: 50%;
			left: 16px;
		}
	}

	&__input[type='text'] {
		display: block;
		position: relative;
		width: 100%;
		height: 58px;
		padding-left: 55px !important;
		font-size: 18px;
		color: $grey-1;

		@include sm {
			font-size: 21px;
		}
	}
}
