.calendarPagination {
	display: flex;
	justify-content: space-between;
	margin-bottom: 60px;
	color: $darkblue;

	&__current-month {
		@include font('headline', 34px, 36px, normal);
	}

	&__arrow-button {
		font-size: 0;
		background: none;
		transition: transform 250ms ease;

		&.is-disabled {
			pointer-events: none;
			i {
				color: $grey-2;
			}
		}

		&:hover {
			background: none;
			transform: scale(1.25);
		}

		[class*='acv-icons--'] {
			font-size: 22px;
		}
	}
}
