.modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9001;
	display: flex;
	justify-content: center;
	align-items: center;

	&:not(.is-open) {
		display: none;
	}

	&__shadowbox {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $typo-color;
		opacity: 0.5;
	}

	&__close {
		background: none;
		border: none;
		position: absolute;
		size: 50px;
		right: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $anthrazit;

		&:before {
			margin: 0;
			font-size: 13px;
		}
	}

	&__box {
		position: relative;
		background-color: $white;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 600px;
		max-width: calc(100% - 30px);
		min-height: 415px;
		padding: 50px;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.22);
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		flex: 1;
	}

	&__headline {
		@include font('headline', 24px, 40px, normal);
	}

	&__copy {
		* {
			@include font('regular', 21px, 25px, normal);
		}
	}

	&__footer {
		margin-top: auto;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;

		@include sm {
			flex-direction: row;
		}
	}
}
