.ASD-select {
	appearance: none;
	position: relative;
	height: $input-height;

	background-color: $anthracite-5;
	color: $anthracite;
	border: 0;
	border-bottom: 1px solid $anthracite-40;

	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

	transition: border-color 250ms ease;

	padding: 22px 10px 0;

	caret-color: $orange;

	width: 100%;

	background-image: svg-load('select-arrow.svg', fill: $anthracite);
	background-repeat: no-repeat;
	background-size: auto 10px;
	background-position: right 15px top 22px;
	padding-right: 50px;

	&--custom {
		cursor: default;
	}

	&.is-invalid {
		border-color: $signal-red;
	}

	&[disabled] {
		color: $anthracite-80;

		&:placeholder-shown,
		&.is-filled {
			color: $anthracite-80;
			opacity: 0.5;
		}
	}

	&:focus {
		outline: none;
		border-color: $anthracite;
	}

	&::placeholder {
		color: $anthracite-80;
	}

	&__error {
		position: absolute;
		top: 100%;
		left: 0;
		padding: 3px 10px 0;
		@include font('regular', 14px, 16px, normal);

		color: $signal-red;
		z-index: 10;
		^&:not(.is-invalid) ~ & {
			display: none;
		}
	}
}
