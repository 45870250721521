.local-clubs {
	font-size: 1rem;

	.form__input-group .form__label {
		.is-start& {
			@include md {
				color: white;
			}
		}
	}

	.btn {
		display: block;
		width: 100%;
	}

	// Disable "layer" selection at the top left of default gmap
	// Disable StreetView
	// Disable Fullscreen
	.gmnoprint .gm-style-mtc,
	.gm-svpc,
	.gm-fullscreen-control {
		display: none;
	}

	&__wrapper {
		position: relative;

		@include md {
			height: 800px;
		}

		&::after {
			content: '';
			size: 100%;
			position: absolute;
			background-color: rgba(0, 0, 0, 0.4);
			top: 0;
			left: 0;
			opacity: 0;
			z-index: -1;
			transition: opacity 0.75s linear, z-index 0.75s step-end;

			^^&.is-start & {
				@include md {
					opacity: 1;
					z-index: 1;
					transition: 0.75s linear, z-index 0.75s step-start;
				}
			}
		}
	}

	&__map {
		height: 100%;
		display: none;

		@include md {
			display: block;
		}
	}

	&__overlay {
		position: relative;
		width: 100%;
		background: white;
		z-index: 2;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
		transform: translate3d(0, 0, 0);
		margin: 0 auto;

		@include sm {
			width: 440px;
		}

		^&.is-start & {
			box-shadow: none;

			@include md {
				width: 440px;
				background: transparent;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
			}
		}

		@include md {
			width: 260px;
			position: absolute;
			top: 45px;
			left: 45px;
			transition: all 0.5s ease;
		}

		@include lg {
			width: 350px;
		}

		@include xl {
			left: 100px;
		}
	}

	input[type='radio']&__tabs-radio {
		display: none;
	}

	&__tabs-list {
		&::after {
			content: '';
			position: absolute;
			height: 1px;
			width: 100%;
			background-color: $grey-3;
			bottom: 0;
			left: 0;
		}

		^&.is-start & {
			@include md {
				display: none;
			}
		}
	}

	&__tabs-item {
		display: inline-block;
		width: 50%;

		&:first-child {
			@include <md {
				width: 100%;
			}
		}

		&:last-child {
			@include <md {
				display: none;
			}
		}
	}

	&__tabs-label {
		position: relative;
		display: block;
		text-align: center;
		padding: 20px 10px;
		cursor: pointer;
		background-color: $grey-4;
		@include font('black', 11px, 13px, 0.8px);
		text-transform: uppercase;
		transition: background-color 0.2s;

		@include sm {
			padding: 20px;
			@include font('black', 12px, 14px, 0.8px);
		}

		.acv-icons {
			vertical-align: middle;
			margin-right: 2px;
			font-size: 1.5em;
		}

		&::after {
			content: '';
			position: absolute;
			height: 4px;
			width: 100%;
			background-color: $orange;
			bottom: 0;
			left: 0;
			transform: scale3d(1, 0, 1);
			transform-origin: bottom;
			transition: transform 0.2s;
		}

		#toggle-tab1:checked ~ ^&__tabs &[for='toggle-tab1'],
		#toggle-tab2:checked ~ ^&__tabs &[for='toggle-tab2'] {
			background: white;

			&::after {
				transform: scale3d(1, 1, 1);
			}
		}
	}

	&__tabs-text {
		user-select: none;

		@include md {
			display: none;
		}

		@include lg {
			display: inline;
		}
	}

	&__tabs-content {
		display: none;
		padding: 30px 15px;

		@include lg {
			padding: 30px 15px 15px;
		}

		#toggle-tab1:checked ~ #tab1&,
		#toggle-tab2:checked ~ #tab2& {
			display: block;
		}
	}

	&__search-label {
		^&:not(.is-start) & {
			display: none;
		}
	}

	&__search-title {
		display: none;
		text-align: center;
		color: white;
		margin-bottom: 40px;
		@include font('headline', 28px, 36px, 0);

		^&.is-start & {
			@include md {
				display: block;
			}
		}
	}

	&__search-field {
		padding-right: 60px !important;
	}

	&__search-btn {
		position: absolute;
		right: 1px;
		bottom: 1px;
		size: 56px;
		background-color: $orange-dark;
		background-image: svg-load('lupe.svg', fill: white);
		background-size: 21px;
		background-repeat: no-repeat;
		background-position: center;
		border: 0;
		outline: 0;
		z-index: 0;
		text-indent: -9999px;
		transition: background-color 0.1s;

		&:hover {
			background-color: $orange;
		}
	}

	&__location-btn {
		// normalize hover
		&:hover {
			^^&.is-start & {
				color: white;

				&::before {
					transform: scale(1);
				}
			}
		}
	}

	&__results-list-wrapper-outer {
		overflow-y: auto;
		overflow-x: hidden;
		margin: 0 -15px;
		padding: 0 15px;

		&::-webkit-scrollbar {
			width: 3px;

			&-thumb {
				background: $grey-3;
				border-radius: 20px;
			}
		}
	}

	&__results-list-wrapper-inner {
		@include md {
			max-height: 350px;
		}
	}

	// .local-clubs__results
	&__results {
		margin-top: 25px;

		^&.is-start & {
			display: none;
		}
	}

	&__no-club-in-radius {
		margin-top: 15px;
	}

	&__results-list {
		margin-top: 30px;

		&:empty {
			display: none;
		}
	}

	&__results-head {
		@include font('bold', 21px, 26px, 0);

		^&__results-list:empty + & {
			display: none;
		}
	}

	// .local-clubs__result
	&__result {
		margin: 0 -15px;
		padding: 0 15px 10px;

		&.active {
			background-color: rgba(#ff7000, 0.1);
		}
	}

	&__result-details {
		display: none;

		^&__result.active & {
			display: block;
		}

		a {
			background-image: linear-gradient($orange, $orange);
			&:hover {
				background-image: linear-gradient($orange, $orange);
				color: $orange;
			}
		}
	}

	&__result-head {
		@include font('regular', 15px, 20px, 0);
		padding: 10px 0;
		cursor: pointer;

		^&__result.active & {
			color: $orange;
		}
	}

	&__result-distance {
		@include font('regular', 13px, 16px, 0px);
	}

	&__no-result {
		display: block;
		@include font('regular', 13px, 16px, 0px);
		color: white;
		position: relative;
		margin-bottom: 10px;

		^&.is-start & {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			margin-bottom: 0;
		}
	}

	&__divider {
		display: block;
		@include font('regular', 15px, 20px, 0);
		text-align: center;
		margin-bottom: 10px;

		^&.is-start & {
			color: white;
		}
	}

	&__info {
		width: 300px;
		padding: 13px;
		font-family: Lato;
	}

	&__info-head {
		@include font('bold', 21px, 26px, 0);
		margin-bottom: 15px;
	}

	&__info-block-top {
		margin-bottom: 30px;
	}

	&__info-row {
		display: block;

		&:not(:last-child) {
			margin-bottom: 15px;
		}

		.acv-icons {
			vertical-align: middle;
			display: inline-block;
			min-width: 32px;
			color: $orange;
		}
	}

	&__filter {
		.form__input-group {
			margin: 0;
			padding: 13px 0;

			&.is-main-club {
				border-top: 1px solid $grey-2;
				border-bottom: 1px solid $grey-2;
			}
		}

		.form__checkbox {
			size: 20px;
		}

		.form__label-text {
			@include font('regular', 15px, 20px, 0);
		}
	}

	&__filter-head {
		@include font('bold', 21px, 26px, 0);
		margin-bottom: 20px;
	}

	&__filter-sub-head {
		display: block;
		@include font('regular', 15px, 20px, 0);
		color: $grey-2;
		margin-bottom: 10px;
	}
}
