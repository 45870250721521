.copyright {
	@include md {
		padding: 0 calc($ce-side-padding - 10px);
	}

	&__item {
		display: inline-flex;
		min-height: 23px;
		margin: 0 15px 11px;
	}

	&__image-wrapper {
		width: 23px;
		height: 23px;
		overflow: hidden;
	}

	&__image-link {
		display: block;
		size: 100%;
	}

	&__image {
		size: 100%;
		object-fit: cover;
	}

	&__content {
		display: flex;
		align-items: center;
		padding-left: 10px;
		@include font('regular', 10.4px, 12px, normal);
		color: $white;
	}

	&__link {
		background-image: none;
		&:hover {
			color: $white;
			background-image: linear-gradient($white, $white);
			background-size: 1px 1px;
		}
	}
}

.frame-type-acvcontent_copyright:empty {
	display: none;
}
