.ASD-value-slider {
	width: 100%;
	max-width: 317px;
	position: relative;
	height: 56px;
	padding: 0 5px;

	&__input {
		width: 100%;
		position: absolute;
		top: 29px;
		transform: translateY(-50%);
		z-index: 100;
		-webkit-appearance: none;
		height: 30px;
		opacity: 0;
		cursor: pointer;
	}

	&__content {
	}

	&:has(&__input.is-invalid) {
		^&__bar,
		^&__dot {
			transition: background-color 250ms ease;
		}
		^&__dot {
			&:after {
				transition: opacity 250ms ease;
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				size: 10px;
				display: block;
				border-radius: 50%;
				opacity: 0;
				background-color: $anthracite;
			}

			&.isBig {
				&:after {
					size: 13px;
				}
			}
		}
	}

	&:has(&__input.is-invalid:hover) {
		^&__bar,
		^&__dot {
			background-color: $anthracite;
		}
		^&__dot {
			&:after {
				opacity: 0.5;
			}

			&.isBig {
				&:after {
					size: 13px;
				}
			}
		}
	}

	&__bar {
		width: 100%;
		height: 2px;
		background-color: $grey-2;
		position: relative;
		top: 29px;
	}

	&__bar-active {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		background-color: $orange;
	}

	&__thumb {
		position: absolute;
		size: 22px;
		background-color: $anthracite;
		border-radius: 50%;
		top: -10px;
		transform: translateX(-50%);
		z-index: 10;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		@include font('', 12px, 13px, normal, normal);

		&.isFocused {
			background-color: $orange;
			color: white;
		}
	}

	&__legend {
		position: absolute;
		top: calc(100% + 11px);
		@include font('', 14px, 15px, normal, normal);
		color: $anthracite;

		&--min {
			left: 0;
			transform: translateX(-50%);
		}

		&--max {
			right: 0;
			transform: translateX(50%);
		}

		&--mid {
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__icon {
		position: absolute;
		bottom: calc(100% + 11px);
		font-size: 16px;

		&--start {
			left: 0;
			transform: translateX(-50%);
		}

		&--end {
			right: 0;
			transform: translateX(50%);
		}
	}

	&__steps {
		display: flex;
		justify-content: space-between;
		position: absolute;
		top: 1px;
		left: -3px;
		width: calc(100% + 6px);
	}

	&__dot {
		position: relative;
		transition: background-color 250ms ease;
		background-color: $grey-2;
		size: 6px;
		border-radius: 50%;
		transform: translateY(-50%);

		&.isBig {
			size: 8px;
		}

		&.isActive {
			background-color: $orange;
		}
	}
}
