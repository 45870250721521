.counter {
	&__header {
		text-align: center;
		padding: 0 40px;
		margin-bottom: 20px;
		.wrapper-white &,
		[data-style='white'] &,
		.twoColGrid__col--white & {
			color: $typo-color;
		}
	}

	&__slider {
		overflow: hidden;
		padding-bottom: 36px;
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 20px;
		@include md {
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		max-height: 400px;
		padding: 0 16px;
		^&__wrapper & {
			flex-basis: 0;
			flex-grow: 1;
			margin: 0 0 40px;
			padding: 0;
			&:nth-last-of-type(-n + 2) {
				margin-bottom: 0;
			}
			@include <sm {
				min-width: 50%;
			}
			@include sm {
				margin: 0 10px 40px;
			}
			@include md {
				flex-basis: calc(50% - 40px);
				flex-grow: 0;
			}
			@include xl {
				flex-grow: 1;
				flex-basis: 0;
				margin-bottom: 0;
			}
		}
		.twoColGrid__col--white & {
			^&__wrapper {
				min-width: 100%;
			}
		}
	}

	&__item-title {
		color: $darkblue;
		text-transform: uppercase;
		text-align: center;
		@include font('headline', 16px, 18px, 0.26875rem);
		.wrapper-white &,
		[data-style='white'] &,
		.twoColGrid__col--white & {
			color: $grey-1;
		}
	}

	&__item-label {
		position: relative;
		color: $darkblue;
		text-align: center;
		min-width: 185px;
		white-space: nowrap;
		margin-top: 20px;
		@include font('headline', 34px, 36px, 0);
		@include <sm {
			min-width: 125px;
			@include font('headline', 28px, 30px, 0);
		}
		&.orange {
			color: $white;
			.wrapper-white &,
			[data-style='white'] &,
			.twoColGrid__col--white & {
				color: $orange;
			}
		}
		&.highlighted {
			@include font('headline', 32px, 32px, 0);
			@include <sm {
				@include font('headline', 28px, 28px, 0);
			}
		}
		&.white {
			color: $white;
			.wrapper-white &,
			[data-style='white'] &,
			.twoColGrid__col--white & {
				color: $darkblue;
			}
		}
	}

	&__number {
		.highlighted & {
			@include font('headline', 36px, 36px, 0);
			@include <sm {
				@include font('headline', 32px, 32px, 0);
			}
		}
	}

	&__item-content {
		color: $darkblue;
		@include font('regular', 15px, 18px, 0);
		.wrapper-white &,
		[data-style='white'] &,
		.twoColGrid__col--white & {
			color: $grey-2;
		}
	}

	&__image-wrapper {
		width: 100%;
		height: 100px;
		padding-top: 10px;
		display: flex;
		justify-content: center;
		svg {
			height: 100%;
		}
		+ ^&__item-content {
			margin-top: 10px;
		}
	}

	&__pagination {
		position: absolute;
		bottom: 0 !important;
		left: 50% !important;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		margin-top: 24px;
	}

	&__pagination-item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $darkblue;
		margin: 0 8px;
		position: relative;
		&.is-active {
			background-color: $white;
			.wrapper-white &,
			[data-style='white'] &,
			.twoColGrid__col--white & {
				background-color: $orange;
			}
		}
		&::marker {
			display: none;
		}
	}
}
