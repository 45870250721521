.content {
	&--white {
		background-color: $white;
		padding-bottom: 50px;
	}

	&__wrapper {
		& + & {
			margin-top: 40px;
		}

		&--show-all ^&-element {
			display: block !important;
		}
	}
}
