.ASD-tariff-info {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
	color: $anthracite;
	position: relative;

	&__content {
		display: flex;
		flex: 1 1 auto;
		gap: 10px;
		align-items: center;
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__tariff-label {
		@include font('headline', 16px, 120%, normal);

		&:last-child {
			margin-top: 3px;
		}
	}

	&__tariff-discount-label {
		color: $anthracite-80;
		@include font('regular', 16px, 120%, normal);
		margin-top: -2px;
	}

	&__discount-price {
		text-decoration: line-through;
	}

	&__price-wrapper {
		margin-left: auto;
		display: flex;
		align-items: center;
		height: 25px;
		margin-top: 1px;
	}

	&__price {
		@include font('headline', 36px, 100%, normal);
	}

	&__suffix-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__currency {
		@include font('headline', 18px, 100%, normal);
		height: 13px;
		margin-top: -2px;
	}

	&__year {
		@include font('', 12px, 14px, normal);
		color: $anthracite-80;
		height: 9px;
		margin-top: 2px;
	}
}
