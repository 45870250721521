/*=====================================
=            media-queries            =
=====================================*/

/* small screens */
@mixin sm {
	@media (min-width: $breakpoint-sm) {
		@content;
	}
}

/* small screens only */
@mixin <sm {
	@media (max-width: calc($breakpoint-sm - 1px)) {
		@content;
	}
}

@mixin only-sm {
	@media (min-width: $breakpoint-sm) and (max-width: calc($breakpoint-md - 1px)) {
		@content;
	}
}

/* medium screens */
@mixin md {
	@media (min-width: $breakpoint-md) {
		@content;
	}
}

@mixin <md {
	@media (max-width: calc($breakpoint-md - 1px)) {
		@content;
	}
}

@mixin >md {
	@media (min-width: calc($breakpoint-lg)) {
		@content;
	}
}

@mixin only-md {
	@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
		@content;
	}
}

/* large screens */
@mixin lg {
	@media (min-width: $breakpoint-lg) {
		@content;
	}
}

@mixin <lg {
	@media (max-width: calc($breakpoint-lg - 1px)) {
		@content;
	}
}

@mixin only-lg {
	@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
		@content;
	}
}

/* large screens */
@mixin xl {
	@media (min-width: $breakpoint-xl) {
		@content;
	}
}

@mixin <xl {
	@media (max-width: calc($breakpoint-xl - 1px)) {
		@content;
	}
}

@mixin only-xl {
	@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
		@content;
	}
}

/* x-large screens */
@mixin xxl {
	@media (min-width: $breakpoint-xxl) {
		@content;
	}
}

/* special screens */
@mixin betweenLgAndXl {
	@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
		@content;
	}
}

@mixin helpTile {
	@media (width >= 1400px) {
		@content;
	}
}

@mixin <helpTile {
	@media (width < 1400px) {
		@content;
	}
}

/*=====  End of media-queries  ======*/
