.ASD-form-progress {
	z-index: 100;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: white;

	&__top {
		position: relative;
		height: 44px;
		display: flex;
		align-items: center;
		padding-right: 15px;

		@include md {
			height: 74px;
		}
	}

	&__text-button {
		position: relative;
		z-index: 10;
		margin-left: auto;
	}

	&__title {
		position: absolute;
		left: 0;
		top: calc(50% + 1px);
		width: 100%;
		transform: translateY(-50%);
		padding: 0 60px;
		text-align: center;

		@include md {
			padding: 0 100px;
		}
	}

	&__close {
		position: relative;
		z-index: 10;
		font-size: 0;
		border: 0;
		background-color: transparent;
		width: 44px;
		height: 100%;
		color: $anthracite;
		transition: color 250ms ease;

		@include md {
			width: 74px;
		}

		&:hover {
			color: $orange;
		}

		&::before {
			font-size: 16px;
			margin: 0 !important;
		}
	}

	&__progress {
		display: block;
		appearance: none;
		width: 100%;
		height: 5px;

		@include md {
			height: 8px;
		}

		&::-webkit-progress-bar {
			background-color: #eee;
		}

		&::-webkit-progress-value {
			transition: width 250ms ease;
			background-image: $gradient-orange;
			border-top-right-radius: 100vw;
			border-bottom-right-radius: 100vw;

			^&.is-full& {
				border-radius: 0;
			}

			^&.is-finished& {
				background: $green;
			}

			^&.is-error& {
				background: $red;
			}

			^&.is-orange& {
				background: $orange;
			}
		}
	}
}
