.featureCards {
	svg {
		position: absolute;
		size: 100%;
		left: 0;
		top: 0;
		fill: $icons;
		transition: fill 0.3s ease;
		* {
			stroke: $icons;
		}
	}

	&__svgWrapper {
		flex-shrink: 0;
		width: 77px;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 100%;
			padding-bottom: 100%;
		}

		@include md {
			width: calc(1 / 3 * 100%);
		}
	}

	&__main {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		box-shadow: $card-shadow;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: $darkblue;
			z-index: -1;
		}
	}

	&__item {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		border: 1px solid rgba(102, 102, 102, 0.3);
		background-color: $darkblue;
		padding: 0;
		transition: all 0.3s ease;

		@include md {
			width: 33.3333333333333333%;
			flex-direction: column;
			justify-content: center;
			padding: 30px 10px;
		}

		&:hover {
			background-color: $orange;
		}

		&:hover svg {
			fill: white;
			* {
				stroke: white;
			}
		}

		&:hover .btn--linkIconRight {
			color: white;

			&::before {
				background-color: white;
				height: 2px;
			}
		}
	}

	&__text {
		@include font('headline', 15px, 18px, 0);
		color: white;
		margin-bottom: initial;
		flex-shrink: 0;

		@include lg {
			@include font('headline', 21px, 25px, 0);
		}

		@include md {
			margin-bottom: 50px;
			text-align: center;
		}

		&:after {
			@include acv-icons-mixin();
			content: '$acv-icons-var-arrow-right';
			margin-left: 10px;
			font-size: 0.7em;
			display: inline-block;

			@include md {
				display: none;
			}
		}
	}

	&__buttonWrapper {
		display: none;

		@include md {
			display: block;
		}
	}

	&__link {
		font-size: 0;
		background: none;

		&:hover {
			background: none;
		}
	}
}
