$lightbox-fading-duration: 500ms;
$lightbox-ui-fading-duration: 200ms;
$lightbox-imageholder-fading-duration: 200ms;

.lightbox {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba($darkblue, 0.6);
	display: none;
	opacity: 0;
	transition: opacity $lightbox-fading-duration;
	z-index: 999;
	padding: 15px 15px 65px;

	@include sm {
		padding: 50px 50px 100px;
	}

	&.js-lightbox--active {
		display: block;
	}
	&.js-lightbox--visible {
		opacity: 1;
	}

	&__inner {
		position: relative;
		width: 100%;
		height: 100%;
	}
	&__container {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: $darkblue;
		width: 100px; // Standards on loading
		height: 50px; // Standards on loading
		padding: 1px; // Fix thin border bug
		background-clip: content-box;
	}

	&__loader {
		z-index: 0;

		&-vcenter {
			position: absolute;
			width: 100%;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			text-align: center;
		}

		.im {
			font-size: 30px;
			color: $white;
			animation: rotate 1s infinite linear;
		}
	}
	&__imageholder {
		box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
		z-index: 1;
		opacity: 0;
		transition: opacity $lightbox-imageholder-fading-duration;
		overflow: hidden;
		background-color: $darkblue;
		padding: 1px; // Fix thin border bug
		background-clip: content-box;

		&.js-lightbox-imageholder--visible {
			opacity: 1;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			display: none;

			&.js-lightbox-image--active {
				display: block;
			}
		}
	}
	&__ui {
		z-index: 2;
		opacity: 0;
		display: none;

		&.js-lightbox-ui--visible {
			opacity: 1;
		}

		&.js-lightbox-ui--transition {
			transition: opacity $lightbox-ui-fading-duration;
		}

		&.js-lightbox-ui--active {
			display: block;
		}

		&-controls {
			display: flex;
			z-index: 1;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 34px;

			^^&.js-lightbox--single & {
				display: none;
			}

			&-field {
				position: absolute;
				height: 100%;
				width: 50%;
				top: 0;
				cursor: default;
				text-decoration: none;
				transition: transform 250ms ease;
				background: none;
				border: 0;
				outline: 0;
				color: white;

				@include sm {
					display: block;
				}

				^^&.js-lightbox-ui--visible & {
					cursor: pointer;
				}

				&:hover,
				.js-lightbox--is-touch & {
					background: none;
					opacity: 1;
					transform: scale(1.25);
					color: inherit;
				}

				&--next {
					right: 0px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				&--previous {
					left: 0px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				& i:before {
					text-decoration: none;
				}
			}
		}

		&-closebtn {
			z-index: 3;
			position: absolute;
			border: 0;
			color: $white;
			background-color: transparent;
			outline: none;
			width: 40px;
			height: 40px;
			top: 10px;
			right: 9px;
			transition: transform 250ms ease;

			&:before {
				content: '';
				display: block;
				size: 45%;
				position: absolute;
				left: 50%;
				top: 50%;
				border-bottom-right-radius: 50%;
				border-bottom-left-radius: 50%;
				transform: translate3d(-50%, -50%, 0);
			}

			> i {
				position: relative;
				padding: 5px;
			}

			^&:hover &,
			.js-lightbox--is-touch & {
				transform: scale(1.25);
			}
		}
		&-bar {
			position: absolute;
			width: 100%;
			height: 0px;
			left: 0;
			top: 100%;
			color: white;
			background-color: $darkblue;
			transition: height $lightbox-ui-fading-duration;
			padding: 0 17px;
			overflow: hidden;
			border-top-right-radius: 0px;
			border-top-left-radius: 0px;
			padding: 0 5px;

			&-inner {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 5px 0;
			}

			&-text {
				@include font('regular', 15px, 20px, normal);

				&--amount {
					margin-right: 10px;

					^^^^&.js-lightbox--single & {
						display: none;
					}
				}
			}

			^&.js-lightbox-ui--visible &.js-lightbox-bar--visible {
				height: auto;
			}
		}
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
