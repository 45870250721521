.calendarList {
	@include sm {
		margin-top: 20px;
	}

	@include lg {
		margin: 0;
	}

	&__chunk {
		border-bottom: 1px solid $grey-3;

		@include sm {
			border-top: 1px solid $grey-3;
		}
	}

	&__item {
		padding: 20px 20px;

		@include sm {
			padding-left: 0;
			padding-right: 0;
		}

		&:not(:first-child) {
			border-top: 1px solid $grey-3;
		}
	}

	&__head {
		display: inline-block;
		@include md {
			max-width: calc(100% - 180px);
		}

		@include lg {
			max-width: initial;
		}

		@include xl {
			max-width: calc(100% - 180px);
		}
	}

	&__attend-link {
		display: none;
		float: right;

		@include md {
			display: block;
		}

		@include lg {
			display: none;
		}

		@include xl {
			display: block;
		}

		&--bottom {
			margin-top: 40px;
			display: block;
			order: -1;
			width: 100%;

			@include md {
				display: none;
			}

			@include lg {
				display: block;
			}

			@include xl {
				display: none;
			}
		}
	}

	&__headline {
		@include font('headline', 15px, 18px, normal);
		margin-bottom: 10px;
		color: $darkblue;

		@include md {
			font-size: 21px;
			line-height: 25px;
		}
	}

	&__meta {
		@include font('regular', 15px, 20px, normal);
		margin-bottom: 20px;
		display: flex;
	}

	&__date {
		display: inline-flex;
		flex-wrap: wrap;
		position: relative;
		padding-left: 27px;
	}

	&__date-icon {
		position: absolute;
		left: 0;
		top: 0;
		&:before {
			font-size: 18px;
		}
	}

	&__date-item {
		&--end {
			&:before {
				content: '—';
				margin: 0 5px;
			}
		}
	}

	*:not(&__location) + &__location {
		&:before {
			content: '|';
			margin: 0 5px;
		}
	}

	&__location + &__location {
		&:before {
			content: ',';
			margin-right: 3px;
		}
	}

	&__body-wrapper {
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	&__show-more-checkbox {
		visibility: hidden;
		position: absolute;
		left: -100vw;
	}

	&__show-more-label {
		&:before {
			content: 'Mehr anzeigen';
		}
		&:after {
			content: '+';
		}

		^&__show-more-checkbox:checked + & {
			&:before {
				content: 'Weniger anzeigen';
			}
			&:after {
				content: '–';
			}
		}
	}

	&__copy-wrapper {
		width: 100%;
		margin-bottom: 32px;
		display: none;
		^&__show-more-checkbox:checked ~ & {
			display: block;
		}
	}

	&__copy {
		width: 100%;
	}

	&__files {
		margin-top: 20px;
	}

	&__file {
		display: inline;

		& + & {
			margin-left: 25px;
		}
	}

	&__empty-copy {
		@include font('headline', 15px, 18px, normal);
		margin-bottom: 10px;
		color: $darkblue;
		margin-top: 25px;
		text-align: center;

		@include md {
			font-size: 16px;
			line-height: 25px;
		}
	}
}
