.contentCards {
	&__top {
		margin-bottom: 50px;
	}

	&__wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.is-mobile-accordion & {
			@include <sm {
				margin-left: -15px;
				margin-right: -15px;
			}
		}

		@include lg {
			justify-content: space-between;
		}
	}

	&__topWrapper {
		display: flex;
		flex-direction: column;
		padding: 0 $ce-side-padding;
	}

	&__card {
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: white;
		width: 100%;
		box-shadow: $card-shadow;
		margin-top: 30px;

		.is-mobile-accordion & {
			@include <lg {
				margin-top: unset;
			}
		}

		^&__wrapper.limit-to-three & {
			^^&__radio:checked ~ & {
				display: flex;
			}

			&:nth-child(n + 4) {
				display: none;
			}
		}

		@include sm {
			width: 100%;
		}

		@include lg {
			width: calc(50% - 15px);
		}

		@include xl {
			width: calc(33.33333% - 20px);
		}

		&:first-child {
			margin-top: initial;
		}

		@include lg {
			&:not(:nth-child(n + 3)) {
				margin-top: initial;
			}
		}

		@include xl {
			&:not(:nth-child(n + 4)) {
				margin-top: initial;
			}
		}

		&:hover ^&__imageWrapper:after {
			opacity: 1;
			visibility: visible;
		}

		&:hover .btn--linkIconRight {
			background-image: linear-gradient($orange, $orange);
		}

		&--empty {
			height: 0;
			visibility: hidden;
			margin-top: 0 !important;
		}
	}

	&__accordion-button {
		position: relative;
		background: transparent;
		border: 0;
		padding: 21px 20px;
		padding-right: 50px;
		text-align: left;
		@include font('bold', 15px, 18px, normal);
		border-top: 1px solid $grey-3;
		transition: color 250ms ease;
		color: $anthrazit;

		@include lg {
			display: none;
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			width: 24px;
			height: 24px;
			background: svg-load('pulldown.svg', fill: $grey-1) no-repeat center;
			transition: transform 250ms ease;
		}

		^&__card.is-open & {
			color: $orange;

			&:after {
				transform: translateY(-50%) rotate(-180deg);
			}
		}
	}

	&__card-content {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;

		@include <lg {
			.is-mobile-accordion ^&__card:not(.is-open) & {
				display: none;
			}
		}
	}

	&__textWrapper {
		padding: 30px 20px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		@include sm {
			padding: 30px 40px;
		}
	}

	&__imageWrapper {
		position: relative;
		width: 100%;
		height: 200px;

		.is-mobile-accordion & {
			@include <sm {
				height: unset;
			}
		}

		.is-mobile-accordion & {
			&:before {
				@include <sm {
					display: block;
					content: '';
					width: 100%;
					padding-top: calc((20 / 29) * 100%);
				}
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 200px;
			background-image: $darkblue-gradient;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

			.is-mobile-accordion & {
				@include <sm {
					height: 100%;
				}
			}
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;

		.is-mobile-accordion & {
			@include <sm {
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	&__cardPrehead {
		@include font('headline', 13px, 18px, 4px);
		text-transform: uppercase;
		margin-bottom: 20px;
		color: $grey-1;
		order: 1;

		.news & {
			text-transform: none;
			@include font('regular', 15px, 20px, 0);
		}

		@include sm {
			@include font('headline', 15px, 18px, 4px);
		}
	}

	&__cardHeadline {
		@include font('headline', 20px, 28px, normal);
		margin-bottom: 10px;
		color: $darkblue;
		order: 2;

		@include <lg {
			.is-mobile-accordion & {
				display: none;
			}
		}

		@include sm {
			margin-bottom: 30px;
			hyphens: auto;
			font-size: 24px;
			line-height: 28px;

			^&__textWrapper--no-image & {
				font-size: 21px;
				line-height: 25px;
			}
		}
	}

	&__cardCopy {
		@include font('regular', 16px, 25px, normal);
		color: $grey-1;
		order: 3;

		&:not(:last-child) {
			margin-bottom: 40px;
		}

		@include sm {
			font-size: 15px;
			line-height: 20px;

			&:not(:last-child) {
				margin-bottom: 58px;
			}
		}
	}

	&__buttonWrapper {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}

	&__cardButtonWrapper {
		margin-top: auto;
		position: relative;
		margin-right: auto;
		order: 4;
	}

	&__link {
		font-size: 0;
		background: none;
		z-index: 6;
		&:hover {
			background: none;
		}
	}

	input[type='radio']&__radio {
		display: none;
	}

	&__show-more-wrapper {
		margin-top: 40px;
		text-align: center;

		^&__radio:checked ~ & {
			display: none;
		}

		.btn {
			color: white;
			cursor: pointer;

			&:hover {
				background-image: linear-gradient(white, white);
			}
		}
	}

	&__show-more-label {
		&:before {
			content: 'Mehr anzeigen';
		}
		&:after {
			content: '+';
		}
	}

	.btn--secondary {
		.wrapper-white &,
		[data-style='white'] & {
			@include btn-primary-orange;
		}
	}
}
