.ASD-checkbox {
	appearance: none;
	position: relative;
	flex: 0 0 auto;
	display: block;

	border: 1.5px solid $anthracite-60;
	border-radius: 4px;

	transition: border-color 250ms ease, background-color 250ms ease;

	size: 22px;

	@include md {
		size: 28px;
	}

	&:not([disabled]) {
		cursor: pointer;
	}

	&--big {
		size: 28px;
	}

	&.is-invalid {
		border-color: $signal-red;
	}

	&:checked {
		background: $green svg-load('acv-icons/checkmark-new.svg', fill: #fff) no-repeat center 40% / auto;
		border-color: $green;
		background-position: center;
	}

	&:focus,
	&:active {
		outline: none;
		border-color: $anthracite;
	}

	&[disabled] {
		opacity: 0.2;
	}

	&__label-group {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;

		@include md {
			gap: 8px;
		}

		^&:not([required]) ~ & {
			^^&__label-text:first-child > div:not(.has-html) {
				&:after {
					display: block;
					content: '(optional)';
				}

				&[data-suffix]:after {
					content: attr(data-suffix);
				}
			}

			^^&__label-text:first-child > div.has-html > p:last-child {
				&:after {
					display: block;
					content: '(optional)';
				}

				&[data-suffix]:after {
					content: attr(data-suffix);
				}
			}
		}
	}

	&__label {
		display: flex;
		gap: 10px;
		color: $anthracite;
		@include font('regular', 16px, 19px, normal);
	}

	&__label-text {
		&:first-child {
			@include md {
				padding-top: 4px;
			}

			^&__label--big & {
				padding-top: 4px;
			}
		}

		^&__label-group[data-show-extended='false'] &[data-extend] {
			display: none;
		}

		^&:not([required]) ~ & {
			&:after {
				display: block;
				content: '(optional)';
			}

			&[data-suffix]:after {
				content: attr(data-suffix);
			}
		}
	}
}
