.ec-pagination {
	// margin-top: 50px;

	// &__pages {
	// 	display: flex;
	// 	justify-content: center;
	// }

	// &__page {
	// 	@include font('bold', 15px, 20px, normal);
	// 	&:not(:last-child) {
	// 		margin-right: 30px;
	// 	}
	// }

	&__page {
		&.is-dot {
			& + & {
				display: none;
			}
		}
	}

	&__button {
		color: inherit;
		background: none;
		border: 0;
		outline: 0;

		&:hover {
			color: $orange;
		}

		// &:focus {
		// 	outline: none;
		// }
	}

	&__arrow {
		background: none;
		border: 0;
		outline: 0;
		transition: transform 0.25s ease;

		&:hover {
			transform: scale(1.2);
		}
	}

	// &__current {
	// 	padding-bottom: 2px;
	// 	text-decoration: none;
	// 	background-image: linear-gradient($orange, $orange);
	// 	background-size: 1px 2px;
	// 	&:hover {
	// 		cursor: pointer;
	// 		color: $orange;
	// 	}
	// }
}
