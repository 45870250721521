.tabbed-pane-contact {
	.rte-copy > * + p {
		margin-top: 30px;
		line-height: 29px;
	}

	.form-tabs__form {
		padding-left: 40px;
		padding-right: 40px;

		@include sm {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.contact {
		.form {
			padding: 0;
		}
	}

	&__col {
		&:not(:last-child) {
			margin-bottom: 30px;

			@include lg {
				margin-bottom: 0;
			}
		}
	}
}
