.info-box{
    color: $anthrazit;
    &__headline{
        @include font('bold',15px,20px,normal);
        margin-bottom: 12px;
    }

    &__content{
        background-color:$grey-4;
        display:table;
        table-layout: fixed;
        flex-direction: column;
        padding:32px;
        width: 100%;

        @include lg{
            justify-content: space-between;
            flex-direction: row;
        }
    }

    &__item{
        display: table-cell;

        & + &{
            padding-left: 15px;
        }

        &:not(:last-child){
            padding-right: 15px;
        }
    }

    &__item-headline{
        color:$grey-1;
        @include font('regular',13px,16px,normal);
    }

    &__item-text{
        margin-top: 4px;
        @include font('bold',15px,20px,normal);
        overflow: hidden;
        text-overflow: ellipsis;
    }


}
