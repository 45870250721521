a {
	color: inherit;

	background: linear-gradient($white, $white);
	background-size: 1px 2px;
	background-repeat: repeat-x;
	background-position: 0% 99%;
	text-decoration: none;

	&:hover {
		color: $darkblue;
		background-image: linear-gradient($darkblue, $darkblue);
	}
}

@mixin normalizeHover {
	&:hover {
		background-image: none;
	}
}

$btnTransitionDuration: 250ms;
.btn {
	position: relative;
	display: block;
	@include font('black', 12px, 14px, 0.8px);
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	outline: 0;
	transition: opacity $btnTransitionDuration ease, color $btnTransitionDuration ease, background-color $btnTransitionDuration ease, border-color $btnTransitionDuration ease;
	background: none;

	@include sm {
		display: inline-block;
	}

	padding: 15px 30px;
	border-width: 3px;
	border-style: solid;

	&:active {
		transform: scale(0.95);
	}

	&[disabled],
	&.is-disabled {
		opacity: 0.3;
		pointer-events: none;
	}

	&:not(&--inline-block, .hidden) + &:not(&--inline-block) {
		margin-left: 0;
		margin-top: 20px;

		@include sm {
			margin-left: 30px;
			margin-top: 0;
		}
	}

	@include <sm {
		.form__row--reverse-order-mobile & {
			&:not(^&--inline-block, .hidden) + .btn:not(.btn--inline-block) {
				margin-top: 0px;
				margin-bottom: 20px;
			}
		}
	}

	&--inline-block-margin {
		@include <sm {
			margin-bottom: 20px;
		}
	}

	&--block {
		display: block;
		text-align: center;
	}

	&--text {
		border: 0;
		padding: 2px 0;
	}

	&--text-linked {
		border: 0;
		padding: 2px 0;
		&:hover {
			color: $orange;
			background: linear-gradient($orange, $orange);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			text-decoration: none;
		}
	}

	&--block {
		display: block;
		text-align: center;
	}

	&--text-orange {
		@include font('black', 14px, 16px, 1px);
		text-transform: uppercase;
		color: $orange;
		background-image: none;
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		text-decoration: none;
		text-align: left;
		display: inline;

		&:hover {
			color: $orange;
			background: linear-gradient($orange, $orange);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			text-decoration: none;
		}
	}

	&--primary {
		@include btn-primary-dark;
		@include btn-hover-from-top;

		&^&--blue {
			@include btn-primary-blue;
		}

		&^&--white {
			@include btn-primary-white;

			.wrapper-white & {
				@include btn-primary-blue;
			}

			.wrapper-blue & {
				@include btn-primary-white;
			}
		}

		&^&--orange {
			@include btn-primary-orange;
		}
	}

	&--secondary {
		@include btn-secondary-dark;
		@include btn-hover-from-bottom;

		.wrapper-white & {
			@include btn-secondary-dark;
		}

		&^&--blue {
			@include btn-secondary-blue;
		}

		&^&--white {
			@include btn-secondary-white;

			.wrapper-white & {
				@include btn-secondary-blue;
			}

			.wrapper-blue & {
				@include btn-secondary-white;
			}
		}

		&^&--orange {
			@include btn-secondary-orange;

			.on-white & {
				color: $orange;
			}
		}
	}

	&--loading {
		color: transparent !important;
		background-image: url('./../images/loading-blue.webp');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 40px auto;
		pointer-events: none;
		opacity: 1 !important;
		&:before {
			content: unset;
		}

		^&--primary& {
			background-color: $orange-dark !important;
			background-image: url('./../images/loading-white.webp');
		}
	}

	&--cta {
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);
		padding-top: 19px;
		padding-bottom: 19px;
	}

	&--round {
		border-radius: 900px;
		overflow: hidden;
	}

	&--full-width {
		width: 100%;
	}

	&-price {
		padding: 13px 20px 7px;
		color: white;
		border-color: $orange;
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			background-image: none;
			color: $orange;
			background-color: white;
			border-color: white;
		}

		&__label {
			text-transform: none;
			display: inline-block;
			@include font('regular', 12px, 14px, 0);
			margin-right: 30px;
			padding-bottom: 5px;
		}

		&__value {
			display: inline-block;
			@include font('headline', 20px, 25px, 0.8px);
		}

		sub {
			@include font('regular', 12px, 14px, 0);
			vertical-align: top;
			bottom: auto;
			left: 3px;
			align-self: flex-start;
		}

		&--login {
			background-color: $orange;
			padding: 18px 33px;
			border: 0;

			&:hover {
				background-image: none;
				background-color: $white;
			}
		}
	}

	&--linkIconRight,
	.wrapper-white .rte-copy a&--linkIconRight {
		@include font('black', 14px, 16px, 1px);
		text-transform: uppercase;
		color: $orange;
		background-image: none;
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		text-decoration: none;
		text-align: left;
		display: inline;

		&:after {
			@include acv-icons-mixin();
			content: '$acv-icons-var-arrow-right';
			margin-left: 3px;
			font-size: 0.7em;
			margin-right: initial;
		}

		&:hover {
			color: $orange;
			background: linear-gradient($orange, $orange);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			text-decoration: none;
		}
	}

	&--linkIconLeft,
	.wrapper-white .rte-copy a&--linkIconLeft {
		@include font('black', 14px, 16px, 1px);
		text-transform: uppercase;
		color: $orange;
		background-image: none;
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		text-decoration: none;
		text-align: left;
		display: inline;

		&:before {
			@include acv-icons-mixin();
			content: '$acv-icons-var-arrow-right';
			font-size: 0.7em;
			transform: rotate(180deg);
			display: inline-block;
		}

		&:hover {
			color: $orange;
			background: linear-gradient($orange, $orange);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			text-decoration: none;
		}
	}

	&--text-smaller {
		@include font('', 12px, 14px, 0.08em);
	}

	&--addIcon {
		@include font('black', 14px, 16px, 1px);
		text-transform: uppercase;
		color: $orange;
		background-image: none;
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		text-decoration: none;
		text-align: left;
		display: inline;
		padding-left: 19px;

		&:before {
			content: '+';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 0.7em;
			display: flex;
			justify-content: center;
			align-items: center;
			size: 13px;
			border-radius: 50%;
			border: 2px solid $orange;
			padding-bottom: 1px;
			padding-left: 2px;
		}

		&:hover {
			color: $orange;
			background: linear-gradient($orange, $orange);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			text-decoration: none;
		}
	}

	&--charRight,
	.wrapper-white .rte-copy a&--charRight {
		@include font('black', 14px, 16px, 1px);
		text-transform: uppercase;
		color: $orange;
		background-image: none;
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		text-decoration: none;
		text-align: left;
		display: inline;
		cursor: pointer;

		&:after {
			content: '–';
			position: absolute;
			left: calc(100% + 3px);
			bottom: 3px;
			font-size: 1.5em;
			font-family: 'Lato';
		}

		&:hover {
			color: $orange;
			background: linear-gradient($orange, $orange);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			text-decoration: none;
		}
	}

	&--download,
	.wrapper-white .rte-copy a&--download {
		@include font('regular', 15px, 20px, normal);
		background: linear-gradient($orange, $orange);
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		text-decoration: none;
		text-transform: none;
		text-align: left;
		display: inline;
		margin-left: 22px;

		&:before {
			@include acv-icons-mixin();
			content: '$acv-icons-var-download';
			margin-left: 3px;
			font-size: 0.9em;
			position: absolute;
			left: -22px;
			bottom: -1px;
		}

		&:hover {
			color: $orange;
			background: linear-gradient($orange, $orange);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
			text-decoration: none;
		}
	}

	&--right {
		margin-left: auto;
	}

	&--show-more {
		color: white;

		&:hover {
			color: white;
			background: linear-gradient(white, white);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
		}

		&:after {
			content: '+';
		}

		&.-open {
			&:after {
				content: '–';
			}
		}

		.wrapper-white & {
			color: $orange;

			&:hover {
				color: $orange;

				background: linear-gradient($orange, $orange);
				background-size: 1px 2px;
				background-repeat: repeat-x;
				background-position: 0% 99%;
			}
		}
	}

	&--add-files {
		border: 0;
		text-transform: initial;
		@include font('regular', 18px, 14px, 0.2px);
		display: flex;
		align-items: center;
		padding: 0;
		&:before {
			content: '+';
			display: flex;
			size: 36px;
			border-radius: 50%;
			border: 2px solid $typo-color;
			margin-right: 13px;
			justify-content: center;
			align-items: center;
			@include font('regular', 29px, 14px, 0.8px);
			padding-bottom: 3px;
		}
	}

	&--full-width-on-mobile {
		@include <sm {
			width: 100%;
		}
	}

	&--color-dark {
		color: $typo-color;
		&:hover {
			color: $typo-color;
		}
	}
}

.arrow-link {
	text-transform: uppercase;
	color: $orange;
	@include font('black', 12px, 14px, 0.08em);

	&:after {
		@include acv-icons-mixin;
		content: '$acv-icons-var-arrow-right';
		margin-left: 4px;
		margin-right: 0 !important;
		font-size: 9px;
		display: inline-block;
		transition: transform 250ms ease;
		^&:hover& {
			transform: translateX(3px);
		}
	}

	&:hover {
		color: $orange;
		background-image: none;
	}
}
