.header-maxi {
	height: 100vh;

	@include sm {
		height: 800px;
	}

	&.is-single {
		height: auto;
	}

	&__holder {
		height: 100%;
	}

	&__origin {
		height: 100%;
		transform: translateX(50%);

		&.has-no-transform {
			transform: none;
		}
	}

	&__slides {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: space-between;
		position: absolute;
		left: 0;
		visibility: hidden;

		&.is-visible {
			visibility: visible;
		}

		^&.is-single & {
			position: relative;
		}
	}

	&__slide {
		display: flex;
		align-items: flex-end;
		height: 100%;
		width: 100vw;
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		padding: 200px 0 70px;

		@include sm {
			align-items: center;
			padding: 200px 0 160px;
		}

		@include lg {
			padding: 160px 0;
		}

		&.is-cutout {
			text-align: left;
		}

		&.image-mobile-hidden {
			@include <lg {
				min-height: auto !important;
				padding-top: 120px;
				padding-bottom: 160px;
			}

			@include <sm {
				padding-bottom: 60px;
			}
		}

		^&.is-single & {
			position: relative;
			min-height: 575px;

			@include lg {
				min-height: 680px;
			}
		}

		&.has-video {
			overflow: hidden;
		}

		&.has-image:not(.is-cutout),
		&.has-video {
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: $darkblue;
				opacity: 0.4;
			}
		}
	}

	&__slide-container {
		z-index: 1;
	}

	&__anim-gif-cover {
		position: absolute;
		top: 90px;
		width: auto;
		height: 300px;
		left: 50%;
		transform: translateX(-50%);

		^&__slide.image-mobile-hidden & {
			@include <lg {
				visibility: hidden;
			}
		}

		@include lg {
			height: calc(100%-180px);
			width: 50%;
			left: 50%;
			transform: none;
			top: 90px;
		}

		@include xl {
			width: calc(1110px / 2);
		}

		@include xxl {
			width: calc(1250px / 2 + 150px);
			left: calc(50% - 150px);
		}

		@media (min-width: 2000px) {
			width: calc(1250px / 2 + 180px);
			left: calc(50% - 180px);
		}
	}

	&__image {
		object-fit: cover;
		pointer-events: none;

		^&__slide.image-mobile-hidden & {
			@include <lg {
				visibility: hidden;
			}
		}

		^&__slide.is-cutout & {
			top: 90px;
			width: auto;
			height: 300px;
			left: 50%;
			transform: translateX(-50%);

			@include lg {
				height: auto;
				width: auto;
				left: auto;
				right: 0;
				transform: none;
				right: -250px;
				top: 30px;
			}

			@include xl {
				right: -100px;
			}

			@include xxl {
				right: 80px;
			}

			@media (min-width: 2000px) {
				left: calc(50% - 250px);
			}
		}
	}

	&__image-gif {
		left: 0;
		top: 0;
		position: absolute;
		size: 100%;
		object-fit: contain;
		object-position: right;
	}

	&__video {
		position: absolute;
		size: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
	}

	&__content-wrapper {
		display: flex;
		flex-direction: column;
	}

	&__seal-container {
		display: flex;
		align-items: center;
		margin-top: 50px;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;

		@include lg {
			margin: 0;
			align-items: flex-end;
			position: absolute;
			bottom: -50px;
			right: 0;
			padding-right: calc($gutter-lg / 2);
		}

		@include xl {
			bottom: -40px;
			padding-right: calc($gutter-xl / 2);
		}

		^&.animated & {
			margin-top: 0;
			margin-top: 50px;
			@include lg {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

	&__seal {
		position: relative;
		& + & {
			margin-left: 30px;
		}

		&--big {
			flex: 1 1 113%;

			@include lg {
				flex: 1 1 auto;
				width: 130px;
			}

			@include xl {
				width: 160px;
			}
		}

		&--small {
			flex: 0 1 50%;

			@include lg {
				flex: 1 1 auto;
				width: 50px;
			}

			@include xl {
				width: 65px;
			}
		}
	}

	&__seal-image {
		position: relative;
		width: 100%;
		height: auto;
		z-index: 2;
	}

	&__seal-shadow {
		z-index: 1;
		position: absolute;
		size: 100%;
		left: 10px;
		top: 10px;

		@include <lg {
			display: none;
		}
	}

	&__tooltip {
		z-index: 10 !important;
	}

	&__content {
		margin: 0 auto;
		text-align: center;

		@include sm {
			max-width: 610px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		@include lg {
			text-align: left;
			align-items: flex-start;
		}

		^&__slide.image-mobile-hidden & {
			margin: 0;

			@include sm {
				margin-left: auto;
				margin-right: auto;
			}
		}

		^&__slide.is-cutout & {
			@include lg {
				margin: 0;
			}
		}

		^&__slide:not(.is-cutout) & {
			margin-top: 20px;
		}
	}

	&__button-row {
		margin: 30px -15px -15px;

		@include sm {
			margin: 40px -15px -15px;
		}
	}

	&__action-link {
		display: inline-block;
		margin: 0 15px 15px !important;
	}

	&__head {
		color: white;
		@include font('headline', 35px, 41px, 0);
		margin-bottom: 30px;

		@include sm {
			@include font('headline', 54px, 60px, 0);
			margin-bottom: 40px;
		}

		^&__slide.has-image:not(.is-cutout) &,
		^&__slide.has-video & {
			text-shadow: $text-shadow;
		}

		^&__slide.has-image.is-cutout:not(.image-mobile-hidden) & {
			@include <lg {
				text-shadow: $text-shadow;
			}
		}
	}

	&__copy {
		> p,
		ul {
			color: white;
			@include font('regular', 18px, 26px, 0);

			@include sm {
				@include font('regular', 21px, 26px, 0);
			}

			^^&__slide.has-image:not(.is-cutout) &,
			^^&__slide.has-video & {
				text-shadow: $text-shadow;
			}
		}

		ul {
			margin-top: 30px;
		}
	}

	&__controls {
		display: none;
		position: absolute;
		overflow: hidden;
		bottom: 70px;
		left: 50%;
		transform: translateX(-50%);
		width: 420px;
		padding: 0 10px;
		height: 50px;
		z-index: 5;

		@include sm {
			display: flex;
		}
	}

	&__navig-button {
		background: transparent;
		border: none;
		outline: none;
		z-index: 1;
		transition: transform 0.2s;

		&:nth-child(1) {
			margin-right: auto;
		}

		&:nth-child(2) {
			margin-left: auto;
		}

		&:hover {
			transform: scale(1.4);
		}
	}

	&__navig-icon {
		color: white;
		font-size: 22px;
		line-height: 22px;
	}

	&__dots {
		position: absolute;
		left: 50%;
		bottom: 30px;
		transform: translateX(-50%);
		display: flex;

		@include sm {
			display: none;
		}
	}

	&__dot {
		size: 10px;
		border-radius: 50%;
		border: 0;
		background-color: white;
		outline: none;
		margin: 0 5px;
		transition: background-color 0.3s;

		&.is-active {
			background-color: $orange;
		}
	}

	&__count {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		span {
			@include font('black', 12px, 14px, 0.8px);
			color: white;
			margin: 0 1px;
		}
	}

	&__indicator {
		position: absolute;
		size: 250px;
		left: 50%;
		transform: translateX(-50%);

		svg {
			size: 100%;
		}

		svg:nth-child(2) {
			position: absolute;
			top: 0;
			left: 0;
			transition: transform 0.2s;
		}
	}
}

.headerMaxiTooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;
	white-space: wrap;

	@include sm {
		&:hover &__text {
			visibility: visible;
			opacity: 1;
		}

		&:hover &__icon::before {
			color: $orange;
		}
	}

	@include <sm {
		&.active &__text {
			visibility: visible;
			opacity: 1;
		}

		&.active &__icon::before {
			color: $orange;
		}
	}

	&.no-mobile {
		@include <lg {
			^&__text {
				display: none;
			}
		}
	}

	&__icon {
		font-size: 11px;
		color: $typo-color;
	}

	&__inline-text {
		color: $darkblue;
		border: 0;
		background: linear-gradient(#ffffff, #ffffff);
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		background-image: linear-gradient($orange, $orange);
		padding-bottom: 1px;
		text-decoration: none;
	}

	&__text {
		@include font('regular', 13px, 16px, 0px);
		visibility: hidden;
		background-color: $darkblue;
		color: $white;
		padding: 15px;
		border-radius: 3px;

		position: absolute;
		z-index: 5;
		opacity: 0;
		text-transform: none;
		transform: translateY(50%);
		hyphens: auto;
		text-align: left;

		width: 260px;
		bottom: 50%;
		left: 30px;

		^&--auto-width & {
			width: auto;
		}

		^&--no-wrap & {
			width: auto;
			white-space: nowrap;
		}

		^&--up &,
		^&--down & {
			left: 50%;
			transform: translateX(-50%);
		}

		^&--up & {
			bottom: calc(100% + 15px);
		}

		^&--down & {
			top: calc(100% + 15px);
			bottom: initial;
		}
	}

	&__text.rte-copy {
		p + p {
			margin-top: 8px;
		}
	}

	&__text::after {
		content: '';
		position: absolute;

		top: calc(50% - 5px);
		right: 100%;
		margin-left: -10px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent $darkblue transparent transparent;

		^&--up &,
		^&--down & {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			margin-left: 0;
			right: initial;
			left: calc(50% - 5px);
		}

		^&--up & {
			border-top: 5px solid $darkblue;
			top: 100%;
		}

		^&--down & {
			border-bottom: 5px solid $darkblue;
			bottom: 100%;
			top: initial;
		}
	}

	&__text-small-left {
		@include <lg {
			width: 260px;
			bottom: 50%;
			left: -275px;

			&::after {
				top: calc(50% - 10px);
				right: -20px;
				margin-left: -10px;
				border-width: 10px;
				border-style: solid;
				border-color: transparent transparent transparent $darkblue;
			}
		}

		@include <sm {
			width: 50vw;
			left: calc(-50vw - 20px);
		}
	}

	&__text-small-bottom {
		@media (max-width: 435px) {
			width: 160px;
			top: -12px;
			left: -80px;
			bottom: auto;

			&::after {
				top: -20px;
				right: calc(50% - 15px);
				margin-left: -10px;
				border-width: 10px;
				border-style: solid;
				border-color: transparent transparent $darkblue transparent;
			}
		}
	}

	&__text-long {
		width: 170px;

		@include md {
			width: 300px;
		}

		^&__text-small-left& {
			@include <lg {
				left: calc(-300px - 20px);
			}
			@include <md {
				left: calc(-170px - 20px);
			}
		}
	}

	&__image {
		width: 100%;
		height: auto;
		margin-top: 20px;
	}
}
