.ASD-tariff-radio {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
	color: $anthracite;
	position: relative;

	& + & {
		margin-top: 28px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -14px;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $anthracite-40;

			@include md {
				top: -17px;
			}
		}

		@include md {
			margin-top: 34px;
		}
	}

	&__input {
		flex: 0 0 auto;
		appearance: none;
		size: 22px;
		border: 1.5px solid $anthracite-60;
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		@include md {
			size: 28px;
		}

		&:after {
			content: '';
			display: block;
			background-color: $green;
			position: absolute;
			left: 0;
			top: 0;
			size: 100%;
			cursor: pointer;
			opacity: 0;
		}

		&:before {
			content: '';
			display: block;
			size: 14px;
			background-color: $green;
			border-radius: 50%;
			opacity: 0;
			transition: opacity 250ms ease;

			@include md {
				size: 18px;
			}
		}

		&:checked {
			&:before {
				opacity: 1;
			}
		}
	}

	&__content {
		display: flex;
		flex: 1 1 auto;
		gap: 10px;
		align-items: center;
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__tariff-label {
		@include font('headline', 16px, 120%, normal);

		&:last-child {
			margin-top: 3px;
		}
	}

	&__tariff-discount-label {
		color: $anthracite-80;
		@include font('regular', 16px, 120%, normal);
		margin-top: -2px;
	}

	&__discount {
		margin-left: auto;

		& + ^&__price-wrapper {
			margin-left: unset;
		}
	}

	&__discount-price {
		text-decoration: line-through;
	}

	&__price-wrapper {
		margin-left: auto;
		display: flex;
		align-items: center;
		height: 25px;
		margin-top: 1px;
	}

	&__price {
		@include font('headline', 36px, 100%, normal);
	}

	&__suffix-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__currency {
		@include font('headline', 18px, 100%, normal);
		height: 13px;
		margin-top: -2px;
	}

	&__year {
		@include font('', 12px, 14px, normal);
		color: $anthracite-80;
		height: 9px;
		margin-top: 2px;
	}
}
