.ce-header {
	@include sm {
		padding: 0 $ce-side-padding;

		.no-default-ce-padding &{
			padding: 0;
		}

		.ce-bodytext & {
			padding-left: 0;
			padding-right: 0;
		}

		.twoColGrid__col--left:not(.twoColGrid__col--white) & {
			padding-right: 0;
		}

		.twoColGrid__col--right:not(.twoColGrid__col--white) & {
			padding-left: 0;
		}
	}

	:--headlines {
		color: $darkblue;
		// Headline mit Subheadline
		// Headline und "Nur" Headline
		&:first-child:not(:last-child),
		&:last-child:first-child {
			@include font('headline', 24px, 28px, normal);
			margin-bottom: 20px;
		}

		// Subheadline
		&:last-child:not(:first-child) {
			margin-bottom: 10px;
		}
	}
}
