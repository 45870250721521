.ek-form {
	width: 100%;

	&__finish {
		padding: 10px 0;
		text-align: center;

		p {
			color: $grey-1;
			@include font('regular', 18px, 23px, normal);

			@include md {
				font-size: 21px;
				line-height: 26px;
			}
		}
	}
}
