.autocomplete-suggestion {
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover {
		color: $orange;
	}
}

.autocomplete-suggestion b {
	font-weight: normal;
	font-family: 'Lato';
}

.autocomplete-suggestion.selected {
	color: $orange;
}
