.ec-filter-dropdown {
	height: 0;
	transition: height 0.3s;
	z-index: 1;

	@include sm {
		// display: none;
		position: absolute;
		height: auto !important;
		background: white;
		border-top: 4px solid $orange;
		box-shadow: $card-shadow;
		margin-top: 31px;
		min-width: 300px;

		display: block;
		opacity: 0;
		visibility: hidden;
		transform: translate3d(0, 10px, 0);
	}

	@include lg {
		min-width: 430px;
	}

	&.visible {
		@include sm {
			display: block;
			opacity: 1;
			visibility: visible;
			transform: translate3d(0, 0, 0);
			transition: opacity 300ms, transform 300ms, visibility 300ms;
		}

		@include <sm {
			& + .ec-filter__button {
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 4px;
					background-color: $orange;
				}
				&:after {
					transform: translateY(-50%) rotate(270deg);
				}
			}
		}
	}

	&__oc-search {
		display: block;
		position: relative;
		margin: 16px 16px 0;

		&:before {
			content: '$acv-icons-var-lupe';
			position: absolute;
			font-family: 'acv-icons';
			font-size: 20px;
			color: $grey-3;
			transform: scaleX(-1) translateY(-50%);
			size: 20px;
			z-index: 1;
			top: 50%;
			left: 16px;
		}
	}

	&__oc-search-input[type='text'] {
		@include font('regular', 15px, 20px, normal);
		padding-left: 44px !important;
		color: $grey-1;
		height: 52px;

		@include <sm {
			margin: 0 0 30px;
		}
	}

	&__list {
		@include font('regular', 15px, 18px, normal);
		color: $typo-color;
		border-bottom: solid 1px $grey-3;
		padding: 16px;
		@include <sm {
			padding: 0 16px 30px;
			border-bottom: none;
		}
		@include sm {
			max-height: 450px;
			overflow-y: auto;
		}

		&::-webkit-scrollbar {
			width: 7px;

			&-thumb {
				background: $grey-3;
				border-radius: 20px;
				box-shadow: inset -2px 0 0 white, inset 2px 0 0 white;
			}
		}
	}

	&__list-item {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__label-wrapper {
		height: 100%;
		display: flex;
		align-items: center;
	}

	&__label-checkbox {
		margin-bottom: 0 !important;
		size: 24px !important;
		flex-shrink: 0;
	}

	&__label-text {
		@include font('regular', 16px, 25px, normal);
		margin-left: 10px;

		@include sm {
			@include font('regular', 15px, 20px, normal);
		}
	}

	&__button {
		margin: 16px;
		width: calc(100% - 30px);
		@include <sm {
			display: none;
		}
	}

	&__period-wrapper {
		display: flex;
		padding: 0 16px 24px;
		border-bottom: solid 1px $grey-3;
		@include xl {
			min-width: 600px;
		}

		@include <sm {
			display: block;
			border-bottom: none;
		}
	}
	&__period-date {
		@include sm {
			width: 50%;
		}
		&:first-child {
			margin: 16px 8px 0 0;
			@include <sm {
				margin: 0;
			}
		}
		&:last-child {
			margin: 16px 0 0 8px;
			@include <sm {
				margin: 20px 0 0;
			}
		}
		& input {
			@include font('regular', 16px, 25px, normal);
			height: 57px;

			@include sm {
				height: 52px;
				@include font('regular', 15px, 18px, normal);
			}

			&::placeholder {
				color: #666;
			}
		}
	}
	&__period-label {
		@include font('regular', 13px, 16px, normal);
		margin-bottom: 4px;
		color: #333;
	}
}
