.wrapper-white {
	position: relative;
	padding: 50px 0;

	&--no-top-padding {
		padding-top: 0;
	}

	&--no-bottom-padding {
		padding-bottom: 0;
	}
	// margin-top: calc($component-margin-scale * 60px);

	// .frame-default:nth-child(1) > &{
	// 	margin-top: 0;
	// }

	&::before {
		content: '';
		box-shadow: $card-shadow;
		position: absolute;
		left: 0;
		width: 100%;
		top: 0;
		display: block;
		height: 100%;
		background: white;

		@include sm {
			left: 15px;
			width: calc(100% - 30px);
		}

		@each $val, $i in sm, md, lg, xl, xxl {
			@include $(val) {
				left: calc($gutter-$(val) / 2);
				width: calc(100% - $gutter-$(val));
			}
		}
	}

	&--full-width::before {
		left: 0;
		width: 100%;
	}

	.frame {
		position: relative;
		z-index: 1;
	}
}

.frame-type-acvcontent_backgroundwrapper {
	&.frame-layout-10 {
		margin-top: 0 !important;

		.wrapper-white {
			padding-top: 0;
		}
	}

	&.frame-layout-20 {
		.wrapper-white {
			padding: 40px 0 !important;
		}
	}

	&.frame-layout-30 {
		+ .content-element {
			display: none;
		}
	}
}

.frame-type-acvcontent_backgroundwrapper + .frame-type-acvcontent_backgroundwrapper {
	&.frame-layout-10 {
		.wrapper-white {
			&:after {
				content: '';
				position: absolute;
				background-color: $white;
				width: 100%;
				height: 20px;
				left: 0;
				top: -10px;

				@each $val, $i in sm, md, lg, xl, xxl {
					@include $(val) {
						left: calc($gutter-$(val) / 2);
						width: calc(100% - $gutter-$(val));
					}
				}
			}
		}
	}
}
