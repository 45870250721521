.ASD-text {
	@include font('regular', 16px, 120%, normal);
	overflow: hidden;

	&:has(> &__icon) {
		position: relative;
		padding-left: 2.44em;
	}

	&__icon {
		font-size: 1.2em;
		&:before {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: inherit;
			margin: 0 !important;
		}
	}

	b {
		font-family: 'Lato bold';
	}

	p + p {
		margin-top: 8px;
	}

	& + &:not(.no-margin) {
		margin-top: 10px;
	}

	&--small {
		font-size: 14px;
	}

	&--regular {
		font-size: 16px;

		p + p {
			margin-top: 10px;
		}
	}

	&--large {
		font-size: 18px;
	}

	sup {
		font-size: inherit;
		top: 0;
	}

	h1 + &,
	h2 + &,
	h3 + &,
	h4 + &,
	h5 + &,
	h6 + & {
		margin-top: 10px;
	}
}
