/*=============================
=            fonts            =
=============================*/

/*----------  require fonts  ----------*/

// Flexo-Demi
@font-face {
	font-family: 'Flexo-Demi';
	font-display: swap;
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/Flexo-Demi.eot');
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/flexo/Flexo-Demi.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/flexo/Flexo-Demi.woff?__blob=publicationFile') format('woff');
	font-weight: normal;
	font-style: normal;
}

// Flexo-Bold
@font-face {
	font-family: 'Flexo-Bold';
	font-display: swap;
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/flexo/Flexo-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

// MaxWebPro-Demibold
@font-face {
	font-family: 'MaxWebPro-Demibold';
	font-display: swap;
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/maxwebpro/MaxWebPro-Demibold.eot');
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/maxwebpro/MaxWebPro-Demibold.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/maxwebpro/MaxWebPro-Demibold.woff?__blob=publicationFile') format('woff');
	font-weight: normal;
	font-style: normal;
}

// Lato
@font-face {
	font-family: 'Lato';
	font-display: swap;
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-regular.eot');
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-regular.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-regular.woff?__blob=publicationFile') format('woff'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-regular.woff2?__blob=publicationFile') format('woff2'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-regular.svg?__blob=publicationFile') format('svg'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-regular.ttf?__blob=publicationFile') format('truetype');
	font-weight: 400;
	font-style: normal;
}

// Lato bold
@font-face {
	font-family: 'Lato bold';
	font-display: swap;
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-700.eot');
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-700.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-700.woff?__blob=publicationFile') format('woff'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-700.woff2?__blob=publicationFile') format('woff2'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-700.svg?__blob=publicationFile') format('svg'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-700.ttf?__blob=publicationFile') format('truetype');
	font-weight: 700;
	font-style: normal;
}

// Lato black
@font-face {
	font-family: 'Lato black';
	font-display: swap;
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-900.eot');
	src: url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-900.eot?#iefix') format('embedded-opentype'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-900.woff?__blob=publicationFile') format('woff'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-900.woff2?__blob=publicationFile') format('woff2'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-900.svg?__blob=publicationFile') format('svg'), url('/typo3conf/ext/acv_content/Resources/Public/fonts/lato/lato-v14-latin-900.ttf?__blob=publicationFile') format('truetype');
	font-weight: 900;
	font-style: normal;
}

/*----------  font mixins  ----------*/

/**

	USAGE:
	- @include font('FONT ALIAS', $fontSize, $lineHeight, $letter-spacing);

 **/

@mixin font($id, $fontSize, $lineHeight, $letter-spacing) {
	/* set font alias */
	@if $id == 'headline' {
		font-family: 'Flexo-Demi';
	} @else if $id == 'regular' {
		font-family: 'Lato';
	} @else if $id == 'bold' {
		font-family: 'Lato bold';
	} @else if $id == 'black' {
		font-family: 'Lato black';
	} @else if $id == 'Flexo-Bold' {
		font-family: 'Flexo-Bold';
	}

	/* set font values */
	font-size: $fontSize;
	font-style: normal;
	font-weight: normal;
	// letter-spacing: calc($fontSize * $letter-spacing / 1000);
	letter-spacing: $letter-spacing;

	/* set line-height */
	@if $lineHeight == none {
		/* don't return line-height */
	} @else if $lineHeight == normal {
		line-height: normal;
	} @else {
		line-height: $lineHeight;
	}
}

/*=====  End of fonts  ======*/
