.ce-text {
	@include sm {
		padding: 0 $ce-side-padding;
		.no-default-ce-padding &{
			padding: 0;
		}

		@include lg {
			.twoColGrid__col--left:not(.twoColGrid__col--white) & {
				padding-right: 0;
			}

			.twoColGrid__col--right:not(.twoColGrid__col--white) & {
				padding-left: 0;
			}
		}
	}
}
