.info-button {
	display: flex;
	justify-content: center;
	align-items: center;
	size: 20px;
	border: 1px solid $grey-3;
	border-radius: 50%;
	flex: 0 0 auto;
	background-color: transparent;
	@include font('regular', 16px, 25px, normal);

	transition: background-color 250ms ease, border-color 250ms ease;

	@include lg {
		@include font('regular', 15px, 20px, normal);
		size: 35px;
	}

	&:active {
		background-color: white;
		border-color: $orange-dark;
	}
}
