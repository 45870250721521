/*=================================
=            richTable            =
=================================*/

.richTable {
	&__body {
		margin: 0 calc(-0.5 * $gutter);

		@include sm {
			margin: 0;
		}
	}

	&__check {
		@include font('bold', 13px, 17px, 0px);
		border-radius: 500px;
		padding: 0.45em 1em;
		display: inline-flex;
		color: #26ba4d;
		justify-content: center;
		align-items: center;

		&:not(:empty) {
			display: block;
			background: #e2ffea;
			border: 2px solid #26ba4d;

			@include lg {
				display: inline-block;
			}
		}

		@include lg {
			padding: 0.45em 1.5em;
			max-width: 100%;
		}

		&.is-invalid {
			color: #e21e36;

			&:not(:empty) {
				border-color: #e21e36;
				background: #f5e2e5;
			}
		}

		&:empty {
			size: 28px;
			padding: 0;
			position: relative;

			@include lg {
				size: 32px;
			}

			&::after {
				font-family: 'acv-icons';
				content: '$acv-icons-var-checkmark';
				display: inline-block;
				font-size: 1.75rem;

				@include lg {
					font-size: 2rem;
				}

				.is-invalid& {
					content: '$acv-icons-var-stop';
				}
			}
		}
		& + & {
			margin-left: 10px;
		}
	}

	&__check + p.richTable__copy {
		@include font('regular', 12px, 18px, 0px);
	}

	&__check-label {
		&:not(:first-child) {
			margin-left: 10px;
		}

		&:after {
			margin-left: 5px;
			font-size: 0.6em;
		}
	}

	&__copy {
		@include font('regular', 15px, 20px, 0px);
		display: block;
		hyphens: auto;

		@include sm {
			hyphens: none;
		}

		@include lg {
			display: block;
		}

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	&__content {
		background-color: $white;
		border-bottom: 1px solid #d4d4d4;
	}

	&__content-copy {
		@include font('regular', 15px, 20px, normal);
		padding: 25px 15px 15px;
		text-align: center;

		@include md {
			padding: 53px $ce-side-padding 39px;
		}
	}

	&__column {
		display: table-cell;
		text-align: center;
		vertical-align: middle;

		&--full-width {
			width: 100% !important;
		}

		^&__body & {
			border: 1px solid $grey-3;
		}

		^&__row:not(:first-child) & {
			border-top: 0;
		}

		& + & {
			border-left: none;
		}

		// mini
		^&__body & {
			^^&--mini & {
				padding: 20px 20px 13px;
			}
		}

		// maxi
		^&--maxi & {
			display: table;
			border-right: 0;
			width: 100%;

			@include lg {
				border-right: 1px solid $grey-3;
				display: table-cell;
				width: auto;
			}
		}

		^&__row:last-child & {
			^^&--maxi & {
				@include lg {
					border-bottom: none;
				}
			}
		}

		^&__row:first-child & {
			^^&--maxi & {
				border-top: 0;
			}
		}

		&:first-child {
			^^&--maxi & {
				@include lg {
					border-left: 1px solid $grey-3;
					width: 20%;
				}
			}

			^^&--maxi[data-columns='2'] & {
				@include lg {
					width: 33%;
				}
			}
		}

		&:last-child {
			^^&__row:last-child & {
				^^^&--maxi & {
					border-bottom: 0;
				}
			}
		}

		// ::after
		&::after {
			content: '';
			display: block;
			clear: both;
		}

		// .is-special
		&.is-special {
			border-top: 6px solid $orange !important;
			border-bottom: 6px solid $orange;

			@include lg {
				border-top: 0 !important;
				border-bottom: 1px solid $grey-3;
				border-left: 6px solid $orange;
				border-right: 6px solid $orange;
			}
		}
	}

	&__column-content {
		display: table-cell;
		max-width: 100%;
		padding: 12px;
		vertical-align: middle;
		transform: translateY(0.15em);

		@include lg {
			display: inline-block;
			width: 100%;
			padding: 20px;
		}

		&.hide-on-desktop {
			@include lg {
				display: none;
			}
		}

		&:first-child:not(:last-child) {
			border-right: 1px solid $grey-3;
			width: 20%;
			min-width: 110px;

			@include lg {
				border: none;
			}
		}
	}

	&__footer {
		&:not(:first-child) {
			margin-top: 30px;
		}
	}

	&__header {
		position: relative;
		z-index: 5;
		margin: 0 calc(-0.5 * $gutter);

		@include sm {
			margin: 0 0 20px 0;
		}

		^&--mini & {
			background: linear-gradient(180deg, white 0%, #f3f3f3 100%);
			padding: 20px;
			border-top: 1px solid #d4d4d4;
			box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.22);
			margin-bottom: 0;
		}

		^&--maxi & {
			display: none;

			@include lg {
				display: block;
			}
		}
	}

	&__headline {
		@include font('bold', 21px, 26px, 0px);

		^&--maxi & {
			text-align: left;
		}

		^&--mini & {
			color: $orange;
			@include font('headline', 17px, 19px, 0px);
		}
	}

	&__logo {
		width: 70px;
		max-width: 100%;
		max-height: 55px;
		height: auto;
		fill: $grey-1;

		@include lg {
			width: 100px;
		}

		.is-special & {
			fill: $orange;
		}
	}

	&__meta {
		@include font('regular', 15px, 20px, 0px);
		color: white;

		@include lg {
			@include font('regular', 13px, 16px, 0px);
		}

		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	&__price {
		@include font('headline', 28px, 33px, 0px);
		vertical-align: baseline;
		//display: inline-block;
		float: right;
		order: 2;

		@include lg {
			float: none;
		}

		.is-special & {
			color: $orange;
		}

		&::after {
			content: '€';
			font-family: 'Lato';
			font-size: 0.5em;
			line-height: 1em;
			display: inline-block;
			vertical-align: top;
		}

		& + ^&__check {
			float: left;

			@include lg {
				float: none;
			}
		}
	}

	&__bullets {
		text-align: left;
	}

	&__row {
		position: relative;
		display: table-row;
		width: 100%;
		background: white;

		^&__header & {
			^^&--mini & {
				background: transparent;
			}
		}

		&:not(:first-child) {
			@include lg {
				border-top: 1px solid $grey-3;
			}
		}
	}

	&__table-wrapper {
		padding: 0;
		width: 100%;
	}

	&__table {
		padding: 0;
		display: table;
		table-layout: fixed;
		width: 100%;
	}

	&__title {
		@include font('bold', 15px, 20px, 0);
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		padding: 15px 0;

		@include lg {
			padding: 0;
			text-align: left;
		}
	}
}

/*=====  End of richTable  ======*/
