.ec-event {
	&:not(:last-child) {
		margin-bottom: 8px;
	}
	&-month {
		@include font('headline', 13px, 15px, 3px);
		color: $grey-1;
		text-transform: uppercase;
		margin: 35px 0 16px;

		@include sm {
			@include font('headline', 15px, 18px, 4.03px);
			margin: 50px 0 16px;
		}
	}
	&__item {
		position: relative;
		box-shadow: $card-shadow;
		&:hover {
			cursor: pointer;
		}
	}

	&__wrapper {
		position: relative;
		display: flex;
		padding: 24px 0;

		@include <sm {
			padding: 24px 20px 32px 0;
		}
	}

	&__toggle-button {
		position: absolute;
		transform: translateY(-50%);
		right: 40px;
		top: 50%;
		width: 26px;
		height: 26px;
		border-radius: 100%;
		background-color: transparent;
		@include <sm {
			display: none;
		}

		&:after {
			content: '$acv-icons-var-arrow-right';
			font-family: 'acv-icons';
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			transform: rotate(90deg);
			transition: transform 150ms ease;
			width: 26px;
			height: 28px;

			^^&__item.is-open & {
				transform: rotate(-90deg) translateX(2px);
				transition: transform 150ms ease;
			}
		}
		&:hover {
			background-color: $grey-3;
		}
		&--mobile {
			@include sm {
				display: none;
			}
			@include <sm {
				display: block;
				left: 50%;
				bottom: 0px;
				right: initial;
				top: initial;
				transform: translateX(-50%);
				z-index: 10;

				&:hover {
					background-color: initial;
				}
			}
		}
	}

	&__date {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 24px;
		&-day {
			@include font('headline', 36px, 32px, normal);
			color: $orange;
			min-width: 50px;
			text-align: center;
		}
		&-month {
			@include font('black', 12px, 14px, 0.8px);
			text-transform: uppercase;
			color: $darkblue;
		}
	}

	&__info {
		width: 72%;
		@include <sm {
			width: 100%;
		}
		&-head {
			@include font('headline', 15px, 18px, normal);
			margin-bottom: 8px;
			color: $darkblue;
			hyphens: auto;

			@include sm {
				@include font('headline', 21px, 25px, normal);
				margin-bottom: 4px;
				hyphens: none;
			}
		}
		&-wrapper {
			@include font('regular', 16px, 19px, normal);
			color: $grey-1;

			@include sm {
				@include font('regular', 15px, 20px, normal);
			}
		}
		&-period {
		}
		&-location {
		}
		&-club {
		}
	}
}
