.contact {
	.form {
		@include sm {
			padding: 0 $ce-side-padding;
		}
	}

	&__headline {
		@include font('headline', 24px, 38px, normal);
		color: $anthrazit;
		margin-bottom: 34px;
	}

	&__form-error,
	&__form-success {
		@include font('bold', 15px, 20px, 0);
		color: $grey-1;
		margin-bottom: 30px;
	}

	.is-hidden {
		display: none;
	}

	&__message {
		padding: 0 $ce-side-padding;
	}

	&__section {
		position: relative;

		&[data-form-type='placeholder'] {
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: calc(100% - 100px);
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 25%, #ffffff 100%);
				left: 0;
				bottom: 0;
			}
		}
	}

	&__loading-dots {
		display: flex;
		justify-content: center;
	}

	&__process-headline {
		text-align: center;
		color: $anthrazit;
		@include font('', 28px, 36px, normal);
		margin-bottom: 19px;
		display: flex;
		flex-direction: column;

		&:before {
			font-size: 47px;
			color: $signal-green;
			margin: 0 !important;
			padding-top: 6px;
			padding-bottom: 17px;
		}

		&.acv-icons--warning:before {
			color: $signal-red;
		}
	}

	&__process-copy {
		p {
			@include font('', 18px, 26px, normal);

			& + p {
				margin-top: 40px;
			}
		}
	}

	&__process-buttons {
		margin-top: 30px;
		display: flex;
		justify-content: center;
	}
}
