.caravan-form {
	&__table {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 22px 0;
	}

	&__table-row {
		display: flex;
		font-size: 15px;
		@include <md {
			flex-wrap: wrap;
		}
		> div {
			&:first-child {
				width: 100%;
				flex: 1 0 auto;
				@include md {
					max-width: 250px;
					flex: 1 0 250px;
					margin-right: 20px;
				}

				@include xl {
					max-width: 340px;
					flex: 1 0 340px;
				}
			}

			&:nth-child(3) {
				margin-left: 40px;
				width: 90px;
				text-align: right;
			}

			&:last-child {
				flex: 1 1 auto;
				text-align: right;
			}
		}
	}

	&__bold {
		font-family: 'Flexo-Demi';
		font-weight: normal;
	}

	&__sum {
		font-family: 'Flexo-Demi';
		font-size: 28px;
		text-align: right;
		margin-top: 20px;
		border-top: 1px solid $grey-3;
		padding-top: 20px;
	}

	&__big-copy {
		font-family: 'Flexo-Demi';
		font-weight: normal;
		font-size: 17px;
		line-height: 19px;

		p + p {
			@include md {
				margin-top: 10px;
			}
		}
	}
}
