.linkBox {
	&__container {
		@include <sm {
			padding: 0 !important;
		}
	}

	&__list {
		background-color: white;
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		width: 100%;
		display: flex;
		min-height: 58px;
		border: 1px solid $grey-3;
		align-items: center;
		padding: 0 20px;
		gap: 16px;

		@include md {
			min-height: 106px;
			padding: 0 40px;
			gap: 40px;
		}

		@include lg {
			width: 50%;
		}

		position: relative;

		@include lg {
			&:nth-child(odd) {
				border-right: 1px solid $grey-3;
			}
		}

		&:last-child {
			border-bottom: initial;
		}

		@include lg {
			&:nth-last-child(2):not(:nth-child(even)) {
				border-bottom: initial;
			}
		}

		&:before {
			content: '';
			position: absolute;
			width: 6px;
			height: 100%;
			transform: scaleX(0);
			transform-origin: left;
			transition: transform 250ms ease;
			left: 0;
			top: 0;
			background-color: $grey-3;
		}

		&:hover {
			&:before {
				transform: scaleX(1);
			}
		}
	}

	&__link {
		display: flex;
		size: 100%;
		padding: 15px 0 10px;
		@include font('regular', 18px, 26px, normal);
		text-decoration: none;
		background: none;
		color: $typo-color;

		&:hover {
			background: none;
		}

		@include md {
			font-size: 21px;
			line-height: 29px;
			padding: 20px 0;
		}
		align-items: center;
	}

	&__label {
		@include font('', 21px, 26px);
		@include md {
			line-height: 29px;
		}
		&:after {
			font-size: 13px;
			margin-left: 7px;
			@include md {
				font-size: 12px;
				margin-left: 11px;
			}
		}
	}

	&__icon-wrapper {
		flex: 0 0 auto;
		size: 26px;

		@include md {
			size: 63px;
		}
	}

	&__icon {
		size: 100%;
		object-fit: contain;
	}
}
