.ASD-help-tile {
	visibility: hidden;
	opacity: 0;
	transition: visibility 250ms ease, opacity 250ms ease;
	z-index: 500;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	position: fixed;

	&.is-visible {
		visibility: visible;
		opacity: 1;
	}

	@include helpTile {
		z-index: 1;
		display: block;
		left: calc(50% + 340px + 30px);
		top: 101px;
		background: unset;
		width: auto;
		height: auto;

		&.is-absolute {
			position: absolute;
			top: unset;
			bottom: 0;
		}
	}

	&__tile {
		position: relative;
		width: 302px;
		background-color: white;
		padding: 29px 20px;
		border-radius: 12px;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.22);
	}

	&__image {
		max-width: 100%;
		padding: 0 15px;
		margin-bottom: 13px;
	}

	&__close {
		position: absolute;
		right: 13px;
		top: 15px;
		background-color: transparent;
		font-size: 0;
		border: 0;
		size: 14px;
		&:before {
			margin: 0 !important;
			font-size: 16px;
			color: $anthracite;
		}
	}

	&__intro {
		display: flex;
		flex-direction: column;
		gap: 5px;
		margin-bottom: 16px;
	}

	&__copy {
		margin: 0 !important;
		p {
			@include font('', 12px, normal, normal);
		}
	}

	&__contact {
		display: flex;
		flex-direction: column;
		gap: 2px;
		align-items: flex-start;
	}

	&__phone {
		@include font('', 15px, normal, normal);
	}

	&__link {
		display: inline-block;
		margin-top: 15px;
	}
}

.no-scroll-for-help-tile {
	@include <helpTile {
		overflow: hidden;
	}
}
