.tarifCompareFooter {
	position: fixed;
	left: calc($gutter/2);
	width: 100vw;
	bottom: 0;
	padding: 18px 0;
	z-index: 5;
	transform: translateY(100%);
	transition: transform 250ms ease, visibility 250ms ease;
	display: flex;
	visibility: hidden;

	@include lg {
		left: 0;
		padding: 12px 0;
		background-color: $anthrazit;
	}

	&.is-visible {
		visibility: visible;
		transform: translateY(0);
	}

	> div {
		width: 100%;
	}

	&__inner {
		position: relative;
		width: 100%;
		@include lg {
			display: flex;
			align-items: center;
		}
	}

	&__change-btn-wrapper {
		position: relative;

		@include <lg {
			size: 48px;
		}
	}

	&__change-btn {
		color: $anthrazit !important;
		display: flex;
		gap: 14px;
		flex-direction: row-reverse;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);

		@include <lg {
			background-color: white !important;
			justify-content: center;
			align-items: center;
			padding: 0;
			flex: 0 1 48px;
			size: 48px;
			&:not(.is-active)&:hover {
				color: $anthrazit !important;
			}
		}

		&.has-filter-active {
			border-color: $orange;

			@include <lg {
				border-width: 2px;
			}
		}

		span:first-child {
			@include <lg {
				display: none;
			}
		}

		&.is-active {
			&:after {
				color: $orange;
				content: '$acv-icons-var-cross';
			}
		}

		&:hover {
			color: $white !important;
		}

		&:after {
			@include <lg {
				margin-right: 0 !important;
			}
			content: '$acv-icons-var-filter';
		}
	}

	&__change-btn-marker {
		@include font('regular', 12px, 14px, normal);
		z-index: 1;
		position: absolute;
		size: 20px;
		top: 0;
		right: 0;
		background: $orange;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		pointer-events: none;
	}

	&__switch-group {
		color: $white;
		display: flex;
		align-items: center;
		@include font('normal', 14px, 18px, 0.0025em);
		gap: 13px;

		@include <lg {
			display: none;
		}
	}

	&__link-btn {
		height: 58px;
		margin-left: auto;
		border-color: $signal-green !important;
		padding: 19px 88px;
		color: white !important;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);

		@include <lg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
			padding: 19px 19px;
			width: calc(100% - 140px);
			background-color: $signal-green !important;

			&:active {
				transform: translate3d(-50%, -50%, 0) scale(0.95);
			}
		}

		&:before {
			background-color: $signal-green !important;
		}
	}
}
