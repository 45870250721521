.iconInfoCard{
	&__content{
		background-color: white;
		box-shadow: $card-shadow;
		padding:32px 16px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	&__headline{
		text-align: center;
		@include font('',17px,19px,normal);
		color:$anthrazit;
	}

	&__icon{
		display: flex;
		justify-content: center;
		height:150px;

		&--small{
			height: 70px;
		};

		&--additional-margin{
			&:not(:first-child){
				margin-top: 32px;
			}
			&:not(:last-child){
				margin-bottom: 32px;
			}
		}

		svg {
			object-fit: contain;
			size:100%;
			*{
				fill:$anthrazit;
			}
		}
	}

	&__copy{
		p {
			font-size: 13px;
			line-height:16px;
			color:$grey-1;

			& + p{
				margin-top: 10px;
			}
		}

		a{
			font-size: 12px;
			line-height:14px;
		}
	}
}
