.login-overlay {
	.articleIntro {
		margin-bottom: 50px;
	}

	padding: 50px 0;
	position: fixed;
	flex-flow: column nowrap;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($darkblue, 0.8);
	z-index: 1000;
	display: flex;
	align-items: center;
	overflow: auto;
	transition: opacity 0.5s ease;

	@include lg {
		justify-content: center;
	}

	&.fade-out {
		opacity: 0;
	}

	&__inner {
		background: $white;
		padding: 80px 20px 50px;
		transition: transform 0.4s ease;

		@include sm {
			padding: 80px 0 50px;
		}

		^&.fade-out & {
			transform: translate3d(0, -100%, 0);
		}
	}

	&__form {
		@include sm {
			padding: 0 $ce-side-padding;
		}
	}
}
