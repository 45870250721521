.frame-type-acvcontent_articleintro {
	&:first-child {
		.articleIntro__headline {
			padding-top: 30px;
		}
	}
}

.articleIntro {
	&__wrapper {
		@include sm {
			padding: 0 $ce-side-padding;
		}
	}

	&__tagline {
		@include font('standard', 15px, 20px, normal);
		color: $grey-2;
		margin-bottom: 12px;
	}

	&__headline {
		@include font('headline', 28px, 33px, 0);
		color: $orange;

		@include sm {
			font-size: 34px;
			line-height: 38px;
		}

		&:not(:last-child) {
			margin-bottom: 40px;

			@include sm {
				margin-bottom: 50px;
			}
		}

		&::after {
			background: $orange;
			content: '';
			display: block;
			height: 6px;
			margin-top: 20px;
			width: 80px;
		}

		h1 {
			font-size: 1.17em;

			@include sm {
				font-size: 1.17em;
			}
		}
	}

	&__teaser {
		@include font('bold', 18px, 26px, normal);
		color: $darkblue;

		@include sm {
			font-size: 21px;
		}

		&:not(:last-child) {
			margin-bottom: 30px;

			@include sm {
				margin-bottom: 50px;
			}
		}
	}
}
