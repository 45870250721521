.ASD-h1,
.ASD-h2,
.ASD-h3,
.ASD-h4,
.ASD-h5,
.ASD-h6 {
	color: $anthracite;
	@include font('headline', 16px, 120%, normal);
}

.ASD-h1 {
	font-size: 36px;

	@include md {
		font-size: 54px;
	}
}

.ASD-h2 {
	font-size: 32px;

	@include md {
		font-size: 36px;
	}
}

.ASD-h3 {
	font-size: 24px;

	@include md {
		font-size: 32px;
	}
}

.ASD-h4 {
	font-size: 20px;

	@include md {
		font-size: 24px;
	}
}

.ASD-h5 {
	font-size: 16px;

	@include md {
		font-size: 20px;
	}
}

.ASD-h6 {
	font-size: 16px;

	@include md {
		font-size: 18px;
	}
}
