.u-breakGrid {
	&:not(&--only-mobile) {
		position: relative;
		left: 50%;
		transform: translateX(-50vw) translateX(calc(var(--sWidth, 0vw) / 2)) translateX(-1px);
		width: calc(100vw - var(--sWidth, 0vw) + 2px);
	}

	&--only-mobile {
		@include <md {
			position: relative;
			left: 50%;
			transform: translateX(-50vw) translateX(calc(var(--sWidth, 0vw) / 2)) translateX(-1px);
			width: calc(100vw - var(--sWidth, 0vw) + 2px);
		}
	}
}
