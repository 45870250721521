.contactCardMinified {
	min-height: 200px;
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: center;

	@include lg {
		flex-direction: row;
		text-align: center;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 50px 0;
		padding: 0 15px;
	}

	&__prehead {
		order: 1;
		text-align: center;
		@include font('headline', 13px, 15px, 3px);
		text-transform: uppercase;
		color: $darkblue;
		margin-bottom: 22px;

		.wrapper-white section:not([force-white]) & {
			color: $grey-1;
		}
	}

	&__name {
		@include font('headline', 28px, 33px, 0);
		color: $darkblue;
		margin-bottom: 7px;
		order: 2;
		width: 100%;
	}

	&__position {
		@include font('bold', 18px, 26px, 0);
		color: $typo-color;
		margin-bottom: 25px;

		order: 3;

		@include sm {
			margin-bottom: 35px;
		}
	}

	&__contactInfo {
		@include font('regular', 18px, 19px, 0);
		color: $typo-color;

		order: 4;
	}

	&__contactType {
		& + & {
			margin-top: 17px;
		}
	}

	&__mail {
		background-image: linear-gradient($orange, $orange);
		&:hover {
			color: $orange;
			background-image: linear-gradient($orange, $orange);
		}
	}

	&__phone {
		@include lg {
			background-image: none;
		}

		&:hover {
			color: $orange;
			background-image: linear-gradient($orange, $orange);
		}
	}

	&__imageContainer {
		width: 100%;
		height: 250px;

		@include sm {
			height: 250px;
		}

		@include lg {
			position: relative;
			height: 200px;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;

		@include lg {
			position: absolute;
		}
	}
}
