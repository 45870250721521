.ASD-label {
	position: absolute;
	top: 17px;
	left: 10px;

	pointer-events: none;

	@include font('regular', 16px, 20px, normal);

	transition: top 250ms ease, font-size 250ms ease, color 250ms ease;

	color: $anthracite-80;

	&__content {
		display: inline;
		white-space: nowrap;

		&:not(:last-child) {
			margin-right: 7px;
		}
	}

	.ASD-select:not([required]) ~ &,
	.ASD-textarea:not([required]) ~ &,
	.ASD-input:not([required]) ~ & {
		^&__content:after {
			content: '(optional)';
			margin-left: 0.25rem;
		}

		^&__content[data-suffix]:after {
			content: attr(data-suffix);
		}
	}

	.ASD-select ~ & {
		transition: none;
	}

	.ASD-select ~ &,
	.ASD-input[type='date'] ~ &,
	.ASD-input[data-is-date-input] ~ &,
	.ASD-input:focus ~ &,
	.ASD-input:placeholder-shown ~ &,
	.ASD-address-select__input:focus ~ &,
	.ASD-address-select__input.is-filled ~ &,
	.ASD-input.is-filled ~ &,
	.ASD-textarea:focus ~ &,
	.ASD-textarea:placeholder-shown ~ &,
	.ASD-textarea.is-filled ~ & {
		top: 4px;
		font-size: 14px;
		color: $anthracite;
	}

	.ASD-textarea.is-filled ~ & {
		background-color: $anthracite-5;
		width: calc(100% - 21px);

		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 10px;
			top: -5px;
			left: 0;
			pointer-events: none;
			background-color: $anthracite-5;
		}
	}

	.ASD-select[disabled] ~ &,
	.ASD-textarea[disabled] ~ &,
	.ASD-input[disabled] ~ & {
		color: $anthracite-60;
	}

	.ASD-input[disabled]:placeholder-shown ~ &,
	.ASD-input[disabled].is-filled ~ &,
	.ASD-textarea[disabled]:placeholder-shown ~ &,
	.ASD-textarea[disabled].is-filled ~ & {
		color: $anthracite-80;
	}

	.ASD-address-select__input.is-invalid:not(:focus) ~ &,
	.ASD-select.is-invalid:not(:focus) ~ &,
	.ASD-textarea.is-invalid:not(:focus) ~ &,
	.ASD-input.is-invalid:not(:focus) ~ & {
		color: $signal-red;
	}
}
