$verticalColumnMargin: $content-element-margin-small;
$verticalColumnMargin-sm: $content-element-margin-small-sm;
$verticalColumnMargin-md: $content-element-margin-small-md;
$verticalColumnMargin-lg: $content-element-margin-small-lg;
$verticalColumnMargin-xl: $content-element-margin-small-xl;
$verticalColumnMargin-xxl: $content-element-margin-small-xxl;

.column-wrapper {
	position: relative;
	&__headline {
		margin-bottom: 37px;
		@include sm {
			margin-bottom: 59px;
		}
	}

	@include <sm {
		&--col-margin {
			margin-top: $verticalColumnMargin;
		}
	}

	@include only-sm {
		&--col-margin-sm {
			margin-top: $verticalColumnMargin-sm;
		}
	}

	@include only-md {
		&--col-margin-md {
			margin-top: $verticalColumnMargin-md;
		}
	}

	@include only-lg {
		&--col-margin-lg {
			margin-top: $verticalColumnMargin-lg;
		}
	}

	@include only-xl {
		&--col-margin-xl {
			margin-top: $verticalColumnMargin-xl;
		}
	}

	@include xxl {
		&--col-margin-xxl {
			margin-top: $verticalColumnMargin-xl;
		}
	}

	&--2 {
		:--headlines {
			@include <lg {
				hyphens: auto;
			}
		}
	}

	&--3,
	&--4 {
		:--headlines {
			hyphens: auto;
		}
	}


	&--vertical-align {
		@include md {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			& ^&__header {
				width: 100%;
			}
		}
	}
}
