.tour-guide {
	margin-top: -50px;

	@include sm {
		padding: 0 15px;
	}

	@include lg {
		margin-top: 14px;
	}

	&--only .content-element:not(:first-child) {
		display: none;
	}

	main:not([data-layout='for-web-view']) &--dotnav-fixed {
		padding-top: 146px;
		margin-top: -50px;
	}

	//Dotnav
	&__dotnav {
		position: relative;
		background: white;

		main:not([data-layout='for-web-view']) ^&--dotnav-fixed & {
			margin-top: 0;
			position: fixed;
			z-index: 10;
			border-bottom: 1px solid $grey-3;
			width: 100%;
			top: 70px;
			left: 50%;
			transform: translateX(-50%);

			@include sm {
				width: calc(100% - $gutter);
			}

			@include md {
				width: 690px;
			}
		}
	}

	&__dotnav-top {
		border-bottom: 1px solid $grey-3;
		margin: 0 -15px;

		@include md {
			margin: 0 -75px;
		}

		@include lg {
			display: none;
		}

		main:not([data-layout='for-web-view']) ^&--dotnav-fixed & {
			@include <xl {
				margin: 0;
			}
		}
	}

	&__dotnav-step-view {
		@include font('headline', 20px, 25px);
		color: $orange;
		text-align: center;
		padding: 30px 0;

		@include lg {
			display: none;
		}
	}

	&__dotnav-bottom {
		position: relative;

		main:not([data-layout='for-web-view']) ^&--dotnav-fixed & {
			margin: 0px 15px;

			@include md {
				width: 540px;
				margin: 0 auto;
			}
		}
	}

	&__dotnav-container {
		overflow: hidden;
		position: relative;
		height: 50px;
		margin: 0 30px;
		margin-bottom: 10px;

		@include lg {
			height: 100px;
			margin: 0 15px;
		}
	}

	&__dotnav-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 18px 0;
		position: absolute;
		transition: transform 0.4s ease;

		@include lg {
			padding: 0 45px 0 55px;
		}

		^&__dotnav--statik & {
			position: initial;
			transform: none !important;
			transition: none;
		}
	}

	&__dotnav-step {
		position: relative;
		pointer-events: none;
	}

	&__dotnav-step.done {
		cursor: pointer;
	}

	&__dotnav-step.current {
		cursor: default;
	}

	&__dotnav-step:not(:last-child) {
		min-width: 9vw;

		@media (min-width: 336px) {
			min-width: 9.6vw;
		}

		@media (min-width: 360px) {
			min-width: 9.5vw;
		}

		@media (min-width: 370px) {
			min-width: 10vw;
		}

		@media (min-width: 410px) {
			min-width: 10.4vw;
		}

		@media (min-width: 460px) {
			min-width: 10.8vw;
		}

		main:not([data-layout='for-web-view']) ^&--dotnav-fixed & {
			@include sm {
				min-width: 11.2vw;
			}

			@include md {
				min-width: 62px;
			}
		}

		@include sm {
			min-width: 10.8vw;
		}

		@include md {
			min-width: 62px;
		}

		@include lg {
			min-width: 140px;
		}
		@include xl {
			min-width: 174px;
		}
		@include xxl {
			min-width: 198px;
		}

		^&__dotnav--statik & {
			width: 50%;
			min-width: 0;
		}
	}

	&__dotnav-step::before {
		content: '';
		display: block;
		height: 14px;
		width: 14px;
		border: 1px solid $grey-7;
		border-radius: 50%;
		position: relative;
		pointer-events: all;
	}

	&__dotnav-step.done::before {
		border: 2px solid $typo-color;
	}
	&__dotnav-step.current::before,
	&__dotnav-step.done:hover::before {
		border: 2px solid $orange;
	}

	&__dotnav-step:not(:last-child)::after {
		content: '';
		height: 1px;
		width: calc(100% - 18px);
		background-color: $grey-7;
		display: block;
		position: absolute;
		left: 16px;
		top: 7px;
	}

	&__dotnav-step.done::after {
		background-color: $typo-color !important;
	}

	&__dotnav-copy {
		@include font('regular', 15px, 26px, normal);
		position: absolute;
		transform: translateX(-50%);
		left: 8.5px;
		top: 17px;
		color: $grey-7;
		white-space: nowrap;
		display: none;
		pointer-events: all;

		@include lg {
			display: block;
		}

		^&__dotnav-step.done & {
			color: $typo-color;
		}
		^&__dotnav-step.done:hover & {
			color: $orange;
		}
		^&__dotnav-step.current & {
			@include font('bold', 15px, 26px, normal);
			color: $orange;
		}
	}

	&__dotnav-control {
		position: absolute;
		border: none;
		background-color: transparent;
		outline: none;
		font-size: 12px;
		width: 14px;
		color: $grey-8;
		padding: 18px 0;

		@include lg {
			padding: 0;
		}

		&--back {
			left: 0px;
			top: -1px;
			transform: rotate(180deg);

			@include lg {
				left: -10px;
			}
		}

		&--next {
			right: 0px;
			top: 1px;

			@include lg {
				right: -10px;
			}
		}
	}

	//Header
	&__header {
		margin-bottom: 60px;
	}

	&__header-headline {
		@include font('headline', 28px, 33px, 0);
		color: $orange;

		@include sm {
			font-size: 34px;
			line-height: 38px;
		}

		&:not(:last-child) {
			margin-bottom: 50px;
		}

		&::after {
			background: $orange;
			content: '';
			display: block;
			height: 6px;
			margin-top: 20px;
			width: 80px;
		}
	}

	&__header-headline-small {
		margin-bottom: 7px;
	}

	&__header-subheadline {
		@include font('bold', 21px, 26px, normal);
	}

	//General
	&__section {
		margin-bottom: 60px !important;

		&--margin-small {
			margin-bottom: 50px !important;
		}
	}

	&__success {
		margin-bottom: 1.25rem !important;
	}

	&__divider {
		border-bottom: 1px solid $grey-3;
		display: block;
		margin-bottom: 60px;
	}

	&__divider--big {
		margin-bottom: 60px;
		margin-top: 28px;
	}

	&__label-text {
		@include font('regular', 21px, 26px, normal);
	}

	&__required-text {
		@include font('regular', 13px, 26px, normal);

		&--top {
			margin-top: 0;
			margin-bottom: 32px;
		}
	}

	&__action-bar {
		margin-top: 10px;
		flex-direction: row;
		padding-top: 60px;

		&.flex-end {
			justify-content: flex-end;
		}
	}

	//Headlines & Copy
	&__headline {
		margin-bottom: 20px;

		@include lg {
			margin-bottom: 50px;
		}
	}

	&__headline-small {
		margin-bottom: 15px;
	}
	&__headline-middle {
		margin-bottom: 30px;
	}

	&__headline-top {
		margin-bottom: 15px;
		display: block;
	}

	&__subheadline {
		margin-bottom: 20px;
	}

	&__tagline {
		@include font('headline', 15px, 15px, 4.03px);
		text-transform: uppercase;
		color: $grey-1;
		margin-bottom: 20px;
	}

	&__copy {
		@include font('regular', 15px, 20px);
		color: $grey-1;
		margin-bottom: 50px;

		&--margin-small {
			margin-bottom: 30px;
		}
	}

	//Radio
	&__radio-list {
		display: flex;
		flex-direction: column;

		&:not(:last-child) {
			margin-bottom: 28px;

			@include sm {
				margin-bottom: 0px;
			}
		}
	}

	&__radio-list-vertical {
		^&__radio-label:not(:last-child),
		^&__radio-label:last-child {
			margin-bottom: 38px;
		}
	}

	&__radio-label {
		display: flex;
		align-items: center;
	}

	&__radio-label:not(:last-child) {
		margin-bottom: 28px;
	}

	&__radio-tooltip-group {
		display: flex;
		align-items: center;
		gap: 10px;
		> ^&__radio-label:not(:last-child) {
			margin-bottom: 0;
		}

		> .tooltip {
			transform: unset;
		}
	}

	&__radio-input {
		margin: 0 18px 0 0 !important;
		flex-shrink: 0;
	}

	&__alternative-start {
		margin-top: 50px;
	}

	//Tabs
	&__tabs {
		padding: 0px;
	}

	&__tabs-item {
		padding-top: 50px;
	}

	//GeoSelect
	&__geo-select .form__column:last-child {
		margin-top: 28px;
	}

	&__geoselect-list {
		position: absolute;
		width: 100%;
		max-height: 250px;
		border: 1px solid $grey-5;
		background-color: $white;
		z-index: 4;
		overflow-y: scroll;
	}

	&__geoselect-list-item div {
		@include font('bold', 16px, 14px, 0.8px);
		color: $typo-color;
		width: 100%;
		padding: 10px 0px 10px 15px;
		border-bottom: 2px solid $grey-5;
	}

	&__geoselect-list-item button {
		border: none;
		background-color: transparent;
		color: $grey-1;
		width: 100%;
		padding: 10px 0px 10px 15px;
		text-align: left;
	}
	&__geoselect-list-item button:hover {
		background-color: $grey-5;
	}

	&__geoselect-input-wrapper {
		position: relative;

		&::after {
			content: '';
			display: block;
			opacity: 0;
			size: 25px;
			margin-top: -12.5px;
			border: 3px solid $orange;
			border-bottom-color: transparent;
			border-left-color: transparent;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			right: 15px;
			pointer-events: none;
			transition: opacity 0.2s;
			animation: rotate 0.9s ease-in-out infinite;

			^&.loading& {
				opacity: 1;
			}
		}

		&.loading {
		}
	}

	&__geoselect-list-input {
		margin-bottom: 10px !important;

		@include lg {
			margin-bottom: 5px !important;
		}

		^&__geoselect-input-wrapper.loading & {
			padding-right: 50px !important;
		}
	}

	//Destination
	&__destination-list &__destination:only-child,
	&__destination-list &__destination:last-child {
		margin-bottom: 58px;
	}

	&__destination-list &__destination:not(:last-child) {
		margin-bottom: 40px;
	}

	&__destination-column {
		margin-bottom: 50px;
	}

	&__destination-delete {
		font-size: 13px;
		float: right;
		background: transparent;
		border: none;
		text-decoration: underline;
	}

	&__destination-add {
		@include font('bold', 12px, 14px, 0.8px);
		color: $darkblue;
		text-transform: uppercase;
		background: transparent;
		border: none;
		outline: none;
		position: relative;
		height: 21px;
		display: flex;
		align-items: center;

		&::before {
			content: '+';
			@include font('headline', 21px, 23px, normal);
			border: none;
			background-color: $grey-5;
			height: 21px;
			width: 21px;
			margin-right: 15px;
			display: block;
		}
	}

	//IconSelection
	&__icon-selection  {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&__icon-selection-checked {
		background-color: $grey-5;
	}

	&__icon-selection-label  {
		user-select: none;
		width: 50%;
		border: 1px solid $grey-6;
		padding: 15px;
		cursor: pointer;

		@include sm {
			width: 33.33%;
		}
	}

	&__icon-selection-icon {
		height: 80px;
		display: block;
		margin: 0 auto 12px auto;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position-y: center;
	}

	&__icon-selection--plane {
		background-image: svg-load('toure-guide/plane.svg', fill: $orange);
		width: 70px;
	}
	&__icon-selection--escooter {
		background-image: svg-load('toure-guide/escooter.svg', fill: $orange);
		width: 64px;
	}
	&__icon-selection--bike {
		background-image: svg-load('toure-guide/bike.svg', fill: $orange);
		width: 66px;
	}
	&__icon-selection--camper-gt-35,
	&__icon-selection--camper-lt-35 {
		background-image: svg-load('toure-guide/camper.svg', fill: $orange);
		width: 78px;
	}
	&__icon-selection--caravan {
		background-image: svg-load('toure-guide/caravan.svg', fill: $orange);
		width: 75px;
	}
	&__icon-selection--carsharing {
		background-image: svg-load('toure-guide/carsharing.svg', fill: $orange);
		width: 60px;
	}
	&__icon-selection--cruiseship {
		background-image: svg-load('toure-guide/cruiseship.svg', fill: $orange);
		width: 70px;
	}
	&__icon-selection--motorcycle {
		background-image: svg-load('toure-guide/motorcycle.svg', fill: $orange);
		width: 66px;
	}
	&__icon-selection--train {
		background-image: svg-load('toure-guide/train.svg', fill: $orange);
		width: 40px;
	}
	&__icon-selection--pkw {
		background-image: svg-load('toure-guide/pkw.svg', fill: $orange);
		width: 60px;
	}

	&__icon-selection-value {
		@include font('headline', 17px, 19px, normal);
		text-align: center;
		display: block;
	}

	&__icon-selection-tag {
		text-align: center;
		display: block;
		font-size: 15px;
	}

	//Counter
	&__counter {
		@include font('headline', 21px, 25px, normal);
		display: inline-flex;
		justify-content: space-between;
		width: 260px;

		@include sm {
			width: 280px;
		}

		@include xl {
			justify-content: none;
			width: auto;
		}
	}

	&__counter:not(:last-child) {
		margin-bottom: 38px;

		@include xl {
			margin-bottom: 0px;
			margin-right: 34px;
		}

		@include xxl {
			margin-right: 60px;
		}
	}

	&__counter-label {
		display: flex;
		line-height: 31px;

		@include xl {
			margin-right: 12px;
		}

		@include xxl {
			margin-right: 16px;
		}
	}

	&__counter-number {
		padding: 0 18px;
		@include font('bold', 24px, 31px, normal);
		min-width: 65px;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
	}

	&__counter-minus,
	&__counter-plus {
		@include font('headline', 21px, 0px, normal);
		border: none;
		background-color: $grey-5;
		height: 21px;
		width: 21px;
		outline: none;
		vertical-align: middle;
	}

	&__counter-disabled {
		opacity: 0.6;
		cursor: default;
	}

	//Materials
	&__materials-destination {
		margin-bottom: 42px;
	}
	&__materials-destination tr:not(:last-child) td {
		padding-bottom: 15px;
	}

	&__materials-destination tr td:last-child {
		padding-left: 30px;
	}

	&__materials-destination-info {
		@include font('bold', 21px, 26px, normal);
		padding-left: 15px;
	}

	&__materials-destination-value {
		@include font('regular', 21px, 26px, normal);
	}

	&__materials-empty {
		@include font('regular', 18px, 26px, normal);
		color: $orange;
		width: 100%;
		padding: 0 15px;
		margin-bottom: 28px;
	}

	&__materials-max {
		@include font('regular', 16px, 26px, normal);
		color: $signal-red;
		text-align: center;
		width: 100%;
	}

	&__materials-loading {
		width: 100%;
		height: 350px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__materials-loader {
		position: relative;
		size: 75px;
		margin-bottom: 25px;

		.circular {
			animation: rotate 2s linear infinite;
			height: 100%;
			transform-origin: center center;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.path {
			stroke-width: 5px;
			stroke: $orange;
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
			animation: dash 1.5s ease-in-out infinite;
		}
	}

	&__materials-regions {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	&__materials-regions-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 0 40px;
		margin-bottom: -25px;

		@include md {
			justify-content: space-between;
		}
	}

	&__materials-error {
		color: $signal-red;
	}

	&__no-materials-selected {
		margin-bottom: 30px;
	}

	&__material-summary {
		margin-top: 30px;
	}

	&__materials-button-headline {
		display: none;
		visibility: hidden;
		margin-bottom: 30px;

		@include md {
			display: block;
		}
	}

	&__materials-button-wrapper {
		position: relative;
		width: 100%;
		margin-bottom: 25px;

		@include md {
			width: 170px;
		}

		&--empty {
			visibility: hidden;
			height: 0px;
			margin-bottom: 0;
		}

		&--premium {
			&:first-child {
				& ^^^&__materials-button-headline {
					visibility: visible;
					display: block;
					color: $orange;
				}
			}
		}

		&--standard {
			&:first-child {
				& ^^^&__materials-button-headline {
					visibility: visible;
					display: block;
				}
			}
		}

		&--premium + &--standard {
			margin-top: 20px;

			& ^^&__materials-button-headline {
				visibility: visible;
				display: block;
			}

			@include md {
				margin-top: 0;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				background-color: $grey-3;

				top: -23px;
				left: 0;
				height: 1px;
				width: 100%;

				@include md {
					left: -85px;
					top: 0;
					width: 1px;
					height: 100%;
				}

				@include lg {
					left: -105px;
				}

				@include xl {
					left: -40px;
				}

				@include xxl {
					left: -65px;
				}
			}
		}
	}

	&__materials-button {
		position: relative;
		border: none;
		text-align: left;
		outline: none;
		width: 170px;
		height: 300px;

		&:disabled {
			user-select: none;

			+ ^^&__materials-button-copy {
				user-select: none;
			}
		}
	}

	&__materials-src {
		size: 100%;
		object-fit: cover;
		object-position: top center;
		opacity: 0.5;
		transition: opacity 0.4s ease;

		^&__materials-button:hover &,
		^&__materials-button.added & {
			opacity: 1;
		}
	}

	&__materials-check {
		display: none;
		@include font('bold', 13px, 17px, 0px);
		border-radius: 500px;
		color: #26ba4d;
		position: absolute;
		right: -8px;
		bottom: -8px;

		&:not(:empty) {
			background: #e2ffea;
			border: 2px solid #26ba4d;
		}

		&:empty {
			size: 28px;
			padding: 0;

			@include lg {
				size: 32px;
			}

			&::after {
				font-family: 'acv-icons';
				content: '$acv-icons-var-checkmark';
				display: inline-block;
				position: absolute;
				font-size: 1.75rem;

				@include lg {
					font-size: 2rem;
				}

				.is-invalid& {
					content: '$acv-icons-var-cross';
				}
			}
		}

		^&__materials-button.added & {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__materials-button-copy {
		@include font('regular', 13px, 16px, normal);
		color: $grey-1;
		margin-top: 8px;
	}

	&__materials-info {
		margin-top: 40px;
		color: $signal-red;
		display: block;
		text-align: center;
	}

	//personaldata
	&__personaldata-info {
		@include font('regular', 13px, 16px, normal);
		color: $grey-1;
	}
	//
	&__textimage {
		margin-bottom: 60px;
	}

	&__textimage-headline {
		@include font('headline', 21px, 25px, normal);
		margin-bottom: 20px;
	}

	&__textimage-img {
		margin: 30px auto 0 auto;

		@include md {
			width: 313px;
			margin: 0 0 50px 0;
		}
	}

	.user-info-block__list {
		padding-bottom: 30px;
	}

	// Help Desk
	&__accordion {
		margin-top: 25px;
		margin-bottom: 25px;

		.rte-copy ul,
		.rte-copy li {
			margin-top: 5px;
		}

		+ .tour-guide__section {
			margin-top: 60px;
		}

		.accordion__item-title::before,
		&-tips-headline::before {
			content: '';
			background-image: svg-load('Icon-tipp.svg', fill: white);
			background-size: contain;
			background-repeat: no-repeat;
			display: inline-block;
			vertical-align: middle;
			margin-right: 12px;
			width: 15px;
			height: 24px;
		}

		.accordion__item {
			box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
		}

		.accordion__item-title {
			color: $orange;

			&::before {
				transform: translateY(-2px);
			}
		}

		.accordion__item-bar {
			border-top: 0;
			box-shadow: none;
		}

		.accordion__item-arrow {
			color: $orange;
		}
	}

	&__accordion-tips {
		margin-top: 25px;
		padding-top: 25px;
		position: relative;

		&::before {
			content: '';
			width: calc(100% + 200px);
			height: 1px;
			background: $grey-4;
			position: absolute;
			top: 0;
			left: -100px;
		}
	}

	&__accordion-tips-headline {
		color: $orange;
		@include font('bold', 15px, 19px, normal);
		display: inline-block;
		margin-bottom: 10px;
	}

	//User Info
	&__user-info {
		padding-bottom: 0 !important;
		margin-bottom: 50px;
	}

	&__loading-container {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100px;

		&--hide-after-load {
			&:not(:last-child) {
				display: none;
			}
		}
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}
