.login {
	@include <sm {
		margin-left: calc($gutter / -2);
		margin-right: calc($gutter / -2);
	}
	&__grid {
		display: flex !important;
		flex-direction: column;

		@include lg {
			flex-direction: row;
			min-height: 810px;
		}
	}

	&__image-container {
		display: none;
		height: 100%;
		position: relative;

		@include lg {
			display: block;
		}
	}

	&__image-holder {
		overflow: hidden;
		position: absolute;
		width: calc(100% + $gutter);
		height: 100%;
		left: 0;
		top: 0;
	}

	&__teaser-image {
		position: absolute;
		size: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__teaser-logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 240px;
		height: auto;
	}

	&__form {
		background-color: white;
		box-shadow: $card-shadow;
		padding: 80px calc($gutter / 2);

		@include sm {
			padding: 80px 32px;
		}

		@include lg {
			height: 100%;
		}

		@include xl {
			padding: 80px 64px;
		}
	}

	&__headline {
		text-align: center;
		@include font('', 24px, 26px, normal);
	}

	&__copy {
		@include <md {
			@include font('', 15px, 20px, normal);
		}

		&:not(:first-child) {
			margin-top: 4px;
		}
		p {
			color: $grey-1;
		}
		strong {
			color: $typo-color;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
		gap: 30px;
	}

	&__password-link {
		margin: 0 auto;

		@include lg {
			margin-right: 0;
		}
	}

	&__primary-btn {
		align-self: stretch;
	}

	&__footer {
		color: $grey-2;
		strong,
		b {
			color: $grey-1;
		}
	}
}
