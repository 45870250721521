.breadcrumb {
	position: absolute;
	bottom: 100%;
	z-index: 1;
	padding-bottom: 7px;
	display: none;

	@include sm {
		display: block;
	}

	li&__item {
		@include font('regular', 13px, 16px, 0);
		color: white;
		display: inline-block;

		&:not(:last-child)::after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 8px;
			height: 5px;
			margin-right: 5px;
			background-image: svg-load('breadcrumb-arrow.svg', fill: white);
			background-repeat: no-repeat;
		}
	}

	a&__item {
		background-image: none;
		margin-right: 10px;
		padding: 2px 0;

		&:hover {
			color: $white;
			background-image: linear-gradient($white, $white);
			background-size: 1px 1px;
		}
	}
}
