.eventBack {
	&__wrapper {
		z-index: 3;
		padding: 0 0 50px 0;

		&:before {
			box-shadow: none;
		}
	}

	&__link-wrapper {
		display: flex;
		justify-content: center;
	}
}
