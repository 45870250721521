// main .frame-type-acvcontent_headermedium,
// main .frame-type-acvcontent_headermaxi {
// 	& + .frame {
// 		margin-top: 0;

// 		@include sm {
// 			margin-top: -67px;
// 		}
// 	}
// }

.header {
	&:empty {
		width: 100%;
		height: 70px;

		@include lg {
			height: 160px;
		}
	}

	&:not(:empty) + .content:not(.content--sustainability) {
		@include sm {
			margin-top: -70px;
		}
	}
}
