.ASD-textarea {
	position: relative;
	height: 107px;

	background-color: $anthracite-5;
	color: $anthracite;
	border: 0;
	border-bottom: 1px solid $anthracite-40;

	border-radius: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

	transition: border-color 250ms ease;

	padding: 22px 10px 0;

	caret-color: $anthracite;

	width: 100%;
	margin-bottom: 0 !important;

	&:-moz-ui-invalid {
		box-shadow: initial;
	}

	&.is-invalid {
		border-color: $signal-red;
	}

	&:focus {
		outline: none;
		border-color: $anthracite;
	}

	&::placeholder {
		color: $anthracite-80;

		^&[data-is-date-input]& {
			text-transform: uppercase;
		}
	}

	&[disabled] {
		color: $anthracite-80;

		&:placeholder-shown,
		&.is-filled {
			color: $anthracite-80;
		}
	}

	&__error {
		display: none;
		padding: 3px 10px 0;
		@include font('regular', 14px, 16px, normal);

		max-width: 100%;

		color: $signal-red;
		^&.is-invalid:not(:focus) ~ & {
			display: block;
		}
	}

	&.is-invalid:not(:focus) ~ .ASD-input__error {
		display: block;
	}

	&__char-counter {
		font-size: 14px;
		position: absolute;
		right: 0;
		top: 107px;
		padding-top: 3px;

		&--overMax {
			color: $signal-red;
		}

		&--max {
		}
	}
}
