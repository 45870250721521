.header-medium {
	display: flex;
	align-items: center;
	min-height: 370px;
	padding: 90px 0;

	@include sm {
		min-height: 440px;
		padding: 130px 0;
	}

	@include <md {
		&--small-on-mobile {
			min-height: 180px !important;
			padding: 35px 0;

			@include sm {
				padding: 95px 0;
			}
		}
	}

	&.is-mini {
		min-height: 240px;

		@include sm {
			min-height: 400px;
		}
	}

	&__src {
		object-fit: cover;
		z-index: 0;
		pointer-events: none;
	}

	&__text-wrapper {
		position: relative;
		z-index: 1;
		transform: translateY(35px);
		text-align: center;

		@include sm {
			transform: translateY(10px);
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&__title {
		@include font('headline', 35px, 41px, 0);
		color: white;
		text-align: center;

		^&.has-image & {
			text-shadow: $text-shadow;
		}

		@include sm {
			@include font('headline', 54px, 60px, 0);
		}
	}

	&__copy {
		&:not(:first-child) {
			margin-top: 40px;
		}
		text-align: center;

		& > p,
		ul {
			color: white;
			@include font('regular', 18px, 26px, 0);

			@include sm {
				@include font('regular', 21px, 26px, 0);
			}

			^^&.has-image & {
				text-shadow: $text-shadow;
			}
		}
	}

	ul {
		margin-top: 30px;
	}
}
