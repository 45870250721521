.orangeLine {
	height: 6px;
	transform: scaleX(0.19);
	transform-origin: left;
	background-color: $orange;
	transition: transform 0.3s ease-out;

	&--absolute {
		position: absolute;
		left: 0;
		top: 0;
	}

	&--offset {
		@include sm {
			left: 15px;
		}
	}

	&.is-static {
		transform: initial;
		width: 77px;
	}

	*:hover > &:not(.is-static) {
		transform: scaleX(1);
	}
}
