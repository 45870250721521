.tree-plant-form {
	@include md {
		position: relative;
	}

	&__void {
		@include md {
			min-height: 550px;
		}
	}

	&__steps-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__step {
		@include lg {
			margin-bottom: 60px;
		}
	}

	&__step-indicator {
		@include <lg {
			display: none !important;
		}
	}

	&__action-bar {
		border-top: none;
		padding-top: 0;
		@include lg {
			margin-top: auto;
		}
	}

	&__intro-copy {
		text-align: center;
		@include font('regular', 18px, 26px, 0);

		&--left {
			text-align: left;
		}

		&--add-space {
			margin-bottom: 10px;
		}
	}

	&__loading-container {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100px;

		&--hide-after-load {
			&:not(:last-child) {
				display: none;
			}
		}
	}

	&__privacy-info-rte {
		font-size: 13px;
		line-height: 16px;
		color: $grey-1;
		margin-top: 17px;

		a {
			padding: 2px 0;
			background-size: 1px 1px;
		}
	}

	&__accordion {
		margin-top: 30px;
		@include <sm {
			width: 100vw;
			margin-left: -15px;
		}
	}

	&__footer {
		padding: 42px 0px 7px;
	}

	&__footer-copy {
		p {
			font-size: 14px;
		}
	}

	&__start-image {
		max-width: 88%;
		margin: 0 auto;

		@include md {
			max-width: 50%;
		}
	}

	&__card-image {
		max-width: 100%;
		margin: 0 auto;

		@include md {
			max-width: 70%;
		}
	}

	&__response-icon {
		&--error {
			color: $signal-red;
			border: 6px solid $signal-red;
			border-radius: 50%;
			&:before {
				font-size: 30px;
			}
		}
	}

	&__tree-image {
		position: relative;
		width: 100%;
		height: auto;
	}

	&__years-wrapper {
		@include font('bold', 25px, 20px, normal);
		position: absolute;
		left: 50%;
		top: 42%;
		transform: translate(-50%, -50%);
		z-index: 1;
		color: White;
		text-align: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
		size: 150px;
		padding-bottom: 25px;

		@include md {
			@include font('', 30px, 20px, normal);
			size: 200px;
			padding-bottom: 30px;
		}
	}

	&__years-slot {
		& + & {
			@include md {
				margin-top: 5px;
			}
		}
	}

	&__coupon-image {
		width: 100%;
		height: auto;
		margin: 0 auto;
		@include md {
			max-width: 50%;
		}
	}

	&__success-link-row {
		margin-top: 66px;
		padding-top: 40px;
	}

	&__success-link-head {
		margin-bottom: 45px;
	}

	&__success-link-wrapper {
		display: flex;
		justify-content: center;
	}
}
