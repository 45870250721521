.smallBanderole {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		^&--white & {
			padding: 50px 0;
			background-color: white;
			box-shadow: $card-shadow;

			.wrapper-white &,
			[data-style='white'] & {
				box-shadow: none;
			}
		}
	}

	&__wrapper-inner {
		width: 100%;
	}

	.wrapper-white &,
	[data-style='white'] & {
		padding-top: initial;
		padding-bottom: initial;
	}

	@include md {
		flex-direction: row;
	}

	&__textWrapper {
		margin-bottom: 30px;
		text-align: center;

		padding: 0 30px;

		@include sm {
			padding: 0 15px;
		}

		@include md {
			padding: 0;
			margin-bottom: 0;
			text-align: left;
		}
	}

	&__headline {
		@include font('headline', 20px, 25px, 0);
		text-align: center;
		margin-bottom: 20px;
		color: white;

		@include md {
			@include font('headline', 24px, 28px, 0);
			text-align: left;
		}

		&:nth-last-child(1) {
			margin-bottom: initial;
		}
	}

	&__copy {
		color: white;
	}

	&__btnWrapper {
		display: flex;
		justify-content: center;

		@include md {
			justify-content: flex-end;
		}
	}

	.btn--primary {
		.wrapper-white &,
		[data-style='white'] &,
		^&--white& {
			@include btn-primary-blue;
		}
	}

	.wrapper-white &__headline,
	[data-style='white'] &__headline,
	&--white &__headline {
		color: $darkblue;
	}

	.wrapper-white &__copy,
	[data-style='white'] &__copy,
	&--white &__copy {
		color: $grey-1;
	}
}

.u-va-middle {
	vertical-align: middle !important;
}
