.bulli-page {
	// padding-top: 110px;

	&__breakGrid {
		@include <sm {
			padding: 0 !important;
		}
	}

	//HEADER
	&__header {
		margin-top: -170px;
		margin-bottom: 60px;

		@include sm {
			margin-bottom: 80px;
		}

		@include md {
			margin-bottom: 90px;
		}
	}

	&__header-container {
		position: relative;
		z-index: 2;
		top: 100px;
		width: 120%;
		left: -10%;

		@include md {
			width: 100%;
			left: 0;
		}
	}

	&__header-svg-wrapper {
		margin-top: -2px;
		width: 100%;
		z-index: 1;
		position: relative;
		max-height: 884px;

		@include sm {
			width: auto;
		}

		@include md {
			margin-top: -2px;
		}

		@include xl {
			margin-top: -1px;
		}

		@include xxl {
			margin-top: 0px;
		}

		&::after {
			content: '';
			display: block;
			width: 100%;
			padding-bottom: calc(884 / 1710 * 100%);
		}

		svg {
			position: absolute;
			height: 100%;
			width: 100%;
			max-width: 1710px;
			left: 50%;
			transform: translateX(-50%);
		}

		.hero-animation__heart-group {
			display: none;
		}

		@media (hover: hover) {
			.hero-animation__heart-group {
				display: block;
			}
		}
		@media (hover: none) {
			.hero-animation__heart-group {
				display: block;
			}
		}
	}

	&__header-bulli {
		width: 100%;
		height: auto;
		max-width: 1068px;
	}

	&__header-bulli-wrapper {
		position: absolute;
		width: 60%;
		top: 46.8%;
		max-width: 1048px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;

		@include sm {
			top: 47.1%;
		}

		@include md {
			top: 46.9%;
		}

		@include xxl {
			top: 46.7%;
		}

		&-withdeko {
			position: absolute;
			width: 62%;
			top: 46%;
			left: 50%;
			transform: translateX(-50%);

			img {
				visibility: hidden;
			}

			&::before {
				content: '';
				width: 100vw;
				height: 70vw;
				position: absolute;
				bottom: 21.3vw;
				left: 50%;
				transform: translateX(-50%);
				display: block;
				background: radial-gradient(circle, rgba(245, 246, 243, 1) 0%, rgba(217, 220, 209, 1) 50%, rgba(217, 220, 209, 1) 100%);
				z-index: -1;

				@include sm {
					bottom: 21.1vw;
					height: 60vw;
				}

				@include md {
					bottom: 17.7vw;
					height: 53vw;
				}

				@include lg {
					bottom: 62%;
					height: 53vw;
				}

				@include xl {
					height: 100vw;
				}
			}
		}
	}

	&__header-ie-image {
		width: 100%;
	}

	//COUNTDOWN
	&__countdown {
		position: absolute;
		width: auto;
		height: auto;
		z-index: 1;
		flex-direction: row;
		display: inline-flex;
		justify-content: space-around;
		top: 31%;
		padding-left: 8vw;
		left: 50%;
		transform: translateX(-50%);

		@include md {
			padding-left: 5vw;
		}

		@include lg {
			top: 30%;
		}

		@include xl {
			padding-left: 45px;
		}
	}

	&__countdown-bottom {
		position: absolute;
		width: 100%;
		height: auto;
		z-index: 1;
		flex-direction: row;
		display: inline-flex;
		justify-content: space-around;
		top: 100%;
	}

	&__countdown-section {
		display: flex;
		flex-direction: column;
		align-items: center;

		&:not(:last-child) {
			margin-right: 2vw;

			@include xxl {
				margin-right: 26px;
			}
		}
		&:not(:first-child) {
			margin-left: 2vw;

			@include xxl {
				margin-left: 26px;
			}
		}
	}

	@keyframes dotBlink {
		50% {
			opacity: 1;
		}
		0% {
			opacity: 0;
		}
	}

	&__countdown-dot {
		// animation: dotBlink 1s linear infinite;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-self: center;
		height: 3.5vw;
	}

	&__countdown-dot::before,
	&__countdown-dot::after {
		content: '';
		border-radius: 10px;
		background-color: $darkblue;
		height: 0.83vw;
		width: 0.81vw;

		max-height: 13px;
		max-width: 11px;
	}

	&__countdown-date {
		color: $orange;
		@include font('headline', 6vw, 4vw, 0);

		@include xxl {
			font-size: 125px;
			line-height: 95px;
		}
	}

	&__countdown-copy {
		color: $darkblue;
		@include font('headline', 1.5vw, 1.5vw, 0);
		position: absolute;
		top: 100%;
		padding-top: 6px;

		@include xxl {
			font-size: 23px;
			line-height: 23px;
			// padding-top: 15px;
		}
	}

	//INTRO
	&_intro {
	}

	&__intro-wrapper {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-top: 60px;
	}

	&__intro-headline-wrapper {
		text-align: center;
	}

	&__intro-headline {
		font-size: 25px;
		line-height: 25px;
		display: inline-block;
		position: relative;
		color: $white;
		margin-bottom: 34px;

		@include sm {
			font-size: 35px;
			line-height: 35px;
		}

		@include lg {
			font-size: 64px;
			line-height: 66px;
		}

		&::after {
			content: '';
			width: 100%;
			height: 5px;
			background: #ffffff;
			position: absolute;
			display: block;
			left: 0;
			top: 28px;

			@include sm {
				top: 38px;
				height: 7px;
			}

			@include lg {
				top: 68px;
			}
		}
	}

	&__intro-copy {
		margin: 0 auto;
		color: $white;
		max-width: 450px;
		width: 100%;
		font-size: 18px;
		line-height: 25px;
		text-align: center;

		@include md {
			padding-top: 20px;
			font-size: 30px;
			max-width: 800px;
			line-height: 30px;
		}

		@include lg {
			padding-top: 45px;
			font-size: 38px;
			line-height: 48px;
			max-width: 1000px;
		}
	}

	&__intro-svg {
		margin-top: -30px;
		width: 100%;

		@include xl {
			margin-top: -100px;
			width: 1200px;
			margin-left: -30px;
		}

		@include xxl {
			width: 1400px;
			margin-left: -78px;
		}
	}

	//CONTEST
	&__contest {
		position: relative;
	}

	&__contest-image-wrapper {
		background-color: $white;
		padding: 10px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

		@include md {
			padding: 22px;
		}
	}

	&__contest-image {
		width: 100%;
		height: 100%;
		max-height: 900px;
		object-fit: cover;
	}

	&__contest-wrapper {
		background-color: $white;
		padding: 30px 10px 20px 10px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
		margin-top: 20px;
		position: relative;
		z-index: 2;

		@include md {
			margin-top: 32px;
			padding: 58px 22px 22px 22px;
		}
	}

	&__contest-subheadline {
		@include font('headline', 13px, 15px, 1.8px);
		text-transform: uppercase;
		margin-bottom: 20px;
		color: $grey-1;
		text-align: center;

		@include sm {
			font-size: 16px;
			line-height: 18px;
		}
	}

	&__contest-headline {
		@include font('headline', 28px, 35px, 1px);
		max-width: 915px;
		margin: 0 auto;
		margin-bottom: 33px;
		text-align: center;

		@include sm {
			@include font('headline', 42px, 50px, 1px);
			letter-spacing: 3px;
		}
	}

	&__contest-copy {
		@include font('regular', 18px, 26px, 0);
		max-width: 750px;
		text-align: center;
		margin: 16px auto 30px auto;
		color: $grey-1;

		@include sm {
			@include font('regular', 21px, 26px, 0);
		}

		a:not(.btn) {
			background-image: linear-gradient($orange, $orange);
			&:hover {
				background-image: linear-gradient($orange, $orange);
				color: $orange;
			}
		}
	}

	&__contest-video-wrapper {
		margin-top: 12px;

		height: 250px;

		@include sm {
			height: 350px;
		}

		@include md {
			height: 500px;
		}

		@include xl {
			height: 678px;
		}
	}

	&__contest-video {
		width: 100%;
		height: 100%;
	}

	&__contest-svg {
		position: absolute !important;
		top: 190px;
		right: -315px;
		width: 1900px;
		height: 100%;
		z-index: -1;
		display: none;

		@include xxl {
			display: block;
		}
	}

	//STEPS
	&__steps {
		margin-top: 50px;
	}

	&__steps-headline {
		text-align: center;
		font-size: 32px;
		letter-spacing: 1.3px;
		color: $white;
	}

	&__steps-copy {
		color: $white;
		margin: 0 auto;
		max-width: 1000px;
		font-size: 18px;
		line-height: 26px;
		text-align: center;

		@include sm {
			font-size: 38px;
			line-height: 48px;
		}
	}

	//KLICK
	&__click {
		margin-top: 50px;
		margin-bottom: 50px;
	}

	&__click-headline {
		text-align: center;
		font-size: 28px;
		letter-spacing: 1.3px;
		color: $white;

		@include sm {
			font-size: 32px;
		}
	}

	&__click-subheadline {
		text-align: center;
		font-size: 28px;
		letter-spacing: 1.3px;
		color: $white;

		@include sm {
			font-size: 32px;
		}
	}

	&__click-copy {
		color: $white;
		margin: 0 auto;
		font-size: 18px;
		line-height: 26px;
		text-align: center;

		@include sm {
			font-size: 32px;
			line-height: 48px;
		}
	}

	//TEAM
	&__team {
	}

	&__team-infobox {
		background-color: $darkblue;
		margin-bottom: 25px;
		padding: 15px;
	}

	&__team-infobox-inner {
		color: $white;
		margin: 0 auto;
		max-width: 1000px;
		font-size: 18px;
		line-height: 26px;
		text-align: center;

		@include sm {
			font-size: 38px;
			line-height: 48px;
		}
	}

	&__team-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: -30px;

		@include lg {
			margin-bottom: 0px;
		}
	}

	&__team-list-item {
		width: 100%;
		position: relative;
		margin-bottom: 30px;

		@include sm {
			width: calc(50% - 15px);

			&:nth-child(odd) {
				margin-right: 30px;
			}
		}

		@include lg {
			width: calc(33.33% - 20px);

			&:nth-child(odd) {
				margin-right: 0;
			}
		}
	}

	&__team-like {
		position: absolute;
		top: 15px;
		right: 15px;
		background-color: $orange;
		height: 60px;
		width: 60px;
		border-radius: 30px;
		border-bottom-right-radius: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		transition: width 0.5s ease;
		overflow: hidden;
		border: none;
		outline: none;

		&.is-active {
			width: 182px;
		}
	}

	&__team-like-icon {
		margin-top: 5px;
		margin-left: 10px;
		font-size: 32px;
		color: $white;
	}

	&__team-like-count {
		color: $white;
		font-size: 20px;
		white-space: nowrap;
	}

	&__team-image-container {
		&::after {
			content: '';
			display: block;
			padding-top: 80%;

			@include sm {
				padding-top: 100%;
			}
		}
	}

	&__team-image {
		height: 85%;
		width: 100%;
		object-fit: cover;
		position: absolute;
		z-index: -1;
	}

	&__team-info {
		display: flex;
		flex-direction: column;
		background-color: $darkblue;
		min-height: 82px;

		&--center {
			justify-content: center;
		}
	}

	&__team-name {
		color: $white;
		text-align: center;
		font-size: 21px;
		padding: 8px;
		letter-spacing: -0.2px;
	}

	&__team-btn {
		font-size: 21px;
		color: $orange;
		border: none;
		background-color: transparent;
		margin: 0 auto;
		padding-bottom: 10px;
		padding-right: 35px;
		display: inline-block;
		position: relative;
		width: 150px;

		&:hover::after {
			right: -2px;
		}

		&::after {
			font-size: 15px;
			position: absolute;
			right: 2px;
			top: 5px;
			transition: right 0.2s ease;
		}
	}

	//VIDEO
	&__video-modal {
		position: fixed;
		flex-flow: column nowrap;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: rgba($darkblue, 0.8);
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__video-modal-inner {
		padding-top: 56.25%;
		transform: scale3d(0, 0, 1);
		transition: transform 0.6s;

		&.is-visible {
			transform: scale3d(1, 1, 1);
		}
	}

	//LASTWORD
	&__lastword {
		padding-bottom: 15vw;

		@include sm {
			padding-bottom: 20vw;
		}
		@include md {
			padding-bottom: 150px;
		}
		@include lg {
			padding-bottom: 200px;
		}
	}

	&__lastword-wrapper {
		background-color: $white;
		padding: 42px 30px 132px 30px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
		position: relative;

		@include sm {
			padding: 42px 30px 200px 30px;
		}
		@include md {
			padding: 42px 30px 220px 30px;
		}
		@include lg {
			padding: 42px 30px 254px 30px;
		}
	}

	&__lastword-copy {
		@include font('headline', 28px, 36px, 3px);
		margin: 0 auto -20px auto;
		text-align: center;
		color: $darkblue;
		max-width: 1000px;
		padding: 0 25px;

		@include sm {
			@include font('headline', 38px, 46px, 3px);
		}
	}

	&__lastword-image {
		width: 100%;
		object-fit: contain;
		position: absolute;
		left: 0;
		top: calc(100% - 125px);
		height: 250px;
		padding: 0 30px;

		@include sm {
			top: calc(100% - 180px);
			height: 360px;
		}

		@include md {
			top: calc(100% - 227px);
			height: 454px;
		}
	}

	//SVG-ANIMATIONS
	&__svg {
		position: relative;
	}

	&__svg::after {
		content: '';
		display: block;
		width: 100%;
	}

	&__intro-svg::after {
		padding-bottom: calc(514 / 1200 * 100%);
	}

	&__steps-svg-1::after {
		padding-bottom: calc(532 / 1100 * 100%);
	}

	&__steps-svg-2::after {
		padding-bottom: calc(825 / 1200 * 100%);
	}

	&__steps-svg-3::after {
		padding-bottom: calc(719 / 1200 * 100%);
	}

	&__click-svg {
		padding-bottom: calc(859 / 1200 * 100%);

		@include md {
			margin-top: -15px;
			margin-bottom: -45px;
		}

		@include lg {
			margin-top: -30px;
			margin-bottom: -90px;
		}
	}

	&__line-svg::after {
		padding-bottom: calc(220 / 1200 * 100%);
	}

	&__teams-svg::after {
		padding-bottom: calc(406 / 1200 * 100%);
	}

	&__svg svg {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}
