.tooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;
	white-space: wrap;
	width: 18px;
	height: 13px;
	transform: translateY(1px);

	&.tourGuide-offset {
		height: 12px;
		transform: translateY(-2px);
	}

	label > & {
		margin-left: 7px;
	}

	&.active {
		z-index: 200;
	}

	&.use-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	&__icon {
		position: absolute;
		font-size: 18px;
		color: $grey-1;

		^&.no-icon & {
			display: none;
		}
	}

	&__inline-text {
		color: $darkblue;
		border: 0;
		background: linear-gradient(#ffffff, #ffffff);
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;
		background-image: linear-gradient($orange, $orange);
		padding-bottom: 1px;
		text-decoration: none;
	}

	&__wrapper {
		position: absolute;
		display: flex;
		justify-content: center;
		visibility: hidden;
		transform: translateY(50%);
		bottom: 50%;
		opacity: 0;
		z-index: 100;

		@include sm {
			left: 30px !important;
			^&.use-wrapper & {
				left: calc(100% + 30px) !important;
			}
		}

		@include <sm {
			width: calc(100vw - 30px);
			top: 50%;
			left: 50%;
			bottom: unset;
			transform: translate3d(15px, -50%, 0) !important;
		}

		^&--auto-width & {
			width: auto;
		}

		^&--no-wrap & {
			width: auto;
			white-space: nowrap;
		}

		@include sm {
			^&--up &,
			^&--down & {
				left: 50% !important;
				transform: translateX(-50%);
			}

			^&--up & {
				bottom: calc(100% + 15px);
			}

			^&--down & {
				top: calc(100% + 15px);
				bottom: initial;
			}
		}

		^&.active & {
			visibility: visible;
			opacity: 1;
		}
	}

	&__text {
		position: relative;
		display: flex;

		flex-direction: column-reverse;
		width: 100%;
		max-width: 332px;

		background-color: $darkblue;
		color: $white;
		border-radius: 3px;
		padding: 28px 12px 12px 12px;

		@include font('regular', 15px, 19px, 0px);
		text-transform: none;
		hyphens: auto;
		text-align: center;
		text-wrap: balance;

		@include sm {
			flex-direction: column;
			@include font('', 13px, 16px, 0px);
			width: 254px;
			text-align: left;
		}

		.rte-orange {
			font-weight: bold;
			color: $orange;
		}

		p + p {
			margin-top: 10px;
		}

		a {
			&:hover {
				color: inherit;
				text-decoration: none;
			}
			text-decoration: underline;
			background: none;
		}

		&:before {
			content: '$acv-icons-var-cross';
			position: absolute;
			right: 13px;
			top: 8px;
			@include acv-icons-mixin();
			margin-right: unset;
			font-size: 8px;
			color: white;
			z-index: 10;
			opacity: 0.5;
		}

		^&.white &:before {
			color: black;
		}

		&:after {
			content: '';
			position: absolute;

			top: calc(50% - 5px);
			right: 100%;
			margin-left: -10px;
			border-width: 5px;
			border-right-width: 9px;
			border-style: solid;
			border-color: transparent $darkblue transparent transparent;
			^^&.white & {
				border-color: transparent $white transparent transparent;
			}

			^^&--up &,
			^^&--down & {
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				margin-left: 0;
				right: initial;
				left: calc(50% - 5px);
			}

			^^&--up & {
				border-top: 9px solid $darkblue;
				top: 100%;
			}

			^^&--down & {
				border-bottom: 9px solid $darkblue;
				bottom: 100%;
				top: initial;
			}
		}

		^&.white & {
			background-color: white;
			color: $typo-color;
			box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
		}

		@include <sm {
			&::after {
				content: unset;
			}
		}
	}

	&__text.rte-copy {
		p + p {
			margin-top: 8px;
		}
	}

	&__text-small-left {
		@include <lg {
			width: 260px;
			bottom: 50%;
			left: -275px;

			&::after {
				top: calc(50% - 10px);
				right: -20px;
				margin-left: -10px;
				border-width: 10px;
				border-style: solid;
				border-color: transparent transparent transparent $darkblue;
				^^&.white & {
					border-color: transparent transparent transparent $white;
				}
			}
		}

		@include <sm {
			width: 50vw;
			left: calc(-50vw - 20px);
		}
	}

	&__text-small-bottom {
		@media (max-width: 435px) {
			width: 160px;
			top: -12px;
			left: -80px;
			bottom: auto;

			&::after {
				top: -20px;
				right: calc(50% - 15px);
				margin-left: -10px;
				border-width: 10px;
				border-style: solid;
				border-color: transparent transparent $darkblue transparent;

				^^&.white & {
					border-color: transparent transparent $white transparent;
				}
			}
		}
	}

	&__text-long {
		width: 170px;

		@include md {
			width: 300px;
		}

		^&__text-small-left& {
			@include <lg {
				left: calc(-300px - 20px);
			}
			@include <md {
				left: calc(-170px - 20px);
			}
		}
	}

	&__image {
		width: 100%;
		height: auto;
		margin-bottom: 16px;

		@include sm {
			margin-bottom: unset;
			margin-top: 12px;
		}
	}
}
