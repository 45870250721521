.ASD-radio {
	appearance: none;
	position: relative;
	flex: 0 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;

	border: 1.5px solid $anthracite-60;
	border-radius: 50%;

	transition: border-color 250ms ease, background-color 250ms ease;

	size: 22px;

	@include md {
		size: 28px;
	}

	& + & {
		margin-top: 15px;
	}

	&:not([disabled]) {
		cursor: pointer;
	}

	&--big {
		size: 28px;
	}

	&.is-invalid {
		border-color: $signal-red;
	}

	&:before {
		content: '';
		display: block;
		size: 14px;
		background-color: $green;
		border-radius: 50%;
		opacity: 0;
		transition: opacity 250ms ease;

		@include md {
			size: 18px;
		}
	}

	&:checked {
		&:before {
			opacity: 1;
		}
	}

	&:focus,
	&:active {
		outline: none;
		border-color: $anthracite;
	}

	&[disabled] {
		opacity: 0.5;
	}

	&__label {
		position: relative;
		display: flex;
		gap: 10px;
		color: $anthracite;
		@include font('regular', 16px, 19px, normal);

		& + & {
			margin-top: 15px;
		}

		&.has-divider + .has-divider {
			margin-top: 34px;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: -21px;
				width: 100%;
				display: block;
				height: 1px;
				background-color: $anthracite-20;
			}
		}
	}

	&__label-text {
		padding-top: 0px;

		@include md {
			padding-top: 4px;
		}

		^&__label--big & {
			padding-top: 4px;
		}
	}
}
