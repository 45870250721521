.bigIcon {
	position: relative;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	size: 96px;
	display: block;
	&:before,
	&:after {
		content: '';
		border-radius: 50%;
		display: block;
		position: absolute;
	}

	&:before {
		background: linear-gradient(90deg, $orange 0%, #f9b000 100%);
		opacity: 0.2;
		size: 100%;
		left: 0;
		top: 0;
	}
	&:after {
		background: linear-gradient(0deg, $orange 0%, #f9b000 100%);
		size: calc(100% - 20px);
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&--left {
		margin: 0;
	}

	&--green {
		&:before {
			background: linear-gradient(90deg, $signal-green 0%, #71d68d 100%);
		}
		&:after {
			background: linear-gradient(0deg, $signal-green 0%, #71d68d 100%);
		}
	}

	&--red {
		&:before {
			background: linear-gradient(90deg, #e21e36 0%, #eb6273 100%);
		}
		&:after {
			background: linear-gradient(0deg, #e21e36 0%, #eb6273 100%);
		}
	}

	&--orange {
		&:before {
			background: linear-gradient(90deg, #f07814 0%, #f9b000 100%);
		}
		&:after {
			background: linear-gradient(0deg, #f07814 0%, #f9b000 100%);
		}
	}

	i {
		z-index: 1;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		&:before {
			font-size: 36px;
			margin: 0 !important;
			color: white;
		}

		&.acv-icons--envelope-send {
			transform: translate(-50%, -50%) translateY(3px);
		}

		&.acv-icons--checkmark-simple {
			transform: translate(-50%, -50%) translate(-2px 2px);
			&:before {
				font-size: 18px;
			}
		}

		&.acv-icons--exclamation {
			transform: translate(-50%, -50%) translateY(1px);
			&:before {
				font-size: 32px;
			}
		}
	}
}
