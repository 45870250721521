$slideHeight: 300px;
$slideWidth: 60px;

.frame-type-acvcontent_marketingbar:not(:empty)+.frame-type-acvcontent_cookiebar>.cookiebar {
	box-shadow: none;
}

.cookiebar {
	position: fixed;
	width: 100%;
	bottom: 0;
	background-color: white;
	z-index: 9999;
	display: none;
	box-shadow: 0 -7px 7px 0 rgba(0, 0, 0, 0.2);

	&__noscript & {
		display: block !important;
	}

	&-checkbox__title {
		padding-bottom: 15px;
		padding-left: 45px;
	}

	&__wrapper {
		padding: 20px 0 10px;
		display: flex;
		flex-direction: column;

		@include md {
			padding: 40px 0;
			flex-direction: row;
			align-items: center;
		}
	}

	&__copy {
		@include font('regular', 13px, 20px, 0);
		color: $grey-1;
		padding-bottom: 20px;

		@include md {
			padding-right: 160px;
			padding-bottom: 0px;
			@include font('regular', 15px, 20px, 0);
		}

		h2 {
			color: $darkblue;
		}

		a:not(.btn) {
			background-image: linear-gradient($orange, $orange);

			&:hover {
				background-image: linear-gradient($orange, $orange);
				color: $orange;
			}
		}

	}

	&__form {
		width: auto;

		@include md {
			display: flex;
		}
	}

	&__btn {
		display: inline-block;
		padding: 8px 14px !important;
		height: 45px;

		&+& {
			margin-left: 30px !important;
		}
	}

	&--open {
		display: block;
	}

	&--closed {
		visibility: visible;
		bottom: -380px;
		transition: bottom 1s ease-in-out;
	}
}

.cookiebar-overlay {
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	z-index: 10000;
	height: 100%;

	&--open {
		display: inline-flex;
	}

	&__background {
		position: relative;
		top: 0;
		left: 0;
		height: 100%;
		width: 100vw;
		background-color: $darkblue;
		opacity: 0.6;
	}

	&__wrapper {
		position: absolute;
		top: 30px;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%) translateZ(0);
		background-color: white;
		padding: 20px 15px;
		height: auto;
		width: 90%;
		min-width: 332px;
		overflow-y: auto;

		@include sm {
			padding: 32px 42px;
		}

		@include md {
			padding: 56px 70px 56px 70px;
			margin-bottom: 0px;
			overflow-y: hidden;
		}

		@include xl {
			top: 50%;
			transform: translateX(-50%) translateY(-50%) translateZ(0);
			height: 694px;
			max-height: 694px;
			max-width: 1096px;
		}
	}

	&__head {
		padding-bottom: 19px;
	}

	&__headline {
		@include font('headline', 28px, 36px, 0);
		color: $orange;
		padding-bottom: 8px;
	}

	&__copy {
		@include font('bold', 16px, 25px, 0);
		color: $darkblue;
		padding-bottom: 8px;
	}

	&__container-left {
		width: 100%;
		display: inline-flex;
		height: 400px;

		@media only screen and (min-width: 387px) {
			height: 380px;
		}

		@include sm {
			padding-bottom: 20px;
			height: 400px;
		}

		@include md {
			height: 375px;
		}

		@include xl {
			height: 355px;
			width: 50%;
			border-right: 2px solid $darkblue;
			border-bottom: none;
			padding-bottom: 0px;
		}
	}

	&__container-right {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		margin-top: 30px;
		border-top: 2px solid $darkblue;
		padding-top: 20px;

		@include sm {
			margin-top: 50px;
		}

		@include xl {
			width: 50%;
			border-top: none;
			margin-top: 0;
			padding-top: 0;
		}
	}

	&__options {
		position: relative;
		width: $slideWidth;
		min-width: $slideWidth;
		height: $slideHeight;
	}

	&__info-container {
		padding: 0 20px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include xl {
			padding: 0 30px;
		}
	}

	&__info {
		display: inline-flex;
		font-size: 1.5625rem;
		height: auto;
		max-width: 300px;
		color: $darkblue;

		@include sm {
			height: 101px;
		}

		@include md {
			height: 74px;
			max-width: 380px;
		}

		&::before {
			color: $darkblue;
		}
	}

	&__info-description {
		padding-left: 10px;
		display: flex;
		flex-direction: column;
		width: 100%;
		text-align: left;
		background-color: transparent;
		border: none;

		&:focus {
			box-shadow: none;
			outline: none;
		}
	}

	&__info-headline {
		@include font('headline', 16px, 19px, 0);
		color: $darkblue;
		position: relative;
		display: inline-table;
		display: -moz-inline-box;
		width: fit-content;
		background: linear-gradient($white, $white);
		background-size: 1px 2px;
		background-repeat: repeat-x;
		background-position: 0% 99%;

		^&__info-description:hover & {
			background-image: linear-gradient($darkblue, $darkblue);
		}

		@include sm {
			font-size: 18px;
			line-height: 23px;
		}

		@include md {
			font-size: 20px;
			line-height: 24px;
		}
	}

	&__info-copy {
		@include font('regular', 12px, 19px, 0);
		color: $darkblue;
		min-height: 78px;

		@include sm {
			font-size: 14px;
			line-height: 23px;
		}

		@include md {
			font-size: 16px;
			line-height: 23px;
		}
	}

	&__info--allowed {
		&:before {
			@include acv-icons-mixin();
			content: '$acv-icons-var-bang';
		}
	}

	&__info--analyze {
		&:before {
			@include acv-icons-mixin();
			content: '$acv-icons-var-lupe';
		}
	}

	&__info--marketing {
		&:before {
			@include acv-icons-mixin();
			content: '$acv-icons-var-bullhorn';
		}
	}

	&__slide {
		position: absolute;
		left: 50%;
		top: 50%;
		writing-mode: none;
		-webkit-appearance: none;
		background-color: transparent;
		width: $slideHeight;
		height: $slideWidth;
		transform: translate3d(-50%, -50%, 0) rotate(90deg);
		-moz-transform: translate3d(-50%, -50%, 0) rotate(180deg);
		transform-origin: 50% 50%;

		@media screen and (-ms-high-contrast: active),
		(-ms-high-contrast: none) {
			width: $slideHeight;
			height: $slideWidth;
		}

		@media screen and (-moz-device-pixel-ratio: 1) {
			width: 100%;
			height: 100%;
		}

		@mixin thumb() {
			border: none;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			background: $orange;
			margin-top: -9px;
			cursor: pointer;
		}

		&::-webkit-slider-runnable-track {
			height: 2px;
			min-width: $slideHeight;
			background: $darkblue;
			cursor: pointer;
		}

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			@include thumb();
		}

		&:focus {
			outline: none;
			border: none;
		}

		&::-moz-range-track {
			width: 2px;
			min-height: $slideHeight;
			background: $darkblue;
			cursor: pointer;
		}

		&::-moz-range-thumb {
			-webkit-appearance: none;
			@include thumb();
		}

		&:-moz-focusring {
			box-shadow: none !important;
			border: none !important;
			outline: 0;
		}

		&::-ms-track {
			width: 100%;
			max-width: $slideHeight;
			height: 2px;
			background: $darkblue;
			border: none;
			color: transparent;
		}

		&::-ms-fill-lower {
			background: $orange;
			border-radius: 10px;
		}

		&::-ms-fill-upper {
			background: $orange;
			border-radius: 10px;
		}

		&::-ms-thumb {
			@include thumb();
		}
	}

	&__mark {
		position: absolute;
		display: block;
		width: 11px;
		height: 1px;
		box-sizing: border-box;
		background-color: $darkblue;

		&--top {
			top: 9px;
		}

		&--mid {
			top: 50%;
		}

		&--bottom {
			bottom: 9px;
		}

		&--right {
			right: 0;
		}
	}

	&__tab-headline-allowed,
	&__tab-headline-notallowed {
		@include font('bold', 18px, 25px, 0);
		padding-bottom: 10px;
	}

	&__tab-headline-allowed {
		color: $darkblue;
	}

	&__tab-list {
		list-style: none;
	}

	&__tab {
		&:not(:last-child) {
			padding-bottom: 26px;

			@include sm {
				padding-right: 45px;
			}

			@include md {
				padding-right: 0px;
			}
		}
	}

	&__tab-group {
		visibility: hidden;
		transition: opacity 0.3s ease-in;
		opacity: 0;
		height: 0;

		@include sm {
			display: flex;
			flex-direction: row;
		}

		@include md {
			flex-direction: none;
		}

		@include xl {
			display: block;
			padding-left: 47px;
		}

		&--show {
			visibility: visible;
			opacity: 1;
			height: auto;
			padding-top: 12px;

			@include sm {
				padding-top: 20px;
			}

			@include md {
				padding-top: 0px;
				overflow-y: auto;
				// max-height: 385px;
			}
		}

		&::-webkit-scrollbar {
			width: 3px;

			&-thumb {
				background: $grey-3;
				border-radius: 20px;
			}
		}
	}

	&__tab-item-allowed,
	&__tab-item-notallowed {
		@include font('regular', 16px, 20px, 0);
		color: $darkblue;
		width: 100%;
		display: inline-block;
		word-break: break-word;

		&:not(:last-child) {
			padding-bottom: 10px;
		}

		p {
			padding-left: 25px;
		}

		&::before {
			display: block;
			float: left;
			font-size: 8px;
		}
	}

	&__tab-item-allowed:before {
		@include acv-icons-mixin();
		content: '$acv-icons-var-checkmark';
	}

	&__tab-item-notallowed:before {
		@include acv-icons-mixin();
		content: '$acv-icons-var-cross';
	}

	&__save-button {
		text-align: center;
		width: 100%;
		padding-top: 32px;

		@include md {
			text-align: left;
			width: auto;
			display: inline-block;
		}

		@include xl {
			position: absolute;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);
			padding-top: 0px;
		}

		.btn {
			display: inline-block;
		}
	}

	&__link-wrapper {
		width: 100%;
		padding-top: 40px;
		text-align: center;

		@include md {
			width: auto;
			padding-top: 44px;
			display: inline-block;
			float: right;
		}

		@include xl {
			position: absolute;
			bottom: 71px;
			right: 68px;
			padding-top: 0px;
			float: none;
		}
	}

	&__link {
		@include font('black', 14px, 16px, 0);
		display: inline-block;

		&:hover {
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
		}
	}

	.form__row {
		padding-top: 10px;
	}
}
