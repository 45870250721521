.accordion {
	&__headline {
		@include font('headline', 20px, 25px, 0.8px);
		color: white;
		margin-bottom: 20px;

		@include lg {
			font-size: 24px;
		}

		@include <sm {
			&--padding {
				padding: 0 15px;
			}
		}
	}

	&__item {
		&:not(&--no-shadow).is-active {
			box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
		}
	}

	&__item-bar {
		display: flex;
		justify-content: space-between;
		background: $white;
		padding: 20px;
		cursor: pointer;
		border-top: 1px solid $grey-3;

		@include lg {
			padding: 17px 20px 13px 40px;
		}

		&:not(&--no-shadow) {
			box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.22);
		}

		&--with-inner {
			justify-content: flex-start;
			flex-direction: column;
		}
	}

	&__item-bar-inner {
		position: relative;
		display: flex;
		justify-content: space-between;
	}

	&__item-content {
		height: 0;
		overflow: hidden;

		transition: height 250ms ease-in-out;
		^&__item[data-initial-open] & {
			transition: none;
		}

		&.is-auto {
			height: auto !important;
		}
	}

	&__item-content-inner {
		@include font('regular', 16px, 25px, 0.4px);
		padding: 30px 40px 40px 40px;
		border-top: 1px solid $grey-3;

		@include lg {
			font-size: 15px;
			line-height: 20px;
		}

		*:last-child {
			margin-bottom: 0;
		}

		&--no-border-top {
			border-top: none;
		}

		&--no-side-padding-mobile {
			@include <sm {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}

	&__list {
		background-color: white;
		// margin-bottom: 50px;

		// @include lg {
		// 	margin: 0;
		// }
	}

	&__item-title {
		@include font('headline', 15px, 18px, 0.58px);
		user-select: none;
		color: $darkblue;
		position: relative;
		padding-right: 30px;
		margin-bottom: 0;
		padding-top: 0;

		@include lg {
			font-size: 17px;
			line-height: 19px;
		}

		^&__item.is-active & {
			color: $orange;
		}
	}

	&__item-arrow {
		align-self: center;
		width: 12px;
	}

	&__item-arrow::after {
		display: block;
		transform: scale(0.8) rotate(90deg);
		transition: transform 250ms ease-in-out;
	}

	&__item.is-active &__item-arrow::after {
		transform: scale(0.8) rotate(-90deg);
	}
}

.twoColGrid {
	.frame-type-acvcontent_accordion {
		&:not(:first-child) {
			margin-top: 50px !important;
		}

		&:first-child {
			margin-top: 25px !important;

			@include lg {
				margin-top: 0 !important;
			}
		}
	}

	&__col--left {
		.frame-type-acvcontent_accordion:first-child {
			margin-top: 0 !important;
		}
	}
}
