.badge {
	@include font('bold', 15px, 17px, 0);
	color: $orange;
	position: absolute;
	left: 0;
	top: 30px;
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 20px;
	background-color: white;
	box-shadow: $badge-shadow;
	z-index: 5;
	text-transform: uppercase;
}
