.contractWidget {
	@include sm {
		overflow: hidden;
	}

	&__loading-dots {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;
	}

	&__complete-wrapper {
		@include lg {
			min-height: 830px;
			display: flex;
			align-items: stretch;
		}
	}

	&__subelement {
		margin-top: 100px;
		margin-bottom: 47px;
	}

	&__action-bar {
		position: relative;
		border-top: 1px solid $grey-3;
		margin-top: 60px;
		padding-top: 30px;
		display: flex;
		justify-content: space-between;

		flex-direction: column;
		gap: 15px;

		@include sm {
			flex-direction: row;
			gap: unset;
		}

		@include md {
			margin-top: auto;
		}

		&--with-required {
			&:before {
				content: '* Pflichtfeld';
				position: absolute;
				top: -30px;
				left: 0;
				display: block;
			}
		}
	}

	&__action-bar-info {
		@include font('', 18px, '');
		position: absolute;
		bottom: calc(100% + 10px);
		color: $grey-2;
		display: flex;
		align-items: center;
	}

	&__campaign {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__campaign-inner {
		display: flex;
		flex-direction: column;
		gap: 14px;
		position: relative;

		@include sm {
			flex-direction: row;
			height: 50px;
		}
	}

	&__campaign-input {
		min-height: 50px !important;
		height: 100% !important;
		margin: 0 !important;
	}

	&__campaign-valid-code {
		position: relative;
		display: flex;
		height: 50px;
		gap: 4px;
	}

	&__campaign-valid-code-text {
		border: 1px solid $signal-green;
		border-radius: 100vw;
		padding: 0 15px 0 50px;
		height: 100%;
		display: flex;
		align-items: center;
		@include font('', 18px, 26px);
	}

	&__campaign-check {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 5px;
		color: white;
		background: linear-gradient(0deg, #26ba4d 0%, #71d68d 100%);
		border-radius: 50%;
		size: 19px;
		padding: 3px;
		left: 18px;
		top: 50%;
		transform: translateY(-50%);

		&:before {
			margin: 0 !important;
		}

		&:after {
			content: '';
			position: absolute;
			top: -3px;
			left: -3px;
			bottom: -3px;
			right: -3px;
			border: 3px rgba(#26ba4d, 0.2) solid;
			border-radius: 50%;
		}
	}

	&__campaign-remove-code-btn {
		background: none;
		border: none;
		width: 24px;
		height: 100%;
		font-size: 0;
		&:before {
			font-size: 24px;
		}
	}

	&__campaign-button {
		flex: 0 0 auto;
	}

	&__campaign-message {
		color: $grey-2;
		font-size: 18px;

		&.error {
			color: $signal-red;
		}
	}

	&__button {
		&.is-hidden {
			visibility: hidden;
			pointer-events: none;
			size: 0;
			@include <sm {
				display: none;
			}
		}

		@include <sm {
			order: 1;
		}

		&--back {
			@include <sm {
				order: 10;
			}
		}
	}

	&__fieldset {
		padding: 0;
		border: none;

		@include sm {
			position: relative;
		}
	}

	&__headline {
		@include font('headline', 26px, 30px, 0px);
		color: $darkblue;
		text-align: center;
	}

	&__copy {
		@include font('regular', 18px, 26px, 0);
		&:not(:first-child) {
			margin-top: 10px;
		}
		margin: 0 auto;

		@include sm {
			&:not(:first-child) {
				margin-top: 20px;
			}
			@include font('regular', 21px, 26px, 0);
		}

		&--small {
			@include font('regular', 16px, 26px, 0);

			@include sm {
				@include font('regular', 18px, 26px, 0);
			}
		}

		&--center {
			text-align: center;
		}

		&--max-width {
			max-width: 600px;
		}

		&--bold {
			font-family: 'Lato bold';
		}

		&--dark-blue {
			color: $darkblue;
		}
	}

	&__form {
		margin-top: 30px;
		@include lg {
			margin-top: 77px;
		}
	}

	&__step-indicator-wrapper {
		flex-shrink: 0;
		align-self: flex-start;
		width: 100%;
		margin-bottom: 51px;

		@include lg {
			margin-bottom: 0;
			align-self: stretch;
			width: 290px;
		}

		&.isDisabled {
			pointer-events: none;
			opacity: 0.2;
		}
	}

	&__step-indicator-inner {
		background-color: $anthrazit;
		padding: 18px 15px 15px;
		flex-shrink: 0;
		height: 100%;
		width: calc(100% + 30px);
		margin-left: -15px;

		@include sm {
			width: 100%;
			margin-left: 0;
		}

		@include lg {
			padding: $ce-side-padding calc($ce-side-padding / 2);
			background-color: $darkblue;
		}

		@include lg {
			padding: 49px 35px 35px 35px;
		}
	}

	&__step-indicator-head {
		color: $white;
		@include font('regular', 13px, 16px, normal);
		margin-bottom: 2px;

		@include lg {
			display: block;
			@include font('', 15px, 19px, normal);
			margin-bottom: 9px;
		}
	}

	&__step-indicator-tarif {
		color: $orange;
		display: inline-block;
		@include font('bold', 15px, 20px, normal);
		@include lg {
			@include font('', 17px, 19px, normal);
			display: block;
		}
	}

	&__step-indicator-tarif-price {
		color: $orange;
		display: inline-block;
		@include font('regular', 15px, 20px, normal);
		margin-left: 3px;

		@include lg {
			margin-left: 0;
			@include font('', 17px, 19px, normal);
			display: block;
			margin-bottom: 43px;
		}
	}

	&__step-indicator-list {
		display: flex;
		justify-content: space-between;
		margin-top: 11px;
		position: relative;

		@include lg {
			margin-top: 0;
			justify-content: initial;
			flex-direction: column;
		}
	}

	&__step-indicator-step {
		display: flex;
		&:not(:first-child) {
			flex: 1 1 auto;
			&:before {
				content: '';
				display: block;

				@include <lg {
					height: 1px;
					background-color: $white;
					flex: 1 1 auto;
					margin-top: 9px;
					margin-left: 2px;
					margin-right: 3px;
				}
			}
		}

		@include lg {
			display: block;
			margin-right: 0;

			&:not(:first-child) {
				&:before {
					width: 100%;
					height: 46px;
					border-left: 1px solid white;
					margin-left: 5px;
				}
			}
		}

		&[disabled] {
			&:before {
				background-color: $grey-1;
				@include lg {
					background-color: initial;
					border-left-color: $grey-1;
				}
			}
		}

		&.is-active {
			&:before {
				background-color: $white;
				@include lg {
					background-color: initial;
					border-left-color: $white;
				}
			}
		}

		&--success {
			order: 99;
		}
	}

	&__summary-jump-button {
		position: absolute;
		right: 0;
		top: 0;
		background-color: transparent;
		border: 0;
		size: 30px;
		font-size: 0;

		@include md {
			right: -15px;
		}
		color: $grey-1;

		&:before {
			font-size: 29px;
		}
	}

	&__form-group {
		position: relative;
		& + & {
			margin-top: 30px;
		}
	}

	&__indicator-button {
		position: relative;
		@include font('regular', 15px, 20px, normal);
		background-color: transparent;
		border: 0;
		color: white;
		transition: color 250ms ease;
		text-align: left;
		flex: 0 1 auto;

		@include lg {
			flex: 1 1 auto;
			width: 100%;
		}

		&:not(.is-disabled)&:hover {
			color: $orange;
			&:before {
				border-color: $orange;
			}
		}

		^&__step-indicator-step.is-active & {
			color: $orange;
		}

		^&__step-indicator-step.no-click & {
			cursor: initial;
		}

		&[disabled] {
			color: $grey-2;
		}

		&:before {
			content: '';
			display: inline-block;
			size: 12px;
			border-radius: 50%;
			border: 2px solid $white;
			transition: border-color 250ms ease;

			^^&__step-indicator-step.is-active & {
				border-color: $orange;
			}

			^&[disabled]& {
				border-color: $grey-1;
				border-width: 1px;
			}
		}

		span {
			display: none;

			@include lg {
				display: inline-block;
				position: absolute;
				left: 0;
				margin-left: 20px;
			}
		}
	}

	&__step {
		&--contracts {
			&:not(.hidden) + div {
				display: none;
			}
		}

		&--form {
			background-color: white;

			@include md {
				margin-bottom: 64px;
			}

			@include lg {
				margin-top: 57px;
			}

			&::before {
				content: '';
				background: white;
				display: block;
				height: 100%;
				width: calc(100%);
				position: absolute;
				left: 0;
				top: 0;
				z-index: -100000;
			}
		}
	}

	&__child-head-wrapper {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $grey-3;
		padding-bottom: 11px;
		margin-bottom: 30px;
	}

	&__child-headline {
		@include font('regular', 21px, 25px, normal);
		color: $typo-color;
	}

	&__child-add-btn {
		margin-top: 26px;
	}

	// CONTRACTS
	&__screen-wrapper {
		&.is-full {
			@include lg {
				width: 100%;
			}
		}

		@include sm {
			flex: 1 1 auto;
		}
	}

	&__steps-wrapper {
		width: 100%;
		padding-bottom: 30px;

		@include sm {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include md {
			height: 100%;
			display: flex;
			flex-direction: column;
			padding-left: 40px;
			padding-right: 40px;
		}

		@include xl {
			padding-left: 80px;
			padding-right: 80px;
		}
	}

	&__contract-tile {
		cursor: pointer;
	}

	&__success-icon {
		size: 85px;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #26ba4d;
		margin: 0 auto 40px auto;

		&:before {
			margin-right: 0 !important;
			font-size: 87px;
		}
	}

	&__error-icon {
		size: 85px;
		background: #ffe2e2;
		border-radius: 50%;
		border: 4px solid $signal-red;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $signal-red;
		margin: 0 auto 40px auto;

		&:before {
			margin-right: 0 !important;
		}
	}

	&__success-copy {
		&:not(:last-child) {
			margin-bottom: 40px;
		}
	}

	&__campaign-teaser {
		background-color: $darkblue;
		color: white;
		padding: 26px 7px 26px;
		position: relative;

		@include md {
			padding: 45px 7px 32px;
		}

		&:before {
			content: '';
			background: #293b49;
			position: absolute;
			right: 100%;
			top: 0;
			display: block;
			width: 200px;
			height: 100%;

			@include lg {
				width: 26px;
			}
		}

		&:after {
			content: '';
			background: #293b49;
			position: absolute;
			left: 100%;
			top: 0;
			display: block;
			width: 200px;
			height: 100%;
		}
	}

	&__campaign-teaser-copy {
		@include font('headline', 14px, 15px, normal);
		text-align: center;

		@include md {
			text-align: left;
			font-size: 20px;
			line-height: 25px;
		}
	}

	&__privacy-link {
		margin-top: 30px;

		.hidden + & {
			display: none;
		}
	}

	&__abo-alarm-iframe-wrapper {
		margin-top: 30px;

		@include lg {
			margin-top: 40px;
		}
	}
}
