.ASD-u-m-l-auto {
	margin-left: auto;
}

.ASD-u-m-0-auto {
	&-md {
		@include md {
			margin: 0 auto;
		}
	}
}
