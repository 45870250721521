/*================================
=            elements            =
================================*/

/*----------  GLOBALS  ----------*/

*,
*:after,
*:before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	background: $orange-dark $orange-gradient;
}

body,
html {
	margin: 0;
	padding: 0;
	cursor: default;
	font-family: Verdana, Helvetica Neue, Helvetica, Arial, sans-serif;
	@include font('regular', 16px, normal, 0);
	color: $typo-color;
	overflow-x: hidden;
	width: 100%;
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
}

html {
	&.scroll-block {
		overflow: hidden;
	}

	&.scroll-block-mobile {
		@include <lg {
			overflow: hidden;
		}
	}
}

address {
	font-style: normal;
}

ul,
ol {
	list-style-type: none;
}

button {
	&[disabled] {
		pointer-events: none;
	}

	&:not([disabled], .is-disabled) {
		cursor: pointer;
	}

	-webkit-tap-highlight-color: transparent;

	> * {
		pointer-events: none;
	}
}

form {
	width: 100%;
}

.input-blueprint {
	display: block;
	caret-color: $orange;

	&:not(:last-child) {
		margin-bottom: 10px;
	}

	&[type='checkbox'],
	&[type='radio'] {
		-webkit-appearance: none;
		display: inline-block;
		size: 32px;
		border: 1px solid $grey-2;
		cursor: pointer;

		&:checked {
			background: $orange;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 40% auto;
			border-color: $orange;

			&[disabled],
			.is-disabled & {
				background-repeat: no-repeat;
				background-position: center;
				background-size: 40% auto;
				border-color: $grey-3;
				background-color: $grey-3;
				box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.14);
			}
		}

		&[disabled],
		.is-disabled & {
			background: white;
			border-color: $grey-3 !important;
			color: $grey-3;
		}

		&:hover:not([disabled]) {
			border-color: $orange;
		}

		&:focus {
			outline: none;
			border-color: $orange;
		}
	}

	&[type='checkbox'] {
		border-radius: 3px;

		&:checked {
			background-image: svg-load('checkmark.svg', fill: #fff);

			&[disabled],
			.is-disabled & {
				background-image: svg-load('checkmark.svg', fill: #fff);
			}
		}
	}

	&[type='radio'] {
		border-radius: 10000px;

		&:checked {
			background-image: svg-load('circle.svg', fill: #fff);

			&[disabled],
			.is-disabled & {
				background-image: svg-load('circle.svg', fill: #fff);
			}
		}
	}

	&.keep-normal {
		color: initial;
	}

	&[type='text'],
	&[type='number'],
	&[type='email'],
	&[type='date'],
	&[type='password'],
	&[type='search'] {
		@include font('regular', 18px, 26px, 0);
		background: white;
		display: block;
		width: 100%;
		height: 58px;
		padding: 0 0.75em;
		outline: 0;
		border: 1px solid $grey-2;
		border-radius: 0;
		color: $anthrazit;

		&::-ms-clear,
		&::-ms-reveal {
			display: none;
		}

		&:-moz-ui-invalid {
			box-shadow: initial;
		}
	}

	&[type='date'] {
		-webkit-appearance: none;
		padding-top: 15px;
		padding-bottom: 15px;
		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			display: none;
			-webkit-appearance: none;
		}
		&::-webkit-date-and-time-value {
			text-align: left;
		}
	}

	&[type='submit'] {
		border-radius: 0;
	}

	&:focus,
	&:hover {
		border-color: $orange;
	}

	&[disabled],
	.is-disabled > & {
		cursor: initial !important;
		user-select: none;
	}

	&[disabled]:not(.keep-normal),
	.is-disabled:not(.keep-normal) > & {
		border-color: $grey-3;
		color: $grey-3;
	}

	.is-invalid > &,
	&.is-invalid {
		border: 1px solid $signal-red !important;
	}
}

[data-design-key='classic'] {
	input {
		@extend .input-blueprint;

		&[type='checkbox'],
		&[type='radio'] {
			@extend .input-blueprint[type='checkbox'];
		}

		&[type='checkbox'] {
			@extend .input-blueprint[type='checkbox'];
		}

		&[type='radio'] {
			@extend .input-blueprint[type='radio'];
		}

		// text input
		&[type='text'],
		&[type='number'],
		&[type='email'],
		&[type='date'],
		&[type='password'] {
			@extend .input-blueprint[type='text'];
		}

		&[type='date'] {
			@extend .input-blueprint[type='date'];
		}

		// submit
		&[type='submit'] {
			@extend .input-blueprint[type='submit'];
		}

		// disabled
		&[disabled],
		.is-disabled > & {
			@extend .input-blueprint[disabled];
		}

		&[disabled]:not(.keep-normal),
		.is-disabled:not(.keep-normal) > & {
			@extend .input-blueprint[disabled];
		}
	}
	.is-invalid > input,
	input.is-invalid {
		border: 1px solid $signal-red !important;
	}
}

textarea {
	@extend .input-blueprint[type='text'];
	@extend .input-blueprint;
	-webkit-appearance: none;
	resize: none;
	padding: 0.75em;
	height: 230px;

	// :focus, :hover
	&:focus,
	&:hover {
		@extend .input-blueprint:focus;
	}

	// disabled
	&[disabled],
	.is-disabled > & {
		@extend .input-blueprint[disabled];
	}

	// invalid
	.is-invalid > &,
	&.is-invalid {
		@extend .is-invalid > .input-blueprint;
	}
}

[data-design-key='classic'] {
	select {
		@extend .input-blueprint[type='text'];
		@extend .input-blueprint;
		-webkit-appearance: none;
		border-radius: 0;
		background-image: svg-load('pulldown.svg', fill: $typo-color);
		background-repeat: no-repeat;
		background-size: auto 10px;
		background-position: right 15px center;
		padding-right: 50px;

		&::-ms-expand {
			display: none;
		}

		&.flatpickr-monthDropdown-months {
			display: inline-block;
		}

		&:focus,
		&:hover {
			@extend .input-blueprint:focus;
		}

		&[disabled],
		.is-disabled > & {
			@extend .input-blueprint[disabled];
			background-image: svg-load('pulldown.svg', fill: $grey-3);
		}

		.is-invalid > select,
		select.is-invalid {
			@extend .is-invalid > .input-blueprint;
		}
	}
}

img {
	display: block;
}

hr {
	opacity: 0.1;

	&.is-dark {
		opacity: 1;
		border: 0;
		height: 1px;
		background-color: #d4d4d4;
	}
}

/*----------  GLOBAL CLASSES  ----------*/

// hidden
.hidden {
	display: none !important;
}

.no-scroll {
	overflow: hidden;
}

.no-scroll-on-small {
	@include <lg {
		overflow: hidden;
	}
}

// clearfix
.clearfix {
	&::before,
	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

/*----------  TYPOSTANDARDS  ----------*/

:--headlines {
	margin: 0;
	font-family: 'Flexo-Demi';
	font-weight: normal;
}

/*=====  End of elements  ======*/

/*----------  TYPO3  ----------*/

main {
	padding-bottom: 50px;

	&.main {
		&--white {
			padding-bottom: 0;
			margin-bottom: -50px;
		}
	}

	&.web-application {
		background-color: #f5f6f9;

		@include lg {
			min-height: calc(100vh - 85px);
		}
	}

	.content-element {
		position: relative;
		&:not(:first-child) {
			margin-top: $content-element-margin;

			@each $val, $i in sm, md, lg, xl, xxl {
				@include $(val) {
					margin-top: $content-element-margin-$(val);
				}
			}
		}

		&.frame-type-header + .content-element {
			margin-top: 10px !important;
		}

		&.frame-layout-100 {
			@include sm {
				display: none;
			}
		}

		&.frame-layout-105 {
			@include <sm {
				display: none;
			}
			@include >md {
				display: none;
			}
		}

		&.frame-layout-110 {
			@include <sm {
				display: none;
			}
		}

		&.frame-layout-120 {
			@include <lg {
				display: none;
			}
		}
	}

	&.have-small-margin {
		.content-element {
			position: relative;
			&:not(:first-child) {
				margin-top: $content-element-margin-small;

				@each $val, $i in sm, md, lg, xl, xxl {
					@include $(val) {
						margin-top: $content-element-margin-small-$(val);
					}
				}
			}
		}
	}

	body.main-has-fixed & {
		position: relative;
		z-index: 30;
	}
}
