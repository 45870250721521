.form-tabs {
	@include sm {
		&:not(&--no-padding) {
			padding: 0 $ce-side-padding;
		}
	}

	&__radio,
	> input[type='radio'] {
		display: none;
	}

	@for $i from 1 to 10 {
		#toggle-tab$(i):checked ~ &__list [for='toggle-tab$(i)']::after {
			transform: scale3d(1, 1, 1);
		}

		#toggle-tab$(i):checked ~ #tab$(i) {
			display: block;
		}
	}

	&__list {
		border-bottom: 1px solid #d4d4d4;
		text-align: center;

		&--scroll {
			position: relative;
			overflow: auto;
			display: flex;
			flex-wrap: nowrap;
			padding: 0 calc(15px + 8.4vw);
			border-bottom: unset;

			&:after {
				content: '';
				position: absolute;
				height: 1px;
				bottom: 0;
				background-color: #d4d4d4;

				@include md {
					left: calc(15px + 47px);
					width: calc(100% - ((15px + 47px) * 2));
				}

				@include lg {
					left: calc(15px + 65px);
					width: calc(100% - ((15px + 65px) * 2));
				}

				@include xl {
					left: calc(15px + 80px);
					width: calc(100% - ((15px + 80px) * 2));
				}

				@include xxl {
					left: calc(15px + 92px);
					width: calc(100% - ((15px + 92px) * 2));
				}
			}

			&::-webkit-scrollbar {
				height: 0;
			}
			scrollbar-width: none;

			/* Track */
			&::-webkit-scrollbar-track {
				background: transparent;
			}

			scrollbar-color: transparent;

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: transparent;
			}

			@include sm {
				margin: 0 15px;
				padding: 0 calc(15px + 6.2vw);
			}

			@include md {
				padding: 0 calc(15px + 47px);
			}

			@include lg {
				padding: 0 calc(15px + 65px);
			}

			@include xl {
				padding: 0 calc(15px + 80px);
			}

			@include xxl {
				padding: 0 calc(15px + 92px);
			}
			padding-bottom: 1px;

			& ^^&__list-item {
				position: relative;
				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					background-color: #d4d4d4;
					height: 1px;
					width: 100%;
				}

				&:not(:first-child) {
					&:before {
						left: -30px;
					}
				}

				&:not(:last-child) {
					&:before {
						width: calc(100% + 60px);
					}
				}

				&:first-child {
					&:before {
						width: calc(100% + 30px);
					}
				}

				&:last-child {
					&:before {
						width: calc(100% + 30px);
					}
				}
			}
		}

		@include md {
			text-align: left;
		}
	}

	&__list-item {
		display: inline-block;

		^&__list--scroll & {
			flex: 0 0 auto;
		}

		&:not(:last-child) {
			margin-right: 30px;

			@include md {
				margin-right: 30px;
			}
		}

		^&__list--stacked & {
			@include <md {
				margin-right: 0;
				width: 100%;
			}
		}
	}

	&__radio-label {
		@include font('black', 12px, 14px, 0.8px);
		cursor: pointer;
		display: block;
		text-transform: uppercase;
		padding: 14px 0;
		position: relative;
		user-select: none;

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 4px;
			bottom: 0;
			left: 0;
			background-color: $orange;
			transform: scale3d(1, 0, 1);
			transform-origin: bottom;
			transition: transform 0.25s ease;

			^&:hover& {
				transform: scale3d(1, 1, 1);
			}
		}
	}

	&__form {
		display: none;
		padding-top: 40px;

		form {
			display: inline-block;
		}
	}
}

.typo3-messages {
	margin: 0 0 20px;

	@include sm {
		margin: 0 40px 20px;
	}

	&--no-margin {
		margin: 0;
	}

	.alert {
		padding: 15px;

		&-warning {
			background-color: #f2dede;
			border-color: #ebccd1;

			a {
				background-image: linear-gradient(#a94442, #a94442);
				&:hover {
					background: none;
				}
			}

			a:hover,
			& {
				color: #a94442;
			}
		}

		&-success {
			background-color: #ebf2de;
			border-color: #e7ebcc;

			a {
				background-image: linear-gradient(#6fa942, #6fa942);
				&:hover {
					background: none;
				}
			}

			a:hover,
			& {
				color: #6fa942;
			}
		}
	}
}
