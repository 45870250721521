.frame-type-acvcontent_tarifcomparetarifcompareservice {
	margin-top: 0 !important;
}

.tarifCompareService {
	&__table {
		display: flex;
		gap: 2px;

		@include <lg {
			flex-wrap: wrap;
		}
	}

	&__head,
	&__cell {
		flex: 1 1 10px;

		display: flex;
		align-items: center;
		min-height: 75px;

		@include lg {
			padding: 0 24px;
			div:nth-child(even) > ^&:not(.is-odd):not(.is-even) & {
				background-color: $vista;
				border-radius: 6px;
			}
			^&.is-odd & {
				background-color: $vista;
				border-radius: 6px;
			}
			^&.is-even & {
				background-color: none;
				border-radius: none;
			}
		}
	}

	&__head {
		@include font('bold', 14px, 18px, normal);
		hyphens: auto;

		@include md {
			padding-top: 18px;
			padding-bottom: 18px;
		}

		@include <lg {
			@include font('normal', 14px, 18px, normal);
			flex: 1 0 auto;
			width: 100%;
			height: auto;
			margin-top: 21px;
			margin-bottom: 13px;
			min-height: unset;
		}
	}

	&__cell {
		&--icon {
			justify-content: center;
			[class*='checkmark-simple'] {
				color: $signal-green;
				&:before {
					margin-right: 0;
					font-size: 10.5px;

					@include lg {
						font-size: 13px;
					}
				}
			}

			[class*='cross'] {
				color: $signal-red;
				&:before {
					margin-right: 0;
					font-size: 12px;

					@include lg {
						font-size: 16px;
					}
				}
			}
			@include <lg {
				justify-content: center;
			}
		}

		@include <md {
			&:not(.visible):nth-child(n + 2) {
				display: none;
			}
		}

		@include <lg {
			min-height: 33px;
			background-color: $vista;
			border-radius: 3px;
		}
	}

	&__head-text {
		margin-left: 16px;

		&:first-child {
			margin-left: 36px;

			@include lg {
				margin-left: 51px;
			}
		}
	}

	&__details {
		position: relative;
		display: none;
		transition: background-color 250ms ease, visibility 250ms ease;

		@include <lg {
			overflow: auto;
			display: flex;
			flex-direction: column;
			position: fixed;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			z-index: 8;
			visibility: hidden;
			pointer-events: none;
		}

		@include lg {
			display: none;
			flex-direction: column;
			position: fixed;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			z-index: 8;
			background-color: rgba(0, 0, 0, 0.5);
			padding: 100px 25px;
		}

		@include xl {
			padding-left: 100px;
			padding-right: 100px;
		}

		&.open {
			display: flex;
			@include <lg {
				visibility: visible;
				pointer-events: unset;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}
	}

	&__details-inner-wrapper {
		@include <lg {
			margin-top: auto;
		}

		@include lg {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__details-inner {
		@include <lg {
			margin-top: calc(70px + 75px);
			bottom: 0;
			left: 0;
			width: 100%;
			background: $white;
			border-radius: 15px 15px 0 0;
			padding: 46px 36px;
			box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
			display: flex;
			flex-direction: column;
			transform: translate3d(0, 100%, 0);
			visibility: hidden;
			transition: transform 0.25s ease, visibility 0.25s ease;
		}

		@include lg {
			background: $white;
			border-radius: 22px;
			padding: 55px 53px;
			padding-right: 62px;
			max-height: 100%;
			overflow: auto;
			position: relative;
			max-width: 1247px;
		}

		^&__details.open & {
			@include <lg {
				transform: translate3d(0, 0, 0);
				visibility: visible;
			}
		}
	}

	&__close-button {
		position: absolute;
		right: 33px;
		top: 48px;
		background: transparent;
		border: 0;
		color: $orange;
		&:before {
			font-size: 18px;
			margin-right: 0 !important;
		}

		@include lg {
			top: 55px;
			right: 36px;
		}
	}

	&__columns {
		display: flex;
		gap: 60px;

		@include <lg {
			overflow: hidden;
		}
	}

	&__column {
		display: flex;
		overflow: auto;
		@include <lg {
			&:last-child {
				display: none;
			}
			&:first-child {
				flex-direction: column;
			}
		}

		@include lg {
			flex: 1 1 50%;
		}
	}

	&__detail-headline {
		color: $anthrazit;
		@include font('headline', 20px, 25px, -0.0125em);
		margin-bottom: 60px;

		@include <lg {
			padding-right: 40px;
		}

		@include lg {
			@include font('headline', 28px, 36px, -0.0125em);
			margin-bottom: 59px;
		}
	}

	&__detail-infos {
		display: flex;
		gap: 2px;
		margin-bottom: 46px;
		width: 100%;

		@include lg {
			display: none;
		}
	}

	&__detail-info-cell {
		flex: 1 1 50%;
		display: flex;
		flex-direction: column;
		&:not(.visible) {
			display: none;
		}

		> .tarifCompareService__cell {
			margin-top: auto;
			flex: 0 1 auto;
		}
	}

	&__detail-info-headline {
		@include font('', 14px, 18px);
		margin-bottom: 14px;
	}

	&__detail-copy {
		color: $anthrazit;
		* {
			@include font('normal', 15px, 25px, normal);
		}

		strong .rte-orange {
			@include font('normal', 14px, 22px, normal);
		}

		p + p {
			margin-top: 15px;
		}

		p + ul {
			margin-top: 24px;
		}

		li + li {
			margin-top: 25px;
		}
	}

	&__contact {
		background-color: $cornflower;
		padding: 56px;
		color: $anthrazit;
		align-self: flex-start;
		width: 100%;
	}

	&__contact-headline {
		@include font('headline', 20px, 26px, 0.0025em);
		margin-bottom: 53px;
	}

	&__contact-copy {
		*:not([class*='btn--cta']) {
			@include font('normal', 14px, 22px, normal);
		}

		li * {
			@include font('normal', 15px, 25px, -0.005em);
		}

		li + li {
			margin-top: 31px;
		}

		p + ul {
			margin-top: 57px;
		}

		ul + p {
			margin-top: 65px;
		}
	}
}
