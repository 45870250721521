.recruitment {
	.frame-type-acvcontent_recruitmembers & {
		@include lg {
			text-align: center;
		}
	}

	&__inner {
		.frame-type-acvcontent_recruitmembers & {
			@include lg {
				display: inline-block;
				text-align: left;
			}
		}
	}

	&__btn-wrap {
		margin-top: 20px;
		text-align: center;

		@include lg {
			margin-top: 0;
			display: inline-block;
			transform: translateY(-3px);
		}

		.bounty-selection__recruitment & {
			@include lg {
				margin-top: 20px;
			}
		}
	}

	&__btn {
		display: inline-block;
	}

	&__input {
		@include <sm {
			font-size: 12px !important;
			padding-left: 5px !important;
			padding-right: 5px !important;
		}

		@include <lg {
			text-align: center;
		}

		@include lg {
			width: 425px !important;
			display: inline-block !important;
			margin-right: 40px;
		}

		.frame-type-acvcontent_recruitmembers & {
			@include lg {
				width: 345px !important;
			}

			@include xl {
				width: 465px !important;
			}

			@include xxl {
				width: 560px !important;
			}
		}
	}

	&__copy-info {
		margin-top: 15px;
		text-align: center;

		@include lg {
			text-align: left;
		}
	}
}
