.textBox {
	&__container {
		@include sm {
			padding: 0 $ce-side-padding;
		}

		.twoColGrid .twoColGrid__col--left:not(.twoColGrid__col--white) & {
			padding-right: 0;
		}
		.twoColGrid .twoColGrid__col--right:not(.twoColGrid__col--white) & {
			padding-left: 0;
		}
	}

	&__wrapper {
		position: relative;
		background-color: $grey-4;

		padding: 24px 30px 40px;
	}

	&__tagWrapper {
		display: flex;
		align-items: center;

		height: 34px;
		margin-bottom: 21px;

		@include sm {
			height: 38px;
			margin-bottom: 38px;
		}

		&--no-image {
			height: auto;
			margin-bottom: 20px;
		}
	}

	&__image {
		height: 100%;
		width: auto;

		&:not(:only-child) {
			margin-right: 18px;

			@include sm {
				margin-right: 21px;
			}
		}
	}

	&__copy-image-wrapper {
		&--left {
			float: left;
			margin-right: 30px;
		}

		&--right {
			float: right;
			margin-left: 30px;
		}
	}

	&__tag {
		text-transform: uppercase;
		@include font('headline', 13px, 15px, 3px);
		color: $orange-dark;

		&--dark {
			color: $grey-1;
		}

		@include sm {
			font-size: 15px;
			line-height: 18px;
			letter-spacing: 4.03px;
		}
	}

	&__headline {
		color: $darkblue;
		@include font('headline', 20px, 25px, normal);
		margin-bottom: 20px;

		@include sm {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 30px;
		}
	}

	&__subheadline {
		@include font('bold', 16px, 25px, normal);
		margin-bottom: 10px;

		@include sm {
			font-size: 15px;
			line-height: 19px;
			margin-bottom: 6px;
		}
	}
}
