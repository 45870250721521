/*=============================================
  =            links-buttons                 =
=============================================*/

@mixin btn-primary-blue {
	color: white;
	background-color: transparent;
	border-color: $darkblue;

	&:not(.is-disabled) {
		&:hover {
			background-image: none;
			color: $darkblue;
		}
	}

	&::before {
		background-color: $darkblue;
	}
}

@mixin btn-primary-white {
	color: $orange-dark;
	background-color: transparent;
	border-color: $white;
	z-index: 1;

	&:not(.is-disabled) {
		&:hover {
			background-image: none;
			color: $white;
		}
	}

	&:before {
		background-color: $white;
	}
}

@mixin btn-primary-dark {
	color: $white;
	background-color: transparent;
	border-color: $darkblue;
	z-index: 1;

	&:not(.is-disabled) {
		&:hover {
			background-image: none;
			color: $darkblue;
		}
	}

	&:before {
		background-color: $darkblue;
	}
}

@mixin btn-primary-orange {
	color: $white;
	background-color: transparent;
	border-color: $orange-dark;

	&:not(.is-disabled) {
		&:hover {
			background-image: none;
			color: $orange-dark;
		}
	}

	&:before {
		background-color: $orange-dark;
	}
}

@mixin btn-secondary-blue {
	color: $darkblue;
	background-color: transparent;
	border-color: $darkblue;
	z-index: 1;

	&:not(.is-disabled) {
		&:hover {
			background-image: none;
			color: $white;
		}
	}

	&:before {
		background-color: $darkblue;
	}
}

@mixin btn-secondary-white {
	color: $white;
	background-color: transparent;
	border-color: $white;
	z-index: 1;

	&:not(.is-disabled) {
		&:hover {
			background-image: none;
			color: $darkblue;
		}
	}

	&:before {
		background-color: $white;
	}
}

@mixin btn-secondary-dark {
	color: $darkblue;
	background-color: transparent;
	border-color: $darkblue;
	z-index: 1;

	&:not(.is-disabled) {
		&:hover {
			background-image: none;
			color: $white;
		}
	}

	&:before {
		background-color: $darkblue;
	}
}

@mixin btn-secondary-orange {
	color: $white;
	background-color: transparent;
	border-color: $orange-dark;
	z-index: 1;

	&:not(.is-disabled) {
		&:hover {
			background-image: none;
			color: $white;
		}
	}

	&:before {
		background-color: $orange-dark;
	}
}

@mixin btn-hover-from-bottom {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100% + 1px);
		height: 100%;
		transform: scaleY(0);
		transform-origin: bottom;
		transition: transform 0.2s ease-in;
		z-index: -1;
	}

	&:not(.is-disabled) {
		&:hover:before {
			transform: scaleY(1);
		}
	}
}

@mixin btn-hover-from-top {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100% + 1px);
		height: 100%;
		transform: scaleY(1);
		transform-origin: top;
		transition: transform 0.2s ease-in;
		z-index: -1;
	}

	&:not(.is-disabled) {
		&:hover:before {
			transform: scaleY(0);
		}
	}
}

/*=====      End of links-button      ======*/
