.ASD-badge {
	display: flex;
	gap: 2px;
	align-items: center;

	padding: 0 7px;
	height: 21px;

	color: $green;
	border: 2px solid $green;
	border-radius: 100vw;
	@include font('bold', 14px, 120%, normal);
	white-space: nowrap;

	&--red {
		color: $red;
		border-color: $red;
	}
}
