.ASD-upload {
	&__label {
		margin-bottom: 10px;
		color: $anthracite;

		@include font('', 14px, normal, normal);
		display: block;

		^&:not(.is-required) & {
			&:after {
				content: '(optional)';
			}

			&^&:not(:empty):after {
				margin-left: 0.25rem;
			}

			&[data-suffix]:after {
				content: attr(data-suffix);
			}
		}
	}

	&__inner {
		border: 1px solid $anthracite-20;
		border-radius: 5px;
		width: 100%;
		height: 160px;
		position: relative;
		padding: 20px 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		^&.is-dragOver & {
			background-color: $green-15;
			> * {
				pointer-events: none;
			}
		}
	}

	&__inner-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
	}

	&__input {
		visibility: hidden;
		position: absolute;
	}

	&__input-label {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__field-text {
		z-index: 10;
		color: $anthracite;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;

		&--onlyDesktop > * {
			@include <md {
				display: none;
			}
		}

		^&.is-max & {
			color: $anthracite-80;
		}

		^&.is-overMax & {
			color: $red;
		}

		^&.is-dragOver & {
			color: $green;
		}

		> div {
			font-size: 14px;
		}

		&:before {
			font-size: 24px;
			margin: 0 0 5px 0 !important;

			^^&.is-max & {
				color: $anthracite-60;
			}

			^^&.is-overMax & {
				color: $red;
				font-size: 19px;
			}

			^^&.is-dragOver & {
				color: $green;
			}
		}
	}

	&__details {
		margin-top: 8px;
		justify-content: flex-end;
		display: flex;
		gap: 5px;
		color: $anthracite;
		@include font('', 12px, 14px, normal, normal);
	}

	&__error {
		margin-top: 8px;
		color: $red;
		@include font('', 12px, 14px, normal, normal);
	}

	&__files {
		display: flex;
		flex-direction: column;
		margin-top: 16px;
	}

	&__file {
		display: flex;
		align-items: stretch;

		& + & {
			margin-top: 16px;
		}

		&:before {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 22px;
			color: $anthrazit;
			margin-right: 6px !important;
		}
	}

	&__file-details {
		flex-grow: 1;
		min-width: 0;
		color: $anthrazit;
	}

	&__file-details-name {
		@include font('bold', 14px, 20px, normal);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 10px;
	}

	&__file-details-size {
		@include font('', 12px, 16px, normal);

		&.is-error {
			color: $red;
		}
	}

	&__file-status {
		display: flex;
		align-items: center;

		.acv-icons {
			display: none;
			&:before {
				margin: 0 !important;
				font-size: 20px;
			}
		}
	}

	&__file-status-error {
		color: $red;

		&:before {
			font-size: 17px;
			margin-right: 0 !important;
		}

		^&.is-error& {
			display: block;
			margin-top: 2px;
		}
	}

	&__remove-file-btn {
		border: 0;
		background: none;
		padding: 0;
		font-size: 0;
		color: $anthrazit;
		margin-left: 8px;
		transition: color 250ms ease;
		position: relative;

		&:before {
			font-size: 20px;
			margin: 0 !important;
		}

		&:after {
			content: '';
			display: block;
			size: 28px;
			background-color: $signal-red-soft;
			position: absolute;
			left: 50%;
			top: 50%;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;
			transition: opacity 250ms ease;
			opacity: 0;

			^&:hover& {
				opacity: 1;
			}
		}

		&:hover {
			color: $signal-red;
		}
	}

	&__add-more {
		display: block;
		align-self: center;
		margin-top: 17px;
		font-size: 23px;
		cursor: pointer;
	}
}
