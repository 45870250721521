.tiles {
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		@include md {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__tile {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		text-align: center;
		width: 100%;
		height: 360px;
		padding: 40px;
		box-shadow: $card-shadow;
		background-position: center;
		margin-top: 15px;
		background-repeat: no-repeat;
		background-size: cover;

		@include sm {
			height: 440px;
			margin-top: 30px;
		}

		@include md {
			width: calc(50% - 15px);
		}

		@include xl {
			width: calc(33.33333% - 20px);
			height: 360px;
		}

		@include xxl {
			height: 440px;
		}

		&:first-child {
			margin-top: initial;
		}

		@include md {
			&:not(:nth-child(n + 3)) {
				margin-top: initial;
			}
		}

		@include xl {
			&:not(:nth-child(n + 4)) {
				margin-top: initial;
			}
		}

		&:hover .btn-price {
			color: $orange-dark;
			background-color: white;
			border-color: white;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $darkblue;
			opacity: 0.4;
		}

		&:after {
			content: '';
			position: absolute;
			background-image: $darkblue-gradient;
			width: 100%;
			height: 100%;
			transform: scaleY(0.5);
			transform-origin: bottom;
			bottom: 0;
			left: 0;
			transition: transform 0.3s ease-out;
		}

		&:hover:after {
			background-image: $darkblue-gradient;
			transform: scaleY(1);
		}

		&--empty {
			height: 0;
			visibility: hidden;
			margin-top: 0 !important;
			padding: 0;
		}
	}

	&__content--fullWidth {
		^&__tile {
			margin-top: 30px;

			&:first-child {
				margin-top: initial;
			}

			@include md {
				&:not(:nth-child(n + 3)) {
					margin-top: initial;
				}
			}

			@include xl {
				&:not(:nth-child(n + 4)) {
					margin-top: initial;
				}
			}
		}
	}

	&__tile--fullWidth {
		width: 100%;

		&:first-child {
			margin-top: initial;
		}

		~ .tiles__tile:not(:nth-child(n + 4)) {
			margin-top: 30px;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 2;
	}

	&__headline {
		@include font('headline', 24px, 28px, normal);
		margin-bottom: 20px;
		color: white;
		text-shadow: $text-shadow;
	}

	&__copy {
		color: white;
		text-shadow: $text-shadow;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__link {
		font-size: 0;
		z-index: 6;
		background: none;
		&:hover {
			background-image: none;
		}
	}

	&__secondary-button  {
		display: flex;
		justify-content: center;
		margin-top: 45px;
	}
}
