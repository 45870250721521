.video {
	&.is-full {
		background: $darkblue;
	}

	&__content-wrapper {
		^&.is-full & {
			padding: $ce-side-padding 0;

			@include sm {
				padding: 70px $ce-side-padding;
			}
		}

		^&.is-content & {
			padding: $ce-side-padding 15px;
			background: $darkblue;
			margin: 0 -15px;

			@include sm {
				padding: $ce-side-padding;
				margin: 0;
			}
		}
	}

	&__subheadline {
		color: $white;
	}

	&__intro {
		margin-bottom: 70px;
		display: flex;
		flex-direction: column;

		@include sm {
			margin-bottom: 90px;
		}
	}

	&__image-holder {
		position: relative;
		cursor: pointer;
		box-shadow: $box-shadow;
	}

	&__image {
		width: 100%;
		height: auto;
	}

	&__caption {
		margin-top: 20px;
		text-align: center;

		> p {
			color: white;
			@include font('regular', 15px, 20px, 0);
		}
	}

	&__play {
		size: 70px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: transparent;
		border: 0;
		outline: 0;
	}

	&__play-icon {
		background-image: svg-load(play.svg, fill: transparent, stroke: $orange);
		background-size: cover;
		z-index: 1;

		^&__image-holder:hover & {
			background-image: svg-load(play.svg, fill: transparent, stroke: white);
		}
	}

	&__play-bg {
		background: rgba($darkblue, 0.6);
		border-radius: 50%;
		z-index: 0;
	}

	&__modal {
		position: fixed;
		flex-flow: column nowrap;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: rgba($darkblue, 0.8);
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__modal-inner {
		padding-top: 56.25%;
		transform: scale3d(0, 0, 1);
		transition: transform 0.6s;

		&.is-visible {
			transform: scale3d(1, 1, 1);
		}
	}
}

.multiVideo {
	&.is-full {
		background: $darkblue;
	}

	&__content-wrapper {
		^&.is-full & {
			padding: $ce-side-padding 0;

			@include sm {
				padding: 70px 0;
			}
		}

		^&.is-content & {
			padding: $ce-side-padding 15px;
			background: $darkblue;
			margin: 0 -15px;

			@include md {
				margin: 0 -30px;
				padding: $ce-side-padding 30px;
			}
		}
	}

	&__wrapper {
		@include md {
			display: flex;
		}
		& + & {
			margin-top: 100px;
		}
	}

	&__copy-wrapper {
		display: flex;
		justify-content: center;
		height: 100%;
		color: $white;
		flex-direction: column;
	}

	&__headline {
		@include font('headline', 20px, 25px, normal);
		margin-bottom: 20px;

		@include sm {
			font-size: 24px;
			line-height: 28px;
		}
	}

	&__copy {
		@include font('regular', 18px, 26px, normal);

		@include sm {
			font-size: 21px;
			line-height: 26px;
		}

		@include md {
			margin: 0;
		}
	}

	&__play-icon {
		background-image: svg-load(play.svg, fill: transparent, stroke: $orange);
		background-size: cover;
		z-index: 1;

		^&__image-holder:hover & {
			background-image: svg-load(play.svg, fill: transparent, stroke: white);
		}
	}

	&__image-wrapper {
		height: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 40px;

		@include md {
			margin: 0;
		}
	}

	&__image-holder {
		position: relative;
		cursor: pointer;
		box-shadow: $box-shadow;
	}
}
