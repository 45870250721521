.ASD-step-tile {
	background-color: white;
	padding: 20px 0;
	border-radius: 12px;

	& + & {
		margin-top: 10px;

		@include md {
			margin-top: 15px;
		}
	}

	&--no-padding-bottom {
		padding-bottom: 0;
	}

	&__head {
		display: flex;
		align-items: center;
		gap: 7px;
		padding: 0 20px;

		&:not(:last-child) {
			margin-bottom: 25px;

			@include md {
				margin-bottom: 30px;
			}
		}
	}

	&__check {
		background-color: $green;
		size: 22px;
		flex: 0 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		border-radius: 50%;
		font-size: 7px;
		&:before {
			margin: 0 !important;
		}
	}

	&__title {
		display: flex;
		align-items: center;
		gap: 7px;
	}

	&__subtitle {
		margin-top: 0 !important;
	}

	&__change-button {
		margin-left: auto;
	}

	&__body {
		padding: 0 20px;

		& + & {
			margin-top: 30px;

			&--smallMargin {
				margin-top: 17px;

				@include md {
					margin-top: 23px;
				}
			}

			&--noBottomPadding {
				padding-bottom: 0;
			}
		}

		&--fullWidth {
			padding: 0;

			& > * {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}
