.imageGalleryGrid {
	&__radio:checked ~ &__list &__item {
		display: inline-block;
	}

	&__item {
		text-align: center;
		margin-bottom: 30px;

		&:nth-child(n + 7) {
			display: none;
		}
	}

	&__image-wrapper {
		position: relative;
		margin-bottom: 10px;

		@include sm {
			padding-top: 100%;
		}
	}

	&__image-link {
		background: none;
	}

	&__image {
		object-fit: cover;
		size: 185px;
		display: inline-block;

		@include sm {
			size: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__date,
	&__label {
		color: white;
		text-decoration: none;
		display: block;
	}

	&__date {
		@include font('regular', 13px, 16px, 0);
		margin-bottom: 5px;
	}

	&__label {
		@include font('bold', 15px, 20px, 0);

		^&__item:hover & {
			color: $orange;
		}
	}

	&__show-more-wrapper {
		text-align: center;

		^&__radio:checked ~ & {
			display: none;
		}
	}

	input[type='radio']&__radio {
		display: none;
	}

	&__show-more-label {
		cursor: pointer;

		&:before {
			content: 'Mehr anzeigen';
		}
		&:after {
			content: '+';
		}
	}
}
