.membership-application {
	position: relative;
	padding-top: 69px;
	min-height: 800px;

	&__loading-dots {
		position: absolute;
		left: 0;
		top: 0;
		size: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__steps {
		max-width: 680px;
		margin: 0 auto;
	}

	@include md {
		padding-top: 101px;
	}

	&__resend-spinner {
		&:before {
			display: block;
			animation: rotate 500ms linear infinite;
			transform-origin: calc(50% - 0.1px) calc(50% - 0.1px);
			margin: 0 !important;
		}
	}

	&__referrer {
		text-align: center;
		margin-bottom: 35px;
		@include font('bold', 13px, 16px, normal);
	}

	&__success-info {
		display: flex;
		gap: 15px;
		width: 100%;
		flex-direction: column;

		i {
			display: block;
			width: fit-content;
		}

		@include md {
			gap: 32px;
			align-items: center;
			flex-direction: row;

			> *:first-child {
				flex: 1 1 auto;
				margin-right: 10px;
			}

			> *:last-child:not(:first-child) {
				flex: 1 0 auto;
				width: 160px;
				text-align: right;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
