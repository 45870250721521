.audio-player {
	&.is-content {
		background-color: white;
		padding: 40px 0;
	}
	&.is-full {
		background-color: $darkblue;
		padding: 40px 0;
	}

	&__browser-player {
		display: none;
	}

	&__custom {
		width: 100%;
		margin: 0 auto;
		background-color: $darkblue;
		padding: 0 20px;

		@include sm {
			padding: 0 40px;
		}

		@include lg {
			width: auto;
			max-width: 643px;
			padding: 0;
		}

		@include lg {
			max-width: 743px;
		}

		^&.is-full & {
			box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
			margin-top: 90px;
			margin-bottom: 40px;
		}
	}

	&__custom-inner {
		padding: 40px 0;

		@include sm {
			padding: 40px;
		}
	}

	&__custom-preheadline {
		@include font('regular', 16px, 18px, 4.3px);
		color: white;
		text-align: center;
		padding-bottom: 20px;
	}

	&__custom-headline {
		@include font('headline', 21px, 25px, 0);
		color: white;

		^&.is-full & {
			@include font('headline', 34px, 38px, 0);
			text-align: center;
		}
	}
	&__custom-subheadline {
		@include font('regular', 15px, 20px, 0);
		color: white;
		padding-top: 20px;

		^&.is-full & {
			@include font('regular', 21px, 26px, 0);
			text-align: center;
			padding-top: 30px;
		}
	}

	&__custom-button {
		background-color: transparent;
		border: none;
		outline: none;
		margin-right: 10px;
		height: 72px;
		width: 72px;

		@include lg {
			margin-right: 40px;
		}
	}

	&__custom-play {
		background-image: svg-load(play.svg, fill: transparent, stroke: $orange);
		background-size: cover;
		z-index: 1;

		&:hover {
			background-image: svg-load(play.svg, fill: transparent, stroke: white);
		}
	}

	&__custom-pause {
		background-image: svg-load(pause.svg, fill: $orange, stroke: $orange);
		background-size: cover;
		z-index: 1;

		&:hover {
			background-image: svg-load(pause.svg, fill: white, stroke: white);
		}
	}

	&__custom-control {
		display: flex;

		@include sm {
			justify-content: space-between;
		}

		@include lg {
			justify-content: initial;
		}

		&:not(:first-child) {
			padding-top: 25px;

			@include md {
				padding-top: 50px;
			}
		}
	}

	&__custom-audiobar-wrapper {
		margin-right: 15px;
		width: 60%;

		@include sm {
			width: 210px;
		}

		@include md {
			margin-right: 20px;
			width: 380px;
		}

		@include lg {
			width: 473px;
		}
	}

	@mixin thumb() {
		-webkit-appearance: none;
		appearance: none;
		width: 19px;
		height: 19px;
		border: none;
		border-radius: 50%;
		background-color: $orange;
		cursor: pointer;
	}

	&__custom-audiobar {
		position: relative;
		top: calc(50% - 10px);
		height: 20px;
		-webkit-appearance: none;
		width: 100%;
		border-radius: 0px;
		outline: none;

		-webkit-transition: 0.2s;
		transition: opacity 0.2s;

		&:hover {
			opacity: 1;
		}

		//CROME
		&::-webkit-slider-thumb {
			@include thumb();
		}

		@media screen and (-webkit-min-device-pixel-ratio: 0) {
			& {
				position: relative;
				top: calc(50% - 6px);
				-webkit-appearance: none;
				width: 100%;
				height: 6px;
				border-radius: 0px;
				background-color: $grey-1;
				outline: none;
			}
		}

		//FIREFOX
		&::-moz-range-thumb {
			@include thumb();
		}

		//IE
		&::-ms-thumb {
			@include thumb();
		}

		&::-ms-track {
			background-color: $grey-1;
			height: 6px;
			border: none;
		}

		&::-ms-fill-lower {
			background-color: #fff;
		}

		&::-ms-fill-upper {
			background-color: $grey-1;
		}
	}

	&__custom-time {
		@include font('regular', 15px, 15px, 0);
		color: $grey-2;
		user-select: none;
		line-height: 65px;
		display: table-cell;
		vertical-align: middle;
	}
}
