.shariff:after,
.shariff:before {
	content: ' ';
	display: table;
}
.shariff:after {
	clear: both;
}
.shariff ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.shariff li {
	overflow: hidden;
}
.shariff li,
.shariff li a {
	height: 35px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.shariff li a {
	color: #fff;
	position: relative;
	display: block;
	text-decoration: none;
}
.shariff li .share_count,
.shariff li .share_text {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	vertical-align: middle;
	line-height: 35px;
}
.shariff li .fab,
.shariff li .far,
.shariff li .fas {
	width: 35px;
	line-height: 35px;
	text-align: center;
	vertical-align: middle;
}
.shariff li .share_count {
	padding: 0 8px;
	height: 33px;
	position: absolute;
	top: 1px;
	right: 1px;
}
.shariff .orientation-horizontal li {
	-webkit-box-flex: 1;
}
.shariff .orientation-horizontal .info {
	-webkit-box-flex: 0;
}
.shariff .orientation-horizontal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.shariff .orientation-horizontal li {
	float: left;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 35px;
	margin-right: 3%;
	margin-bottom: 10px;
}
.shariff .orientation-horizontal li:last-child {
	margin-right: 0;
}
.shariff .orientation-horizontal li .share_text {
	display: block;
	text-indent: -9999px;
	padding-left: 3px;
}
.shariff .orientation-horizontal li .share_count {
	display: none;
}
.shariff .theme-grey .shariff-button a {
	background-color: #b0b0b0;
}
.shariff .theme-grey .shariff-button .share_count {
	background-color: #ccc;
	color: #333;
}
.shariff .theme-white .shariff-button {
	border: 1px solid #ddd;
}
.shariff .theme-white .shariff-button a {
	background-color: #fff;
}
.shariff .theme-white .shariff-button a:hover {
	background-color: #eee;
}
.shariff .theme-white .shariff-button .share_count {
	background-color: #fff;
	color: #999;
}
.shariff .orientation-vertical.button-style-icon {
	min-width: 35px;
}
.shariff .orientation-vertical.button-style-icon-count {
	min-width: 80px;
}
.shariff .orientation-vertical.button-style-standard {
	min-width: 110px;
}
.shariff .orientation-vertical li {
	display: block;
	width: 100%;
	margin: 5px 0;
}
.shariff .orientation-vertical.button-style-icon-count li .share_count,
.shariff .orientation-vertical.button-style-standard li .share_count {
	width: 24px;
	text-align: right;
}
@media only screen and (min-width: 360px) {
	.shariff .orientation-horizontal li {
		margin-right: 1.8%;
	}
	.shariff .orientation-horizontal.button-style-icon-count li,
	.shariff .orientation-horizontal.button-style-standard li {
		min-width: 80px;
	}
	.shariff .orientation-horizontal.button-style-icon-count li .share_count,
	.shariff .orientation-horizontal.button-style-standard li .share_count {
		display: block;
	}
	.shariff .orientation-horizontal.button-style-standard li {
		width: auto;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
	}
	.shariff .orientation-horizontal.button-style-standard.shariff-col-1 li,
	.shariff .orientation-horizontal.button-style-standard.shariff-col-2 li {
		min-width: 110px;
		max-width: 160px;
	}
	.shariff .orientation-horizontal.button-style-standard.shariff-col-1 li .share_text,
	.shariff .orientation-horizontal.button-style-standard.shariff-col-2 li .share_text {
		text-indent: 0;
		display: inline;
	}
	.shariff .orientation-horizontal.button-style-standard.shariff-col-5 li,
	.shariff .orientation-horizontal.button-style-standard.shariff-col-6 li {
		-webkit-box-flex: 0;
		-ms-flex: none;
		flex: none;
	}
}
@media only screen and (min-width: 640px) {
	.shariff .orientation-horizontal.button-style-standard.shariff-col-3 li {
		min-width: 110px;
		max-width: 160px;
	}
	.shariff .orientation-horizontal.button-style-standard.shariff-col-3 li .share_text {
		text-indent: 0;
		display: inline;
	}
}
@media only screen and (min-width: 768px) {
	.shariff .orientation-horizontal.button-style-standard li {
		min-width: 110px;
		max-width: 160px;
	}
	.shariff .orientation-horizontal.button-style-standard li .share_text {
		text-indent: 0;
		display: inline;
	}
	.shariff .orientation-horizontal.button-style-standard.shariff-col-5 li,
	.shariff .orientation-horizontal.button-style-standard.shariff-col-6 li {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
	}
}
@media only screen and (min-width: 1024px) {
	.shariff li,
	.shariff li a {
		height: 30px;
	}
	.shariff li .fab,
	.shariff li .far,
	.shariff li .fas {
		width: 30px;
		line-height: 30px;
	}
	.shariff li .share_count,
	.shariff li .share_text {
		line-height: 30px;
	}
	.shariff li .share_count {
		height: 28px;
	}
}
.shariff .addthis a {
	background-color: #f8694d;
}
.shariff .addthis a:hover {
	background-color: #f75b44;
}
.shariff .addthis .fa-plus {
	font-size: 14px;
}
.shariff .addthis .share_count {
	color: #f8694d;
	background-color: #f1b8b0;
}
.shariff .theme-white .addthis a {
	color: #f8694d;
}
@media only screen and (min-width: 600px) {
	.shariff .addthis .fa-plus {
		font-size: 14px;
		position: relative;
		top: 1px;
	}
}
.shariff .diaspora a {
	background-color: #999;
}
.shariff .diaspora a:hover {
	background-color: #b3b3b3;
}
.shariff .diaspora .fa-times-circle {
	font-size: 17px;
}
.shariff .theme-white .diaspora a {
	color: #999;
}
@media only screen and (min-width: 600px) {
	.shariff .diaspora .fa-times-circle {
		font-size: 16px;
	}
}
.shariff .facebook a {
	background-color: #3b5998;
}
.shariff .facebook a:hover {
	background-color: #4273c8;
}
.shariff .facebook .fa-facebook-f {
	font-size: 22px;
}
.shariff .facebook .share_count {
	color: #183a75;
	background-color: #99adcf;
}
.shariff .theme-white .facebook a {
	color: #3b5998;
}
@media only screen and (min-width: 600px) {
	.shariff .facebook .fa-facebook-f {
		font-size: 19px;
	}
}
.shariff .flattr a {
	background-color: #7ea352;
}
.shariff .flattr a:hover {
	background-color: #f67c1a;
}
.shariff .flattr a:hover .share_count {
	color: #d56308;
	background-color: #fab47c;
}
.shariff .flattr .fa-money-bill-alt {
	font-size: 22px;
}
.shariff .flattr .share_count {
	color: #648141;
	background-color: #b0c893;
}
.shariff .theme-white .flattr a {
	color: #f67c1a;
}
@media only screen and (min-width: 600px) {
	.shariff .flattr .fa-money-bill-alt {
		font-size: 19px;
	}
}
.shariff .flipboard a {
	background-color: #e12828;
}
.shariff .flipboard a:hover {
	background-color: #ff2e2e;
}
.shariff .flipboard .fa-flipboard {
	font-size: 22px;
}
.shariff .theme-white .flipboard a {
	color: #e12828;
}
@media only screen and (min-width: 600px) {
	.shariff .flipboard .fa-flipboard {
		font-size: 19px;
	}
}
.shariff .info {
	border: 1px solid #ccc;
}
.shariff .info a {
	color: #666;
	background-color: #fff;
}
.shariff .info a:hover {
	background-color: #efefef;
}
.shariff .info .fa-info {
	font-size: 20px;
	width: 33px;
}
.shariff .info .share_text {
	display: block !important;
	text-indent: -9999px !important;
}
.shariff .theme-grey .info a {
	background-color: #fff;
}
.shariff .theme-grey .info a:hover {
	background-color: #efefef;
}
.shariff .orientation-vertical .info {
	width: 35px;
	float: right;
}
@media only screen and (min-width: 360px) {
	.shariff .orientation-horizontal .info {
		-webkit-box-flex: 0 !important;
		-ms-flex: none !important;
		flex: none !important;
		width: 35px;
		min-width: 35px !important;
	}
}
@media only screen and (min-width: 1024px) {
	.shariff .info .fa-info {
		font-size: 16px;
		width: 23px;
	}
	.shariff .orientation-horizontal .info {
		width: 25px;
		min-width: 25px !important;
	}
	.shariff .orientation-vertical .info {
		width: 25px;
	}
}
.shariff .linkedin a {
	background-color: #0077b5;
}
.shariff .linkedin a:hover {
	background-color: #0369a0;
}
.shariff .linkedin .fa-linkedin-in {
	font-size: 22px;
}
.shariff .linkedin .share_count {
	color: #004785;
	background-color: #33aae8;
}
.shariff .theme-white .linkedin a {
	color: #0077b5;
}
@media only screen and (min-width: 600px) {
	.shariff .linkedin .fa-linkedin-in {
		font-size: 19px;
	}
}
.shariff .mail a {
	background-color: #999;
}
.shariff .mail a:hover {
	background-color: #a8a8a8;
}
.shariff .mail .fa-envelope {
	font-size: 21px;
}
.shariff .theme-white .mail a {
	color: #999;
}
@media only screen and (min-width: 600px) {
	.shariff .mail .fa-envelope {
		font-size: 18px;
	}
}
.shariff .print a {
	background-color: #999;
}
.shariff .print a:hover {
	background-color: #a8a8a8;
}
.shariff .print .fa-print {
	font-size: 21px;
}
.shariff .theme-white .print a {
	color: #999;
}
@media only screen and (min-width: 600px) {
	.shariff .print .fa-print {
		font-size: 18px;
	}
}
.shariff .pinterest a {
	background-color: #bd081c;
}
.shariff .pinterest a:hover {
	background-color: #d50920;
}
.shariff .pinterest .fa-pinterest-p {
	font-size: 22px;
}
.shariff .pinterest .share_count {
	color: #a31601;
	background-color: #eda79d;
}
.shariff .theme-white .pinterest a {
	color: #bd081c;
}
@media only screen and (min-width: 600px) {
	.shariff .pinterest .fa-pinterest-p {
		font-size: 19px;
		position: relative;
		top: 1px;
	}
}
.shariff .pocket a {
	background-color: #ef4056;
}
.shariff .pocket a:hover {
	background-color: #ff5066;
}
.shariff .pocket .fa-get-pocket {
	font-size: 22px;
}
.shariff .theme-white .pocket a {
	color: #ef4056;
}
@media only screen and (min-width: 600px) {
	.shariff .pocket .fa-get-pocket {
		font-size: 19px;
	}
}
.shariff .reddit a {
	background-color: #ff4500;
}
.shariff .reddit a:hover {
	background-color: #ff6a33;
}
.shariff .reddit .fa-reddit {
	font-size: 17px;
}
.shariff .theme-white .reddit a {
	color: #ff4500;
}
@media only screen and (min-width: 600px) {
	.shariff .reddit .fa-reddit {
		font-size: 16px;
	}
}
.shariff .stumbleupon a {
	background-color: #eb4924;
}
.shariff .stumbleupon a:hover {
	background-color: #ef7053;
}
.shariff .stumbleupon .fa-stumbleupon {
	font-size: 17px;
}
.shariff .theme-white .stumbleupon a {
	color: #eb4924;
}
@media only screen and (min-width: 600px) {
	.shariff .stumbleupon .fa-stumbleupon {
		font-size: 16px;
	}
}
.shariff .twitter a {
	background-color: #55acee;
}
.shariff .twitter a:hover {
	background-color: #32bbf5;
}
.shariff .twitter .fa-twitter {
	font-size: 28px;
}
.shariff .twitter .share_count {
	color: #0174a4;
	background-color: #96d4ee;
}
.shariff .theme-white .twitter a {
	color: #55acee;
}
@media only screen and (min-width: 600px) {
	.shariff .twitter .fa-twitter {
		font-size: 24px;
	}
}
.shariff .whatsapp a {
	background-color: #5cbe4a;
}
.shariff .whatsapp a:hover {
	background-color: #34af23;
}
.shariff .whatsapp .fa-whatsapp {
	font-size: 28px;
}
.shariff .theme-white .whatsapp a {
	color: #5cbe4a;
}
@media only screen and (min-width: 600px) {
	.shariff .whatsapp .fa-whatsapp {
		font-size: 22px;
	}
}
.shariff .xing a {
	background-color: #126567;
}
.shariff .xing a:hover {
	background-color: #29888a;
}
.shariff .xing .fa-xing {
	font-size: 22px;
}
.shariff .xing .share_count {
	color: #15686a;
	background-color: #4fa5a7;
}
.shariff .theme-white .xing a {
	color: #126567;
}
@media only screen and (min-width: 600px) {
	.shariff .xing .fa-xing {
		font-size: 19px;
	}
}
.shariff .tumblr a {
	background-color: #36465d;
}
.shariff .tumblr a:hover {
	background-color: #44546b;
}
.shariff .tumblr .fa-tumblr {
	font-size: 28px;
}
.shariff .theme-white .tumblr a {
	color: #5cbe4a;
}
@media only screen and (min-width: 600px) {
	.shariff .tumblr .fa-tumblr {
		font-size: 22px;
	}
}
.shariff .threema a {
	background-color: #333;
}
.shariff .threema a:hover {
	background-color: #1f1f1f;
}
.shariff .threema .fa-lock {
	font-size: 28px;
}
.shariff .theme-white .threema a {
	color: #333;
}
@media only screen and (min-width: 600px) {
	.shariff .threema .fa-lock {
		font-size: 22px;
	}
}
.shariff .weibo a {
	background-color: #f56770;
}
.shariff .weibo a:hover {
	background-color: #fa7f8a;
}
.shariff .weibo .fa-weibo {
	font-size: 28px;
}
.shariff .weibo .share_count {
	color: #0174a4;
	background-color: #f56770;
}
.shariff .theme-white .weibo a {
	color: #f56770;
}
@media only screen and (min-width: 600px) {
	.shariff .weibo .fa-weibo {
		font-size: 24px;
	}
}
.shariff .tencent-weibo a {
	background-color: #26ace0;
}
.shariff .tencent-weibo a:hover {
	background-color: #38bbeb;
}
.shariff .tencent-weibo .fa-tencent-weibo {
	font-size: 28px;
}
.shariff .tencent-weibo .share_count {
	color: #0174a4;
	background-color: #26ace0;
}
.shariff .theme-white .tencent-weibo a {
	color: #26ace0;
}
@media only screen and (min-width: 600px) {
	.shariff .tencent-weibo .fa-tencent-weibo {
		font-size: 24px;
	}
}
.shariff .telegram a {
	background-color: #08c;
}
.shariff .telegram a:hover {
	background-color: #007dbb;
}
.shariff .telegram .fa-telegram {
	font-size: 28px;
}
.shariff .theme-white .telegram a {
	color: #08c;
}
@media only screen and (min-width: 600px) {
	.shariff .telegram .fa-telegram {
		font-size: 22px;
	}
}
.shariff .qzone a {
	background-color: #2b82d9;
}
.shariff .qzone a:hover {
	background-color: #398fe6;
}
.shariff .qzone .fa-qq {
	font-size: 28px;
}
.shariff .qzone .share_count {
	color: #0174a4;
	background-color: #2b82d9;
}
.shariff .theme-white .qzone a {
	color: #2b82d9;
}
@media only screen and (min-width: 600px) {
	.shariff .qzone .fa-qq {
		font-size: 24px;
	}
}
.shariff .vk a {
	background-color: #5d7fa4;
}
.shariff .vk a:hover {
	background-color: #678eb4;
}
.shariff .vk .fa-vk {
	font-size: 22px;
}
.shariff .vk .share_count {
	color: #55677d;
	background-color: #fff;
}
.shariff .theme-white .vk a {
	color: #3b5998;
}
@media only screen and (min-width: 600px) {
	.shariff .vk .fa-vk {
		font-size: 19px;
	}
}
