.sidebar-content-less-padding {
	& .wrapper-white {
		padding-top: 0;
	}
}

.sidebar-less-margin {
	.content-element:not(:first-child) {
		margin-top: 30px;
	}

	.content-element:first-child .wrapper-white {
		padding: 0;
	}
}

.sidebar {
	@include <lg {
		margin-top: 30px;
	}
}
