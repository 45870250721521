.u-color- {
	&orange {
		&,
		&::placeholder {
			color: $orange;
		}
	}
	&blue {
		color: $darkblue;
	}

	&grey-1{
		color:$grey-1;
	}

	&grey-2{
		color:$grey-2;
	}

	&grey-3{
		color:$grey-3;
	}

	&grey-4{
		color:$grey-4;
	}

	&grey-5{
		color:$grey-5;
	}

	&grey-6{
		color:$grey-6;
	}

	&grey-7{
		color:$grey-7;
	}

	&grey-8{
		color:$grey-8;
	}

	&anthrazit{
		color:$anthrazit;
	}
}
