.frame-type-acvcontent_campaignbar:empty {
	display: none;
}

.campaign-bar {
	box-shadow: 0 -7px 7px 0 rgba(0, 0, 0, 0.2);
	position: fixed;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	bottom: 0;
	left: 0;
	background-color: $darkblue;
	padding: 25px 25px;
	text-align: center;
	z-index: 9999;
	visibility: hidden;
	pointer-events: none;
	transform: translate3d(0, 100%, 0);
	transition: transform 0.4s ease, visibility 0.4s ease;

	&.is-visible {
		pointer-events: auto;
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}

	@include <lg {
		&.is-removed {
			pointer-events: none;
			visibility: hidden;
			transform: translate3d(0, 100%, 0);
		}
	}

	@include lg {
		transform: translate3d(0, -100%, 0);
		display: flex;
		box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.2);
		bottom: initial;
		top: 0;
		flex-direction: row;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	&__brand {
		display: none;

		@include xl {
			display: block;
			margin-right: 25px;
		}

		@include xxl {
			margin-right: 100px;
		}
	}

	&__brand-link {
		background: none;
		display: block;

		@include normalizeHover;
	}

	&__brand-image {
		display: inline-block;
		vertical-align: middle;
		width: auto;
		height: 28px;
		background-size: 100%;
		background-repeat: no-repeat;
		margin-right: 14px;
		transition: height 0.2s, width 0.2s;

		@include lg {
			width: 103px;
			margin-right: 24px;
		}

		^&.is-small & {
			@include lg {
				width: 73px;
			}
		}
	}

	&__brand-caption {
		display: inline-block;
		vertical-align: middle;
		color: white;
		@include font('headline', 15px, 15px, 0);
		min-width: 110px;
		opacity: 0;
		transition: opacity 0.1s;
		text-align: left;

		@media (min-width: 350px) {
			opacity: 1;
		}

		@include lg {
			@include font('headline', 15px, 15px, 0);
		}

		@include xl {
			display: none;
		}

		@include xxl {
			display: inline-block;
		}
	}

	&__cross {
		background: transparent;
		border: 0;
		position: absolute;
		top: 10px;
		right: 10px;

		.acv-icons__after {
			color: white;
			font-size: 12px;
			line-height: 12px;
		}

		@include lg {
			display: none;
		}
	}

	&__text {
		margin-bottom: 20px;
		width: 100%;
		color: $white;
		@include font('headline', 15px, 18px, 0);

		a:hover {
			color: $orange;
		}

		@include lg {
			text-align: left;
			flex: 1 1 auto;
			margin-bottom: 0;
			width: auto;
			font-size: 18px;
			line-height: 27px;
		}

		@include xl {
			font-size: 21px;
		}
	}

	&__link {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
