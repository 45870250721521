.news {
	&__meta {
		margin-bottom: 30px;
	}

	&__meta {
		color: $grey-1;
	}

	&__headline,
	&__meta,
	&__backlinkWrapper {
		margin: 0px 0px 15px 0px;
		@include sm {
			margin: 0 40px 15px 40px;
		}
	}

	&__content {
		padding: 0 0 30px;
		@include sm {
			padding: 0 40px 40px;
		}
	}

	&__imgWrapper {
		width: auto;
		height: auto;
		margin: 30px 0;

		& img {
			width: 100%;
			height: auto;
		}
		@include md {
			width: calc(50% - 15px);
			float: right;
			margin: 0 0 15px 30px;
		}
	}

	&__related {
		margin-bottom: 30px;

		&-files {
			padding: 0;
			margin-top: 30px;
			@include sm {
				padding: 15px 40px;
			}
			&-list {
				width: 100%;
				margin: 15px 0;
				padding: 15px;
				border-top: solid 1px $grey-3;
				border-bottom: solid 1px $grey-3;
				&:last-child {
					border-top: none;
				}
				@include md {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
				}

				&-left {
					margin-right: 15px;
					word-break: break-word;
				}

				&-detail {
					margin-bottom: 5px;
					margin-right: 15px;
				}

				&-link {
					font-weight: 700;
				}

				&-download {
					display: block;
					margin-top: 15px;
					@include sm {
						margin-top: 0;
					}
				}
			}
			&-image {
				margin-bottom: 15px;
			}
		}
	}
	&__imageCaption {
		@include font('regular', 12px, normal, 0);
		min-width: 100%;
		width: 0;
		padding-top: 5px;
	}
}
