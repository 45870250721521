.pre-component-headline {
	order: 2;
	text-align: center;
	@include font('headline', 28px, 33px, 0);
	color: $white;

	@include sm {
		@include font('headline', 34px, 38px, 0);
	}

	.wrapper-white section:not([force-white]) & {
		color: $darkblue;
	}
}

.pre-component-subheadline {
	order: 1;
	text-align: center;
	@include font('headline', 13px, 15px, 3px);
	text-transform: uppercase;
	color: $darkblue;

	margin-bottom: 20px;

	@include sm {
		@include font('headline', 16px, 18px, 4.3px);
	}

	.wrapper-white section:not([force-white]) & {
		color: $grey-1;
	}
}

.pre-component-copy {
	text-align: center;
	@include font('regular', 18px, 26px, 0);
	margin-top: 30px;
	color: $white;
	order: 3;

	&:first-child {
		margin-top: 0;
	}

	&:not(:last-child) {
		margin-bottom: 50px;
	}

	@include sm {
		@include font('regular', 21px, 26px, 0);
	}

	.wrapper-white section:not([force-white]) & {
		color: $grey-1;
	}
}
