.sponsors-teaser {
	&__col {
		padding: 0 !important;
	}

	&__list {
		text-align: center;

		@include md {
			text-align: left;
			margin: 0 -15px;
		}
	}

	&__item {
		display: inline-block;
		width: 100%;
		max-width: 290px;
		vertical-align: top;

		@include md {
			width: calc(50% - 15px);
		}

		&:nth-child(odd) {
			@include md {
				margin-right: 30px;
			}
		}

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__image {
		width: 100%;
		height: 190px;
		object-fit: cover;
		box-shadow: $card-shadow;
		margin-bottom: 40px;
	}

	&__head {
		margin-bottom: 30px;
	}
}
