.nav-search {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: $white;
	color: $anthrazit;

	@include md {
		width: 375px;
		right: 0;
	}

	.is-open & {
		@include <lg {
			transform: translateX(0%);
		}
	}

	@include lg {
		justify-self: center;
		transition: unset;
		transform: unset;
		width: unset;
		height: unset;
		background-color: transparent;
	}

	&__flyout-inner {
		display: flex;
		flex-direction: column;
	}

	&__search-form {
		display: flex;
		gap: 15px;
		align-items: center;
	}

	&__search-form-inner {
		position: relative;
		display: flex;
		align-items: center;
		gap: 15px;
		width: 100%;
		height: 50px;
		padding: 16px 15px;
		background: $anthracite-5;
		border-radius: 10px;

		@include lg {
			height: 54px;
			width: 400px;

			.is-small & {
				height: 43px;
			}
		}

		@include xl {
			width: 470px;
		}
	}

	&__search-input {
		background: transparent;
		border: none;
		padding: 0;
		height: 30px;
		margin-bottom: 0 !important;
		display: block;
		width: 100%;
		outline: 0;
		color: $anthrazit;
		order: 1;

		@include lg {
			order: 2;
		}

		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}

	&__search-icon {
		order: 3;

		@include <lg {
			input:not(:placeholder-shown) ~ & {
				display: none;
			}
		}

		@include lg {
			order: 1;
		}
	}

	&__search-close {
		display: none;

		@include lg {
			position: absolute;
			top: 50%;
			right: 18px;
			transform: translateY(-50%);
			background: none;
			border: none;
			display: flex;
		}
	}

	&__search-clear {
		font-size: 0;
		border: 0;
		background: transparent;
		color: $anthrazit;
		order: 2;

		@include lg {
			transition: visibility 0.2s ease, opacity 0.2s ease;
			order: 3;
		}

		&:before {
			font-size: 27px;
			margin: 0 !important;
		}
		input:placeholder-shown ~ & {
			visibility: hidden;
			opacity: 0;
		}
	}

	&__search-submit {
		@include <lg {
			display: none !important;
		}
	}

	&__flyout {
		position: absolute;
		width: 100%;
		background-color: $anthracite-5;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
		left: 0;
		top: 100%;
		display: none;
		flex-direction: column;

		&:before {
			content: '';
			position: absolute;
			top: -10px;
			height: 10px;
			border-bottom: 1px solid $anthracite-25;
			width: 100%;
			left: 0;
			background-color: $anthracite-5;
		}
	}

	&__flyout-inner {
		@include <lg {
			max-height: calc(100vh - 157px);
			overflow: auto;
			padding-bottom: 113px;
		}
	}

	&.is-focused {
		^&__search-input[data-has-min-length='true'] {
			~ ^^&__flyout {
				&.has-autocomplete,
				&.has-searchresults {
					display: flex;
				}
			}
		}
	}

	&__flyout-autocomplete {
		display: none;
		padding: 20px;
		@include font('bold', 18px, none, normal);
		display: flex;
		flex-direction: column;
		gap: 25px;

		@include lg {
			padding: 30px 40px;
		}

		&:not(.is-visible) {
			display: none;
		}
	}

	&__spinner {
		margin: 0 auto;
		^&__flyout-autocomplete.is-visible + & {
			display: none;
		}
		^&__flyout.has-searchresults:not(.has-autocomplete) & {
			display: none;
		}
	}

	&__flyout-results {
		padding: 20px 20px 30px;

		@include lg {
			padding: 30px 40px;
		}

		^&__flyout:not(.has-searchresults) & {
			display: none;
		}

		^&__flyout-autocomplete.is-visible ~ & {
			border-top: 1px solid $anthracite-25;
		}
	}

	&__title {
		@include font('bold', 14px, 18px, 0.5px);
		text-transform: uppercase;
		color: $grey-1;
		margin-bottom: 25px;
	}

	&__search-results {
		display: flex;
		flex-direction: column;
		gap: 25px;

		.hit {
			color: $orange;
		}
	}

	&__search-result-item {
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	&__search-result-title {
		@include font('headline', 18px, 21px, normal);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__search-result-link {
		background: none;
		transition: color 0.25s ease;
		&:hover {
			background: none;
			color: $orange;
		}
	}

	&__search-result-copy {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__search-button-wrapper {
		padding: 30px 40px 40px;

		@include <lg {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background: $anthracite-5;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;

			&:before {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				width: 100%;
				height: 1px;
				background: $anthracite-25;
			}
		}

		@include lg {
			padding: 30px 0 0;
			margin-top: 30px;
		}
	}
}
