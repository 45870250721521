.ASD-alert {
	position: relative;
	color: $anthracite;
	border-radius: 12px;
	background-color: white;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 2px;
	width: 100%;

	&--success,
	&--error {
		padding-left: 42px;
	}

	&--success {
		background-color: $green-15;
	}

	&--error {
		background-color: $red-15;
	}

	&--no-padding {
		padding: 0;

		^&--success&,
		^&--error& {
			padding-left: 31px;
		}
	}

	&__icon {
		position: absolute;
		left: 11px;
		top: 11px;

		^&--no-padding & {
			left: 0;
			top: 1px;
		}

		^&--error & {
			font-size: 17px;
			color: $red;
		}

		^&--success & {
			font-size: 20px;
			color: $green;
		}

		&:before {
			margin: 0 !important;
		}
	}
}
