.survey-teaser {
	a:not([class]) {
		background-image: linear-gradient($orange, $orange);

		&:hover {
			color: $orange;
		}
	}

	.frame-type-text .rte-meta,
	.cke_editable .rte-meta,
	.rte-copy .rte-meta {
		font-size: 0.8em;
	}

	&__popup {
		cursor: pointer;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 200;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;
		transition: opacity 0.4s ease;

		&.is-invisible {
			opacity: 0;
		}
	}

	&__popup-inner {
		cursor: default;
		width: calc(100% - 50px);
		max-height: calc(100% - 50px);
		overflow: hidden;

		@include md {
			width: auto;
		}
	}

	&__popup-container {
		padding: 50px 25px 75px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		text-align: center;
		color: white;
		overflow: auto;
		max-height: calc(100vh - 50px);

		@include md {
			padding: 100px 50px;
		}

		.apple & {
			@include <md {
				padding-bottom: 178px;
			}
		}
	}

	&__popup-close {
		position: absolute;
		top: 10px;
		right: 20px;

		@include md {
			top: 15px;
			right: 30px;
		}
	}

	&__popup-headline {
		@include font('headline', 28px, 33px, 0);
		margin-bottom: 34px;

		@include sm {
			@include font('', 34px, 38px, normal);
		}
	}

	&__popup-copy {
		@include font('regular', 18px, 26px, 0);
		margin-bottom: 50px;

		@include sm {
			@include font('', 21px, 26px, normal);
		}
	}

	&__popup-link-wrapper {
		margin: -20px 0;

		@include sm {
			margin: 0 -20px;
		}

		> * {
			margin: 20px 0;

			@include sm {
				margin: 0 20px;
			}
		}
	}
}
