.tabbedpane {
	&--left {
		@include lg {
			padding-right: 0 !important;
			width: 80% !important;
		}
	}

	&--right {
		@include lg {
			padding-left: 0 !important;
			width: 25% !important;
		}
	}

	&__wrapper {
		display: flex;
		flex-direction: column;

		@include lg {
			flex-direction: row;
			height: 400px;
		}
	}

	&__cards {
		position: relative;
		height: 500px;

		@include lg {
			height: 400px;
		}
	}

	&__card {
		position: absolute;
		visibility: hidden;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.2s ease-in;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $darkblue;
			opacity: 0.4;
		}

		&:after {
			content: '';
			position: absolute;
			background-image: $darkblue-gradient;
			width: 100%;
			height: 100%;
			transform: scaleY(0.75);
			transform-origin: bottom;
			bottom: 0;
			left: 0;
			transition: transform 0.3s ease-out;
		}

		&:hover:after {
			background-image: $darkblue-gradient;
			transform: scaleY(1);
		}

		&.is-selected {
			visibility: visible;
			opacity: 1;
			z-index: 3;
		}

		&.is-hidden {
			z-index: 2;
		}

		// SUB SELECTOREN

		&-content {
			position: absolute;
			left: 50%;
			bottom: 40px;
			transform: translate3d(-50%, 0, 0);
			z-index: 1;
			width: 100%;
			padding: 0 25px;
		}

		&-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&-headline {
			color: white;
			@include font('headline', 28px, 33px, 0);
			text-align: center;
			padding-bottom: 30px;
		}

		&-copy {
			color: white;
			@include font('regular', 16px, 25px, 0);
			text-align: center;
			padding: 0 10px 30px 10px;
			max-width: 700px;
			margin: 0 auto;

			@include md {
				@include font('regular', 21px, 25px, 0);
			}
		}

		&-price {
			display: flex;
			justify-content: center;
		}
	}

	&__tabs {
		display: flex;
		box-shadow: $card-shadow;

		@include lg {
			flex-direction: column;
			height: 400px;
		}
	}

	&__tab {
		display: flex;
		height: 100px;
		width: 100%;
		min-width: 33.33%;
		position: relative;

		&:not(:first-child) {
			border-left: 1px solid $grey-3;

			@include lg {
				border-left: none;
				border-top: 1px solid $grey-3;
			}
		}

		@include lg {
			width: 100%;
			height: 100%;
			min-height: 33.33%;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			background-color: $orange;
			height: 8px;
			width: 100%;
			transform: scaleY(0);
			transform-origin: top;
			transition: transform 0.25s ease;

			@include lg {
				transform: scaleX(0);
				transform-origin: left;
				width: 8px;
				height: 100%;
			}

			^&.is-selected& {
				transform: scaleY(1);

				@include lg {
					transform: scaleX(1);
				}
			}
		}

		&-btn {
			height: 100%;
			width: 100%;
			border: none;
			background-color: white;
			transition: background-color 0.5s ease;
			padding: 8px 5px 0 5px;

			@include sm {
				padding: 8px 30px 0 30px;
			}

			@include lg {
				padding: 0 30px 0 40px;
			}

			&:hover {
				background-color: $grey-4;
			}

			&:focus {
				outline: none;
			}

			^&.is-selected & {
				background-color: $grey-4;
			}
		}

		&-headline {
			color: $darkblue;
			@include font('headline', 16px, 25px, 0);

			@include lg {
				font-size: 21px;
				text-align: left;
				padding-bottom: 10px;
			}
		}

		&-copy {
			display: none;

			@include lg {
				display: block;
			}

			p {
				@include lg {
					@include font('regular', 15px, 20px, 0);
					color: $grey-1;
					text-align: left;
				}
			}
		}
	}
}
