.fineCalculator {
	@include sm {
		padding: 0 $ce-side-padding;
	}

	#s_r_de_bussgeld_tempo1 #s_r_de_bussgeld_tempo2 #s_r_de_bussgeld_tempo3 #s_r_de_bussgeld_tempo4 #s_r_de_bussgeld_tempo5 #s_r_de_bussgeld_tempo {
		& .ui-state-default span,
		& .ui-widget-content .ui-state-default,
		& .ui-myresult .ui-mytooltip .ui-state-default .ui-mytooltip-titlebar-title {
			color: $typo-color !important;
		}

		& .ui-state-active span {
			color: $orange !important;
		}
		& .ui-widget-header {
			border: 1px solid $orange;
			background: $orange;
			color: #ffffff;
			font-weight: bold;
		}
	}
}
