.media-download {
	&__show-more-checkbox {
		visibility: hidden;
		position: absolute;
		left: -100vw;
	}

	&__show-more {
		display: flex;
		justify-content: center;
	}

	&__show-more-label {
		margin-top: 32px;
		display: inline-block;
		cursor: pointer;
		color: white;

		&:hover {
			color: white;
			background: linear-gradient(white, white);
			background-size: 1px 2px;
			background-repeat: repeat-x;
			background-position: 0% 99%;
		}

		.wrapper-white & {
			color: $orange;

			&:hover {
				color: $orange;

				background: linear-gradient($orange, $orange);
				background-size: 1px 2px;
				background-repeat: repeat-x;
				background-position: 0% 99%;
			}
		}

		&:before {
			content: 'Mehr anzeigen';
		}
		&:after {
			content: '+';
		}

		.media-download__show-more-checkbox:checked ~ ^&__show-more & {
			&:before {
				content: 'Weniger anzeigen';
			}
			&:after {
				content: '–';
			}
		}
	}

	&__file-more-wrapper {
		margin-top: 32px;
	}

	&__file-more {
		width: 100%;
		display: none;
		^&__show-more-checkbox:checked ~ & {
			display: block;
		}
	}

	&__wrapper {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			position: absolute;
			left: 0;
			top: 0;
			background-color: $grey-3;

			^^&--darkblue & {
				background-color: $grey-1;
			}

			@include sm {
				width: calc(100% - 2 * $ce-side-padding);
				left: $ce-side-padding;
			}
		}

		^&--transparent & {
			background-color: transparent;
		}

		^&--darkblue & {
			background-color: $darkblue;
		}

		@include sm {
			background-color: transparent;
		}

		@include md {
			padding: 0 $ce-side-padding;
			min-height: 96px;
			display: flex;
			justify-content: space-between;
		}
	}

	&__head {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		padding: 20px 17px;

		@include md {
			padding: 20px 50px 20px 0;
			min-height: 96px;
		}
	}

	&__header {
		@include font('headline', 16px, 19px, 0.7px);
		text-align: center;
		padding-bottom: 15px;

		@include md {
			font-size: 21px;
			line-height: 25px;
			text-align: left;
			padding-bottom: 0;
			padding-top: 0;
		}

		^&--transparent & {
			color: $darkblue;
		}

		^&--darkblue & {
			color: white;
		}
	}

	&__subheader {
		@include font('regular', 15px, 20px, 0.4px);
		text-align: center;

		@include md {
			width: 100%;
			text-align: left;
			padding-bottom: 0;
			padding-top: 10px;
		}

		^&--transparent & {
			color: $darkblue;
		}

		^&--darkblue & {
			color: white;
		}
	}

	&__file {
		padding-bottom: 30px;
		display: flex;
		justify-content: center;
		min-width: 145px;
		flex-shrink: 0;

		@include md {
			align-self: center;
			padding-bottom: 0;
		}
	}

	&__btn_ {
		^&--transparent & {
			border-color: $darkblue;
		}

		^&--darkblue & {
			border-color: $orange;
			color: white;

			&:hover {
				background-color: $orange;
			}
		}
	}
}

// .frame-type-acvcontent_mediadownload + .frame-type-acvcontent_mediadownload {
// 	margin-top: 0 !important;
// }
