.ASD-tooltip {
	z-index: 100;
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100%;
	pointer-events: none;

	color: $anthracite;

	.rte-orange {
		color: $orange;
	}

	&__button {
		pointer-events: auto;
		border: 0;
		font-size: 0;
		transform: translateY(2px);

		&:before {
			font-size: 16px;
			margin: 0 !important;
			display: inline-block;
		}
	}

	&__popup {
		position: absolute;
		background-color: white;
		pointer-events: auto;

		cursor: pointer;

		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		max-width: 332px;

		border-radius: 3px;
		padding: 28px 12px 12px 12px;

		@include font('regular', 15px, 19px, 0px);
		text-transform: none;
		hyphens: auto;
		text-align: center;
		text-wrap: balance;
		filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.25));

		@include <sm {
			left: 50% !important;
			transform: translate(-50%, -50%);
		}

		@include sm {
			transform: translate(-50%, 20px);
			flex-direction: column;
			@include font('', 13px, 16px, 0px);
			width: 254px;
			text-align: left;
		}

		&:before {
			content: '$acv-icons-var-cross';
			position: absolute;
			right: 13px;
			top: 8px;
			@include acv-icons-mixin();
			margin-right: unset;
			font-size: 8px;
			color: $anthracite;
			z-index: 10;
			opacity: 0.5;
		}

		&:after {
			content: '';
			position: absolute;

			top: calc(50% - 5px);
			right: 100%;
			margin-left: -10px;
			border-width: 5px;
			border-right-width: 9px;
			border-style: solid;
			border-color: transparent $white transparent transparent;

			^&--up&,
			^&--down& {
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				margin-left: 0;
				right: initial;
				left: calc(50% - 5px);
			}

			^&--up& {
				border-top: 9px solid $white;
				top: 100%;
			}

			^&--down& {
				border-bottom: 9px solid $white;
				bottom: 100%;
				top: initial;
			}

			@include <sm {
				content: unset;
			}
		}
	}

	&__image {
		width: 100%;
		max-width: 230px;
		height: auto;
		margin: 0 auto 16px;

		@include sm {
			margin-bottom: unset;
			margin-top: 12px;
		}
	}
}
