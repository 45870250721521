.u-append {
	&:after {
		position: relative;
		font-size: inherit;
		font-family: acv-icons !important;
		font-style: normal;
		vertical-align: baseline;
		font-weight: normal !important;
		display: inline-block;
		margin-left: 4px;
	}

	&--x {
		&:after {
			content: '$acv-icons-var-cross';
			transform: translateY(19%) scale(0.7);
		}
	}

	&--check {
		&:after {
			content: '$acv-icons-var-checkmark';
			transform: translateY(19%) scale(0.7);
		}
	}
}
