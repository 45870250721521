.user-documents {
	&__headline {
		color: $anthrazit;
		margin-bottom: 15px;
	}

	&__copy {
		margin-bottom: 40px;
	}

	&__list-item {
		list-style: none;
		display: flex;
		padding: 20px 0 19px;
		border-top: 1px solid $grey-3;
		flex-direction: column;
		position: relative;

		@include md {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		&:last-child {
			border-bottom: 1px solid $grey-3;
		}

		&--empty {
			padding: 32px 0 34px;

			.rte {
				display: flex;
				align-items: center;
				p {
					@include font('', 15px, 28px, normal);
					font-style: italic;
				}
				&:before {
					font-size: 20px;
					margin-right: 10px;
				}
			}
		}
	}

	&__list-item-content {
		min-width: 0;

		@include md {
			margin-bottom: 20px;
			text-align: center;
			padding-right: 30px;
			text-align-last: left;
			margin-bottom: 0;
		}
	}

	&__list-item-headline {
		color: $anthrazit;
		@include font('', 16px, 19px, 0.7px);

		@include md {
			@include font('', 21px, 25px, normal);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&__list-item-date {
		margin-top: 10px;
	}

	&__link {
		@include <md {
			opacity: 0;
			position: absolute;
			left: 0;
			top: 0;
			size: 100%;
		}
	}

	&__download-icon {
		position: absolute;
		right: 0;
		bottom: 20px;
		color: $anthrazit;
		@include md {
			display: none;
		}
	}

	&__pagination {
		margin-top: 20px;

		@include md {
			margin-top: 40px;
		}
	}
}
