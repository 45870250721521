.bounty-selection {
	.table {
		width: calc(100% - 1px);
		border-collapse: collapse;

		thead {
			background: linear-gradient(180deg, white 0%, #f3f3f3 100%);
			box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.22);

			th {
				text-align: left;
				padding: 18px 20px 13px;
				@include font('headline', 17px, 19px, 0);
				font-weight: 600;
			}
		}

		tbody {
			background-color: white;

			td {
				padding: 15px 20px;
				@include font('regular', 13px, 16px, 0);

				a {
					background-image: linear-gradient(#f28b10, #f28b10);

					&:hover {
						color: $orange;
					}
				}
			}
		}

		tr {
			border: 1px solid $grey-3;
		}
	}

	&__col1 {
		margin-bottom: 50px;

		@include xl {
			margin-bottom: 0;
		}
	}

	&__tableWrapper {
		width: 100%;
		overflow-y: auto;
		margin-top: 30px;
	}

	&__intro {
		margin-bottom: 50px;

		:--headlines {
			margin-bottom: 20px;
			color:$anthrazit;
		}
	}

	&__intro-copy {
		> * + p {
			margin-top: 10px;
			@include font('regular', 15px, 20px, 0);
		}
	}

	&__sub-head {
		@include font('headline', 21px, 25px, 0);
		margin-bottom: 25px;
		color:$anthrazit;
	}

	&__text {
		margin-bottom: 30px;
	}

	&__available {
		margin-bottom: 50px;
	}

	&__available-list {
		display: flex;
	}

	&__available-item {
		margin-right: 15px;
		max-width: 200px;
	}

	&__available-image {
		width: 100%;
		height: auto;
		margin-bottom: 10px;
	}
}
