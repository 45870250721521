.twoColGrid {
	&--even-columns {
		@include lg {
			display: flex;
		}
	}
	&__col {
		& + & {
			margin-top: 25px;

			@include lg {
				margin-top: initial;
			}
		}

		&--white {
			position: relative;
			padding: 60px 0;

			&::before {
				content: '';
				box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
				position: absolute;
				left: 0;
				width: 100%;
				top: 0;
				display: block;
				height: 100%;
				background: white;

				@include sm {
					left: 15px;
					width: calc(100% - 30px);
				}

				@each $val, $i in sm, md, lg, xl, xxl {
					@include $(val) {
						left: calc($gutter-$(val) / 2);
						width: calc(100% - $gutter-$(val));
					}
				}
			}
		}
	}

	.content-element {
		position: relative;
		z-index: 1;
	}
}
