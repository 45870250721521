.card-grid {
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	transition: opacity 250ms ease, visiblity 250ms ease;
	&.is-visible {
		visibility: visible;
		opacity: 1;
	}

	*:hover > .orangeLine {
		transform: scaleX(0.19);
	}

	.orangeLine {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1;
		width: 100%;

		.news & {
			position: relative;
		}

		&--top {
			top: 0;
		}
	}

	&__item-row:hover .orangeLine:not(.is-static):not(.orangeLine--top) {
		transform: scale(1);
	}

	&__item-row:hover .btn--linkIconRight {
		background-image: linear-gradient($orange, $orange);
	}

	&__intro {
		margin-bottom: 50px;
	}

	&__categories {
		margin-bottom: 50px;
	}

	&__categories-list {
		border-bottom: 1px solid rgba(255, 255, 255, 0.29);
		text-align: center;

		@include md {
			text-align: left;
		}
	}

	&__categories-item {
		display: inline-block;

		&:not(:last-child) {
			margin-right: 20px;

			@include md {
				margin-right: 30px;
			}
		}
	}

	&__categories-button {
		background: transparent;
		padding: 14px 0;
		border: 0;
		outline: 0;
		@include font('black', 12px, 14px, 0.8px);
		color: white;
		text-transform: uppercase;
		position: relative;

		&.is-selected::after,
		&:hover::after {
			transform: scale3d(1, 1, 1);
		}

		&::after {
			position: absolute;
			bottom: 0;
			height: 4px;
			left: 0;
			content: '';
			width: 100%;
			transform: scale3d(1, 0, 1);
			background-color: white;
			transform-origin: bottom;
			transition: transform 0.25s ease;
		}
	}

	&__items {
		position: relative;
		margin: -15px;
	}

	&__item {
		display: block;
		position: absolute;
		margin: 15px;
		width: calc(100% - 30px);

		@include md {
			width: calc(50% - 30px);
		}

		@include xl {
			width: calc(33.3% - 30px);
		}

		&-inner {
			height: 100%;
			background: white;
			box-shadow: $card-shadow;
		}
	}

	&__item-row {
		position: relative;
	}

	&__image-wrapper {
		position: relative;
		font-size: 0;
		display: block;
		size: 100%;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(transparent, #293b49);
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

			^^&__item-row:hover & {
				opacity: 1;
				visibility: visible;
			}
		}

		&--small {
			height: 200px;
		}

		&--big {
			height: 400px;
		}

		&--image {
			// Nothing sepcial to do
		}
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;

		^&__image-wrapper--image & {
			position: relative;
			height: auto;
			object-fit: initial;
		}
	}

	&__text-wrapper {
		padding: 40px 15px 20px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		position: relative;
		z-index: 2;

		@include md {
			padding: 40px 40px 30px;
		}

		&--private {
			background-color: $grey-4;
			z-index: 1;

			.rte-copy > p {
				color: $typo-color;
			}

			a {
				background-image: linear-gradient($orange, $orange);

				&:hover {
					color: $orange;
				}
			}
		}

		&--shadow {
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.22);
			overflow: hidden;
		}
	}

	&__headline {
		@include font('headline', 24px, 28px, 0);
		margin-bottom: 30px;
		color: $darkblue;
		order: 2;
	}

	&__prehead {
		order: 1;
		color: $orange;
		@include font('regular', 15px, 20px, 0);
		margin-bottom: 20px;
	}

	&__copy {
		order: 3;

		> p {
			@include font('regular', 16px, 25px, 0);
			color: $grey-1;

			@include md {
				@include font('regular', 15px, 20px, 0);
			}
		}
	}

	&__link-wrapper {
		order: 4;
		margin-top: 30px;

		@include md {
			margin-top: 40px;
		}
	}

	&__link {
		// &:hover &::before {
		// 	height: 2px;
		// }

		.news ^&__item:hover & {
			background-image: linear-gradient($orange, $orange);
		}
	}

	&__cover-link {
		font-size: 0;
		background: none;
		z-index: 6;
		&:hover {
			background: none;
		}
	}

	&__buttonWrapper {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}
}
