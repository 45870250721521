.news {
	.ce-header + & {
		margin-top: 50px;
	}
}

.news-list-view {
	@include sm {
		padding: 0 40px;
	}
}

.newslist {
	& + & {
		margin-top: 30px;
		padding-top: 30px;
		border-top: solid 1px #d4d4d4;

		@include sm {
			margin-top: 40px;
			padding-top: 40px;
		}
	}

	&__meta {
		margin-bottom: 13px;
		color: $grey-1;
	}

	&__header {
		margin-bottom: 29px;

		a {
			@include font('headline', 24px, 28px, normal);
		}

		.e-paper & {
			margin-bottom: 20px;
		}
	}

	&__mehrerfahren {
		margin-top: 25px;
		display: inline-block;

		@include sm {
			margin-top: 48px;
		}
	}

	&__contentRow {
		display: flex;
		flex-direction: column;

		@include sm {
			flex-direction: row;
		}
	}

	&__imageWrapper {
		margin-bottom: 48px;

		@include sm {
			margin-right: 60px;
			margin-bottom: 0;
		}
	}

	&__image {
		max-width: 145px;
		height: auto;
		margin: 0 auto;
	}

	&__details {
		@include md {
			column-count: 2;
		}

		@include lg {
			column-count: 1;
		}

		@include xl {
			column-count: 2;
		}
	}

	&__details-item {
		padding-left: 25px;
		position: relative;

		&:not(:last-child) {
			margin-bottom: 15px;
		}

		&::before {
			content: '';
			position: absolute;
			size: 6px;
			border-radius: 50%;
			background: $typo-color;
			top: 0.5em;
			left: 0;
		}
	}
}

.pagination {
	&__wrapper {
		margin-top: 60px;
		display: flex;
		justify-content: center;
	}

	&__pages {
		display: flex;
		align-items: center;
		@include font('bold', 24px, normal, 0);

		& li {
			display: flex;
			align-items: flex-end;
			font-size: 15px;
			line-height: 20px;
			padding: 2px 0;
		}

		& li a {
			padding: 0;
			color: $darkblue;
		}

		& li:not(:last-child) {
			margin-right: 30px;
		}

		& li a:hover {
			background-image: none;
			color: $orange;
		}

		.acv-icons__before {
			padding-bottom: 2px;
			color: $darkblue;
		}

		&-current {
			color: $orange;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $orange;
				bottom: 0;
				left: 0;
			}
		}

		& .acv-icons__before {
			@include font('regular', 20px, normal, 0);
		}
	}

	&__arrow {
		transform: translateY(5px);

		& a {
			transition: transform 250ms ease;
			&:hover {
				color: $typo-color !important;
				transform: scale(1.2);
			}
		}
	}
}
