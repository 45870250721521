@svg-load em-contact url(contact.svg) {
	fill: transparent;
	path:first-child {
		stroke: $white;
	}
}

@svg-load em-contact-hover url(contact.svg) {
	fill: transparent;
	path:first-child {
		stroke: $orange;
	}
}

.emergency-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: 500;
	padding: 0 15px;

	&__shadowbox {
		background-color: rgba($darkblue, 0.9);
		position: absolute;
		left: 0;
		top: 0;
		size: 100%;
		z-index: 5;
	}

	&__step {
		position: relative;
		z-index: 10;
		height: 100%;
		display: flex;
		flex-direction: column;

		&--center {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&&__close-hitarea {
		z-index: 1;
	}

	&__button-wrapper {
		z-index: 2;
		margin-top: auto;
		margin-bottom: 150px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	&__button {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		& + & {
			margin-top: 10px;
		}
	}

	&__button-icon {
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 5px;

		&--towing {
			width: 30px;
			height: 24px;
			background-image: svg-load('towing.svg', fill: $white);

			^^&__button:hover & {
				background-image: svg-load('towing.svg', fill: $orange);
			}
		}

		&--contact {
			width: 27px;
			height: 21px;
			background-image: svg-inline(em-contact);

			^^&__button:hover & {
				background-image: svg-inline(em-contact-hover);
			}
		}
	}

	&__card {
		z-index: 2;
		max-width: 400px;
		background-color: white;
		padding-bottom: 30px;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		max-height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__card-header {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		background-color: $darkblue;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 52px;
		flex: 0 0 auto;
	}

	&__card-icon {
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 5px;
		width: 30px;
		height: 24px;
		background-image: svg-load('towing.svg', fill: $orange);
	}

	&__card-headline {
		color: white;
		@include font('bold', 12px, 14px, 0.8px);
		text-transform: uppercase;
	}

	&__card-content {
		padding: 20px 20px 0;
		@include font('bold', 15px, 18px, 0.8px);
		text-align: center;
		overflow: auto;
	}

	&__card-image {
		margin-top: 20px;
		width: 100%;
		height: auto;
	}

	&__card-footer {
		padding: 40px 20px 0;
		flex: 0 0 auto;
	}

	&__card-button {
		width: 100%;
		text-align: center;

		&--ok {
			padding-top: 12px;
			padding-bottom: 12px;
		}

		&--cancel {
			font-size: 12px;
			line-height: 14px;
			margin-top: 14px !important;
			color: $darkblue;
			&:hover {
				background: none;
			}
		}
	}
}
