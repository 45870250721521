.ASD-add-button {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
	height: 21px;
	border: 0;
	outline: none;
	background: none;
	color: $anthracite;
	@include font('bold', 14px, 120%, normal);
	transition: color 250ms ease;

	&:focus-visible,
	&:hover {
		color: $orange;
	}

	&[disabled] {
		color: $anthracite-60;
	}

	&:before {
		margin: 0 !important;
		font-size: 22px;
	}
}
