.bigBanderole {
	.acv-void {
		min-height: 200px;
		position: relative;
		display: flex;
		flex-direction: column;

		@include lg {
			flex-direction: row;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 30px 0;

		padding: 0 30px;

		@include sm {
			padding: 0 15px;
			margin: 50px 0;
		}

		@include md {
			padding: 0;
		}

		@include lg {
			align-items: flex-start;
		}
	}

	&__wrapper {
		overflow: hidden;
		^&--white & {
			background-color: white;
			box-shadow: $card-shadow;

			.wrapper-white &,
			[data-style='white'] & {
				box-shadow: none;
			}
		}
	}

	&__headline {
		@include font('headline', 20px, 25px, 0);
		margin-bottom: 20px;
		text-align: center;
		color: $white;

		^&--white &,
		.wrapper-white &,
		[data-style='white'] & {
			color: $darkblue;
		}

		@include lg {
			@include font('headline', 24px, 28px, 0);
			text-align: left;
		}
	}

	&__copy {
		color: $grey-1;
		text-align: center;

		@include lg {
			text-align: left;
		}

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__btnWrapper {
		display: flex;
		justify-content: center;

		@include lg {
			justify-content: flex-start;
		}
	}

	&__imageContainer {
		width: 100%;
		height: 250px;

		@include lg {
			position: relative;
			height: 100%;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;

		@include lg {
			position: absolute;
		}

		&--contain {
			object-fit: contain;
		}
	}
}
