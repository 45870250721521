.search-results {
	&__no-results {
		margin-top: 12px;
		a {
			font-weight: bold;
			background: none !important;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__too-short {
		margin-bottom: 30px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: 48px;

		@include lg {
			gap: 64px;
		}
	}

	&__list-item {
		position: relative;
	}

	&__link {
		background: none !important;
		display: flex;
		flex-direction: column;
		gap: 16px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: -20px;
			top: -17px;
			width: calc(100% + (2 * 20px));
			height: calc(100% + (2 * 17px));
			background-color: $grey-9;
			visibility: hidden;
			opacity: 0;
			transition: visibility 0.25s ease, opacity 0.25s ease;

			@include sm {
				border-radius: 12px;
			}

			^&:hover& {
				visibility: visible;
				opacity: 1;
			}
		}

		> * {
			position: relative;
		}
	}

	.hit {
		color: $orange;
	}

	.kesearch_pagebrowser {
		display: flex;
		justify-content: center;

		ul {
			position: relative;
			display: flex;
			gap: 20px;
			justify-content: center;
			align-items: center;
			min-width: 255px;
			@include font('bold', 18px, 21px, 0);
		}

		a {
			display: block;

			&.current {
				color: $orange;
			}

			&:hover {
				background-image: none;
				color: $orange;
			}

			&.next,
			&.prev {
				height: 100%;
				width: 30px;
				position: relative;
				font-size: 0;
				&:before {
					position: absolute;
					top: 50%;
					transform: translateY(-50%) translateY(-2px);
					display: block;
					font-size: 30px;
					margin: 0 !important;
				}

				&:not(.active) {
					pointer-events: none;
					color: $anthracite-20;
				}
			}

			&.prev {
				&:before {
					top: 50%;
					transform: translateY(-50%) translateY(-2px) rotate(180deg);
				}
			}
		}
	}

	&__num {
		@include font('headline', 32px, 38px, normal);
		margin-bottom: 34px;

		@include lg {
			@include font('headline', 36px, 42px, normal);
			margin-bottom: 24px;
		}
	}

	&__id {
		@include font('regular', 24px, 28px, normal);
		display: inline-block;
	}

	&__title {
		@include font('headline', 20px, 24px, normal);

		@include lg {
			@include font('headline', 24px, 28px, normal);
			display: flex;
			gap: 5px;
		}
	}
}
