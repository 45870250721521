.search {
	position: relative;
	background: $darkblue;
	padding: 40px 0;

	&::after {
		content: '';
		top: 0;
		display: block;
		width: 150vw;
		height: 100%;
		position: absolute;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		background: $darkblue;
		z-index: 0;

		@include sm {
			display: none;
		}
	}

	&__holder {
		position: relative;
		z-index: 2;

		@include sm {
			padding: 0 $ce-side-padding;
		}
	}

	&__head {
		@include font('headline', 28px, 32px, 0);
		text-align: center;
		color: white;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__label {
		@include font('regular', 15px, 20px, 0);
		display: block;
		color: $grey-2;

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	&__form {
		display: flex;
		position: relative;
	}

	&__input {
		display: block;
		width: 100%;
		height: 58px;
		padding-left: 10px !important;
		padding-right: 60px !important;
		border-radius: 0px;
	}

	&__button {
		position: absolute;
		font-size: 0;
		background: $orange;
		border: 4px solid white;
		size: 50px;
		border: 0;
		top: 4px;
		right: 4px;
		cursor: pointer;

		&:before {
			color: white;
			font-size: 25px;
			transform: translateY(-50%) scaleX(-1);
			display: block;
			margin-right: 0;
			width: 100%;
			position: absolute;
			left: 0;
			top: 50%;
		}
	}
}
