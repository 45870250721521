.downloadGrid {
	&__wrapper {
		padding: 0;
		@include sm {
			padding: 0 40px;
		}
	}

	&__gridwrapper {
		margin-top: 50px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		@include sm {
			flex-direction: row;
		}
	}
	&__item {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 20px 15px;
		border: solid 1px $grey-3;
		transition: background-color 250ms ease-out;
		width: 100%;

		&:not(:first-child) {
			border-top: 0;
		}
		@include lg {
			flex-direction: column;
			padding: 30px 20px;
			align-items: flex-start;
			width: 33.3%;

			&:not(:first-child) {
				border-top: solid 1px $grey-3;
			}

			&:nth-child(3n + 2) {
				border-left: 0;
			}

			&:nth-child(3n + 3) {
				border-left: 0;
			}

			&:nth-child(3n + 4),
			&:nth-child(3n + 5),
			&:nth-child(3n + 6) {
				border-top: 0;
			}
		}

		&:hover {
			background-color: $grey-4;
		}

		&:hover .downloadGrid__btn {
			background-image: linear-gradient(#f28b10, #f28b10);
		}

		&-content {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			width: 60%;
			@include lg {
				display: flex;
				flex-direction: column;
				align-items: initial;
				height: 100%;
				width: 100%;
			}

			& span {
				color: $orange;
				font-size: 2rem;
				height: 50px;
				display: block;
				display: flex;
				align-items: flex-start;
				padding-right: 15px;
				@include lg {
					font-size: 3rem;
					padding-right: 0;
					align-items: center;
				}
			}
			& .acv-icons--liste {
				font-size: 1.2rem;
				@include lg {
					font-size: 1.6rem;
				}
			}
		}
	}

	&__name {
		padding: 0 0 10px 0;
		hyphens: auto;
		width: 100%;
		@include lg {
			padding: 20px 0;
		}
		&-headline {
			padding-bottom: 5px;
			line-height: 22px;
		}
	}

	&__btn {
		z-index: 1;
		// margin-top: auto;
	}

	&__link {
		font-size: 0;
		background: none;
		z-index: 0;
		&:hover {
			background: none;
			text-decoration: none;
		}
	}
}
