$quizTextShadow: 0 0 30px rgba(0, 0, 0, 0.5);
.quiz {
	&__screen {
		display: none;
		background-color: $grey-4;
		box-shadow: $card-shadow;

		&.is-active {
			display: block;
		}
	}

	&__wrapper {
		@include <sm {
			padding: 0 !important;
		}
	}

	&__center-wrapper {
		width: 100%;
		position: relative;
		@include lg {
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			padding: 50px 0;
		}

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1 0 auto;

		&--relative {
			@include lg {
				position: relative;
			}
		}
	}

	&__screen-content {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 50px calc($gutter / 2);

		@include lg {
			min-height: 770px;
			padding-left: 0;
			padding-right: 0;
		}

		&--bg-image {
			background-size: cover;
			background-position: center;
		}

		&--blue {
			background-color: $darkblue;
		}

		&--white {
			background-color: $white;
		}

		@include <lg {
			&--no-padding-on-mobile {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&--no-padding-bottom {
			padding-bottom: 0;
		}

		&--big-mobile-padding {
			@include <lg {
				padding-top: 270px;
				padding-bottom: 270px;
			}
		}
	}

	// INTRO

	&__intro-content {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		@include md {
			padding: 0 calc($gutter / 2);
		}

		@include lg {
			padding: 0 $ce-side-padding;
			width: 825px;
		}

		^&--white & {
			color: $white;
		}

		^&--black & {
			color: $typo-color;
		}
	}

	&__intro-headline,
	&__intro-subheadline {
		@include font('headline', 34px, 36px, normal);
		width: 100%;

		&:not(.no-shadow) {
			text-shadow: $quizTextShadow;
		}
	}

	&__intro-subheadline {
		margin-top: 20px;
	}

	&__intro-copy-holder {
		@include font('regular', 21px, 26px, normal);
		margin-top: 30px;
		width: 100%;

		&:not(.no-shadow) {
			text-shadow: $quizTextShadow;
		}
	}

	&__intro-button-holder {
		margin-top: 30px;
		display: flex;
		flex-direction: column;

		@include sm {
			display: block;
		}
	}

	&__footer {
		width: 100%;
		padding: 30px calc($gutter / 2);

		@include lg {
			margin: 0 auto;
			padding: 30px $ce-side-padding;
			width: 825px;
		}
	}

	// DETAIL

	&__detail-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: $white;

		@include md {
			padding: 0 calc($gutter / 2);
		}

		@include lg {
			width: 825px;
			padding: 0 $ce-side-padding;
			margin: 0 auto;
		}
	}

	&__detail-heads {
		display: flex;
		flex-direction: column-reverse;
		text-shadow: $quizTextShadow;
	}

	&__detail-headline {
		@include font('headline', 34px, 38px, normal);
		margin-bottom: 30px;
		width: 100%;
		text-align: center;
	}

	&__detail-subhead {
		@include font('headline', 16px, 18px, 4.3px);
		text-transform: uppercase;
		margin-bottom: 20px;
		width: 100%;
		text-align: center;
	}

	&__detail-copy-holder {
		@include font('regular', 21px, 26px, normal);
		text-shadow: $quizTextShadow;
		width: 100%;
	}

	&__detail-button-holder {
		margin-top: 30px;
	}

	// INDICATOR
	$quiz-step-indicator-bar: $orange;
	$quiz-step-indicator-track: rgba(242, 139, 16, 0.1);
	&__step-indicator {
		margin-bottom: 50px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: none;
		height: 6px;
		background-color: $quiz-step-indicator-track;
		color: $quiz-step-indicator-bar;
		width: 100%;

		@include md {
			width: 610px;
		}

		&::-webkit-progress-bar {
			background: $quiz-step-indicator-track;
		}

		&::-moz-progress-bar {
			background: $quiz-step-indicator-bar;
		}

		&::-webkit-progress-value {
			background: $quiz-step-indicator-bar;
		}
	}

	// QUESTIONS

	&__questions {
		display: flex;
		flex: 1 0 auto;
		flex-direction: column;
		width: 100%;

		@include xl {
			width: auto;
		}
	}

	&__question {
		display: none;
		flex-direction: column;
		justify-content: space-between;

		@include lg {
			padding: 0 calc($gutter / 2);
			flex: 1 1 auto;
		}

		&.is-active {
			display: flex;
		}

		&--align-top {
			justify-content: flex-start;
		}
	}

	&__question-headline {
		hyphens: auto;
		@include font('headline', 25px, 28px, regular);
		width: 100%;
		color: $darkblue;
		margin-bottom: 85px;
		margin: 0 auto 80px auto;
		text-align: center;

		@include lg {
			font-size: 34px;
			line-height: 40px;
			hyphens: initial;
			width: 825px;
			padding: 0 $ce-side-padding;
		}
	}

	&__question-image {
		width: 75%;
		max-height: 100px;
		height: auto;
		margin: 0 auto 40px auto;

		@include lg {
			max-height: 200px;
			width: 825px;
		}
	}

	// ANSWERS

	&__answer-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-left: 40px;
		position: relative;

		@include md {
			padding-left: 0;
			align-items: center;
		}

		.is-vertical & {
			flex-direction: row;
		}
	}

	&__answer-images {
		display: none;
		justify-content: space-between;
		align-items: center;

		&:not(:empty) {
			margin-bottom: 40px;
		}

		@include md {
			display: flex;
		}
	}

	&__answer-image-wrapper {
		width: 100%;
		&:not(:empty) {
			height: 200px;
		}

		@include md {
			max-width: calc(33% - 20px);
		}

		@include lg {
			width: 245px;
		}
	}

	&__answer-image {
		width: 100%;
		height: 100%;
		object-fit: contain;
		//height: auto;
		//max-height: 200px;
		margin: 0 auto;

		&--mobile {
			max-width: 75%;
			max-height: 100px;
			margin-bottom: 20px;
			@include md {
				display: none;
			}
		}
	}

	&__answer-headlines {
		display: none;
		justify-content: space-between;
		align-items: center;

		@include md {
			width: 100%;
			display: flex;
		}
	}

	&__answer-headline-wrapper {
		margin-bottom: 20px;
		width: 100%;

		@include md {
			width: calc(33% - 20px);
		}

		@include lg {
			width: 245px;
		}

		&--empty {
			margin-bottom: 0;
		}
	}

	&__answer-headline {
		hyphens: auto;
		@include font('Bold', 21px, 26px, normal);
		width: 100%;

		@include md {
			hyphens: initial;
			text-align: center;
		}

		&--mobile {
			@include md {
				display: none;
			}
		}
	}

	&__answers {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include md {
			margin: 0 auto 0 auto;
			flex-direction: row;
		}

		@include md {
			width: 100%;
		}

		@include lg {
			width: 890px;
		}

		&.is-vertical {
			@include md {
				align-items: center;
				flex-direction: column;
				width: 100%;
			}
		}
	}

	&__answer {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		@include <lg {
			&[data-order]:not([data-order='1']),
			&:not([data-order]) + & {
				margin-top: 40px;
			}
		}

		@include md {
			margin-top: initial;
		}

		@include md {
			width: calc(50% - 20px);
		}

		@include lg {
			padding: 0 30px;
			width: 245px;
		}

		.is-vertical & {
			@include lg {
				padding: 30px 0;
				width: 100%;
			}
		}
	}

	&__answer-text {
		@include font('regular', 17px, 20px, normal);

		@include md {
			width: 100%;
			text-align: center;
		}

		.is-vertical & {
			@include md {
				text-align: initial;
				margin-left: 10px;
			}
		}
	}

	&__answer-button {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);

		font-size: 0;
		border-radius: 50%;
		background-color: $white;
		border: 1px solid $grey-2;
		transition: border-color 250ms ease, background-color 250ms ease;
		size: 32px;
		outline: 0;
		margin-bottom: 18px;

		flex-shrink: 0;

		.is-vertical & {
			margin-bottom: 0;
		}

		@include md {
			position: relative;
			left: initial;
			top: initial;
			transform: initial;
		}

		&:before {
			display: none;
			font-size: 14px;
			position: absolute;
			color: $white;
			width: 100%;
			left: 0;
			top: 9px;
			margin: 0;
		}

		&:hover {
			border-color: $orange;
		}

		&:active {
			background-color: $orange;
			border: none;

			&:before {
				display: block;
			}
		}
	}

	// RESULTS

	&__results {
		width: 100%;
	}

	&__result {
		display: none;

		&.is-active {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&__result-image {
		width: 75%;
		height: auto;
		margin-bottom: 50px;

		@include md {
			width: 400px;
		}
	}

	&__result-headline {
		@include font('headline', 16px, 18px, 4.3px);
		width: 100%;
		color: $orange;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 20px;

		@include lg {
			width: 825px;
			padding: 0 $ce-side-padding;
		}
	}

	&__result-title {
		@include font('headline', 34px, 36px, normal);
		width: 100%;
		color: $darkblue;
		text-align: center;
		margin-bottom: 30px;

		@include lg {
			width: 825px;
			padding: 0 $ce-side-padding;
		}
	}

	&__result-copy {
		@include font('regular', 15px, 20px, normal);
		text-align: center;
		margin-bottom: 50px;
		width: 100%;

		@include lg {
			width: 825px;
			padding: 0 $ce-side-padding;
		}
	}

	&__result-share-holder {
		margin-bottom: 80px;
	}

	&__result-detail {
		position: relative;
		@include font('regular', 15px, 20px, normal);
		background-color: $grey-4;
		width: 100%;
		padding: 40px 20px 30px;

		@include lg {
			width: 745px;
			padding: 40px $ce-side-padding 30px;
		}
	}

	&__result-detail-headline {
		@include font('headline', 17px, 19px, normal);
		color: $orange;
		margin-bottom: 30px;
		width: 100%;
	}

	&__result-detail-copy {
		@include font('regular', 15px, 20px, normal);
		width: 100%;
	}
}
