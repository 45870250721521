.countryinformations {
	&__content-element {
		margin-top: 0 !important;

		@include sm {
			margin-top: -70px !important;
		}
	}

	&__search-wrapper {
		background-color: $anthrazit;
		padding: 70px 0;
	}

	&__search-headline {
		color: $white;
		max-width: 700px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 40px;
		@include font('headline', 24px, 26px, normal);

		@include md {
			@include font('headline', 34px, 36px, normal);
		}
	}

	&__search-form {
		max-width: 700px;
		width: 100%;
		margin: 0 auto;
	}

	&__search-input {
		position: relative;

		input {
			border-radius: 12px !important;
			border: none !important;
			height: 46px !important;

			&:not(:placeholder-shown) {
				@include font('bold', 18px, 20px, normal);
			}
		}
	}

	&__search-submit {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		border: none;
		background-color: transparent;
		font-size: 0;
	}

	&__search-result {
		background-color: $white;
		height: 0px;
		overflow: hidden;
		transition: height 0.3s ease-in-out;

		&.is-open {
			padding: 50px 0 40px 0;
			height: auto;
		}
	}

	&__search-result-wrapper {
		max-width: 652px;
		width: 100%;
		margin: 0 auto;
	}

	&__search-result-info {
		margin-bottom: 16px;
		color: $anthrazit;

		@include md {
			margin-bottom: 20px;
		}
	}

	&__search-result-item {
		background-color: $grey-9;
		border-radius: 12px;
		height: fit-content;
		@include font('headline', 16px, 18px, normal);

		@include md {
			@include font('headline', 24px, 26px, normal);
		}

		a {
			text-decoration: none;
			background: none;
			width: 100%;
			display: block;
			height: 100%;
			padding: 15px;
			color: $anthrazit;
			transition: color 0.3s ease-in-out;
		}

		a:hover {
			color: $orange;
		}

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	.accordion__item-content-inner {
		overflow-y: auto;
	}

	&__check-icon {
		color: $green;
	}

	//spezial themen styling
	&__holidays {
		p:not(:first-child) {
			margin-top: 5px !important;
		}

		strong {
			@include font('regular', 16px, 25px, 0.4px);

			@include lg {
				font-size: 15px;
				line-height: 20px;
			}
		}
	}

	&__embassy {
		p:not(:first-child) {
			margin-top: 8px !important;
		}

		br {
			display: none;
		}

		strong {
			margin-top: 20px !important;
			display: block;
		}
	}
}
