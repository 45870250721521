.tarifCompareChangeWidget {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	transition: background-color 250ms ease;
	pointer-events: none;
	z-index: 8;

	@include <lg {
		overflow: auto;
		display: flex;
		flex-direction: column;
	}

	&.show {
		pointer-events: auto;
		@include <lg {
			background-color: rgba(0, 0, 0, 0.1);
		}
	}

	&__inner-wrapper {
		margin-top: auto;
	}

	&__inner {
		margin-top: 150px;
		pointer-events: auto;
		background: white;
		border-radius: 15px 15px 0 0;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
		width: 100%;
		padding: 41px 36px 54px;
		overflow: hidden;
		flex-direction: column;
		display: none;

		@include <lg {
			display: flex;
			transform: translate3d(0, 100%, 0);
			visibility: hidden;
			transition: transform 0.25s ease, visibility 0.25s ease;
		}

		^&.show & {
			display: flex;

			@include <lg {
				transform: translate3d(0, 0, 0);
				visibility: visible;
			}
		}

		@include lg {
			position: absolute;
			padding: 41px 60px 54px;
			border-radius: 15px 3px 5px 5px;
			left: 100px;
			bottom: 0;
			width: 375px;
			transition: transform 0.25s ease;
			transform: translate3d(0, -20px, 0);

			.tarifCompareFooter.is-visible ~ ^& & {
				transform: translate3d(0, -102px, 0);
			}
		}
	}

	&__headline {
		@include font('headline', 20px, 25px, -0.0125em);
		color: $anthrazit;
	}

	&__close-button {
		position: absolute;
		right: 38px;
		top: 45px;
		background: transparent;
		border: 0;
		color: $orange;
		&:before {
			font-size: 18px;
			margin-right: 0 !important;
		}
	}

	&__switch-group {
		border-radius: 3px;
		padding: 17px;
		background-color: $cornflower;
		color: $typo-color;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include font('normal', 14px, 18px, 0.0025em);

		margin-top: 33px;
	}

	&__tarifs {
		display: flex;
		gap: 2px;
		margin-top: 30px;
	}

	&__tarif-group-button {
		position: relative;
		flex: 1 1 10px;
		border-radius: 15px 3px 5px 5px;
		border: none;
		text-align: left;
		padding: 18px 0px 18px 15px;
		background-color: $cornflower;
		display: flex;
		flex-direction: column;

		&:after {
			content: '';
			display: block;
			position: absolute;
			size: 20px;
			border: 1px solid $anthrazit;
			left: 18px;
			top: 18px;
			border-radius: 50%;
		}

		&.is-selected {
			outline: 2px solid $signal-green;
			outline-offset: -1px;
			background-color: $white;

			&:after {
				content: unset;
			}

			&:before {
				@include acv-icons-mixin();
				position: absolute;
				left: 18px;
				top: 18px;
				content: '$acv-icons-var-checkmark';
				font-size: 20px;
				color: $signal-green;
			}
		}
	}

	&__tarif-group-button-head {
		@include font('bold', 16px, 20px, 0.0025em);
		color: $anthrazit;
		margin-top: 38px;
	}

	&__tarif-group-button-desc {
		@include font('regular', 12px, 15px, 0.0025em);
		color: $grey-1;
		padding-right: 10px;
		margin-top: 12px;
		hyphens: auto;
	}

	&__compare-headline {
		@include font('regular', 12px, 20px, normal);
		color: $grey-1;
		margin-top: 38px;
		margin-bottom: 18px;

		@include md {
			span:first-child {
				display: none;
			}
		}

		@include <md {
			span:last-child {
				display: none;
			}
		}
	}

	&__compare-list {
		display: flex;
		flex-direction: column;
		color: $anthrazit;
		@include font('regular', 15px, 22px, 0.0025em);
		border-top: 1px solid $grey-3;
	}

	&__compare-list-item {
		position: relative;
		display: flex;
		flex: 1 0 auto;
		height: 62px;
		border-bottom: 1px solid $grey-3;
	}

	&__compare-list-label {
		position: absolute;
		left: 0;
		top: 0;
		size: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;

		@include <md {
			cursor: pointer;
		}

		@include md {
			pointer-events: none;
		}
	}

	&__compare-list-radio {
		flex: 0 0 auto;

		@include md {
			display: none !important;
		}
	}
}
