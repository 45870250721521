/*===================================
=            featureBox            =
===================================*/

.featureBox {
	// text-align: center;

	&__image {
		width: 100%;
		height: auto;
		fill: $white;

		* {
			fill: $white;
		}

		.wrapper-white section:not([force-white]) &,
		[data-style='white'] section:not([force-white]) & {
			fill: $icons;

			* {
				fill: $icons;
			}
		}

		^&__list[data-mobile-slider='1'] & {
			@include <sm {
				display: none;
			}
		}
	}

	&__icon {
		height: 34px;
		width: auto;
		margin-bottom: 10px;

		@include sm {
			display: none;
		}
	}

	&__intro {
		text-align: left;
		display: flex;
		flex-direction: column;

		&:not(:last-child) {
			margin-bottom: 40px;
		}

		@include sm {
			padding: 0 $ce-side-padding;
		}
	}

	&__buttons {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include sm {
			display: block;
		}
	}

	&__main {
		text-align: center;

		&[data-mobile-slider='1'] {
			@include <sm {
				margin-top: 30px;
				margin-left: -15px;
				margin-right: -15px;
			}
		}

		&:not(:last-child) {
			margin-bottom: 50px;
		}

		&[data-mobile-slider='1']:not(.swiper-container-initialized) {
			visibility: hidden;
		}
	}

	&__list {
		&:not([data-mobile-slider='1']) {
			display: inline-block;
			@include sm {
				width: calc(1170 / (1170 + 2 * strip($ce-side-padding)) * 100%);
			}
		}

		&[data-mobile-slider='1'] {
			@include <sm {
				left: 15px;
			}
		}

		@include sm {
			display: inline-block;
		}
	}

	&__item {
		display: inline-block;
		padding: 15px;
		vertical-align: top;
		width: 50%;

		@include sm {
			width: calc(1 / 3 * 100%);
		}

		@include lg {
			padding: 25px;
			width: 20%;
		}

		^&__list[data-mobile-slider='1'] & {
			@include <sm {
				border-radius: 12px;
				@include font('', 16px, 25px, normal);
				text-align: left;
				padding: 20px;
				height: unset;
				min-height: 174px;

				&--grey {
					background-color: $grey-4;
					^^^&__copy {
						color: $anthrazit;
					}
				}

				&--white {
					background-color: $white;
					^^^&__copy {
						color: $anthrazit;
					}
				}

				&--anthracite {
					background-color: $anthrazit;
					^^^&__copy {
						color: $white;
					}
				}
			}
		}
	}

	&__copy {
		color: $white;
		@include font('regular', 15px, 20px, 0);

		.wrapper-white section:not([force-white]) &,
		[data-style='white'] section:not([force-white]) & {
			color: $grey-1;
		}
	}
}

/*=====  End of feature-box  ======*/
