.ASD-custom-select {
	z-index: 50;
	background-color: white;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.22);

	&__option {
		padding: 10px 17px;
		display: block;
		width: 100%;
		border: 0;
		background-color: transparent;
		text-align: left;
		transition: background-color 250ms ease;
		color: inherit;

		&:hover,
		&.is-selected {
			background-color: $anthracite-20;
		}

		&--tariff {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: 2px;
	}

	&__subline {
		@include font('regular', 14px, 120%, normal);
	}

	&__price {
		display: flex;
		align-items: center;
		gap: 2px;
		color: $green;
		@include font('headline', 28px, 100%, normal);
		padding-top: 4px;
		margin-right: 1px;
	}

	&__currency {
		@include font('regular', 20px, 100%, normal);
		padding-bottom: 5px;
	}
}
