.frame-type-text,
.cke_editable,
.rte-copy {
	@include font('regular', 16px, 25px, normal);

	@include sm {
		font-size: 15px;
		line-height: 20px;
	}

	a:not(.btn, .cta, .ASD-button) {
		padding-bottom: 2px;
	}

	b,
	strong {
		font-family: 'Lato bold';
	}

	ul {
		list-style-type: disc;
		margin-left: 1.25em;
		.ce-textpic.ce-left.ce-intext & {
			margin-left: 0;
		}
	}

	ol {
		list-style-type: decimal;
		margin-left: 1.25em;
	}

	* + ol,
	* + ul {
		margin-top: 15px;
	}

	> * + :--headlines {
		margin-top: 40px;
		@include sm {
			margin-top: 50px;
		}
	}

	:--headlines {
		line-height: 26px;
		color: $darkblue;
	}

	ul {
		&.seperator {
			margin-left: 0;
			@include md {
				margin-bottom: 40px;
			}
			li {
				margin-left: 20px;
				padding-top: 12px;
				padding-bottom: 12px;

				@include <sm {
					padding-top: 13px;
					padding-bottom: 13px;
					font-size: 16px;
				}

				&:first-child {
					padding-top: 0;
				}
				&:last-child {
					padding-bottom: 0;
				}
				&.checkmark {
					padding-left: 30px;
					margin-left: 0;
				}
				&:before {
					left: 0;
					top: 0.7em;
				}
				&:first-child::before {
					top: 0;
				}
			}
			li + li {
				margin-top: 0;
				border-top: 1px solid #979797;
			}
		}

		&.narrow-list {
			margin-top: 0;
			@include md {
				margin-top: 3px;
			}
			margin-left: 12px;
			list-style: none;

			li + li {
				margin-top: 0px;

				@include md {
					margin-top: 2px;
				}
			}

			li:before {
				content: '•';
				position: absolute;
				left: -11px;
				top: 0;
			}
		}
	}

	li {
		position: relative;
		& + li {
			margin-top: 12px;
		}
		&.checkmark {
			list-style-type: none;
			margin-left: 10px;
			text-align: left;

			.ce-textpic.ce-left.ce-intext & {
				margin-left: 0;
			}

			@include <sm {
				margin-top: 13px;
			}

			.header & {
				margin-top: 14px;

				@include sm {
					margin-top: 16px;
				}
			}

			&:before {
				@include acv-icons-mixin();
				content: '$acv-icons-var-checkmark';
				position: absolute;
				line-height: 1;
				left: -28px;
				top: 2px;
				font-size: 1.333em;

				.ce-textpic.ce-left.ce-intext & {
					display: inline-block;
					position: relative;
					left: 0;
					margin-right: 10px;
				}

				.header & {
					left: -30px;
					font-size: 1.222em;

					@include sm {
						left: -35px;
						font-size: 1.1428em;
					}
				}
			}
		}

		&.checkmark--green {
			list-style-type: none;
			margin-left: 15px;
			&:before {
				@include acv-icons-mixin();
				content: '$acv-icons-var-checkmark';
				position: absolute;
				left: -40px;
				font-size: 1.1428em;
				color: $signal-green;
			}
		}

		&.checkmark--green-app {
			list-style-type: none;
			margin-left: 12px;
			&:before {
				@include acv-icons-mixin();
				content: '$acv-icons-var-checkmark-simple';
				margin-right: 0;
				position: absolute;
				left: -30px;
				top: 5px;
				font-size: 0.75em;
				color: $signal-green;

				@include lg {
					font-size: 13px;
				}
			}
		}

		&.checkmark--big-green-app {
			list-style-type: none;
			margin-left: 34px;
			&:before {
				@include acv-icons-mixin();
				content: '$acv-icons-var-checkmark-simple';
				margin-right: 0;
				position: absolute;
				left: -44px;
				top: 9px;
				font-size: 0.96em;
				color: $signal-green;
			}
		}

		&.checkmark--green-big {
			&:before {
				color: $signal-green;
			}
		}

		&.checkmark--orange {
			&:before {
				color: $orange;
			}
		}

		&.cross {
			list-style-type: none;
			margin-left: 25px;

			.ce-textpic.ce-left.ce-intext & {
				margin-left: 0;
			}

			&:before {
				@include acv-icons-mixin();
				content: '$acv-icons-var-cross';
				position: absolute;
				left: -40px;
				font-size: 1.2em;
				color: $signal-red;

				.ce-textpic.ce-left.ce-intext & {
					display: inline-block;
					position: relative;
					left: 0;
					margin-right: 10px;
				}
			}
		}
	}

	> * + p {
		margin-top: 15px;
		@include sm {
			margin-top: 20px;
		}
	}

	.text-left {
		text-align: left;
	}
	.text-right {
		text-align: right;
	}
	.text-center {
		text-align: center;
	}
	.text-justify {
		text-align: justify;
	}

	.rte-orange {
		color: $orange;
	}

	.rte-white {
		color: $white;
	}

	.dotted-underline {
		text-decoration: underline dotted;
		text-underline-position: under;
	}

	.rte-meta {
		@include font('regular', 15px, 20px, 0px);
		@include lg {
			font-size: 13px;
			line-height: 16px;
		}

		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	.wrapper-white &,
	&--white {
		a:not(.btn, .cta) {
			background-image: linear-gradient($orange, $orange);
			&:hover {
				background-image: linear-gradient($orange, $orange);
				color: $orange;
			}
		}
	}

	&--blue {
		a:not(.btn, .cta) {
			background-image: linear-gradient($orange, $orange);
			&:hover {
				background-image: linear-gradient($orange, $orange);
				color: $orange;
			}
		}
	}

	&--orange {
		a:not(.btn, .cta) {
			background-image: linear-gradient($darkblue, $darkblue);
			&:hover {
				color: $darkblue;
			}
		}
	}

	.footer__nav-title {
		color: $darkblue;
		text-transform: uppercase;
		@include font('bold', 16px, 19px, 0.8px);
		padding-bottom: 25px;

		@include md {
			@include font('bold', 18px, 19px, normal);
		}
	}
}

.twoColGrid__col--white {
	a:not(.btn, .cta) {
		background-image: linear-gradient($orange, $orange);
		&:hover {
			background-image: linear-gradient($orange, $orange);
			color: $orange;
		}
	}
}

.cke_editable {
	a:not(.btn, .cta) {
		background-image: linear-gradient($orange, $orange);
		&:hover {
			background-image: linear-gradient($orange, $orange);
			color: $orange;
		}
	}
}
