.wahl-o-mat {
	&__headline {
		@include <sm {
			font-size: 29px;
			margin-bottom: 24px;
		}

		@include md {
			margin-bottom: 40px;
		}
	}

	&__bar-wrapper {
		border-radius: 5px;
		background-color: $grey-4;
		width: 100%;
		height: 28px;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		margin-top: 9px;
		align-items: center;

		&.have-zero-score {
			justify-content: center;
		}
	}

	&__bar {
		background-color: $darkblue;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		&:not([data-score='0']) {
			margin-right: 10px;
		}

		&[data-score='0'] {
			display: none;
		}
	}

	&__bar-name {
		font-size: 21px;
		color: $darkblue !important;
	}

	&__bar-score {
		color: white;
		@include font('black', 15px, 14px, 0.8px);
		^&__bar-wrapper.have-outer-score & {
			display: none;
		}

		&--outer {
			color: $darkblue;
			display: none;
			^^&__bar-wrapper.have-outer-score & {
				display: block;
			}
		}
	}

	&__item-bar {
		padding: 26px 20px 23px;
	}

	&__arrow {
		position: absolute;
		right: 17px;
		top: 0;
		&:after {
			font-size: 22px;
		}
	}

	&__parties-headline {
		padding: 35px 20px 25px;
		border-top: 2px solid $grey-3;
		width: 100%;

		@include <sm {
			font-size: 20px;
			padding: 22px 20px 21px;
		}
	}

	&__content {
		padding: 31px 20px 23px;
	}

	&__content-header {
		display: flex;
		margin-bottom: 13px;
	}

	&__content-link {
		margin-top: 20px;
		display: inline-block;
	}

	&__accordion {
		width: 100%;
	}

	&__logo {
		height: 91px;
		width: auto;
		margin-bottom: 23px;

		@include md {
			height: 91px;
			margin-bottom: 20px;
		}
	}

	&__result-copy {
		width: 100%;
		padding: 0px 44px;
		font-size: 19px;

		line-height: 25px;
		color: $grey-1;
		margin-bottom: 60px;

		@include md {
			margin-bottom: 151px;
			padding: 0;
			font-size: 22px;
			max-width: 60%;
		}
	}

	&__chart-result {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 86px;

		@include md {
			margin-bottom: 119px;
		}
	}

	&__chart-wrapper {
		position: relative;
		size: 205px;

		@include md {
			size: 247px;
		}
	}

	&__chart-result-name {
		@include font('bold', 13px, 14px, normal);
		position: absolute;
		left: 50%;
		top: calc(50% + 27px);
		width: 47%;
		transform: translate3d(-50%, -50%, 0);

		text-align: center;
		text-transform: uppercase;

		@include md {
			width: 44%;
			font-size: 14px;
			line-height: 18px;
			top: calc(50% + 33px);
			font-size: 14px;
		}
	}

	&__chart {
		position: absolute;
		overflow: visible;
		transform: translate3d(-50%, -50%, 0) scale(1.05);
		left: 50%;
		top: 50%;

		@include md {
			transform: translate3d(-50%, -50%, 0) scale(1.3);
		}
	}

	&__chart-fill {
		animation-timing-function: ease-in-out;
		animation: circle-chart-fill 1s reverse;
		transform: rotate(90deg);
		transform-origin: center;
		stroke: $darkblue;
	}

	&__chart-background,
	&__chart-fill {
		stroke-width: 4.8px;
	}

	@keyframes circle-chart-fill {
		to {
			stroke-dasharray: 0 100;
		}
	}

	&__chart-number {
		position: absolute;
		left: 50%;
		top: calc(50% - 11px);
		transform: translate3d(-50%, -50%, 0);
		font-size: 36px;

		@include md {
			top: calc(50% - 12px);
			font-size: 38px;
		}
	}
}
