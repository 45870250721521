.footer {
	margin-top: 50px;
	[data-page-key='acv-sustainability'] & {
		margin-top: 11px;
	}

	&--tiny {
		margin-top: 0;
	}

	&__wrapper {
		border-top: 1px solid rgba(#fff, 0.2);
		padding-top: 40px;

		@include md {
			padding-top: 50px;
		}
	}

	&__nav {
		width: 100%;

		@include md {
			padding-bottom: 50px;
		}
	}

	&__nav-list {
		display: flex;
		flex-direction: column;

		@include md {
			display: block;
		}
	}

	&__nav-list-item {
		@include md {
			text-align: left;
			padding-bottom: 0;

			&:last-child {
				padding-bottom: 0;
			}

			&:nth-child(n + 3) {
				padding-top: 40px;
			}
		}

		@include lg {
			&:nth-child(n + 3) {
				padding-top: 0;
			}
		}

		^&--small &:first-child {
			display: none;

			@include lg {
				display: inline-block;
			}
		}

		^&--small &:nth-child(2) {
			padding-bottom: 0;
		}
	}

	&__nav-site-list {
		padding-bottom: 5px;
	}
	&__nav-site-list-item {
		&:not(:last-child) {
			padding-bottom: 24px;
		}
	}

	&__nav-title {
		color: $white;
		@include font('bold', 16px, 19px, 0.8px);
		padding-bottom: 25px;

		@include md {
			@include font('bold', 18px, 19px, normal);
		}

		&--sub {
			padding-top: 30px;

			@include md {
				padding-top: 20px;
			}
		}

		&--rte {
			display: inline-block;
			padding-bottom: 15px !important;
		}
	}

	&__nav-link,
	&__nav-copy &__nav-link,
	&__tiny-menu-link {
		color: $white;
		@include font('regular', 16px, 19px, 0);
		font-size: 16px !important;
		background-image: none;
		padding: 4px 0;
		text-decoration: none;

		@include md {
			padding: 2px 0;
		}

		&:hover {
			color: $white;
			background-image: linear-gradient($white, $white);
			background-size: 1px 1px;
		}
	}

	&__nav-copy {
		color: $white;
		@include font('regular', 16px, 24px, 0);

		a {
			text-decoration: none;
			background-image: linear-gradient($white, $white);
			padding: 2px 0;
			background-size: 1px 1px;

			&:hover {
				background-image: none;
				color: $white;
			}
		}
	}

	&__nav-copy-top {
		@include lg {
			margin-top: 34px;
		}
	}

	&__bottom {
		width: 100%;
		min-height: 110px;
		padding-bottom: 50px;

		^&--tiny & {
			padding-bottom: 0;
			min-height: unset;
		}
	}

	&__bottom-inner {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include md {
			flex-direction: row;
		}

		@include lg {
			padding: 0;
		}

		^&--small & {
			@include lg {
				justify-content: center;
			}
		}
	}

	&__border-wrapper {
		@include md {
			display: none !important;
		}
	}

	&__border {
		width: 100%;
		border-top: 1px solid $white;
		opacity: 0.2;
		display: block;
		padding-bottom: 50px;

		@include md {
			padding-bottom: 32px;
		}

		&--desktop {
			display: none;

			@include md {
				display: block;
			}
		}
	}

	&__brand {
		width: 262px;
		padding-bottom: 44px;

		@include md {
			width: auto;
			padding-bottom: 0px;
		}

		@include lg {
		}

		^&--small & {
			@include md {
				margin-top: 40px;
			}
		}

		^&--tiny & {
			display: none;

			@include lg {
				display: block;
			}
		}
	}

	&__brand-image {
		^&--tiny & {
			height: 23px;
		}
	}

	&__brand-mobile-caption {
		display: inline-block;
		width: 84px;

		@include lg {
			display: none;
		}
	}

	&__brand-second-caption {
		display: none;

		@include lg {
			max-width: 130px;
			vertical-align: middle;
			display: inline-block;
			max-width: none;
			height: 48px;
			margin-left: 6px;
		}
	}

	&__social-menu {
		display: flex;
		width: auto;
	}
	&__social-menu-item {
		padding: 0 12px;

		&:first-child {
			padding-left: 0px;
		}
		&:last-child {
			padding-right: 0px;
		}

		@include md {
			padding: 0 10px;
		}
	}
	&__social-menu-link {
		text-decoration: none;
		background: transparent;

		&:hover {
			background: transparent;
		}
	}
	&__social-menu-icon {
		color: $white;
		transition: color 250ms ease;
		font-size: 25px;

		&::before {
			margin-right: 0 !important;
			font-size: 21px;
			vertical-align: middle !important;
		}

		&.acv-icons--logo-x::before,
		&.acv-icons--logo-youtube::before {
			font-size: 19px;
		}

		&:hover {
			color: $darkblue;
		}

		@include md {
			font-size: 20px;
		}
	}

	&__social-text {
		color: $white;
		@include font('', 14px, 14px, 0.5px);
		margin-bottom: 20px;
		text-align: left;
		@include md {
			margin-bottom: 11px;
		}
	}

	&__social-menu-wrapper {
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include md {
			display: block;
			width: 50%;
		}
		@include lg {
			width: auto;
		}
		@include xl {
			width: 25%;
		}
	}

	&__certificate {
		position: relative;
	}

	&__certificate-wrapper {
		display: flex;
		align-items: center;
		gap: 36px;
		margin-bottom: 20px;

		@include md {
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 0px;
		}
		a {
			font-size: 0;
			text-decoration: none;
			background: transparent !important;
		}
	}

	&__certificate-image {
		height: auto;
		&--1 {
			max-width: 168px;
		}
		&--2 {
			max-width: 83px;
		}
	}

	&__tiny-menu-wrapper {
		width: 100%;
		padding: 16px 18px 18px;

		display: flex;
		justify-content: space-between;
		align-items: center;

		@include lg {
			padding: 31px 24px 31px 52px;
		}
	}

	&__tiny-menu {
		display: grid;
		gap: 13px;
		grid-template-columns: 1fr 1fr;
		width: 100%;

		@include lg {
			width: auto;
			gap: 69px;
			display: flex;
		}
	}

	&__tiny-menu-link {
		font-size: 13px;
		line-height: 16px;
	}

	.accordion-container {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		width: 100%;

		&:last-child:not(:first-child) {
			@include md {
				padding-top: 40px;
			}
		}
	}

	.ac {
		overflow: hidden;

		&:first-child {
			@include md {
				grid-column: 1 / 2;
				grid-row: 1 / 3;
			}
		}
	}

	.ac-trigger {
		color: $white;
		@include font('bold', 16px, 19px, 0.8px);
		padding-bottom: 20px;
		padding-top: 20px;
		border: none;
		background: transparent;
		border-top: 1px solid rgba(#fff, 0.2);
		width: 100%;
		text-align: left;
		position: relative;

		@include md {
			@include font('bold', 18px, 19px, 0.8px);
			pointer-events: none;
			padding-bottom: 20px;
			padding-top: 0;
			border: none;
		}

		&:after {
			@include <md {
				@include acv-icons-mixin();
				content: '$acv-icons-var-nav-mobile-chevron-right';
				font-size: 14px;
				color: $white;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%) rotate(90deg);
				transition: transform 0.6s;
			}
		}

		&[aria-expanded='true'] {
			&:after {
				transform: translateY(-50%) rotate(-90deg);
			}
		}
	}

	.ac-panel {
		height: 0;

		@include md {
			height: auto !important;
		}

		&:after {
			@include <md {
				content: '';
				display: block;
				height: 45px;
			}
		}
	}
}
